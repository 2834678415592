import React, { useState, useEffect, useRef } from "react"; // React, useState, useEffect
import "bootstrap/dist/css/bootstrap.min.css";
import "../Profilepages/ProfilePage.css";
import { Button } from "@mui/material"; // Button from MUI
import { NavLink } from "react-router-dom"; // NavLink from react-router-dom
import { useTranslation } from "react-i18next"; // useTranslation for i18n
import { server } from "../../../common"; // Assuming 'server' is defined in the common file
import { AxiosError } from "axios"; // AxiosError from axios
import { toast } from "react-toastify"; // toast from react-toastify
import { useSelectAccess } from "../../../store/stateFunctions"; // Custom hook for user state
import { Modal } from "react-bootstrap";

const ProfilePage = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const user = useSelectAccess("User");
  const fileInputRef = useRef();
  const [profielphoto, setprofilephoto] = useState([]);

  const [photoModal, setPhoto] = useState(false);
  const closePhotoModal = () => setPhoto(false);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
  };

  useEffect(() => {
    getData();
    getProfilePhoto();
  }, []);

  const getData = () => {
    server
      .get("/user/userprofile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setProfile(response.data);
        }
      })
      .catch((error) => {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else {
          toast.error(error.response?.data?.error || "An error occurred");
        }
      })
      .finally(() => setLoading(false));
  };

  // Get
  const getProfilePhoto = () => {
    server
      .get("/user/getallprofile_photo", {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          setprofilephoto(response.data);
          // getProfilePhoto();
        }
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  const AddProfilePicture = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", fileInputRef.current.files[0]);
    try {
      if (user.profilePhotoId) {
        // If user already has a profile photo, update it
        await server.put(
          `/user/updateprofile_photo/${profielphoto.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: user.authToken,
            },
          }
        );
        toast.success("Profile Photo updated successfully");
      } else {
        // If user does not have a profile photo, add it
        const response = await server.post("/user/addprofile_photo", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: user.authToken,
          },
        });

        toast.success("Profile Photo added successfully");
      }

      // resetForm();
      closePhotoModal();
    } catch (error) {
      if (error instanceof AxiosError && error.response?.data?.message)
        toast.error(error.response.data.message);
      else if (error.response?.data?.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Failed to connect to the server");
        console.log(error.message);
      }
    }
  };

  if (loading || !profile) {
    return <p>Loading profile...</p>;
  }

  return (
    <div className="row gutters-sm mt-4">
      <div className="col-md-4 mb-3">
        <div className="card">
          <div className="card-body">
            <div className="d-flex flex-column align-items-center text-center">
              {/* <img
                src="https://bootdey.com/img/Content/avatar/avatar7.png"
                alt="Admin"
                className="rounded-circle"
                width="150"
              /> */}

              {profielphoto?.map((photo) => (
                <img
                  key={photo.id} // Use a unique key for each image if available
                  src={photo.image}
                  alt="avatar"
                  className="rounded-circle img-fluid cropped-image"
                  style={{ width: 150 }}
                />
              ))}
              <div className="mt-3">
                <h4>{profile.fullname}</h4>
                <div className="btn btn-info" onClick={() => setPhoto(true)}>
                  Upload Profile Photo
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-8">
        <div className="card mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-3">
                <h6 className="mb-0">{t("profile.fullName")}:</h6>
              </div>
              <div className="col-sm-9 text-secondary">
                <span>{profile.fullname}</span>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-3">
                <h6 className="mb-0">{t("profile.mobile")}:</h6>
              </div>
              <div className="col-sm-9 text-secondary">{profile.mobile}</div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-3">
                <h6 className="mb-0">{t("profile.email")}:</h6>
              </div>
              <div className="col-sm-9 text-secondary">{profile.email}</div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-3">
                <h6 className="mb-0">{t("profile.dob")}:</h6>
              </div>
              <div className="col-sm-9 text-secondary">{profile.dob}</div>
            </div>
            <hr />

            <div className="row">
              <div className="col-sm-3">
                <h6 className="mb-0">{t("profile.adhar")}:</h6>
              </div>
              <div className="col-sm-9 text-secondary">
                {profile.adhar_number}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-3">
                <h6 className="mb-0">{t("profile.pancard")}:</h6>
              </div>
              <div className="col-sm-9 text-secondary">{profile.pancard}</div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-3">
                <h6 className="mb-0">{t("profile.ward")}:</h6>
              </div>
              <div className="col-sm-9 text-secondary">{profile.ward_no}</div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-3">
                <h6 className="mb-0">{t("profile.division")}:</h6>
              </div>
              <div className="col-sm-9 text-secondary">{profile.division}</div>
            </div>
            <hr />

            <div className="row">
              <div className="text-center mt-4">
                <Button variant="contained" color="primary">
                  <NavLink
                    to="/profile/editprofile"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    {t("profile.editProfile")}
                  </NavLink>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={photoModal} onHide={closePhotoModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Upload Your Profile Photo</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => AddProfilePicture(e, user)}>
            <div className="form-outline mb-2">
              <input
                name="image"
                margin="dense"
                type="file"
                className="form-control"
                onChange={handleFileChange}
                ref={fileInputRef}
                fullWidth
                required
              />
            </div>
            <Button variant="contained" className="ms-2" type="submit">
              Upload
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ProfilePage;
