import React from "react";
import SchemeOneRejectedApplications from "../../Administration/SchemeOne/SchemeOneRejectedApplications";

const ChananiOneRejectedApplications = () => {
  return (
    <div>
      <SchemeOneRejectedApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiOneRejectedApplications;
