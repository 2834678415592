import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { logoutAccess, useSelectAccess } from "../../store/stateFunctions";


const UserProfileSidebar = () => {
  const user = useSelectAccess("User");

  const sidebarStyle = {
    display: "flex",
    flexDirection: "column",
    borderRight: "1px solid #ddd",
    maxWidth: "300px",
    width: "100%",
    padding: "10px",
    transition: "max-width 0.3s ease, padding 0.3s ease",
    // backgroundColor: "#f0f4f8",
  };

  const linkStyle = {
    display: "block",
    padding: "10px 15px",
    color: "#0077b6",
    backgroundColor: "white",
    textDecoration: "none",
    border: "1px solid #ddd",
    marginBottom: "5px",
    borderRadius: "4px",
    transition: "background-color 0.3s ease, color 0.3s ease",
    width: "100%",
    textAlign: "left",
  };

  const activeLinkStyle = {
    backgroundColor: "#98c1d9",
    borderLeft: "3px solid #3d5a80",
    color: "black",
  };

  const { t } = useTranslation();

  return (
    <div style={sidebarStyle}>
      <NavLink
        to="/profile/profilepage"
        style={({ isActive }) =>
          isActive ? { ...linkStyle, ...activeLinkStyle } : linkStyle
        }
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = "#0077b6";
          e.target.style.color = "white";
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = "white";
          e.target.style.color = "#0077b6";
        }}
      >
        {t("userprofilesidebar.myProfile")}
      </NavLink>
      {/* <NavLink
        to="/profile/editprofile"
        style={({ isActive }) =>
          isActive ? { ...linkStyle, ...activeLinkStyle } : linkStyle
        }
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = "#0077b6";
          e.target.style.color = "white";
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = "white";
          e.target.style.color = "#0077b6";
        }}
      >
        {t("userprofilesidebar.editProfile")}
      </NavLink> */}
      <NavLink
        to="/profile/trackapplication"
        style={({ isActive }) =>
          isActive ? { ...linkStyle, ...activeLinkStyle } : linkStyle
        }
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = "#0077b6";
          e.target.style.color = "white";
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = "white";
          e.target.style.color = "#0077b6";
        }}
      >
        {t("userprofilesidebar.trackApplication")}
      </NavLink>

      {/* <NavLink
        to="/profile/totalapplications"
        style={({ isActive }) =>
          isActive ? { ...linkStyle, ...activeLinkStyle } : linkStyle
        }
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = "#0077b6";
          e.target.style.color = "white";
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = "white";
          e.target.style.color = "#0077b6";
        }}
      >
        {t("userprofilesidebar.Applicationstatus")}
      </NavLink> */}
      <NavLink
        to="/profile/myappliedschemes"
        style={({ isActive }) =>
          isActive ? { ...linkStyle, ...activeLinkStyle } : linkStyle
        }
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = "#0077b6";
          e.target.style.color = "white";
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = "white";
          e.target.style.color = "#0077b6";
        }}
      >
        {t("userprofilesidebar.MyAppliedSchemes")}
      </NavLink>

      {/* <NavLink
        to="/profile/canceledforms"
        style={({ isActive }) =>
          isActive ? { ...linkStyle, ...activeLinkStyle } : linkStyle
        }
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = "#0077b6";
          e.target.style.color = "white";
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = "white";
          e.target.style.color = "#0077b6";
        }}
      >
        {t("userprofilesidebar.myCanceledApplications")}
      </NavLink> */}

      {/* <NavLink
        to="/profile/declarationforms"
        style={({ isActive }) =>
          isActive ? { ...linkStyle, ...activeLinkStyle } : linkStyle
        }
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = "#0077b6";
          e.target.style.color = "white";
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = "white";
          e.target.style.color = "#0077b6";
        }}
      >
        {t("userprofilesidebar.download")}
      </NavLink> */}

      <NavLink
        to="/profile/contactandsupport"
        style={({ isActive }) =>
          isActive ? { ...linkStyle, ...activeLinkStyle } : linkStyle
        }
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = "#0077b6";
          e.target.style.color = "white";
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = "white";
          e.target.style.color = "#0077b6";
        }}
      >
        {t("userprofilesidebar.contactAndSupport")}
      </NavLink>
      <NavLink
      
        onClick={() => logoutAccess("User")}
        style={({ isActive }) =>
          isActive ? { ...linkStyle, ...activeLinkStyle } : linkStyle
        }
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = "#0077b6";
          e.target.style.color = "white";
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = "white";
          e.target.style.color = "#0077b6";
        }}
      >
        {t("navbar.logout")}
      </NavLink>

      {/* 
      <style>{`
        @media (max-width: 768px) {
          div {
            position: relative;
            border-right: none;
            border-bottom: 1px solid #ddd;
          }
          ${JSON.stringify(sidebarStyle).replace(/"/g, "")}
          {
            padding: 5px;
          }
        }
      `}</style> */}
    </div>
  );
};

export default UserProfileSidebar;
