import React from "react";
import SchemeOnePendingApplications from "../../Administration/SchemeOne/SchemeOnePendingApplications";

const ShaskiySamitiSchemeOnePendingApplications = () => {
    return (
        <div>
            <SchemeOnePendingApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeOnePendingApplications;
