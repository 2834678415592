import React from "react";
import { Routes, Route } from "react-router-dom";

import "./i18n.js";

// user side
import Home from "./pages/Home/Home";
import FeaturingSchemes from "./Components/FeaturingSchemes/FeaturingSchemes";
import InfoCard from "./Components/Card/InfoCard";
// import Eligibility from "./Components/Eligibility/Eligibility";
import HomepageContainer from "./containers/HomeContainer";
import ContactEntries from "./Administration/ContactEntries";
import Allschemespage from "./pages/Allschemes/Allschemespage";
import Graphdashboard from "./Administration/dashboard/GraphDashboard/Graphdashboard";
import FinalPermission from "./pages/profile/FinalPermission/Finalpermission.jsx";

// admin side
import SuperAdminLogin from "./superadmin/SuperAdminLogin";
import SuperAdminContainer from "./containers/SuperAdminContainer";
import SuperAdminDashboard from "./superadmin/SuperAdminDashboard";
import SuperAdminAddSlider from "./superadmin/SuperAdminAddSlider";
import SuperAdminManageUser from "./superadmin/SuperAdminManageUser";

// Schemeone
import SuperAdminSchemeOneTotalApplications from "./superadmin/superadminschemeone/SuperAdminSchemeOneTotalApplications";
import SuperAdminSchemeOneApprovedApplications from "./superadmin/superadminschemeone/SuperAdminSchemeOneApprovedApplications.jsx";
import SuperAdminSchemeOnePendingApplications from "./superadmin/superadminschemeone/SuperAdminSchemeOnePendingApplications.jsx";
import SuperAdminSchemeOneRejectedApplications from "./superadmin/superadminschemeone/SuperAdminSchemeOneRejectedApplications.jsx";

// SchemeTwo
import SuperAdminSchemeTwoTotalApplications from "./superadmin/superadminschemetwo/SuperAdminSchemeTwoTotalApplications";
import SuperAdminSchemeTwoApprovedApplications from "./superadmin/superadminschemetwo/SuperAdminSchemeTwoApprovedApplications.jsx";
import SuperAdminSchemeTwoPendingApplications from "./superadmin/superadminschemetwo/SuperAdminSchemeTwoPendingApplications.jsx";
import SuperAdminSchemeTwoRejectedApplications from "./superadmin/superadminschemetwo/SuperAdminSchemeTwoRejectedApplications.jsx";

// Schemethree
import SuperAdminSchemeThreeTotalApplications from "./superadmin/superadminschemethree/SuperAdminSchemeThreeTotalApplications";
import SuperAdminSchemeThreeApprovedApplications from "./superadmin/superadminschemethree/SuperAdminSchemeThreeApprovedApplications.jsx";
import SuperAdminSchemeThreePendingApplications from "./superadmin/superadminschemethree/SuperAdminSchemeThreePendingApplications.jsx";
import SuperAdminSchemeThreeRejectedApplications from "./superadmin/superadminschemethree/SuperAdminSchemeThreeRejectedApplications.jsx";

// Schemefour
import SuperAdminSchemeFourTotalApplications from "./superadmin/superadminschemefour/SuperAdminSchemeFourTotalApplications.jsx";
import SuperAdminSchemeFourApprovedApplications from "./superadmin/superadminschemefour/SuperAdminSchemeFourApprovedApplications.jsx";
import SuperAdminSchemeFourPendingApplications from "./superadmin/superadminschemefour/SuperAdminSchemeFourPendingApplications.jsx";
import SuperAdminSchemeFourRejectedApplications from "./superadmin/superadminschemefour/SuperAdminSchemeFourRejectedApplications.jsx";

// Schemefive
import SuperAdminSchemeFiveTotalApplications from "./superadmin/superadminschemefive/SuperAdminSchemeFiveTotalApplications";
import SuperAdminSchemeFiveApprovedApplications from "./superadmin/superadminschemefive/SuperAdminSchemeFiveApprovedApplications.jsx";
import SuperAdminSchemeFivePendingApplications from "./superadmin/superadminschemefive/SuperAdminSchemeFivePendingApplications.jsx";
import SuperAdminSchemeFiveRejectedApplications from "./superadmin/superadminschemefive/SuperAdminSchemeFiveRejectedApplications.jsx";

// Schemesix
import SuperAdminSchemeSixTotalApplications from "./superadmin/superadminschemesix/SuperAdminSchemeSixTotalApplications";
import SuperAdminSchemeSixApprovedApplications from "./superadmin/superadminschemesix/SuperAdminSchemeSixApprovedApplications.jsx";
import SuperAdminSchemeSixPendingApplications from "./superadmin/superadminschemesix/SuperAdminSchemeSixPendingApplications.jsx";
import SuperAdminSchemeSixRejectedApplications from "./superadmin/superadminschemesix/SuperAdminSchemeSixRejectedApplications.jsx";

// Schemeseven
import SuperAdminSchemeSevenTotalApplications from "./superadmin/superadminschemeseven/SuperAdminSchemeSevenTotalApplications";
import SuperAdminSchemeSevenApprovedApplications from "./superadmin/superadminschemeseven/SuperAdminSchemeSevenApprovedApplications.jsx";
import SuperAdminSchemeSevenPendingApplications from "./superadmin/superadminschemeseven/SuperAdminSchemeSevenPendingApplications.jsx";
import SuperAdminSchemeSevenRejectedApplications from "./superadmin/superadminschemeseven/SuperAdminSchemeSevenRejectedApplications.jsx";

// SchemeEight
import SuperAdminSchemeEightTotalApplications from "./superadmin/superadminschemeeight/SuperAdminSchemeEightTotalApplications";
import SuperAdminSchemeEightApprovedApplications from "./superadmin/superadminschemeeight/SuperAdminSchemeEightApprovedApplications.jsx";
import SuperAdminSchemeEightPendingApplications from "./superadmin/superadminschemeeight/SuperAdminSchemeEightPendingApplications.jsx";
import SuperAdminSchemeEightRejectedApplications from "./superadmin/superadminschemeeight/SuperAdminSchemeEightRejectedApplications.jsx";

// Schemenine
import SuperAdminSchemeNineTotalApplications from "./superadmin/superadminschemenine/SuperAdminSchemeNineTotalApplications";
import SuperAdminSchemeNineApprovedApplications from "./superadmin/superadminschemenine/SuperAdminSchemeNineApprovedApplications.jsx";
import SuperAdminSchemeNinePendingApplications from "./superadmin/superadminschemenine/SuperAdminSchemeNinePendingApplications.jsx";
import SuperAdminSchemeNineRejectedApplications from "./superadmin/superadminschemenine/SuperAdminSchemeNineRejectedApplications.jsx";

// Schemeten
import SuperAdminSchemeTenTotalApplications from "./superadmin/superadminschemeten/SuperAdminSchemeTenTotalApplications";
import SuperAdminSchemeTenApprovedApplications from "./superadmin/superadminschemeten/SuperAdminSchemeTenApprovedApplications.jsx";
import SuperAdminSchemeTenPendingApplications from "./superadmin/superadminschemeten/SuperAdminSchemeTenPendingApplications.jsx";
import SuperAdminSchemeTenRejectedApplications from "./superadmin/superadminschemeten/SuperAdminSchemeTenRejectedApplications.jsx";

// schemeForms
import SchemeOne from "./pages/SchemeForms/SchemeOne";
import SchemeTwo from "./pages/SchemeForms/SchemeTwo";
import SchemeThree from "./pages/SchemeForms/SchemeThree";
import SchemeFour from "./pages/SchemeForms/SchemeFour";
import SchemeFive from "./pages/SchemeForms/SchemeFive";
import SchemeSix from "./pages/SchemeForms/SchemeSix";
import SchemeSeven from "./pages/SchemeForms/SchemeSeven";
import SchemeEight from "./pages/SchemeForms/SchemeEight";
import SchemeNine from "./pages/SchemeForms/SchemeNine";
import SchemeTen from "./pages/SchemeForms/SchemeTen";
import SchemeForm from "./pages/SportSchemeForm/SchemeForm";
import SchemeOneForm from "./pages/WelfareSchemeForms/SchemeOneForm.jsx";
import SchemeTwoForm from "./pages/WelfareSchemeForms/SchemeTwoForm.jsx";

import TrackApplication from "./pages/profile/TrackApplication";
import ScrollToTop from "./ScrollToTop";
import TotalApplications from "./pages/profile/TotalApplications";
import ProfilePage from "./pages/profile/Profilepages/ProfilePage";
import Registration from "./pages/Register/Registration";
import Login from "./pages/Login/Login";
import Contact from "./pages/profile/Contact";
import CanceledSchemes from "./pages/profile/CanceledSchemes";
import ContactAndSupport from "./pages/profile/ContactandSupport";
import DeclarationForm from "./pages/profile/DeclarationForm";
import AadhaarVerificationForm from "./pages/AdharVerification/AadhaarVerificationForm";
import Helpandguidelines from "./pages/profile/Helpandguidelines";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";

// schemedetails
import DisablilitySchemeOnedetails from "./pages/DisablilitySchemeDetails/DisablitySchemeOnedetails.jsx";
import DisablilitySchemeTwoDetails from "./pages/DisablilitySchemeDetails/DisablilitySchemeTwodetails.jsx";
import DisablilitySchemeThreeDetails from "./pages/DisablilitySchemeDetails/DisablilitySchemeThreedetails.jsx";
import DisablilitySchemeFourDetails from "./pages/DisablilitySchemeDetails/DisablilitySchemeFourdetails.jsx";
import DisablilitySchemeFiveDetails from "./pages/DisablilitySchemeDetails/DisablilitySchemeFivedetails.jsx";
import DisablilitySchemeSixDetails from "./pages/DisablilitySchemeDetails/DisablilitySchemeSixdetails.jsx";
import DisablilitySchemeSevenDetails from "./pages/DisablilitySchemeDetails/DisablilitySchemeSevendetails.jsx";
import DisablilitySchemeEightDetails from "./pages/DisablilitySchemeDetails/DisablilitySchemeEightdetails.jsx";
import DisablilitySchemeNineDetails from "./pages/DisablilitySchemeDetails/DisablilitySchemeNinedetails.jsx";
import DisablilitySchemeTenDetails from "./pages/DisablilitySchemeDetails/DisablilitySchemeTendetails.jsx";

// scheme tables

import DisabilityFormsTable from "./pages/Allschemes/DisabilityFormsTable.jsx";
import SportsFormsTable from "./pages/Allschemes/SportsFormsTable.jsx";

// sports schems details

import SportSchemeOnedetails from "./pages/SportsSchemeDetails/SportSchemeOneDetails.jsx";
import SportSchemeTwoDetails from "./pages/SportsSchemeDetails/SportSchemeTwoDetails.jsx";
import SportSchemeThreeDetails from "./pages/SportsSchemeDetails/SportSchemeThreeDetails.jsx";

// welfare scheme details
import WelfareSchemeOneDetails from "./pages/WelfareSchemeDetails/WelfareSchemeOneDetails.jsx";
import WelfareSchemeTwoDeatails from "./pages/WelfareSchemeDetails/WelfareSchemeTwoDetails.jsx";
import WelfareSchemeThreeDetails from "./pages/WelfareSchemeDetails/WelfareSchemeThreeDetails.jsx";
import WelfareSchemeFourDetails from "./pages/WelfareSchemeDetails/WelfareSchemeFourDetails.jsx";

import { ToastContainer } from "react-toastify";
import { ToastConfig } from "./config";
import UserContainer from "./containers/UserContainer";
import EditProfile from "./pages/profile/EditProfile";

import ShaskiySamitiLogin from "./shaskiysamiti/ShaskiySamitiLogin.jsx";
import ShaskiySamitiContainer from "./containers/ShaskiySamitiContainer.jsx";
import ShaskiySamitiDashboard from "./shaskiysamiti/ShaskiySamitiDashboard.jsx";

//chanani samiti

import ChananiSamitiContainer from "./containers/ChananiSamitiContainer.jsx";
import ChananiSamitiLogin from "./chananisamiti/ChananiSamitiLogin.jsx";
import ChananiSamitiDashboard from "./chananisamiti/ChananiSamitiDashboard.jsx";

// Scheme One
import ChananiOneTotalApplications from "./chananisamiti/ChananiSchemeOne/ChananiOneTotalApplications.jsx";
import ChananiOneApprovedApplications from "./chananisamiti/ChananiSchemeOne/ChananiOneApprovedApplications.jsx";
import ChananiOnePendingApplications from "./chananisamiti/ChananiSchemeOne/ChananiOnePendingApplications.jsx";
import ChananiOneRejectedApplications from "./chananisamiti/ChananiSchemeOne/ChananiOneRejectedApplications.jsx";

// Scheme Two
import ChananiTwoTotalApplications from "./chananisamiti/ChananiSchemeTwo/ChananiTwoTotalApplications.jsx";
import ChananiTwoApprovedApplications from "./chananisamiti/ChananiSchemeTwo/ChananiTwoApprovedApplications.jsx";
import ChananiTwoPendingApplications from "./chananisamiti/ChananiSchemeTwo/ChananiTwoPendingApplications.jsx";
import ChananiTwoRejectedApplications from "./chananisamiti/ChananiSchemeTwo/ChananiTwoRejectedApplications.jsx";

// Scheme Three
import ChananiThreeTotalApplications from "./chananisamiti/ChananiSchemeThree/ChananiThreeTotalApplications.jsx";
import ChananiThreeApprovedApplications from "./chananisamiti/ChananiSchemeThree/ChananiThreeApprovedApplications.jsx";
import ChananiThreePendingApplications from "./chananisamiti/ChananiSchemeThree/ChananiThreePendingApplications.jsx";
import ChananiThreeRejectedApplications from "./chananisamiti/ChananiSchemeThree/ChananiThreeRejectedApplications.jsx";

// Scheme Four
import ChananiFourTotalApplications from "./chananisamiti/ChananiSchemeFour/ChananiFourTotalApplications.jsx";
import ChananiFourApprovedApplications from "./chananisamiti/ChananiSchemeFour/ChananiFourApprovedApplications.jsx";
import ChananiFourPendingApplications from "./chananisamiti/ChananiSchemeFour/ChananiFourPendingApplications.jsx";
import ChananiFourRejectedApplications from "./chananisamiti/ChananiSchemeFour/ChananiFourRejectedApplications.jsx";

// Scheme Five
import ChananiFiveTotalApplications from "./chananisamiti/ChananiSchemeFive/ChananiFiveTotalApplications.jsx";
import ChananiFiveApprovedApplications from "./chananisamiti/ChananiSchemeFive/ChananiFiveApprovedApplications.jsx";
import ChananiFivePendingApplications from "./chananisamiti/ChananiSchemeFive/ChananiFivePendingApplications.jsx";
import ChananiFiveRejectedApplications from "./chananisamiti/ChananiSchemeFive/ChananiFiveRejectedApplications.jsx";

// Scheme Six
import ChananiSixTotalApplications from "./chananisamiti/ChananiSchemeSix/ChananiSixTotalApplications.jsx";
import ChananiSixApprovedApplications from "./chananisamiti/ChananiSchemeSix/ChananiSixApprovedApplications.jsx";
import ChananiSixPendingApplications from "./chananisamiti/ChananiSchemeSix/ChananiSixPendingApplications.jsx";
import ChananiSixRejectedApplications from "./chananisamiti/ChananiSchemeSix/ChananiSixRejectedApplications.jsx";

// Scheme Seven
import ChananiSevenTotalApplications from "./chananisamiti/ChananiSchemeSeven/ChananiSevenTotalApplications.jsx";
import ChananiSevenApprovedApplications from "./chananisamiti/ChananiSchemeSeven/ChananiSevenApprovedApplications.jsx";
import ChananiSevenPendingApplications from "./chananisamiti/ChananiSchemeSeven/ChananiSevenPendingApplications.jsx";
import ChananiSevenRejectedApplications from "./chananisamiti/ChananiSchemeSeven/ChananiSevenRejectedApplications.jsx";

// Scheme Eight
import ChananiEightTotalApplications from "./chananisamiti/ChananiSchemeEight/ChananiEightTotalApplications.jsx";
import ChananiEightApprovedApplications from "./chananisamiti/ChananiSchemeEight/ChananiEightApprovedApplications.jsx";
import ChananiEightPendingApplications from "./chananisamiti/ChananiSchemeEight/ChananiEightPendingApplications.jsx";
import ChananiEightRejectedApplications from "./chananisamiti/ChananiSchemeEight/ChananiEightRejectedApplications.jsx";

// Scheme Nine
import ChananiNineTotalApplications from "./chananisamiti/ChananiSchemeNine/ChananiNineTotalApplications.jsx";
import ChananiNineApprovedApplications from "./chananisamiti/ChananiSchemeNine/ChananiNineApprovedApplications.jsx";
import ChananiNinePendingApplications from "./chananisamiti/ChananiSchemeNine/ChananiNinePendingApplications.jsx";
import ChananiNineRejectedApplications from "./chananisamiti/ChananiSchemeNine/ChananiNineRejectedApplications.jsx";

// Scheme Ten
import ChananiTenTotalApplications from "./chananisamiti/ChananiSchemeTen/ChananiTenTotalApplications.jsx";
import ChananiTenApprovedApplications from "./chananisamiti/ChananiSchemeTen/ChananiTenApprovedApplications.jsx";
import ChananiTenPendingApplications from "./chananisamiti/ChananiSchemeTen/ChananiTenPendingApplications.jsx";
import ChananiTenRejectedApplications from "./chananisamiti/ChananiSchemeTen/ChananiTenRejectedApplications.jsx";

//chanani1
//schemeone
import Chanani1SchemeOneTotalApplications from "./chananisamiti1/Chanani1SchemeOne/Chanani1SchemeOneTotalApplications.jsx";
import Chanani1SchemeOneApprovedApplications from "./chananisamiti1/Chanani1SchemeOne/Chanani1SchemeOneApprovedApplications.jsx";
import Chanani1SchemeOnePendingApplications from "./chananisamiti1/Chanani1SchemeOne/Chanani1SchemeOnePendingApplications.jsx";
import Chanani1SchemeOneRejectedApplications from "./chananisamiti1/Chanani1SchemeOne/Chanani1SchemeOneRejectedApplications.jsx";

//schemetwo
import Chanani1SchemeTwoTotalApplications from "./chananisamiti1/Chanani1SchemeTwo/Chanani1SchemeTwoTotalApplications.jsx";
import Chanani1SchemeTwoApprovedApplications from "./chananisamiti1/Chanani1SchemeTwo/Chanani1SchemeTwoApprovedApplications.jsx";
import Chanani1SchemeTwoPendingApplications from "./chananisamiti1/Chanani1SchemeTwo/Chanani1SchemeTwoPendingApplications.jsx";
import Chanani1SchemeTwoRejectedApplications from "./chananisamiti1/Chanani1SchemeTwo/Chanani1SchemeTwoRejectedApplications.jsx";

//schemethree
import Chanani1SchemeThreeTotalApplications from "./chananisamiti1/Chanani1SchemeThree/Chanani1SchemeThreeTotalApplications.jsx";
import Chanani1SchemeThreeApprovedApplications from "./chananisamiti1/Chanani1SchemeThree/Chanani1SchemeThreeApprovedApplications.jsx";
import Chanani1SchemeThreePendingApplications from "./chananisamiti1/Chanani1SchemeThree/Chanani1SchemeThreePendingApplications.jsx";
import Chanani1SchemeThreeRejectedApplications from "./chananisamiti1/Chanani1SchemeThree/Chanani1SchemeThreeRejectedApplications.jsx";

//schemefour
import Chanani1SchemeFourTotalApplications from "./chananisamiti1/Chanani1SchemeFour/Chanani1SchemeFourTotalApplications.jsx";
import Chanani1SchemeFourApprovedApplications from "./chananisamiti1/Chanani1SchemeFour/Chanani1SchemeFourApprovedApplications.jsx";
import Chanani1SchemeFourPendingApplications from "./chananisamiti1/Chanani1SchemeFour/Chanani1SchemeFourPendingApplications.jsx";
import Chanani1SchemeFourRejectedApplications from "./chananisamiti1/Chanani1SchemeFour/Chanani1SchemeFourRejectedApplications.jsx";

//schemefive
import Chanani1SchemeFiveTotalApplications from "./chananisamiti1/Chanani1SchemeFive/Chanani1SchemeFiveTotalApplications.jsx";
import Chanani1SchemeFiveApprovedApplications from "./chananisamiti1/Chanani1SchemeFive/Chanani1SchemeFiveApprovedApplications.jsx";
import Chanani1SchemeFivePendingApplications from "./chananisamiti1/Chanani1SchemeFive/Chanani1SchemeFivePendingApplications.jsx";
import Chanani1SchemeFiveRejectedApplications from "./chananisamiti1/Chanani1SchemeFive/Chanani1SchemeFiveRejectedApplications.jsx";

//schemesix
import Chanani1SchemeSixTotalApplications from "./chananisamiti1/Chanani1SchemeSix/Chanani1SchemeSixTotalApplications.jsx";
import Chanani1SchemeSixApprovedApplications from "./chananisamiti1/Chanani1SchemeSix/Chanani1SchemeSixApprovedApplications.jsx";
import Chanani1SchemeSixPendingApplications from "./chananisamiti1/Chanani1SchemeSix/Chanani1SchemeSixPendingApplications.jsx";
import Chanani1SchemeSixRejectedApplications from "./chananisamiti1/Chanani1SchemeSix/Chanani1SchemeSixRejectedApplications.jsx";

//schemeseven
import Chanani1SchemeSevenTotalApplications from "./chananisamiti1/Chanani1SchemeSeven/Chanani1SchemeSevenTotalApplications.jsx";
import Chanani1SchemeSevenApprovedApplications from "./chananisamiti1/Chanani1SchemeSeven/Chanani1SchemeSevenApprovedApplications.jsx";
import Chanani1SchemeSevenPendingApplications from "./chananisamiti1/Chanani1SchemeSeven/Chanani1SchemeSevenPendingApplications.jsx";
import Chanani1SchemeSevenRejectedApplications from "./chananisamiti1/Chanani1SchemeSeven/Chanani1SchemeSevenRejectedApplications.jsx";

//schemeeight
import Chanani1SchemeEightTotalApplications from "./chananisamiti1/Chanani1SchemeEight/Chanani1SchemeEightTotalApplications.jsx";
import Chanani1SchemeEightApprovedApplications from "./chananisamiti1/Chanani1SchemeEight/Chanani1SchemeEightApprovedApplications.jsx";
import Chanani1SchemeEightPendingApplications from "./chananisamiti1/Chanani1SchemeEight/Chanani1SchemeEightPendingApplications.jsx";
import Chanani1SchemeEightRejectedApplications from "./chananisamiti1/Chanani1SchemeEight/Chanani1SchemeEightRejectedApplications.jsx";

//schemenine
import Chanani1SchemeNineTotalApplications from "./chananisamiti1/Chanani1SchemeNine/Chanani1SchemeNineTotalApplications.jsx";
import Chanani1SchemeNineApprovedApplications from "./chananisamiti1/Chanani1SchemeNine/Chanani1SchemeNineApprovedApplications.jsx";
import Chanani1SchemeNinePendingApplications from "./chananisamiti1/Chanani1SchemeNine/Chanani1SchemeNinePendingApplications.jsx";
import Chanani1SchemeNineRejectedApplications from "./chananisamiti1/Chanani1SchemeNine/Chanani1SchemeNineRejectedApplications.jsx";

//schemeten
import Chanani1SchemeTenTotalApplications from "./chananisamiti1/Chanani1SchemeTen/Chanani1SchemeTenTotalApplications.jsx";
import Chanani1SchemeTenApprovedApplications from "./chananisamiti1/Chanani1SchemeTen/Chanani1SchemeTenApprovedApplications.jsx";
import Chanani1SchemeTenPendingApplications from "./chananisamiti1/Chanani1SchemeTen/Chanani1SchemeTenPendingApplications.jsx";
import Chanani1SchemeTenRejectedApplications from "./chananisamiti1/Chanani1SchemeTen/Chanani1SchemeTenRejectedApplications.jsx";

//chanani2
//schemeone
import Chanani2SchemeOneTotalApplications from "./chananisamiti2/Chanani2SchemeOne/Chanani2SchemeOneTotalApplications.jsx";
import Chanani2SchemeOneApprovedApplications from "./chananisamiti2/Chanani2SchemeOne/Chanani2SchemeOneApprovedApplications.jsx";
import Chanani2SchemeOnePendingApplications from "./chananisamiti2/Chanani2SchemeOne/Chanani2SchemeOnePendingApplications.jsx";
import Chanani2SchemeOneRejectedApplications from "./chananisamiti2/Chanani2SchemeOne/Chanani2SchemeOneRejectedApplications.jsx";

//schemetwo
import Chanani2SchemeTwoTotalApplications from "./chananisamiti2/Chanani2SchemeTwo/Chanani2SchemeTwoTotalApplications.jsx";
import Chanani2SchemeTwoApprovedApplications from "./chananisamiti2/Chanani2SchemeTwo/Chanani2SchemeTwoApprovedApplications.jsx";
import Chanani2SchemeTwoPendingApplications from "./chananisamiti2/Chanani2SchemeTwo/Chanani2SchemeTwoPendingApplications.jsx";
import Chanani2SchemeTwoRejectedApplications from "./chananisamiti2/Chanani2SchemeTwo/Chanani2SchemeTwoRejectedApplications.jsx";

//schemethree
import Chanani2SchemeThreeTotalApplications from "./chananisamiti2/Chanani2SchemeThree/Chanani2SchemeThreeTotalApplications.jsx";
import Chanani2SchemeThreeApprovedApplications from "./chananisamiti2/Chanani2SchemeThree/Chanani2SchemeThreeApprovedApplications.jsx";
import Chanani2SchemeThreePendingApplications from "./chananisamiti2/Chanani2SchemeThree/Chanani2SchemeThreePendingApplications.jsx";
import Chanani2SchemeThreeRejectedApplications from "./chananisamiti2/Chanani2SchemeThree/Chanani2SchemeThreeRejectedApplications.jsx";

//schemefour
import Chanani2SchemeFourTotalApplications from "./chananisamiti2/Chanani2SchemeFour/Chanani2SchemeFourTotalApplications.jsx";
import Chanani2SchemeFourApprovedApplications from "./chananisamiti2/Chanani2SchemeFour/Chanani2SchemeFourApprovedApplications.jsx";
import Chanani2SchemeFourPendingApplications from "./chananisamiti2/Chanani2SchemeFour/Chanani2SchemeFourPendingApplications.jsx";
import Chanani2SchemeFourRejectedApplications from "./chananisamiti2/Chanani2SchemeFour/Chanani2SchemeFourRejectedApplications.jsx";

//schemefive
import Chanani2SchemeFiveTotalApplications from "./chananisamiti2/Chanani2SchemeFive/Chanani2SchemeFiveTotalApplications.jsx";
import Chanani2SchemeFiveApprovedApplications from "./chananisamiti2/Chanani2SchemeFive/Chanani2SchemeFiveApprovedApplications.jsx";
import Chanani2SchemeFivePendingApplications from "./chananisamiti2/Chanani2SchemeFive/Chanani2SchemeFivePendingApplications.jsx";
import Chanani2SchemeFiveRejectedApplications from "./chananisamiti2/Chanani2SchemeFive/Chanani2SchemeFiveRejectedApplications.jsx";

//schemesix
import Chanani2SchemeSixTotalApplications from "./chananisamiti2/Chanani2SchemeSix/Chanani2SchemeSixTotalApplications.jsx";
import Chanani2SchemeSixApprovedApplications from "./chananisamiti2/Chanani2SchemeSix/Chanani2SchemeSixApprovedApplications.jsx";
import Chanani2SchemeSixPendingApplications from "./chananisamiti2/Chanani2SchemeSix/Chanani2SchemeSixPendingApplications.jsx";
import Chanani2SchemeSixRejectedApplications from "./chananisamiti2/Chanani2SchemeSix/Chanani2SchemeSixRejectedApplications.jsx";

//schemeseven
import Chanani2SchemeSevenTotalApplications from "./chananisamiti2/Chanani2SchemeSeven/Chanani2SchemeSevenTotalApplications.jsx";
import Chanani2SchemeSevenApprovedApplications from "./chananisamiti2/Chanani2SchemeSeven/Chanani2SchemeSevenApprovedApplications.jsx";
import Chanani2SchemeSevenPendingApplications from "./chananisamiti2/Chanani2SchemeSeven/Chanani2SchemeSevenPendingApplications.jsx";
import Chanani2SchemeSevenRejectedApplications from "./chananisamiti2/Chanani2SchemeSeven/Chanani2SchemeSevenRejectedApplications.jsx";

//schemeeight
import Chanani2SchemeEightTotalApplications from "./chananisamiti2/Chanani2SchemeEight/Chanani2SchemeEightTotalApplications.jsx";
import Chanani2SchemeEightApprovedApplications from "./chananisamiti2/Chanani2SchemeEight/Chanani2SchemeEightApprovedApplications.jsx";
import Chanani2SchemeEightPendingApplications from "./chananisamiti2/Chanani2SchemeEight/Chanani2SchemeEightPendingApplications.jsx";
import Chanani2SchemeEightRejectedApplications from "./chananisamiti2/Chanani2SchemeEight/Chanani2SchemeEightRejectedApplications.jsx";

//schemenine
import Chanani2SchemeNineTotalApplications from "./chananisamiti2/Chanani2SchemeNine/Chanani2SchemeNineTotalApplications.jsx";
import Chanani2SchemeNineApprovedApplications from "./chananisamiti2/Chanani2SchemeNine/Chanani2SchemeNineApprovedApplications.jsx";
import Chanani2SchemeNinePendingApplications from "./chananisamiti2/Chanani2SchemeNine/Chanani2SchemeNinePendingApplications.jsx";
import Chanani2SchemeNineRejectedApplications from "./chananisamiti2/Chanani2SchemeNine/Chanani2SchemeNineRejectedApplications.jsx";

//schemeten
import Chanani2SchemeTenTotalApplications from "./chananisamiti2/Chanani2SchemeTen/Chanani2SchemeTenTotalApplications.jsx";
import Chanani2SchemeTenApprovedApplications from "./chananisamiti2/Chanani2SchemeTen/Chanani2SchemeTenApprovedApplications.jsx";
import Chanani2SchemeTenPendingApplications from "./chananisamiti2/Chanani2SchemeTen/Chanani2SchemeTenPendingApplications.jsx";
import Chanani2SchemeTenRejectedApplications from "./chananisamiti2/Chanani2SchemeTen/Chanani2SchemeTenRejectedApplications.jsx";

//chanani3
// Scheme One
import Chanani3SchemeOneTotalApplications from "./chananisamiti3/Chanani3SchemeOne/Chanani3SchemeOneTotalApplications.jsx";
import Chanani3SchemeOneApprovedApplications from "./chananisamiti3/Chanani3SchemeOne/Chanani3SchemeOneApprovedApplications.jsx";
import Chanani3SchemeOnePendingApplications from "./chananisamiti3/Chanani3SchemeOne/Chanani3SchemeOnePendingApplications.jsx";
import Chanani3SchemeOneRejectedApplications from "./chananisamiti3/Chanani3SchemeOne/Chanani3SchemeOneRejectedApplications.jsx";

// Scheme Two
import Chanani3SchemeTwoTotalApplications from "./chananisamiti3/Chanani3SchemeTwo/Chanani3SchemeTwoTotalApplications.jsx";
import Chanani3SchemeTwoApprovedApplications from "./chananisamiti3/Chanani3SchemeTwo/Chanani3SchemeTwoApprovedApplications.jsx";
import Chanani3SchemeTwoPendingApplications from "./chananisamiti3/Chanani3SchemeTwo/Chanani3SchemeTwoPendingApplications.jsx";
import Chanani3SchemeTwoRejectedApplications from "./chananisamiti3/Chanani3SchemeTwo/Chanani3SchemeTwoRejectedApplications.jsx";

// Scheme Three
import Chanani3SchemeThreeTotalApplications from "./chananisamiti3/Chanani3SchemeThree/Chanani3SchemeThreeTotalApplications.jsx";
import Chanani3SchemeThreeApprovedApplications from "./chananisamiti3/Chanani3SchemeThree/Chanani3SchemeThreeApprovedApplications.jsx";
import Chanani3SchemeThreePendingApplications from "./chananisamiti3/Chanani3SchemeThree/Chanani3SchemeThreePendingApplications.jsx";
import Chanani3SchemeThreeRejectedApplications from "./chananisamiti3/Chanani3SchemeThree/Chanani3SchemeThreeRejectedApplications.jsx";

// Scheme Four
import Chanani3SchemeFourTotalApplications from "./chananisamiti3/Chanani3SchemeFour/Chanani3SchemeFourTotalApplications.jsx";
import Chanani3SchemeFourApprovedApplications from "./chananisamiti3/Chanani3SchemeFour/Chanani3SchemeFourApprovedApplications.jsx";
import Chanani3SchemeFourPendingApplications from "./chananisamiti3/Chanani3SchemeFour/Chanani3SchemeFourPendingApplications.jsx";
import Chanani3SchemeFourRejectedApplications from "./chananisamiti3/Chanani3SchemeFour/Chanani3SchemeFourRejectedApplications.jsx";

// Scheme Five
import Chanani3SchemeFiveTotalApplications from "./chananisamiti3/Chanani3SchemeFive/Chanani3SchemeFiveTotalApplications.jsx";
import Chanani3SchemeFiveApprovedApplications from "./chananisamiti3/Chanani3SchemeFive/Chanani3SchemeFiveApprovedApplications.jsx";
import Chanani3SchemeFivePendingApplications from "./chananisamiti3/Chanani3SchemeFive/Chanani3SchemeFivePendingApplications.jsx";
import Chanani3SchemeFiveRejectedApplications from "./chananisamiti3/Chanani3SchemeFive/Chanani3SchemeFiveRejectedApplications.jsx";

// Scheme Six
import Chanani3SchemeSixTotalApplications from "./chananisamiti3/Chanani3SchemeSix/Chanani3SchemeSixTotalApplications.jsx";
import Chanani3SchemeSixApprovedApplications from "./chananisamiti3/Chanani3SchemeSix/Chanani3SchemeSixApprovedApplications.jsx";
import Chanani3SchemeSixPendingApplications from "./chananisamiti3/Chanani3SchemeSix/Chanani3SchemeSixPendingApplications.jsx";
import Chanani3SchemeSixRejectedApplications from "./chananisamiti3/Chanani3SchemeSix/Chanani3SchemeSixRejectedApplications.jsx";

// Scheme Seven
import Chanani3SchemeSevenTotalApplications from "./chananisamiti3/Chanani3SchemeSeven/Chanani3SchemeSevenTotalApplications.jsx";
import Chanani3SchemeSevenApprovedApplications from "./chananisamiti3/Chanani3SchemeSeven/Chanani3SchemeSevenApprovedApplications.jsx";
import Chanani3SchemeSevenPendingApplications from "./chananisamiti3/Chanani3SchemeSeven/Chanani3SchemeSevenPendingApplications.jsx";
import Chanani3SchemeSevenRejectedApplications from "./chananisamiti3/Chanani3SchemeSeven/Chanani3SchemeSevenRejectedApplications.jsx";

// Scheme Eight
import Chanani3SchemeEightTotalApplications from "./chananisamiti3/Chanani3SchemeEight/Chanani3SchemeEightTotalApplications.jsx";
import Chanani3SchemeEightApprovedApplications from "./chananisamiti3/Chanani3SchemeEight/Chanani3SchemeEightApprovedApplications.jsx";
import Chanani3SchemeEightPendingApplications from "./chananisamiti3/Chanani3SchemeEight/Chanani3SchemeEightPendingApplications.jsx";
import Chanani3SchemeEightRejectedApplications from "./chananisamiti3/Chanani3SchemeEight/Chanani3SchemeEightRejectedApplications.jsx";

// Scheme Nine
import Chanani3SchemeNineTotalApplications from "./chananisamiti3/Chanani3SchemeNine/Chanani3SchemeNineTotalApplications.jsx";
import Chanani3SchemeNineApprovedApplications from "./chananisamiti3/Chanani3SchemeNine/Chanani3SchemeNineApprovedApplications.jsx";
import Chanani3SchemeNinePendingApplications from "./chananisamiti3/Chanani3SchemeNine/Chanani3SchemeNinePendingApplications.jsx";
import Chanani3SchemeNineRejectedApplications from "./chananisamiti3/Chanani3SchemeNine/Chanani3SchemeNineRejectedApplications.jsx";

// Scheme Ten
import Chanani3SchemeTenTotalApplications from "./chananisamiti3/Chanani3SchemeTen/Chanani3SchemeTenTotalApplications.jsx";
import Chanani3SchemeTenApprovedApplications from "./chananisamiti3/Chanani3SchemeTen/Chanani3SchemeTenApprovedApplications.jsx";
import Chanani3SchemeTenPendingApplications from "./chananisamiti3/Chanani3SchemeTen/Chanani3SchemeTenPendingApplications.jsx";
import Chanani3SchemeTenRejectedApplications from "./chananisamiti3/Chanani3SchemeTen/Chanani3SchemeTenRejectedApplications.jsx";

//chanani4

// Scheme One
import Chanani4SchemeOneApprovedApplications from "./chananisamiti4/Chanani4SchemeOne/Chanani4SchemeOneApprovedApplications.jsx";
import Chanani4SchemeOnePendingApplications from "./chananisamiti4/Chanani4SchemeOne/Chanani4SchemeOnePendingApplications.jsx";
import Chanani4SchemeOneRejectedApplications from "./chananisamiti4/Chanani4SchemeOne/Chanani4SchemeOneRejectedApplications.jsx";
import Chanani4SchemeOneTotalApplications from "./chananisamiti4/Chanani4SchemeOne/Chanani4SchemeOneTotalApplications.jsx";

// Scheme Two
import Chanani4SchemeTwoApprovedApplications from "./chananisamiti4/Chanani4SchemeTwo/Chanani4SchemeTwoApprovedApplications.jsx";
import Chanani4SchemeTwoPendingApplications from "./chananisamiti4/Chanani4SchemeTwo/Chanani4SchemeTwoPendingApplications.jsx";
import Chanani4SchemeTwoRejectedApplications from "./chananisamiti4/Chanani4SchemeTwo/Chanani4SchemeTwoRejectedApplications.jsx";
import Chanani4SchemeTwoTotalApplications from "./chananisamiti4/Chanani4SchemeTwo/Chanani4SchemeTwoTotalApplications.jsx";

// Scheme Three
import Chanani4SchemeThreeApprovedApplications from "./chananisamiti4/Chanani4SchemeThree/Chanani4SchemeThreeApprovedApplications.jsx";
import Chanani4SchemeThreePendingApplications from "./chananisamiti4/Chanani4SchemeThree/Chanani4SchemeThreePendingApplications.jsx";
import Chanani4SchemeThreeRejectedApplications from "./chananisamiti4/Chanani4SchemeThree/Chanani4SchemeThreeRejectedApplications.jsx";
import Chanani4SchemeThreeTotalApplications from "./chananisamiti4/Chanani4SchemeThree/Chanani4SchemeThreeTotalApplications.jsx";

// Scheme Four
import Chanani4SchemeFourApprovedApplications from "./chananisamiti4/Chanani4SchemeFour/Chanani4SchemeFourApprovedApplications.jsx";
import Chanani4SchemeFourPendingApplications from "./chananisamiti4/Chanani4SchemeFour/Chanani4SchemeFourPendingApplications.jsx";
import Chanani4SchemeFourRejectedApplications from "./chananisamiti4/Chanani4SchemeFour/Chanani4SchemeFourRejectedApplications.jsx";
import Chanani4SchemeFourTotalApplications from "./chananisamiti4/Chanani4SchemeFour/Chanani4SchemeFourTotalApplications.jsx";

// Scheme Five
import Chanani4SchemeFiveApprovedApplications from "./chananisamiti4/Chanani4SchemeFive/Chanani4SchemeFiveApprovedApplications.jsx";
import Chanani4SchemeFivePendingApplications from "./chananisamiti4/Chanani4SchemeFive/Chanani4SchemeFivePendingApplications.jsx";
import Chanani4SchemeFiveRejectedApplications from "./chananisamiti4/Chanani4SchemeFive/Chanani4SchemeFiveRejectedApplications.jsx";
import Chanani4SchemeFiveTotalApplications from "./chananisamiti4/Chanani4SchemeFive/Chanani4SchemeFiveTotalApplications.jsx";

// Scheme Six
import Chanani4SchemeSixApprovedApplications from "./chananisamiti4/Chanani4SchemeSix/Chanani4SchemeSixApprovedApplications.jsx";
import Chanani4SchemeSixPendingApplications from "./chananisamiti4/Chanani4SchemeSix/Chanani4SchemeSixPendingApplications.jsx";
import Chanani4SchemeSixRejectedApplications from "./chananisamiti4/Chanani4SchemeSix/Chanani4SchemeSixRejectedApplications.jsx";
import Chanani4SchemeSixTotalApplications from "./chananisamiti4/Chanani4SchemeSix/Chanani4SchemeSixTotalApplications.jsx";

// Scheme Seven
import Chanani4SchemeSevenApprovedApplications from "./chananisamiti4/Chanani4SchemeSeven/Chanani4SchemeSevenApprovedApplications.jsx";
import Chanani4SchemeSevenPendingApplications from "./chananisamiti4/Chanani4SchemeSeven/Chanani4SchemeSevenPendingApplications.jsx";
import Chanani4SchemeSevenRejectedApplications from "./chananisamiti4/Chanani4SchemeSeven/Chanani4SchemeSevenRejectedApplications.jsx";
import Chanani4SchemeSevenTotalApplications from "./chananisamiti4/Chanani4SchemeSeven/Chanani4SchemeSevenTotalApplications.jsx";

// Scheme Eight
import Chanani4SchemeEightApprovedApplications from "./chananisamiti4/Chanani4SchemeEight/Chanani4SchemeEightApprovedApplications.jsx";
import Chanani4SchemeEightPendingApplications from "./chananisamiti4/Chanani4SchemeEight/Chanani4SchemeEightPendingApplications.jsx";
import Chanani4SchemeEightRejectedApplications from "./chananisamiti4/Chanani4SchemeEight/Chanani4SchemeEightRejectedApplications.jsx";
import Chanani4SchemeEightTotalApplications from "./chananisamiti4/Chanani4SchemeEight/Chanani4SchemeEightTotalApplications.jsx";

// Scheme Nine
import Chanani4SchemeNineApprovedApplications from "./chananisamiti4/Chanani4SchemeNine/Chanani4SchemeNineApprovedApplications.jsx";
import Chanani4SchemeNinePendingApplications from "./chananisamiti4/Chanani4SchemeNine/Chanani4SchemeNinePendingApplications.jsx";
import Chanani4SchemeNineRejectedApplications from "./chananisamiti4/Chanani4SchemeNine/Chanani4SchemeNineRejectedApplications.jsx";
import Chanani4SchemeNineTotalApplications from "./chananisamiti4/Chanani4SchemeNine/Chanani4SchemeNineTotalApplications.jsx";

// Scheme Ten
import Chanani4SchemeTenApprovedApplications from "./chananisamiti4/Chanani4SchemeTen/Chanani4SchemeTenApprovedApplications.jsx";
import Chanani4SchemeTenPendingApplications from "./chananisamiti4/Chanani4SchemeTen/Chanani4SchemeTenPendingApplications.jsx";
import Chanani4SchemeTenRejectedApplications from "./chananisamiti4/Chanani4SchemeTen/Chanani4SchemeTenRejectedApplications.jsx";
import Chanani4SchemeTenTotalApplications from "./chananisamiti4/Chanani4SchemeTen/Chanani4SchemeTenTotalApplications.jsx";

// deputy commissioner side

import DeputyCommissionerLogin from "./deputycommissioner/DeputyCommissionerLogin.jsx";
import DeputyCommissionerContainer from "./containers/DeputyCommissionerContainer.jsx";
import DeputyCommissionerDashboard from "./deputycommissioner/DeputyCommissionerDashboard.jsx";
import DeputyCommissionerManageUser from "./deputycommissioner/DeputyCommissionerManageUser.jsx";
import DeputyCommissionerAddSlider from "./deputycommissioner/DeputyCommissionerAddSlider.jsx";
import DeputyCommissionerContactEnquiries from "./deputycommissioner/DeputyCommissionerContactEnquiries.jsx";

// Scheme One
import DeputyCommSchemeOneTotalApplications from "./deputycommissioner/DeputyCommissionerSchemeOne/DeputyCommSchemeOneTotalApplications.jsx";
import DeputyCommSchemeOneApprovedApplications from "./deputycommissioner/DeputyCommissionerSchemeOne/DeputyCommSchemeOneApprovedApplications.jsx";
import DeputyCommSchemeOnePendingApplications from "./deputycommissioner/DeputyCommissionerSchemeOne/DeputyCommSchemeOnePendingApplications.jsx";
import DeputyCommSchemeOneRejectedApplications from "./deputycommissioner/DeputyCommissionerSchemeOne/DeputyCommSchemeOneRejectedApplications.jsx";

// Scheme Two
import DeputyCommSchemeTwoTotalApplications from "./deputycommissioner/DeputyCommissionerSchemeTwo/DeputyCommSchemeTwoTotalApplications.jsx";
import DeputyCommSchemeTwoApprovedApplications from "./deputycommissioner/DeputyCommissionerSchemeTwo/DeputyCommSchemeTwoApprovedApplications.jsx";
import DeputyCommSchemeTwoPendingApplications from "./deputycommissioner/DeputyCommissionerSchemeTwo/DeputyCommSchemeTwoPendingApplications.jsx";
import DeputyCommSchemeTwoRejectedApplications from "./deputycommissioner/DeputyCommissionerSchemeTwo/DeputyCommSchemeTwoRejectedApplications.jsx";

// Scheme Three
import DeputyCommSchemeThreeTotalApplications from "./deputycommissioner/DeputyCommissionerSchemeThree/DeputyCommSchemeThreeTotalApplications.jsx";
import DeputyCommSchemeThreeApprovedApplications from "./deputycommissioner/DeputyCommissionerSchemeThree/DeputyCommSchemeThreeApprovedApplications.jsx";
import DeputyCommSchemeThreePendingApplications from "./deputycommissioner/DeputyCommissionerSchemeThree/DeputyCommSchemeThreePendingApplications.jsx";
import DeputyCommSchemeThreeRejectedApplications from "./deputycommissioner/DeputyCommissionerSchemeThree/DeputyCommSchemeThreeRejectedApplications.jsx";

// Scheme Four
import DeputyCommSchemeFourTotalApplications from "./deputycommissioner/DeputyCommissionerSchemeFour/DeputyCommSchemeFourTotalApplications.jsx";
import DeputyCommSchemeFourApprovedApplications from "./deputycommissioner/DeputyCommissionerSchemeFour/DeputyCommSchemeFourApprovedApplications.jsx";
import DeputyCommSchemeFourPendingApplications from "./deputycommissioner/DeputyCommissionerSchemeFour/DeputyCommSchemeFourPendingApplications.jsx";
import DeputyCommSchemeFourRejectedApplications from "./deputycommissioner/DeputyCommissionerSchemeFour/DeputyCommSchemeFourRejectedApplications.jsx";

// Scheme Five
import DeputyCommSchemeFiveTotalApplications from "./deputycommissioner/DeputyCommissionerSchemeFive/DeputyCommSchemeFiveTotalApplications.jsx";
import DeputyCommSchemeFiveApprovedApplications from "./deputycommissioner/DeputyCommissionerSchemeFive/DeputyCommSchemeFiveApprovedApplications.jsx";
import DeputyCommSchemeFivePendingApplications from "./deputycommissioner/DeputyCommissionerSchemeFive/DeputyCommSchemeFivePendingApplications.jsx";
import DeputyCommSchemeFiveRejectedApplications from "./deputycommissioner/DeputyCommissionerSchemeFive/DeputyCommSchemeFiveRejectedApplications.jsx";

// Scheme Six
import DeputyCommSchemeSixTotalApplications from "./deputycommissioner/DeputyCommissionerSchemeSix/DeputyCommSchemeSixTotalApplications.jsx";
import DeputyCommSchemeSixApprovedApplications from "./deputycommissioner/DeputyCommissionerSchemeSix/DeputyCommSchemeSixApprovedApplications.jsx";
import DeputyCommSchemeSixPendingApplications from "./deputycommissioner/DeputyCommissionerSchemeSix/DeputyCommSchemeSixPendingApplications.jsx";
import DeputyCommSchemeSixRejectedApplications from "./deputycommissioner/DeputyCommissionerSchemeSix/DeputyCommSchemeSixRejectedApplications.jsx";

// Scheme Seven
import DeputyCommSchemeSevenTotalApplications from "./deputycommissioner/DeputyCommissionerSchemeSeven/DeputyCommSchemeSevenTotalApplications.jsx";
import DeputyCommSchemeSevenApprovedApplications from "./deputycommissioner/DeputyCommissionerSchemeSeven/DeputyCommSchemeSevenApprovedApplications.jsx";
import DeputyCommSchemeSevenPendingApplications from "./deputycommissioner/DeputyCommissionerSchemeSeven/DeputyCommSchemeSevenPendingApplications.jsx";
import DeputyCommSchemeSevenRejectedApplications from "./deputycommissioner/DeputyCommissionerSchemeSeven/DeputyCommSchemeSevenRejectedApplications.jsx";

// Scheme Eight
import DeputyCommSchemeEightTotalApplications from "./deputycommissioner/DeputyCommissionerSchemeEight/DeputyCommSchemeEightTotalApplications.jsx";
import DeputyCommSchemeEightApprovedApplications from "./deputycommissioner/DeputyCommissionerSchemeEight/DeputyCommSchemeEightApprovedApplications.jsx";
import DeputyCommSchemeEightPendingApplications from "./deputycommissioner/DeputyCommissionerSchemeEight/DeputyCommSchemeEightPendingApplications.jsx";
import DeputyCommSchemeEightRejectedApplications from "./deputycommissioner/DeputyCommissionerSchemeEight/DeputyCommSchemeEightRejectedApplications.jsx";

// Scheme Nine
import DeputyCommSchemeNineTotalApplications from "./deputycommissioner/DeputyCommissionerSchemeNine/DeputyCommSchemeNineTotalApplications.jsx";
import DeputyCommSchemeNineApprovedApplications from "./deputycommissioner/DeputyCommissionerSchemeNine/DeputyCommSchemeNineApprovedApplications.jsx";
import DeputyCommSchemeNinePendingApplications from "./deputycommissioner/DeputyCommissionerSchemeNine/DeputyCommSchemeNinePendingApplications.jsx";
import DeputyCommSchemeNineRejectedApplications from "./deputycommissioner/DeputyCommissionerSchemeNine/DeputyCommSchemeNineRejectedApplications.jsx";

// Scheme Ten
import DeputyCommSchemeTenTotalApplications from "./deputycommissioner/DeputyCommissionerSchemeTen/DeputyCommSchemeTenTotalApplications.jsx";
import DeputyCommSchemeTenApprovedApplications from "./deputycommissioner/DeputyCommissionerSchemeTen/DeputyCommSchemeTenApprovedApplications.jsx";
import DeputyCommSchemeTenPendingApplications from "./deputycommissioner/DeputyCommissionerSchemeTen/DeputyCommSchemeTenPendingApplications.jsx";
import DeputyCommSchemeTenRejectedApplications from "./deputycommissioner/DeputyCommissionerSchemeTen/DeputyCommSchemeTenRejectedApplications.jsx";

// Additional commissioner side
import AdditionalCommissionerLogin from "./AdditionalCommissioner/AddissionalCommissionerLogin.jsx";
import AddissionalCommissionerContainer from "./containers/AddissionalCommissionerContainer.jsx";
import AdditionalCommissionerDashboard from "./AdditionalCommissioner/AdditionalCommissionerDashboard.jsx";
import AdditionalCommissionerManageUser from "./AdditionalCommissioner/AdditionalCommissionerManageUser.jsx";
import AdditionalCommissionerContactEnquiries from "./AdditionalCommissioner/AdditionalCommissionerContactEnquiries.jsx";
import AdditionalCommissionerAddSlider from "./AdditionalCommissioner/AdditionalCommissionerAddSlider.jsx";

// schemeone

import AdditionalCommSchemeOneTotalApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeOne/AdditionalCommSchemeOneTotalApplications.jsx";
import AdditionalCommSchemeOneApprovedApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeOne/AdditionalCommSchemeOneApprovedApplications.jsx";
import AdditionalCommSchemeOnePendingApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeOne/AdditionalCommSchemeOnePendingApplications.jsx";
import AdditionalCommSchemeOneRejectedApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeOne/AdditionalCommSchemeOneRejectedApplications.jsx";

//schemetwo

import AdditionalCommSchemeTwoTotalApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeTwo/AdditionalCommSchemeTwoTotalApplications.jsx";
import AdditionalCommSchemeTwoApprovedApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeTwo/AdditionalCommSchemeTwoApprovedApplications.jsx";
import AdditionalCommSchemeTwoPendingApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeTwo/AdditionalCommSchemeTwoPendingApplications.jsx";
import AdditionalCommSchemeTwoRejectedApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeTwo/AdditionalCommSchemeTwoRejectedApplications.jsx";

//schemethree

import AdditionalCommSchemeThreeTotalApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeThree/AdditionalCommSchemeThreeTotalApplications.jsx";
import AdditionalCommSchemeThreeApprovedApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeThree/AdditionalCommSchemeThreeApprovedApplications.jsx";
import AdditionalCommSchemeThreePendingApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeThree/AdditionalCommSchemeThreePendingApplications.jsx";
import AdditionalCommSchemeThreeRejectedApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeThree/AdditionalCommSchemeThreeRejectedApplications.jsx";

//schemefour
import AdditionalCommSchemeFourTotalApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeFour/AdditionalCommSchemeFourTotalApplications.jsx";
import AdditionalCommSchemeFourApprovedApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeFour/AdditionalCommSchemeFourApprovedApplications.jsx";
import AdditionalCommSchemeFourPendingApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeFour/AdditionalCommSchemeFourPendingApplications.jsx";
import AdditionalCommSchemeFourRejectedApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeFour/AdditionalCommSchemeFourRejectedApplications.jsx";

//schemefive
import AdditionalCommSchemeFiveTotalApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeFive/AdditionalCommSchemeFiveTotalApplications.jsx";
import AdditionalCommSchemeFiveApprovedApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeFive/AdditionalCommSchemeFiveApprovedApplications.jsx";
import AdditionalCommSchemeFivePendingApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeFive/AdditionalCommSchemeFivePendingApplications.jsx";
import AdditionalCommSchemeFiveRejectedApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeFive/AdditionalCommSchemeFiveRejectedApplications.jsx";

//schemesix
import AdditionalCommSchemeSixTotalApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeSix/AdditionalCommSchemeSixTotalApplications.jsx";
import AdditionalCommSchemeSixApprovedApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeSix/AdditionalCommSchemeSixApprovedApplications.jsx";
import AdditionalCommSchemeSixPendingApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeSix/AdditionalCommSchemeSixPendingApplications.jsx";
import AdditionalCommSchemeSixRejectedApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeSix/AdditionalCommSchemeSixRejectedApplications.jsx";

//schemeseven
import AdditionalCommSchemeSevenTotalApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeSeven/AdditionalCommSchemeSevenTotalApplications.jsx";
import AdditionalCommSchemeSevenApprovedApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeSeven/AdditionalCommSchemeSevenApprovedApplications.jsx";
import AdditionalCommSchemeSevenPendingApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeSeven/AdditionalCommSchemeSevenPendingApplications.jsx";
import AdditionalCommSchemeSevenRejectedApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeSeven/AdditionalCommSchemeSevenRejectedApplications.jsx";

//schemeeight
import AdditionalCommSchemeEightTotalApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeEight/AdditionalCommSchemeEightTotalApplications.jsx";
import AdditionalCommSchemeEightApprovedApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeEight/AdditionalCommSchemeEightApprovedApplications.jsx";
import AdditionalCommSchemeEightPendingApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeEight/AdditionalCommSchemeEightPendingApplications.jsx";
import AdditionalCommSchemeEightRejectedApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeEight/AdditionalCommSchemeEightRejectedApplications.jsx";

//schemenine
import AdditionalCommSchemeNineTotalApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeNine/AdditionalCommSchemeNineTotalApplications.jsx";
import AdditionalCommSchemeNineApprovedApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeNine/AdditionalCommSchemeNineApprovedApplications.jsx";
import AdditionalCommSchemeNinePendingApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeNine/AdditionalCommSchemeNinePendingApplications.jsx";
import AdditionalCommSchemeNineRejectedApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeNine/AdditionalCommSchemeNineRejectedApplications.jsx";

//schemeten
import AdditionalCommSchemeTenTotalApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeTen/AdditionalCommSchemeTenTotalApplications.jsx";
import AdditionalCommSchemeTenApprovedApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeTen/AdditionalCommSchemeTenApprovedApplications.jsx";
import AdditionalCommSchemeTenPendingApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeTen/AdditionalCommSchemeTenPendingApplications.jsx";
import AdditionalCommSchemeTenRejectedApplications from "./AdditionalCommissioner/AdditionalCommissionerSchemeTen/AdditionalCommSchemeTenRejectedApplications.jsx";

// clerk side
import ClerkLogin from "./clerck/ClerkLogin.jsx";
import Clerk1Container from "./containers/Clerk1Container.jsx";
import ClerkDashboard from "./clerck/ClerkDashboard.jsx";
import ClerkAddSlider from "./clerck/ClerkAddSlider.jsx";
import ClerkContactEnquiries from "./clerck/ClerkContactEnquiries.jsx";
import ClerkManageUser from "./clerck/ClerkManageUser.jsx";

// Scheme One
import ClerkSchemeOneTotalApplications from "./clerck/ClerkSchemeOne/ClerkSchemeOneTotalApplications.jsx";
import ClerkSchemeOneApprovedApplications from "./clerck/ClerkSchemeOne/ClerkSchemeOneApprovedApplications.jsx";
import ClerkSchemeOneRejectedApplications from "./clerck/ClerkSchemeOne/ClerkSchemeOneRejectedApplications.jsx";
import ClerkSchemeOnePendingApplications from "./clerck/ClerkSchemeOne/ClerkSchemeOnePendingApplications.jsx";

// Scheme Two
import ClerkSchemeTwoTotalApplications from "./clerck/ClerkSchemeTwo/ClerkSchemeTwoTotalApplications.jsx";
import ClerkSchemeTwoApprovedApplications from "./clerck/ClerkSchemeTwo/ClerkSchemeTwoApprovedApplications.jsx";
import ClerkSchemeTwoRejectedApplications from "./clerck/ClerkSchemeTwo/ClerkSchemeTwoRejectedApplications.jsx";
import ClerkSchemeTwoPendingApplications from "./clerck/ClerkSchemeTwo/ClerkSchemeTwoPendingApplications.jsx";

// Scheme Three
import ClerkSchemeThreeTotalApplications from "./clerck/ClerkSchemeThree/ClerkSchemeThreeTotalApplications.jsx";
import ClerkSchemeThreeApprovedApplications from "./clerck/ClerkSchemeThree/ClerkSchemeThreeApprovedApplications.jsx";
import ClerkSchemeThreeRejectedApplications from "./clerck/ClerkSchemeThree/ClerkSchemeThreeRejectedApplications.jsx";
import ClerkSchemeThreePendingApplications from "./clerck/ClerkSchemeThree/ClerkSchemeThreePendingApplications.jsx";

// Scheme Four
import ClerkSchemeFourTotalApplications from "./clerck/ClerkSchemeFour/ClerkSchemeFourTotalApplications.jsx";
import ClerkSchemeFourApprovedApplications from "./clerck/ClerkSchemeFour/ClerkSchemeFourApprovedApplications.jsx";
import ClerkSchemeFourRejectedApplications from "./clerck/ClerkSchemeFour/ClerkSchemeFourRejectedApplications.jsx";
import ClerkSchemeFourPendingApplications from "./clerck/ClerkSchemeFour/ClerkSchemeFourPendingApplications.jsx";

// Scheme Five
import ClerkSchemeFiveTotalApplications from "./clerck/ClerkSchemeFive/ClerkSchemeFiveTotalApplications.jsx";
import ClerkSchemeFiveApprovedApplications from "./clerck/ClerkSchemeFive/ClerkSchemeFiveApprovedApplications.jsx";
import ClerkSchemeFiveRejectedApplications from "./clerck/ClerkSchemeFive/ClerkSchemeFiveRejectedApplications.jsx";
import ClerkSchemeFivePendingApplications from "./clerck/ClerkSchemeFive/ClerkSchemeFivePendingApplications.jsx";

// Scheme Six
import ClerkSchemeSixTotalApplications from "./clerck/ClerkSchemeSix/ClerkSchemeSixTotalApplications.jsx";
import ClerkSchemeSixApprovedApplications from "./clerck/ClerkSchemeSix/ClerkSchemeSixApprovedApplications.jsx";
import ClerkSchemeSixRejectedApplications from "./clerck/ClerkSchemeSix/ClerkSchemeSixRejectedApplications.jsx";
import ClerkSchemeSixPendingApplications from "./clerck/ClerkSchemeSix/ClerkSchemeSixPendingApplications.jsx";

// Scheme Seven
import ClerkSchemeSevenTotalApplications from "./clerck/ClerkSchemeSeven/ClerkSchemeSevenTotalApplications.jsx";
import ClerkSchemeSevenApprovedApplications from "./clerck/ClerkSchemeSeven/ClerkSchemeSevenApprovedApplications.jsx";
import ClerkSchemeSevenRejectedApplications from "./clerck/ClerkSchemeSeven/ClerkSchemeSevenRejectedApplications.jsx";
import ClerkSchemeSevenPendingApplications from "./clerck/ClerkSchemeSeven/ClerkSchemeSevenPendingApplications.jsx";

// Scheme Eight
import ClerkSchemeEightTotalApplications from "./clerck/ClerkSchemeEight/ClerkSchemeEightTotalApplications.jsx";
import ClerkSchemeEightApprovedApplications from "./clerck/ClerkSchemeEight/ClerkSchemeEightApprovedApplications.jsx";
import ClerkSchemeEightRejectedApplications from "./clerck/ClerkSchemeEight/ClerkSchemeEightRejectedApplications.jsx";
import ClerkSchemeEightPendingApplications from "./clerck/ClerkSchemeEight/ClerkSchemeEightPendingApplications.jsx";

// Scheme Nine
import ClerkSchemeNineTotalApplications from "./clerck/ClerkSchemeNine/ClerkSchemeNineTotalApplications.jsx";
import ClerkSchemeNineApprovedApplications from "./clerck/ClerkSchemeNine/ClerkSchemeNineApprovedApplications.jsx";
import ClerkSchemeNineRejectedApplications from "./clerck/ClerkSchemeNine/ClerkSchemeNineRejectedApplications.jsx";
import ClerkSchemeNinePendingApplications from "./clerck/ClerkSchemeNine/ClerkSchemeNinePendingApplications.jsx";

// Scheme Ten
import ClerkSchemeTenTotalApplications from "./clerck/ClerkSchemeTen/ClerkSchemeTenTotalApplications.jsx";
import ClerkSchemeTenApprovedApplications from "./clerck/ClerkSchemeTen/ClerkSchemeTenApprovedApplications.jsx";
import ClerkSchemeTenRejectedApplications from "./clerck/ClerkSchemeTen/ClerkSchemeTenRejectedApplications.jsx";
import ClerkSchemeTenPendingApplications from "./clerck/ClerkSchemeTen/ClerkSchemeTenPendingApplications.jsx";

import ChananiSamiti1Login from "./chananisamiti1/ChananiSamiti1Login.jsx";
import ChananiSamiti1Container from "./containers/ChananiSamiti1Container.jsx";
import ChananiSamiti1Dashboard from "./chananisamiti1/ChananiSamiti1Dashboard.jsx";
import ChananiSamiti2Container from "./containers/ChananiSamiti2Container.jsx";
import ChananiSamiti2Login from "./chananisamiti2/ChananiSamiti2Login.jsx";
import ChananiSamiti2Dashboard from "./chananisamiti2/ChananiSamiti2Dashboard.jsx";
import ChananiSamiti3Login from "./chananisamiti3/ChananiSamiti3Login.jsx";
import ChananiSamiti3Container from "./containers/ChananiSamiti3Container.jsx";
import ChananiSamiti3Dashboard from "./chananisamiti3/ChananiSamiti3Dashboard.jsx";
import ChananiSamiti4Container from "./containers/ChananiSamiti4Container.jsx";
import ChananiSamiti4Login from "./chananisamiti4/ChananiSamiti4Login.jsx";
import ChananiSamiti4Dashboard from "./chananisamiti4/ChananiSamiti4Dashboard.jsx";

//shaskiy samiti
// scheme one
import ShaskiySamitiSchemeOneApprovedApplications from "./shaskiysamiti/ShaskiySamitiSchemeOne/ShaskiySamitiSchemeOneApprovedApplications.jsx";
import ShaskiySamitiSchemeOneTotalApplications from "./shaskiysamiti/ShaskiySamitiSchemeOne/ShaskiySamitiSchemeOneTotalApplications.jsx";
import ShaskiySamitiSchemeOnePendingApplications from "./shaskiysamiti/ShaskiySamitiSchemeOne/ShaskiySamitiSchemeOnePendingApplications.jsx";
import ShaskiySamitiSchemeOneRejectedApplications from "./shaskiysamiti/ShaskiySamitiSchemeOne/ShaskiySamitiSchemeOneRejectedApplications.jsx";

// scheme two
import ShaskiySamitiSchemeTwoApprovedApplications from "./shaskiysamiti/ShaskiySamitiSchemeTwo/ShaskiySamitiSchemeTwoApprovedApplications.jsx";
import ShaskiySamitiSchemeTwoTotalApplications from "./shaskiysamiti/ShaskiySamitiSchemeTwo/ShaskiySamitiSchemeTwoTotalApplications.jsx";
import ShaskiySamitiSchemeTwoPendingApplications from "./shaskiysamiti/ShaskiySamitiSchemeTwo/ShaskiySamitiSchemeTwoPendingApplications.jsx";
import ShaskiySamitiSchemeTwoRejectedApplications from "./shaskiysamiti/ShaskiySamitiSchemeTwo/ShaskiySamitiSchemeTwoRejectedApplications.jsx";

// scheme three
import ShaskiySamitiSchemeThreeApprovedApplications from "./shaskiysamiti/ShaskiySamitiSchemeThree/ShaskiySamitiSchemeThreeApprovedApplications.jsx";
import ShaskiySamitiSchemeThreeTotalApplications from "./shaskiysamiti/ShaskiySamitiSchemeThree/ShaskiySamitiSchemeThreeTotalApplications.jsx";
import ShaskiySamitiSchemeThreePendingApplications from "./shaskiysamiti/ShaskiySamitiSchemeThree/ShaskiySamitiSchemeThreePendingApplications.jsx";
import ShaskiySamitiSchemeThreeRejectedApplications from "./shaskiysamiti/ShaskiySamitiSchemeThree/ShaskiySamitiSchemeThreeRejectedApplications.jsx";

// scheme four
import ShaskiySamitiSchemeFourApprovedApplications from "./shaskiysamiti/ShaskiySamitiSchemeFour/ShaskiySamitiSchemeFourApprovedApplications.jsx";
import ShaskiySamitiSchemeFourTotalApplications from "./shaskiysamiti/ShaskiySamitiSchemeFour/ShaskiySamitiSchemeFourTotalApplications.jsx";
import ShaskiySamitiSchemeFourPendingApplications from "./shaskiysamiti/ShaskiySamitiSchemeFour/ShaskiySamitiSchemeFourPendingApplications.jsx";
import ShaskiySamitiSchemeFourRejectedApplications from "./shaskiysamiti/ShaskiySamitiSchemeFour/ShaskiySamitiSchemeFourRejectedApplications.jsx";

// scheme five
import ShaskiySamitiSchemeFiveApprovedApplications from "./shaskiysamiti/ShaskiySamitiSchemeFive/ShaskiySamitiSchemeFiveApprovedApplications.jsx";
import ShaskiySamitiSchemeFiveTotalApplications from "./shaskiysamiti/ShaskiySamitiSchemeFive/ShaskiySamitiSchemeFiveTotalApplications.jsx";
import ShaskiySamitiSchemeFivePendingApplications from "./shaskiysamiti/ShaskiySamitiSchemeFive/ShaskiySamitiSchemeFivePendingApplications.jsx";
import ShaskiySamitiSchemeFiveRejectedApplications from "./shaskiysamiti/ShaskiySamitiSchemeFive/ShaskiySamitiSchemeFiveRejectedApplications.jsx";

// scheme six
import ShaskiySamitiSchemeSixApprovedApplications from "./shaskiysamiti/ShaskiySamitiSchemeSix/ShaskiySamitiSchemeSixApprovedApplications.jsx";
import ShaskiySamitiSchemeSixTotalApplications from "./shaskiysamiti/ShaskiySamitiSchemeSix/ShaskiySamitiSchemeSixTotalApplications.jsx";
import ShaskiySamitiSchemeSixPendingApplications from "./shaskiysamiti/ShaskiySamitiSchemeSix/ShaskiySamitiSchemeSixPendingApplications.jsx";
import ShaskiySamitiSchemeSixRejectedApplications from "./shaskiysamiti/ShaskiySamitiSchemeSix/ShaskiySamitiSchemeSixRejectedApplications.jsx";

// scheme seven
import ShaskiySamitiSchemeSevenApprovedApplications from "./shaskiysamiti/ShaskiySamitiSchemeSeven/ShaskiySamitiSchemeSevenApprovedApplications.jsx";
import ShaskiySamitiSchemeSevenTotalApplications from "./shaskiysamiti/ShaskiySamitiSchemeSeven/ShaskiySamitiSchemeSevenTotalApplications.jsx";
import ShaskiySamitiSchemeSevenPendingApplications from "./shaskiysamiti/ShaskiySamitiSchemeSeven/ShaskiySamitiSchemeSevenPendingApplications.jsx";
import ShaskiySamitiSchemeSevenRejectedApplications from "./shaskiysamiti/ShaskiySamitiSchemeSeven/ShaskiySamitiSchemeSevenRejectedApplications.jsx";

// scheme eight
import ShaskiySamitiSchemeEightApprovedApplications from "./shaskiysamiti/ShaskiySamitiSchemeEight/ShaskiySamitiSchemeEightApprovedApplications.jsx";
import ShaskiySamitiSchemeEightTotalApplications from "./shaskiysamiti/ShaskiySamitiSchemeEight/ShaskiySamitiSchemeEightTotalApplications.jsx";
import ShaskiySamitiSchemeEightPendingApplications from "./shaskiysamiti/ShaskiySamitiSchemeEight/ShaskiySamitiSchemeEightPendingApplications.jsx";
import ShaskiySamitiSchemeEightRejectedApplications from "./shaskiysamiti/ShaskiySamitiSchemeEight/ShaskiySamitiSchemeEightRejectedApplications.jsx";

// scheme nine
import ShaskiySamitiSchemeNineApprovedApplications from "./shaskiysamiti/ShaskiySamitiSchemeNine/ShaskiySamitiSchemeNineApprovedApplications.jsx";
import ShaskiySamitiSchemeNineTotalApplications from "./shaskiysamiti/ShaskiySamitiSchemeNine/ShaskiySamitiSchemeNineTotalApplications.jsx";
import ShaskiySamitiSchemeNinePendingApplications from "./shaskiysamiti/ShaskiySamitiSchemeNine/ShaskiySamitiSchemeNinePendingApplications.jsx";
import ShaskiySamitiSchemeNineRejectedApplications from "./shaskiysamiti/ShaskiySamitiSchemeNine/ShaskiySamitiSchemeNineRejectedApplications.jsx";

// scheme ten
import ShaskiySamitiSchemeTenApprovedApplications from "./shaskiysamiti/ShaskiySamitiSchemeTen/ShaskiySamitiSchemeTenApprovedApplications.jsx";
import ShaskiySamitiSchemeTenTotalApplications from "./shaskiysamiti/ShaskiySamitiSchemeTen/ShaskiySamitiSchemeTenTotalApplications.jsx";
import ShaskiySamitiSchemeTenPendingApplications from "./shaskiysamiti/ShaskiySamitiSchemeTen/ShaskiySamitiSchemeTenPendingApplications.jsx";
import ShaskiySamitiSchemeTenRejectedApplications from "./shaskiysamiti/ShaskiySamitiSchemeTen/ShaskiySamitiSchemeTenRejectedApplications.jsx";

//shaskiy samiti one
// scheme one
import ShaskiySamiti1SchemeOneApprovedApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeOne/ShaskiySamiti1SchemeOneApprovedApplications.jsx";
import ShaskiySamiti1SchemeOnePendingApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeOne/ShaskiySamiti1SchemeOnePendingApplications.jsx";
import ShaskiySamiti1SchemeOneRejectedApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeOne/ShaskiySamiti1SchemeOneRejectedApplications.jsx";
import ShaskiySamiti1SchemeOneTotalApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeOne/ShaskiySamiti1SchemeOneTotalApplications.jsx";

// scheme two
import ShaskiySamiti1SchemeTwoApprovedApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeTwo/ShaskiySamiti1SchemeTwoApprovedApplications.jsx";
import ShaskiySamiti1SchemeTwoPendingApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeTwo/ShaskiySamiti1SchemeTwoPendingApplications.jsx";
import ShaskiySamiti1SchemeTwoRejectedApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeTwo/ShaskiySamiti1SchemeTwoRejectedApplications.jsx";
import ShaskiySamiti1SchemeTwoTotalApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeTwo/ShaskiySamiti1SchemeTwoTotalApplications.jsx";

// scheme three
import ShaskiySamiti1SchemeThreeApprovedApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeThree/ShaskiySamiti1SchemeThreeApprovedApplications.jsx";
import ShaskiySamiti1SchemeThreePendingApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeThree/ShaskiySamiti1SchemeThreePendingApplications.jsx";
import ShaskiySamiti1SchemeThreeRejectedApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeThree/ShaskiySamiti1SchemeThreeRejectedApplications.jsx";
import ShaskiySamiti1SchemeThreeTotalApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeThree/ShaskiySamiti1SchemeThreeTotalApplications.jsx";

// scheme four
import ShaskiySamiti1SchemeFourApprovedApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeFour/ShaskiySamiti1SchemeFourApprovedApplications.jsx";
import ShaskiySamiti1SchemeFourPendingApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeFour/ShaskiySamiti1SchemeFourPendingApplications.jsx";
import ShaskiySamiti1SchemeFourRejectedApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeFour/ShaskiySamiti1SchemeFourRejectedApplications.jsx";
import ShaskiySamiti1SchemeFourTotalApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeFour/ShaskiySamiti1SchemeFourTotalApplications.jsx";

// scheme five
import ShaskiySamiti1SchemeFiveApprovedApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeFive/ShaskiySamiti1SchemeFiveApprovedApplications.jsx";
import ShaskiySamiti1SchemeFivePendingApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeFive/ShaskiySamiti1SchemeFivePendingApplications.jsx";
import ShaskiySamiti1SchemeFiveRejectedApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeFive/ShaskiySamiti1SchemeFiveRejectedApplications.jsx";
import ShaskiySamiti1SchemeFiveTotalApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeFive/ShaskiySamiti1SchemeFiveTotalApplications.jsx";

// scheme six
import ShaskiySamiti1SchemeSixApprovedApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeSix/ShaskiySamiti1SchemeSixApprovedApplications.jsx";
import ShaskiySamiti1SchemeSixPendingApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeSix/ShaskiySamiti1SchemeSixPendingApplications.jsx";
import ShaskiySamiti1SchemeSixRejectedApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeSix/ShaskiySamiti1SchemeSixRejectedApplications.jsx";
import ShaskiySamiti1SchemeSixTotalApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeSix/ShaskiySamiti1SchemeSixTotalApplications.jsx";

// scheme seven
import ShaskiySamiti1SchemeSevenApprovedApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeSeven/ShaskiySamiti1SchemeSevenApprovedApplications.jsx";
import ShaskiySamiti1SchemeSevenPendingApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeSeven/ShaskiySamiti1SchemeSevenPendingApplications.jsx";
import ShaskiySamiti1SchemeSevenRejectedApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeSeven/ShaskiySamiti1SchemeSevenRejectedApplications.jsx";
import ShaskiySamiti1SchemeSevenTotalApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeSeven/ShaskiySamiti1SchemeSevenTotalApplications.jsx";

// scheme eight
import ShaskiySamiti1SchemeEightApprovedApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeEight/ShaskiySamiti1SchemeEightApprovedApplications.jsx";
import ShaskiySamiti1SchemeEightPendingApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeEight/ShaskiySamiti1SchemeEightPendingApplications.jsx";
import ShaskiySamiti1SchemeEightRejectedApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeEight/ShaskiySamiti1SchemeEightRejectedApplications.jsx";
import ShaskiySamiti1SchemeEightTotalApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeEight/ShaskiySamiti1SchemeEightTotalApplications.jsx";

// scheme nine
import ShaskiySamiti1SchemeNineApprovedApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeNine/ShaskiySamiti1SchemeNineApprovedApplications.jsx";
import ShaskiySamiti1SchemeNinePendingApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeNine/ShaskiySamiti1SchemeNinePendingApplications.jsx";
import ShaskiySamiti1SchemeNineRejectedApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeNine/ShaskiySamiti1SchemeNineRejectedApplications.jsx";
import ShaskiySamiti1SchemeNineTotalApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeNine/ShaskiySamiti1SchemeNineTotalApplications.jsx";

// scheme ten
import ShaskiySamiti1SchemeTenApprovedApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeTen/ShaskiySamiti1SchemeTenApprovedApplications.jsx";
import ShaskiySamiti1SchemeTenPendingApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeTen/ShaskiySamiti1SchemeTenPendingApplications.jsx";
import ShaskiySamiti1SchemeTenRejectedApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeTen/ShaskiySamiti1SchemeTenRejectedApplications.jsx";
import ShaskiySamiti1SchemeTenTotalApplications from "./shaskiysamiti1/ShaskiySamiti1SchemeTen/ShaskiySamiti1SchemeTenTotalApplications.jsx";

//shaskiy samiti two
// Scheme One
import ShaskiySamiti2SchemeOneApprovedApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeOne/ShaskiySamiti2SchemeOneApprovedApplications.jsx";
import ShaskiySamiti2SchemeOnePendingApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeOne/ShaskiySamiti2SchemeOnePendingApplications.jsx";
import ShaskiySamiti2SchemeOneRejectedApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeOne/ShaskiySamiti2SchemeOneRejectedApplications.jsx";
import ShaskiySamiti2SchemeOneTotalApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeOne/ShaskiySamiti2SchemeOneTotalApplications.jsx";

// Scheme Two
import ShaskiySamiti2SchemeTwoApprovedApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeTwo/ShaskiySamiti2SchemeTwoApprovedApplications.jsx";
import ShaskiySamiti2SchemeTwoPendingApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeTwo/ShaskiySamiti2SchemeTwoPendingApplications.jsx";
import ShaskiySamiti2SchemeTwoRejectedApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeTwo/ShaskiySamiti2SchemeTwoRejectedApplications.jsx";
import ShaskiySamiti2SchemeTwoTotalApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeTwo/ShaskiySamiti2SchemeTwoTotalApplications.jsx";

// Scheme Three
import ShaskiySamiti2SchemeThreeApprovedApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeThree/ShaskiySamiti2SchemeThreeApprovedApplications.jsx";
import ShaskiySamiti2SchemeThreePendingApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeThree/ShaskiySamiti2SchemeThreePendingApplications.jsx";
import ShaskiySamiti2SchemeThreeRejectedApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeThree/ShaskiySamiti2SchemeThreeRejectedApplications.jsx";
import ShaskiySamiti2SchemeThreeTotalApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeThree/ShaskiySamiti2SchemeThreeTotalApplications.jsx";

// Scheme Four
import ShaskiySamiti2SchemeFourApprovedApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeFour/ShaskiySamiti2SchemeFourApprovedApplications.jsx";
import ShaskiySamiti2SchemeFourPendingApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeFour/ShaskiySamiti2SchemeFourPendingApplications.jsx";
import ShaskiySamiti2SchemeFourRejectedApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeFour/ShaskiySamiti2SchemeFourRejectedApplications.jsx";
import ShaskiySamiti2SchemeFourTotalApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeFour/ShaskiySamiti2SchemeFourTotalApplications.jsx";

// Scheme Five
import ShaskiySamiti2SchemeFiveApprovedApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeFive/ShaskiySamiti2SchemeFiveApprovedApplications.jsx";
import ShaskiySamiti2SchemeFivePendingApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeFive/ShaskiySamiti2SchemeFivePendingApplications.jsx";
import ShaskiySamiti2SchemeFiveRejectedApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeFive/ShaskiySamiti2SchemeFiveRejectedApplications.jsx";
import ShaskiySamiti2SchemeFiveTotalApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeFive/ShaskiySamiti2SchemeFiveTotalApplications.jsx";

// Scheme Six
import ShaskiySamiti2SchemeSixApprovedApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeSix/ShaskiySamiti2SchemeSixApprovedApplications.jsx";
import ShaskiySamiti2SchemeSixPendingApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeSix/ShaskiySamiti2SchemeSixPendingApplications.jsx";
import ShaskiySamiti2SchemeSixRejectedApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeSix/ShaskiySamiti2SchemeSixRejectedApplications.jsx";
import ShaskiySamiti2SchemeSixTotalApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeSix/ShaskiySamiti2SchemeSixTotalApplications.jsx";

// Scheme Seven
import ShaskiySamiti2SchemeSevenApprovedApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeSeven/ShaskiySamiti2SchemeSevenApprovedApplications.jsx";
import ShaskiySamiti2SchemeSevenPendingApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeSeven/ShaskiySamiti2SchemeSevenPendingApplications.jsx";
import ShaskiySamiti2SchemeSevenRejectedApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeSeven/ShaskiySamiti2SchemeSevenRejectedApplications.jsx";
import ShaskiySamiti2SchemeSevenTotalApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeSeven/ShaskiySamiti2SchemeSevenTotalApplications.jsx";

// Scheme Eight
import ShaskiySamiti2SchemeEightApprovedApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeEight/ShaskiySamiti2SchemeEightApprovedApplications.jsx";
import ShaskiySamiti2SchemeEightPendingApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeEight/ShaskiySamiti2SchemeEightPendingApplications.jsx";
import ShaskiySamiti2SchemeEightRejectedApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeEight/ShaskiySamiti2SchemeEightRejectedApplications.jsx";
import ShaskiySamiti2SchemeEightTotalApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeEight/ShaskiySamiti2SchemeEightTotalApplications.jsx";

// Scheme Nine
import ShaskiySamiti2SchemeNineApprovedApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeNine/ShaskiySamiti2SchemeNineApprovedApplications.jsx";
import ShaskiySamiti2SchemeNinePendingApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeNine/ShaskiySamiti2SchemeNinePendingApplications.jsx";
import ShaskiySamiti2SchemeNineRejectedApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeNine/ShaskiySamiti2SchemeNineRejectedApplications.jsx";
import ShaskiySamiti2SchemeNineTotalApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeNine/ShaskiySamiti2SchemeNineTotalApplications.jsx";

// Scheme Ten
import ShaskiySamiti2SchemeTenApprovedApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeTen/ShaskiySamiti2SchemeTenApprovedApplications.jsx";
import ShaskiySamiti2SchemeTenPendingApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeTen/ShaskiySamiti2SchemeTenPendingApplications.jsx";
import ShaskiySamiti2SchemeTenRejectedApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeTen/ShaskiySamiti2SchemeTenRejectedApplications.jsx";
import ShaskiySamiti2SchemeTenTotalApplications from "./shaskiysamiti2/ShaskiySamiti2SchemeTen/ShaskiySamiti2SchemeTenTotalApplications.jsx";

//shaskiy samiti three
// scheme one
import ShaskiySamiti3SchemeOneApprovedApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeOne/ShaskiySamiti3SchemeOneApprovedApplications.jsx";
import ShaskiySamiti3SchemeOnePendingApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeOne/ShaskiySamiti3SchemeOnePendingApplications.jsx";
import ShaskiySamiti3SchemeOneRejectedApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeOne/ShaskiySamiti3SchemeOneRejectedApplications.jsx";
import ShaskiySamiti3SchemeOneTotalApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeOne/ShaskiySamiti3SchemeOneTotalApplications.jsx";

// scheme two
import ShaskiySamiti3SchemeTwoApprovedApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeTwo/ShaskiySamiti3SchemeTwoApprovedApplications.jsx";
import ShaskiySamiti3SchemeTwoPendingApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeTwo/ShaskiySamiti3SchemeTwoPendingApplications.jsx";
import ShaskiySamiti3SchemeTwoRejectedApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeTwo/ShaskiySamiti3SchemeTwoRejectedApplications.jsx";
import ShaskiySamiti3SchemeTwoTotalApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeTwo/ShaskiySamiti3SchemeTwoTotalApplications.jsx";

// scheme three
import ShaskiySamiti3SchemeThreeApprovedApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeThree/ShaskiySamiti3SchemeThreeApprovedApplications.jsx";
import ShaskiySamiti3SchemeThreePendingApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeThree/ShaskiySamiti3SchemeThreePendingApplications.jsx";
import ShaskiySamiti3SchemeThreeRejectedApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeThree/ShaskiySamiti3SchemeThreeRejectedApplications.jsx";
import ShaskiySamiti3SchemeThreeTotalApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeThree/ShaskiySamiti3SchemeThreeTotalApplications.jsx";

// scheme four
import ShaskiySamiti3SchemeFourApprovedApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeFour/ShaskiySamiti3SchemeFourApprovedApplications.jsx";
import ShaskiySamiti3SchemeFourPendingApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeFour/ShaskiySamiti3SchemeFourPendingApplications.jsx";
import ShaskiySamiti3SchemeFourRejectedApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeFour/ShaskiySamiti3SchemeFourRejectedApplications.jsx";
import ShaskiySamiti3SchemeFourTotalApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeFour/ShaskiySamiti3SchemeFourTotalApplications.jsx";

// scheme five
import ShaskiySamiti3SchemeFiveApprovedApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeFive/ShaskiySamiti3SchemeFiveApprovedApplications.jsx";
import ShaskiySamiti3SchemeFivePendingApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeFive/ShaskiySamiti3SchemeFivePendingApplications.jsx";
import ShaskiySamiti3SchemeFiveRejectedApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeFive/ShaskiySamiti3SchemeFiveRejectedApplications.jsx";
import ShaskiySamiti3SchemeFiveTotalApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeFive/ShaskiySamiti3SchemeFiveTotalApplications.jsx";

// scheme six
import ShaskiySamiti3SchemeSixApprovedApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeSix/ShaskiySamiti3SchemeSixApprovedApplications.jsx";
import ShaskiySamiti3SchemeSixPendingApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeSix/ShaskiySamiti3SchemeSixPendingApplications.jsx";
import ShaskiySamiti3SchemeSixRejectedApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeSix/ShaskiySamiti3SchemeSixRejectedApplications.jsx";
import ShaskiySamiti3SchemeSixTotalApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeSix/ShaskiySamiti3SchemeSixTotalApplications.jsx";

// scheme seven
import ShaskiySamiti3SchemeSevenApprovedApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeSeven/ShaskiySamiti3SchemeSevenApprovedApplications.jsx";
import ShaskiySamiti3SchemeSevenPendingApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeSeven/ShaskiySamiti3SchemeSevenPendingApplications.jsx";
import ShaskiySamiti3SchemeSevenRejectedApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeSeven/ShaskiySamiti3SchemeSevenRejectedApplications.jsx";
import ShaskiySamiti3SchemeSevenTotalApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeSeven/ShaskiySamiti3SchemeSevenTotalApplications.jsx";

// scheme eight
import ShaskiySamiti3SchemeEightApprovedApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeEight/ShaskiySamiti3SchemeEightApprovedApplications.jsx";
import ShaskiySamiti3SchemeEightPendingApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeEight/ShaskiySamiti3SchemeEightPendingApplications.jsx";
import ShaskiySamiti3SchemeEightRejectedApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeEight/ShaskiySamiti3SchemeEightRejectedApplications.jsx";
import ShaskiySamiti3SchemeEightTotalApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeEight/ShaskiySamiti3SchemeEightTotalApplications.jsx";

// scheme nine
import ShaskiySamiti3SchemeNineApprovedApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeNine/ShaskiySamiti3SchemeNineApprovedApplications.jsx";
import ShaskiySamiti3SchemeNinePendingApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeNine/ShaskiySamiti3SchemeNinePendingApplications.jsx";
import ShaskiySamiti3SchemeNineRejectedApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeNine/ShaskiySamiti3SchemeNineRejectedApplications.jsx";
import ShaskiySamiti3SchemeNineTotalApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeNine/ShaskiySamiti3SchemeNineTotalApplications.jsx";

// scheme ten
import ShaskiySamiti3SchemeTenApprovedApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeTen/ShaskiySamiti3SchemeTenApprovedApplications.jsx";
import ShaskiySamiti3SchemeTenPendingApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeTen/ShaskiySamiti3SchemeTenPendingApplications.jsx";
import ShaskiySamiti3SchemeTenRejectedApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeTen/ShaskiySamiti3SchemeTenRejectedApplications.jsx";
import ShaskiySamiti3SchemeTenTotalApplications from "./shaskiysamiti3/ShaskiySamiti3SchemeTen/ShaskiySamiti3SchemeTenTotalApplications.jsx";

//shaskiy samiti four
// scheme one
import ShaskiySamiti4SchemeOneApprovedApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeOne/ShaskiySamiti4SchemeOneApprovedApplications.jsx";
import ShaskiySamiti4SchemeOnePendingApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeOne/ShaskiySamiti4SchemeOnePendingApplications.jsx";
import ShaskiySamiti4SchemeOneRejectedApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeOne/ShaskiySamiti4SchemeOneRejectedApplications.jsx";
import ShaskiySamiti4SchemeOneTotalApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeOne/ShaskiySamiti4SchemeOneTotalApplications.jsx";

// scheme two
import ShaskiySamiti4SchemeTwoApprovedApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeTwo/ShaskiySamiti4SchemeTwoApprovedApplications.jsx";
import ShaskiySamiti4SchemeTwoPendingApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeTwo/ShaskiySamiti4SchemeTwoPendingApplications.jsx";
import ShaskiySamiti4SchemeTwoRejectedApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeTwo/ShaskiySamiti4SchemeTwoRejectedApplications.jsx";
import ShaskiySamiti4SchemeTwoTotalApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeTwo/ShaskiySamiti4SchemeTwoTotalApplications.jsx";

// scheme three
import ShaskiySamiti4SchemeThreeApprovedApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeThree/ShaskiySamiti4SchemeThreeApprovedApplications.jsx";
import ShaskiySamiti4SchemeThreePendingApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeThree/ShaskiySamiti4SchemeThreePendingApplications.jsx";
import ShaskiySamiti4SchemeThreeRejectedApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeThree/ShaskiySamiti4SchemeThreeRejectedApplications.jsx";
import ShaskiySamiti4SchemeThreeTotalApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeThree/ShaskiySamiti4SchemeThreeTotalApplications.jsx";

// scheme four
import ShaskiySamiti4SchemeFourApprovedApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeFour/ShaskiySamiti4SchemeFourApprovedApplications.jsx";
import ShaskiySamiti4SchemeFourPendingApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeFour/ShaskiySamiti4SchemeFourPendingApplications.jsx";
import ShaskiySamiti4SchemeFourRejectedApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeFour/ShaskiySamiti4SchemeFourRejectedApplications.jsx";
import ShaskiySamiti4SchemeFourTotalApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeFour/ShaskiySamiti4SchemeFourTotalApplications.jsx";

// scheme five
import ShaskiySamiti4SchemeFiveApprovedApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeFive/ShaskiySamiti4SchemeFiveApprovedApplications.jsx";
import ShaskiySamiti4SchemeFivePendingApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeFive/ShaskiySamiti4SchemeFivePendingApplications.jsx";
import ShaskiySamiti4SchemeFiveRejectedApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeFive/ShaskiySamiti4SchemeFiveRejectedApplications.jsx";
import ShaskiySamiti4SchemeFiveTotalApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeFive/ShaskiySamiti4SchemeFiveTotalApplications.jsx";

// scheme six
import ShaskiySamiti4SchemeSixApprovedApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeSix/ShaskiySamiti4SchemeSixApprovedApplications.jsx";
import ShaskiySamiti4SchemeSixPendingApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeSix/ShaskiySamiti4SchemeSixPendingApplications.jsx";
import ShaskiySamiti4SchemeSixRejectedApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeSix/ShaskiySamiti4SchemeSixRejectedApplications.jsx";
import ShaskiySamiti4SchemeSixTotalApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeSix/ShaskiySamiti4SchemeSixTotalApplications.jsx";

// scheme seven
import ShaskiySamiti4SchemeSevenApprovedApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeSeven/ShaskiySamiti4SchemeSevenApprovedApplications.jsx";
import ShaskiySamiti4SchemeSevenPendingApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeSeven/ShaskiySamiti4SchemeSevenPendingApplications.jsx";
import ShaskiySamiti4SchemeSevenRejectedApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeSeven/ShaskiySamiti4SchemeSevenRejectedApplications.jsx";
import ShaskiySamiti4SchemeSevenTotalApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeSeven/ShaskiySamiti4SchemeSevenTotalApplications.jsx";

// scheme eight
import ShaskiySamiti4SchemeEightApprovedApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeEight/ShaskiySamiti4SchemeEightApprovedApplications.jsx";
import ShaskiySamiti4SchemeEightPendingApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeEight/ShaskiySamiti4SchemeEightPendingApplications.jsx";
import ShaskiySamiti4SchemeEightRejectedApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeEight/ShaskiySamiti4SchemeEightRejectedApplications.jsx";
import ShaskiySamiti4SchemeEightTotalApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeEight/ShaskiySamiti4SchemeEightTotalApplications.jsx";

// scheme nine
import ShaskiySamiti4SchemeNineApprovedApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeNine/ShaskiySamiti4SchemeNineApprovedApplications.jsx";
import ShaskiySamiti4SchemeNinePendingApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeNine/ShaskiySamiti4SchemeNinePendingApplications.jsx";
import ShaskiySamiti4SchemeNineRejectedApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeNine/ShaskiySamiti4SchemeNineRejectedApplications.jsx";
import ShaskiySamiti4SchemeNineTotalApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeNine/ShaskiySamiti4SchemeNineTotalApplications.jsx";

// scheme ten
import ShaskiySamiti4SchemeTenApprovedApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeTen/ShaskiySamiti4SchemeTenApprovedApplications.jsx";
import ShaskiySamiti4SchemeTenPendingApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeTen/ShaskiySamiti4SchemeTenPendingApplications.jsx";
import ShaskiySamiti4SchemeTenRejectedApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeTen/ShaskiySamiti4SchemeTenRejectedApplications.jsx";
import ShaskiySamiti4SchemeTenTotalApplications from "./shaskiysamiti4/ShaskiySamiti4SchemeTen/ShaskiySamiti4SchemeTenTotalApplications.jsx";

//shaskiy samiti five
// scheme one
import ShaskiySamiti5SchemeOneApprovedApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeOne/ShaskiySamiti5SchemeOneApprovedApplications.jsx";
import ShaskiySamiti5SchemeOnePendingApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeOne/ShaskiySamiti5SchemeOnePendingApplications.jsx";
import ShaskiySamiti5SchemeOneRejectedApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeOne/ShaskiySamiti5SchemeOneRejectedApplications.jsx";
import ShaskiySamiti5SchemeOneTotalApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeOne/ShaskiySamiti5SchemeOneTotalApplications.jsx";

// scheme two
import ShaskiySamiti5SchemeTwoApprovedApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeTwo/ShaskiySamiti5SchemeTwoApprovedApplications.jsx";
import ShaskiySamiti5SchemeTwoPendingApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeTwo/ShaskiySamiti5SchemeTwoPendingApplications.jsx";
import ShaskiySamiti5SchemeTwoRejectedApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeTwo/ShaskiySamiti5SchemeTwoRejectedApplications.jsx";
import ShaskiySamiti5SchemeTwoTotalApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeTwo/ShaskiySamiti5SchemeTwoTotalApplications.jsx";

// scheme three
import ShaskiySamiti5SchemeThreeApprovedApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeThree/ShaskiySamiti5SchemeThreeApprovedApplications.jsx";
import ShaskiySamiti5SchemeThreePendingApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeThree/ShaskiySamiti5SchemeThreePendingApplications.jsx";
import ShaskiySamiti5SchemeThreeRejectedApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeThree/ShaskiySamiti5SchemeThreeRejectedApplications.jsx";
import ShaskiySamiti5SchemeThreeTotalApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeThree/ShaskiySamiti5SchemeThreeTotalApplications.jsx";

// scheme four
import ShaskiySamiti5SchemeFourApprovedApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeFour/ShaskiySamiti5SchemeFourApprovedApplications.jsx";
import ShaskiySamiti5SchemeFourPendingApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeFour/ShaskiySamiti5SchemeFourPendingApplications.jsx";
import ShaskiySamiti5SchemeFourRejectedApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeFour/ShaskiySamiti5SchemeFourRejectedApplications.jsx";
import ShaskiySamiti5SchemeFourTotalApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeFour/ShaskiySamiti5SchemeFourTotalApplications.jsx";

// scheme five
import ShaskiySamiti5SchemeFiveApprovedApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeFive/ShaskiySamiti5SchemeFiveApprovedApplications.jsx";
import ShaskiySamiti5SchemeFivePendingApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeFive/ShaskiySamiti5SchemeFivePendingApplications.jsx";
import ShaskiySamiti5SchemeFiveRejectedApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeFive/ShaskiySamiti5SchemeFiveRejectedApplications.jsx";
import ShaskiySamiti5SchemeFiveTotalApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeFive/ShaskiySamiti5SchemeFiveTotalApplications.jsx";

// scheme six
import ShaskiySamiti5SchemeSixApprovedApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeSix/ShaskiySamiti5SchemeSixApprovedApplications.jsx";
import ShaskiySamiti5SchemeSixPendingApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeSix/ShaskiySamiti5SchemeSixPendingApplications.jsx";
import ShaskiySamiti5SchemeSixRejectedApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeSix/ShaskiySamiti5SchemeSixRejectedApplications.jsx";
import ShaskiySamiti5SchemeSixTotalApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeSix/ShaskiySamiti5SchemeSixTotalApplications.jsx";

// scheme seven
import ShaskiySamiti5SchemeSevenApprovedApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeSeven/ShaskiySamiti5SchemeSevenApprovedApplications.jsx";
import ShaskiySamiti5SchemeSevenPendingApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeSeven/ShaskiySamiti5SchemeSevenPendingApplications.jsx";
import ShaskiySamiti5SchemeSevenRejectedApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeSeven/ShaskiySamiti5SchemeSevenRejectedApplications.jsx";
import ShaskiySamiti5SchemeSevenTotalApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeSeven/ShaskiySamiti5SchemeSevenTotalApplications.jsx";

// scheme eight
import ShaskiySamiti5SchemeEightApprovedApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeEight/ShaskiySamiti5SchemeEightApprovedApplications.jsx";
import ShaskiySamiti5SchemeEightPendingApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeEight/ShaskiySamiti5SchemeEightPendingApplications.jsx";
import ShaskiySamiti5SchemeEightRejectedApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeEight/ShaskiySamiti5SchemeEightRejectedApplications.jsx";
import ShaskiySamiti5SchemeEightTotalApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeEight/ShaskiySamiti5SchemeEightTotalApplications.jsx";

// scheme nine
import ShaskiySamiti5SchemeNineApprovedApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeNine/ShaskiySamiti5SchemeNineApprovedApplications.jsx";
import ShaskiySamiti5SchemeNinePendingApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeNine/ShaskiySamiti5SchemeNinePendingApplications.jsx";
import ShaskiySamiti5SchemeNineRejectedApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeNine/ShaskiySamiti5SchemeNineRejectedApplications.jsx";
import ShaskiySamiti5SchemeNineTotalApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeNine/ShaskiySamiti5SchemeNineTotalApplications.jsx";

// scheme ten
import ShaskiySamiti5SchemeTenApprovedApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeTen/ShaskiySamiti5SchemeTenApprovedApplications.jsx";
import ShaskiySamiti5SchemeTenPendingApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeTen/ShaskiySamiti5SchemeTenPendingApplications.jsx";
import ShaskiySamiti5SchemeTenRejectedApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeTen/ShaskiySamiti5SchemeTenRejectedApplications.jsx";
import ShaskiySamiti5SchemeTenTotalApplications from "./shaskiysamiti5/ShaskiySamiti5SchemeTen/ShaskiySamiti5SchemeTenTotalApplications.jsx";

import ShaskiySamiti2Container from "./containers/ShaskiySamiti2Container.jsx";
import ShaskiySamiti2Login from "./shaskiysamiti2/ShaskiySamiti2Login.jsx";

import ShaskiySamiti1Login from "./shaskiysamiti1/ShaskiySamiti1Login.jsx";
import ShaskiySamiti1Dashboard from "./shaskiysamiti1/ShaskiySamiti1Dashboard.jsx";
import ShaskiySamiti2Dashboard from "./shaskiysamiti2/ShaskiySamiti2Dashboard.jsx";
import ShaskiySamiti1Container from "./containers/ShaskiySamiti1Container.jsx";
import ShaskiySamiti4Login from "./shaskiysamiti4/ShaskiySamiti4Login.jsx";
import ShaskiySamiti4Container from "./containers/ShaskiySamiti4Container.jsx";
import ShaskiySamiti4Dashboard from "./shaskiysamiti4/ShaskiySamiti4Dashboard.jsx";
import ShaskiySamiti3Login from "./shaskiysamiti3/ShaskiySamiti3Login.jsx";
import ShaskiySamiti3Container from "./containers/ShaskiySamiti3Container.jsx";
import ShaskiySamiti3Dashboard from "./shaskiysamiti3/ShaskiySamiti3Dashboard.jsx";
import ShaskiySamiti5Login from "./shaskiysamiti5/ShaskiySamiti5Login.jsx";
import ShaskiySamiti5Container from "./containers/ShaskiySamiti5Container.jsx";
import ShaskiySamiti5Dashboard from "./shaskiysamiti5/ShaskiySamiti5Dashboard.jsx";
import NewPassword from "./pages/ForgotPassword/NewPassword.jsx";

import Clerk2Login from "./clerk2/Clerk2Login.jsx";
import Clerk2Container from "./containers/Clerk2Container.jsx";

// clerk 2 scheme one
import Clerk2SchemeOneTotalApplications from "./clerk2/ClerkSchemeOne/Clerk2SchemeOneTotalApplications.jsx";
import Clerk2SchemeOneApprovedApplications from "./clerk2/ClerkSchemeOne/Clerk2SchemeOneApprovedApplications.jsx";
import Clerk2SchemeOnePendingApplications from "./clerk2/ClerkSchemeOne/Clerk2SchemeOnePendingApplications.jsx";
import Clerk2SchemeOneRejectedApplications from "./clerk2/ClerkSchemeOne/Clerk2SchemeOneRejectedApplications.jsx";

import Clerk2SchemeTwoTotalApplications from "./clerk2/ClerkSchemeTwo/Clerk2SchemeTwoTotalApplications.jsx";
import Clerk2SchemeTwoApprovedApplications from "./clerk2/ClerkSchemeTwo/Clerk2SchemeTwoApprovedApplications.jsx";
import Clerk2SchemeTwoPendingApplications from "./clerk2/ClerkSchemeTwo/Clerk2SchemeTwoPendingApplications.jsx";
import Clerk2SchemeTwoRejectedApplications from "./clerk2/ClerkSchemeTwo/Clerk2SchemeTwoRejectedApplications.jsx";

import Clerk2SchemeThreeTotalApplications from "./clerk2/ClerkSchemeThree/Clerk2SchemeThreeTotalApplications.jsx";
import Clerk2SchemeThreeApprovedApplications from "./clerk2/ClerkSchemeThree/Clerk2SchemeThreeApprovedApplications.jsx";
import Clerk2SchemeThreePendingApplications from "./clerk2/ClerkSchemeThree/Clerk2SchemeThreePendingApplications.jsx";
import Clerk2SchemeThreeRejectedApplications from "./clerk2/ClerkSchemeThree/Clerk2SchemeThreeRejectedApplications.jsx";

import Clerk2SchemeFourTotalApplications from "./clerk2/ClerkSchemeFour/Clerk2SchemeFourTotalApplications.jsx";
import Clerk2SchemeFourApprovedApplications from "./clerk2/ClerkSchemeFour/Clerk2SchemeFourApprovedApplications.jsx";
import Clerk2SchemeFourPendingApplications from "./clerk2/ClerkSchemeFour/Clerk2SchemeFourPendingApplications.jsx";
import Clerk2SchemeFourRejectedApplications from "./clerk2/ClerkSchemeFour/Clerk2SchemeFourRejectedApplications.jsx";

import Clerk2SchemeFiveTotalApplications from "./clerk2/ClerkSchemeFive/Clerk2SchemeFiveTotalApplications.jsx";
import Clerk2SchemeFiveApprovedApplications from "./clerk2/ClerkSchemeFive/Clerk2SchemeFiveApprovedApplications.jsx";
import Clerk2SchemeFivePendingApplications from "./clerk2/ClerkSchemeFive/Clerk2SchemeFivePendingApplications.jsx";
import Clerk2SchemeFiveRejectedApplications from "./clerk2/ClerkSchemeFive/Clerk2SchemeFiveRejectedApplications.jsx";

import Clerk2SchemeSixTotalApplications from "./clerk2/ClerkSchemeSix/Clerk2SchemeSixTotalApplications.jsx";
import Clerk2SchemeSixApprovedApplications from "./clerk2/ClerkSchemeSix/Clerk2SchemeSixApprovedApplications.jsx";
import Clerk2SchemeSixPendingApplications from "./clerk2/ClerkSchemeSix/Clerk2SchemeSixPendingApplications.jsx";
import Clerk2SchemeSixRejectedApplications from "./clerk2/ClerkSchemeSix/Clerk2SchemeSixRejectedApplications.jsx";

import Clerk2SchemeSevenTotalApplications from "./clerk2/ClerkSchemeSeven/Clerk2SchemeSevenTotalApplications.jsx";
import Clerk2SchemeSevenApprovedApplications from "./clerk2/ClerkSchemeSeven/Clerk2SchemeSevenApprovedApplications.jsx";
import Clerk2SchemeSevenPendingApplications from "./clerk2/ClerkSchemeSeven/Clerk2SchemeSevenPendingApplications.jsx";
import Clerk2SchemeSevenRejectedApplications from "./clerk2/ClerkSchemeSeven/Clerk2SchemeSevenRejectedApplications.jsx";

import Clerk2SchemeEightTotalApplications from "./clerk2/ClerkSchemeEight/Clerk2SchemeEightTotalApplications.jsx";
import Clerk2SchemeEightApprovedApplications from "./clerk2/ClerkSchemeEight/Clerk2SchemeEightApprovedApplications.jsx";
import Clerk2SchemeEightPendingApplications from "./clerk2/ClerkSchemeEight/Clerk2SchemeEightPendingApplications.jsx";
import Clerk2SchemeEightRejectedApplications from "./clerk2/ClerkSchemeEight/Clerk2SchemeEightRejectedApplications.jsx";

import Clerk2SchemeNineTotalApplications from "./clerk2/ClerkSchemeNine/Clerk2SchemeNineTotalApplications.jsx";
import Clerk2SchemeNineApprovedApplications from "./clerk2/ClerkSchemeNine/Clerk2SchemeNineApprovedApplications.jsx";
import Clerk2SchemeNinePendingApplications from "./clerk2/ClerkSchemeNine/Clerk2SchemeNinePendingApplications.jsx";
import Clerk2SchemeNineRejectedApplications from "./clerk2/ClerkSchemeNine/Clerk2SchemeNineRejectedApplications.jsx";

import Clerk2SchemeTenTotalApplications from "./clerk2/ClerkSchemeTen/Clerk2SchemeTenTotalApplications.jsx";
import Clerk2SchemeTenApprovedApplications from "./clerk2/ClerkSchemeTen/Clerk2SchemeTenApprovedApplications.jsx";
import Clerk2SchemeTenPendingApplications from "./clerk2/ClerkSchemeTen/Clerk2SchemeTenPendingApplications.jsx";
import Clerk2SchemeTenRejectedApplications from "./clerk2/ClerkSchemeTen/Clerk2SchemeTenRejectedApplications.jsx";

import Clerk3Login from "./clerk3/Clerk3Login.jsx";
import Clerk3Container from "./containers/Clerk3Container.jsx";

//clerk 3
// Scheme 1
import Clerk3SchemeOneTotalApplications from "./clerk3/ClerkSchemeOne/ClerkSchemeOneTotalApplications.jsx";
import Clerk3SchemeOneApprovedApplications from "./clerk3/ClerkSchemeOne/ClerkSchemeOneApprovedApplications.jsx";
import Clerk3SchemeOnePendingApplications from "./clerk3/ClerkSchemeOne/ClerkSchemeOnePendingApplications.jsx";
import Clerk3SchemeOneRejectedApplications from "./clerk3/ClerkSchemeOne/ClerkSchemeOneRejectedApplications.jsx";

// Scheme 2
import Clerk3SchemeTwoTotalApplications from "./clerk3/ClerkSchemeTwo/ClerkSchemeTwoTotalApplications.jsx";
import Clerk3SchemeTwoApprovedApplications from "./clerk3/ClerkSchemeTwo/ClerkSchemeTwoApprovedApplications.jsx";
import Clerk3SchemeTwoPendingApplications from "./clerk3/ClerkSchemeTwo/ClerkSchemeTwoPendingApplications.jsx";
import Clerk3SchemeTwoRejectedApplications from "./clerk3/ClerkSchemeTwo/ClerkSchemeTwoRejectedApplications.jsx";

// Scheme 3
import Clerk3SchemeThreeTotalApplications from "./clerk3/ClerkSchemeThree/ClerkSchemeThreeTotalApplications.jsx";
import Clerk3SchemeThreeApprovedApplications from "./clerk3/ClerkSchemeThree/ClerkSchemeThreeApprovedApplications.jsx";
import Clerk3SchemeThreePendingApplications from "./clerk3/ClerkSchemeThree/ClerkSchemeThreePendingApplications.jsx";
import Clerk3SchemeThreeRejectedApplications from "./clerk3/ClerkSchemeThree/ClerkSchemeThreeRejectedApplications.jsx";

// Scheme 4
import Clerk3SchemeFourTotalApplications from "./clerk3/ClerkSchemeFour/ClerkSchemeFourTotalApplications.jsx";
import Clerk3SchemeFourApprovedApplications from "./clerk3/ClerkSchemeFour/ClerkSchemeFourApprovedApplications.jsx";
import Clerk3SchemeFourPendingApplications from "./clerk3/ClerkSchemeFour/ClerkSchemeFourPendingApplications.jsx";
import Clerk3SchemeFourRejectedApplications from "./clerk3/ClerkSchemeFour/ClerkSchemeFourRejectedApplications.jsx";

// Scheme 5
import Clerk3SchemeFiveTotalApplications from "./clerk3/ClerkSchemeFive/ClerkSchemeFiveTotalApplications.jsx";
import Clerk3SchemeFiveApprovedApplications from "./clerk3/ClerkSchemeFive/ClerkSchemeFiveApprovedApplications.jsx";
import Clerk3SchemeFivePendingApplications from "./clerk3/ClerkSchemeFive/ClerkSchemeFivePendingApplications.jsx";
import Clerk3SchemeFiveRejectedApplications from "./clerk3/ClerkSchemeFive/ClerkSchemeFiveRejectedApplications.jsx";

// Scheme 6
import Clerk3SchemeSixTotalApplications from "./clerk3/ClerkSchemeSix/ClerkSchemeSixTotalApplications.jsx";
import Clerk3SchemeSixApprovedApplications from "./clerk3/ClerkSchemeSix/ClerkSchemeSixApprovedApplications.jsx";
import Clerk3SchemeSixPendingApplications from "./clerk3/ClerkSchemeSix/ClerkSchemeSixPendingApplications.jsx";
import Clerk3SchemeSixRejectedApplications from "./clerk3/ClerkSchemeSix/ClerkSchemeSixRejectedApplications.jsx";

// Scheme 7
import Clerk3SchemeSevenTotalApplications from "./clerk3/ClerkSchemeSeven/ClerkSchemeSevenTotalApplications.jsx";
import Clerk3SchemeSevenApprovedApplications from "./clerk3/ClerkSchemeSeven/ClerkSchemeSevenApprovedApplications.jsx";
import Clerk3SchemeSevenPendingApplications from "./clerk3/ClerkSchemeSeven/ClerkSchemeSevenPendingApplications.jsx";
import Clerk3SchemeSevenRejectedApplications from "./clerk3/ClerkSchemeSeven/ClerkSchemeSevenRejectedApplications.jsx";

// Scheme 8
import Clerk3SchemeEightTotalApplications from "./clerk3/ClerkSchemeEight/ClerkSchemeEightTotalApplications.jsx";
import Clerk3SchemeEightApprovedApplications from "./clerk3/ClerkSchemeEight/ClerkSchemeEightApprovedApplications.jsx";
import Clerk3SchemeEightPendingApplications from "./clerk3/ClerkSchemeEight/ClerkSchemeEightPendingApplications.jsx";
import Clerk3SchemeEightRejectedApplications from "./clerk3/ClerkSchemeEight/ClerkSchemeEightRejectedApplications.jsx";

// Scheme 9
import Clerk3SchemeNineTotalApplications from "./clerk3/ClerkSchemeNine/ClerkSchemeNineTotalApplications.jsx";
import Clerk3SchemeNineApprovedApplications from "./clerk3/ClerkSchemeNine/ClerkSchemeNineApprovedApplications.jsx";
import Clerk3SchemeNinePendingApplications from "./clerk3/ClerkSchemeNine/ClerkSchemeNinePendingApplications.jsx";
import Clerk3SchemeNineRejectedApplications from "./clerk3/ClerkSchemeNine/ClerkSchemeNineRejectedApplications.jsx";

// Scheme 10
import Clerk3SchemeTenTotalApplications from "./clerk3/ClerkSchemeTen/ClerkSchemeTenTotalApplications.jsx";
import Clerk3SchemeTenApprovedApplications from "./clerk3/ClerkSchemeTen/ClerkSchemeTenApprovedApplications.jsx";
import Clerk3SchemeTenPendingApplications from "./clerk3/ClerkSchemeTen/ClerkSchemeTenPendingApplications.jsx";
import Clerk3SchemeTenRejectedApplications from "./clerk3/ClerkSchemeTen/ClerkSchemeTenRejectedApplications.jsx";

import Clerk2Dashboard from "./clerk2/Clerk2Dashboard.jsx";
import Clerk3Dashboard from "./clerk3/Clerk3Dashboard.jsx";
import MyAppliedSchemes from "./pages/profile/Profilepages/MyAppliedSchemes.jsx";
import AboutUs from "./pages/AboutUs/AboutUs.jsx";
import News from "./pages/News/News.jsx";
import SchemeContainer from "./containers/SchemeContainer.jsx";

import SportSchemeTwoForm from "./pages/SportSchemeForm/SportSchemeTwoForm.jsx";
import SportSchemeThreeForm from "./pages/SportSchemeForm/SportSchemeThreeForm.jsx";
import SchemeThreeForm from "./pages/WelfareSchemeForms/SchemeThreeForm.jsx";
import SchemeFourForm from "./pages/WelfareSchemeForms/SchemeFourForm.jsx";
import SchemeOneEdit from "./pages/editSchemePages/SchemeOneEdit.jsx";

const App = () => {
  return (
    <>
      {/* <Navbar /> */}
      <ScrollToTop />
      <ToastContainer {...ToastConfig} toastClassName="toast-homepage" />

      <Routes>
        <Route path="/" element={<HomepageContainer />}>
          <Route index element={<Home />} />
          <Route path="featuring-schemes" element={<FeaturingSchemes />} />
          <Route path="info-card" element={<InfoCard />} />
          {/* <Route path="Eligibility" element={<Eligibility />} /> */}
          <Route path="FinalPermission" element={<FinalPermission />} />
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="news" element={<News />} />
          {/* <Route path="/faq" element={<FAQSection />} /> */}
          {/* <Route path="view-schemes" element={<ViewSchemes />} /> */}
          <Route path="allschemespage" element={<Allschemespage />} />
          {/* <Route path="schemes" element={<Schemes />} /> */}

          <Route path="schemeoneform" element={<SchemeOneForm />} />
          <Route path="schemetwoform" element={<SchemeTwoForm />} />
          <Route path="schemethreeform" element={<SchemeThreeForm />} />
          <Route path="schemefourform" element={<SchemeFourForm />} />
          <Route path="SchemeForm" element={<SchemeForm />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/newpassword" element={<NewPassword />} />

          <Route path="/registration" element={<Registration />} />
          <Route
            path="aadhaarverification"
            element={<AadhaarVerificationForm />}
          />
          <Route path="helpandguidelines" element={<Helpandguidelines />} />

          {/* view scheme details */}
          <Route
            path="/schemeonedetails"
            element={<DisablilitySchemeOnedetails />}
          />
          <Route
            path="/schemetwodetails"
            element={<DisablilitySchemeTwoDetails />}
          />
          <Route
            path="/schemethreedetails"
            element={<DisablilitySchemeThreeDetails />}
          />
          <Route
            path="/schemefourdetails"
            element={<DisablilitySchemeFourDetails />}
          />
          <Route
            path="/schemefivedetails"
            element={<DisablilitySchemeFiveDetails />}
          />
          <Route
            path="/schemesixdetails"
            element={<DisablilitySchemeSixDetails />}
          />
          <Route
            path="/schemesevendetails"
            element={<DisablilitySchemeSevenDetails />}
          />
          <Route
            path="/schemeeightdetails"
            element={<DisablilitySchemeEightDetails />}
          />
          <Route
            path="/schemeninedetails"
            element={<DisablilitySchemeNineDetails />}
          />
          <Route
            path="/schemetendetails"
            element={<DisablilitySchemeTenDetails />}
          />

          {/* view sports scheme details */}

          <Route path="/sportschemeonedetails" />
          <Route
            path="/sportschemetwodetails"
            element={<SportSchemeTwoDetails />}
          />
          <Route
            path="/sportschemethreedetails"
            element={<SportSchemeThreeDetails />}
          />
          <Route path="/sportschemetwoform" element={<SportSchemeTwoForm />} />
          <Route
            path="/sportschemethreeform"
            element={<SportSchemeThreeForm />}
          />

          <Route
            path="/welfareschemeonedetails"
            element={<WelfareSchemeOneDetails />}
          />

          <Route
            path="/welfareschemetwodetails"
            element={<WelfareSchemeTwoDeatails />}
          />

          <Route
            path="/welfareschemethreedetails"
            element={<WelfareSchemeThreeDetails />}
          />
          <Route
            path="/welfareschemefourdetails"
            element={<WelfareSchemeFourDetails />}
          />

          <Route>
            <Route path="/" element={<Allschemespage />} />
            <Route path="/disability" element={<DisabilityFormsTable />} />
            <Route path="/sports" element={<SportsFormsTable />} />
          </Route>
        </Route>

        {/* user profile */}
        <Route path="/profile" element={<UserContainer />}>
          <Route path="trackapplication" element={<TrackApplication />} />
          <Route path="totalapplications" element={<TotalApplications />} />
          <Route path="profilepage" element={<ProfilePage />} />
          <Route path="contact" element={<Contact />} />
          <Route path="canceledschemes" element={<CanceledSchemes />} />
          <Route path="contactandsupport" element={<ContactAndSupport />} />
          <Route path="declarationforms" element={<DeclarationForm />} />
          <Route path="canceledforms" element={<CanceledSchemes />} />
          <Route path="editprofile" element={<EditProfile />} />
          <Route path="myappliedschemes" element={<MyAppliedSchemes />} />
          <Route path="scheme/:id" element={<FinalPermission />}></Route>
        </Route>

        <Route path="/" element={<SchemeContainer />}>
          <Route path="schemeone" element={<SchemeOne />} />
          <Route path="schemetwo" element={<SchemeTwo />} />
          <Route path="schemethree" element={<SchemeThree />} />
          <Route path="schemefour" element={<SchemeFour />} />
          <Route path="schemefive" element={<SchemeFive />} />
          <Route path="schemesix" element={<SchemeSix />} />
          <Route path="schemeseven" element={<SchemeSeven />} />
          <Route path="schemeeight" element={<SchemeEight />} />
          <Route path="schemenine" element={<SchemeNine />} />
          <Route path="schemeten" element={<SchemeTen />} />
          <Route path="schemeoneedit/:id" element={<SchemeOneEdit />} />
        </Route>

        {/* super admin */}
        <Route path="/super-admin" element={<SuperAdminLogin />} />
        <Route path="/super-admin" element={<SuperAdminContainer />}>
          <Route path="dashboard" element={<SuperAdminDashboard />} />
          <Route path="graphdashboard" element={<Graphdashboard />} />

          {/* schemeone */}
          <Route
            path="schemeonetotalapplications"
            element={<SuperAdminSchemeOneTotalApplications />}
          />
          <Route
            path="schemeoneapprovedapplications"
            element={<SuperAdminSchemeOneApprovedApplications />}
          />

          <Route
            path="schemeonerejectedapplications"
            element={<SuperAdminSchemeOneRejectedApplications />}
          />
          <Route
            path="schemeonependingapplications"
            element={<SuperAdminSchemeOnePendingApplications />}
          />

          {/* schemetwo */}
          <Route
            path="schemetwototalapplications"
            element={<SuperAdminSchemeTwoTotalApplications />}
          />
          <Route
            path="schemetwoapprovedapplications"
            element={<SuperAdminSchemeTwoApprovedApplications />}
          />
          <Route
            path="schemetworejectedapplications"
            element={<SuperAdminSchemeTwoRejectedApplications />}
          />
          <Route
            path="schemetwopendingapplications"
            element={<SuperAdminSchemeTwoPendingApplications />}
          />

          {/* schemethree */}
          <Route
            path="schemethreetotalapplications"
            element={<SuperAdminSchemeThreeTotalApplications />}
          />
          <Route
            path="schemethreeapprovedapplications"
            element={<SuperAdminSchemeThreeApprovedApplications />}
          />

          <Route
            path="schemethreerejectedapplications"
            element={<SuperAdminSchemeThreeRejectedApplications />}
          />
          <Route
            path="schemethreependingapplications"
            element={<SuperAdminSchemeThreePendingApplications />}
          />

          {/* schemefour */}
          <Route
            path="schemefourtotalapplications"
            element={<SuperAdminSchemeFourTotalApplications />}
          />

          <Route
            path="schemefourrejectedapplications"
            element={<SuperAdminSchemeFourRejectedApplications />}
          />

          <Route
            path="schemeFourapprovedapplications"
            element={<SuperAdminSchemeFourApprovedApplications />}
          />
          <Route
            path="schemefourpendingapplications"
            element={<SuperAdminSchemeFourPendingApplications />}
          />

          {/* schemefive */}
          <Route
            path="schemefivetotalapplications"
            element={<SuperAdminSchemeFiveTotalApplications />}
          />

          <Route
            path="schemefiveapprovedapplications"
            element={<SuperAdminSchemeFiveApprovedApplications />}
          />
          <Route
            path="schemefiverejectedapplications"
            element={<SuperAdminSchemeFiveRejectedApplications />}
          />
          <Route
            path="schemefivependingapplications"
            element={<SuperAdminSchemeFivePendingApplications />}
          />

          {/* schemesix */}
          <Route
            path="schemesixtotalapplications"
            element={<SuperAdminSchemeSixTotalApplications />}
          />

          <Route
            path="schemesixapprovedapplications"
            element={<SuperAdminSchemeSixApprovedApplications />}
          />

          <Route
            path="schemesixrejectedapplications"
            element={<SuperAdminSchemeSixRejectedApplications />}
          />
          <Route
            path="schemesixpendingapplications"
            element={<SuperAdminSchemeSixPendingApplications />}
          />

          {/* schemeseven */}
          <Route
            path="schemeseventotalapplications"
            element={<SuperAdminSchemeSevenTotalApplications />}
          />
          <Route
            path="schemesevenapprovedapplications"
            element={<SuperAdminSchemeSevenApprovedApplications />}
          />
          <Route
            path="schemesevenrejectedapplications"
            element={<SuperAdminSchemeSevenRejectedApplications />}
          />
          <Route
            path="schemesevenpendingapplications"
            element={<SuperAdminSchemeSevenPendingApplications />}
          />

          {/* schemeeight */}
          <Route
            path="schemeeighttotalapplications"
            element={<SuperAdminSchemeEightTotalApplications />}
          />

          <Route
            path="schemeeightapprovedapplications"
            element={<SuperAdminSchemeEightApprovedApplications />}
          />
          <Route
            path="schemeeightrejectedapplications"
            element={<SuperAdminSchemeEightRejectedApplications />}
          />
          <Route
            path="schemeeightpendingapplications"
            element={<SuperAdminSchemeEightPendingApplications />}
          />

          {/* schemenine */}
          <Route
            path="schemeninetotalapplications"
            element={<SuperAdminSchemeNineTotalApplications />}
          />
          <Route
            path="schemenineapprovedapplications"
            element={<SuperAdminSchemeNineApprovedApplications />}
          />
          <Route
            path="schemeninerejectedapplications"
            element={<SuperAdminSchemeNineRejectedApplications />}
          />
          <Route
            path="schemeninependingapplications"
            element={<SuperAdminSchemeNinePendingApplications />}
          />

          {/* Schemeten */}

          <Route
            path="schemetentotalapplications"
            element={<SuperAdminSchemeTenTotalApplications />}
          />
          <Route
            path="schemetenapprovedapplications"
            element={<SuperAdminSchemeTenApprovedApplications />}
          />
          <Route
            path="schemetenrejectedapplications"
            element={<SuperAdminSchemeTenRejectedApplications />}
          />
          <Route
            path="schemetenpendingapplications"
            element={<SuperAdminSchemeTenPendingApplications />}
          />

          <Route path="addslider" element={<SuperAdminAddSlider />} />
          <Route path="contactentries" element={<ContactEntries />} />
          <Route path="manageuser" element={<SuperAdminManageUser />} />
        </Route>

        {/* additional commissioner */}
        <Route
          path="/additional-commissioner"
          element={<AdditionalCommissionerLogin />}
        />
        <Route
          path="/additional-commissioner"
          element={<AddissionalCommissionerContainer />}
        >
          <Route
            path="dashboard"
            element={<AdditionalCommissionerDashboard />}
          />
          <Route
            path="addslider"
            element={<AdditionalCommissionerAddSlider />}
          />
          <Route
            path="contactentries"
            element={<AdditionalCommissionerContactEnquiries />}
          />
          <Route
            path="manageuser"
            element={<AdditionalCommissionerManageUser />}
          />
          {/* schemeone */}
          <Route
            path="addslider"
            element={<AdditionalCommissionerAddSlider />}
          />
          <Route
            path="contactentries"
            element={<AdditionalCommissionerContactEnquiries />}
          />
          <Route
            path="manageuser"
            element={<AdditionalCommissionerManageUser />}
          />

          {/* schemeone */}
          <Route
            path="schemeonetotalapplications"
            element={<AdditionalCommSchemeOneTotalApplications />}
          />
          <Route
            path="schemeoneapprovedapplications"
            element={<AdditionalCommSchemeOneApprovedApplications />}
          />

          <Route
            path="schemeonerejectedapplications"
            element={<AdditionalCommSchemeOneRejectedApplications />}
          />
          <Route
            path="schemeonependingapplications"
            element={<AdditionalCommSchemeOnePendingApplications />}
          />

          {/* schemetwo */}
          <Route
            path="schemetwototalapplications"
            element={<AdditionalCommSchemeTwoTotalApplications />}
          />
          <Route
            path="schemetwoapprovedapplications"
            element={<AdditionalCommSchemeTwoApprovedApplications />}
          />
          <Route
            path="schemetworejectedapplications"
            element={<AdditionalCommSchemeTwoRejectedApplications />}
          />
          <Route
            path="schemetwopendingapplications"
            element={<AdditionalCommSchemeTwoPendingApplications />}
          />

          {/* schemethree */}
          <Route
            path="schemethreetotalapplications"
            element={<AdditionalCommSchemeThreeTotalApplications />}
          />
          <Route
            path="schemethreeapprovedapplications"
            element={<AdditionalCommSchemeThreeApprovedApplications />}
          />

          <Route
            path="schemethreerejectedapplications"
            d
            element={<AdditionalCommSchemeThreeRejectedApplications />}
          />
          <Route
            path="schemethreependingapplications"
            element={<AdditionalCommSchemeThreePendingApplications />}
          />

          {/* schemefour */}
          <Route
            path="schemefourtotalapplications"
            element={<AdditionalCommSchemeFourTotalApplications />}
          />

          <Route
            path="schemefourrejectedapplications"
            element={<AdditionalCommSchemeFourRejectedApplications />}
          />

          <Route
            path="schemeFourapprovedapplications"
            element={<AdditionalCommSchemeFourApprovedApplications />}
          />
          <Route
            path="schemefourpendingapplications"
            element={<AdditionalCommSchemeFourPendingApplications />}
          />

          {/* schemefive */}
          <Route
            path="schemefivetotalapplications"
            element={<AdditionalCommSchemeFiveTotalApplications />}
          />

          <Route
            path="schemefiveapprovedapplications"
            element={<AdditionalCommSchemeFiveApprovedApplications />}
          />
          <Route
            path="schemefiverejectedapplications"
            element={<AdditionalCommSchemeFiveRejectedApplications />}
          />
          <Route
            path="schemefivependingapplications"
            element={<AdditionalCommSchemeFivePendingApplications />}
          />

          {/* schemesix */}
          <Route
            path="schemesixtotalapplications"
            element={<AdditionalCommSchemeSixTotalApplications />}
          />

          <Route
            path="schemesixapprovedapplications"
            element={<AdditionalCommSchemeSixApprovedApplications />}
          />

          <Route
            path="schemesixrejectedapplications"
            element={<AdditionalCommSchemeSixRejectedApplications />}
          />
          <Route
            path="schemesixpendingapplications"
            element={<AdditionalCommSchemeSixPendingApplications />}
          />

          {/* schemeseven */}
          <Route
            path="schemeseventotalapplications"
            element={<AdditionalCommSchemeSevenTotalApplications />}
          />
          <Route
            path="schemesevenapprovedapplications"
            element={<AdditionalCommSchemeSevenApprovedApplications />}
          />
          <Route
            path="schemesevenrejectedapplications"
            element={<AdditionalCommSchemeSevenRejectedApplications />}
          />
          <Route
            path="schemesevenpendingapplications"
            element={<AdditionalCommSchemeSevenPendingApplications />}
          />

          {/* schemeeight */}
          <Route
            path="schemeeighttotalapplications"
            element={<AdditionalCommSchemeEightTotalApplications />}
          />

          <Route
            path="schemeeightapprovedapplications"
            element={<AdditionalCommSchemeEightApprovedApplications />}
          />
          <Route
            path="schemeeightrejectedapplications"
            element={<AdditionalCommSchemeEightRejectedApplications />}
          />
          <Route
            path="schemeeightpendingapplications"
            element={<AdditionalCommSchemeEightPendingApplications />}
          />

          {/* schemenine */}
          <Route
            path="schemeninetotalapplications"
            element={<AdditionalCommSchemeNineTotalApplications />}
          />
          <Route
            path="schemenineapprovedapplications"
            element={<AdditionalCommSchemeNineApprovedApplications />}
          />
          <Route
            path="schemeninerejectedapplications"
            element={<AdditionalCommSchemeNineRejectedApplications />}
          />
          <Route
            path="schemeninependingapplications"
            element={<AdditionalCommSchemeNinePendingApplications />}
          />

          {/* Schemeten */}

          <Route
            path="schemetentotalapplications"
            element={<AdditionalCommSchemeTenTotalApplications />}
          />
          <Route
            path="schemetenapprovedapplications"
            element={<AdditionalCommSchemeTenApprovedApplications />}
          />
          <Route
            path="schemetenrejectedapplications"
            element={<AdditionalCommSchemeTenRejectedApplications />}
          />
          <Route
            path="schemetenpendingapplications"
            element={<AdditionalCommSchemeTenPendingApplications />}
          />
        </Route>

        {/* deputy commissioner */}
        <Route
          path="/deputy-commissioner"
          element={<DeputyCommissionerLogin />}
        />
        <Route
          path="/deputy-commissioner"
          element={<DeputyCommissionerContainer />}
        >
          <Route path="dashboard" element={<DeputyCommissionerDashboard />} />{" "}
          <Route path="addslider" element={<DeputyCommissionerAddSlider />} />
          <Route
            path="contactentries"
            element={<DeputyCommissionerContactEnquiries />}
          />
          <Route path="manageuser" element={<DeputyCommissionerManageUser />} />
          {/* schemeone */}
          <Route
            path="schemeonetotalapplications"
            element={<DeputyCommSchemeOneTotalApplications />}
          />
          <Route
            path="schemeoneapprovedapplications"
            element={<DeputyCommSchemeOneApprovedApplications />}
          />
          <Route
            path="schemeonerejectedapplications"
            element={<DeputyCommSchemeOneRejectedApplications />}
          />
          <Route
            path="schemeonependingapplications"
            element={<DeputyCommSchemeOnePendingApplications />}
          />
          {/* schemetwo */}
          <Route
            path="schemetwototalapplications"
            element={<DeputyCommSchemeTwoTotalApplications />}
          />
          <Route
            path="schemetwoapprovedapplications"
            element={<DeputyCommSchemeTwoApprovedApplications />}
          />
          <Route
            path="schemetworejectedapplications"
            element={<DeputyCommSchemeTwoRejectedApplications />}
          />
          <Route
            path="schemetwopendingapplications"
            element={<DeputyCommSchemeTwoPendingApplications />}
          />
          {/* schemethree */}
          <Route
            path="schemethreetotalapplications"
            element={<DeputyCommSchemeThreeTotalApplications />}
          />
          <Route
            path="schemethreeapprovedapplications"
            element={<DeputyCommSchemeThreeApprovedApplications />}
          />
          <Route
            path="schemethreerejectedapplications"
            d
            element={<DeputyCommSchemeThreeRejectedApplications />}
          />
          <Route
            path="schemethreependingapplications"
            element={<DeputyCommSchemeThreePendingApplications />}
          />
          {/* schemefour */}
          <Route
            path="schemefourtotalapplications"
            element={<DeputyCommSchemeFourTotalApplications />}
          />
          <Route
            path="schemefourrejectedapplications"
            element={<DeputyCommSchemeFourRejectedApplications />}
          />
          <Route
            path="schemeFourapprovedapplications"
            element={<DeputyCommSchemeFourApprovedApplications />}
          />
          <Route
            path="schemefourpendingapplications"
            element={<DeputyCommSchemeFourPendingApplications />}
          />
          {/* schemefive */}
          <Route
            path="schemefivetotalapplications"
            element={<DeputyCommSchemeFiveTotalApplications />}
          />
          <Route
            path="schemefiveapprovedapplications"
            element={<DeputyCommSchemeFiveApprovedApplications />}
          />
          <Route
            path="schemefiverejectedapplications"
            element={<DeputyCommSchemeFiveRejectedApplications />}
          />
          <Route
            path="schemefivependingapplications"
            element={<DeputyCommSchemeFivePendingApplications />}
          />
          {/* schemesix */}
          <Route
            path="schemesixtotalapplications"
            element={<DeputyCommSchemeSixTotalApplications />}
          />
          <Route
            path="schemesixapprovedapplications"
            element={<DeputyCommSchemeSixApprovedApplications />}
          />
          <Route
            path="schemesixrejectedapplications"
            element={<DeputyCommSchemeSixRejectedApplications />}
          />
          <Route
            path="schemesixpendingapplications"
            element={<DeputyCommSchemeSixPendingApplications />}
          />
          {/* schemeseven */}
          <Route
            path="schemeseventotalapplications"
            element={<DeputyCommSchemeSevenTotalApplications />}
          />
          <Route
            path="schemesevenapprovedapplications"
            element={<DeputyCommSchemeSevenApprovedApplications />}
          />
          <Route
            path="schemesevenrejectedapplications"
            element={<DeputyCommSchemeSevenRejectedApplications />}
          />
          <Route
            path="schemesevenpendingapplications"
            element={<DeputyCommSchemeSevenPendingApplications />}
          />
          {/* schemeeight */}
          <Route
            path="schemeeighttotalapplications"
            element={<DeputyCommSchemeEightTotalApplications />}
          />
          <Route
            path="schemeeightapprovedapplications"
            element={<DeputyCommSchemeEightApprovedApplications />}
          />
          <Route
            path="schemeeightrejectedapplications"
            element={<DeputyCommSchemeEightRejectedApplications />}
          />
          <Route
            path="schemeeightpendingapplications"
            element={<DeputyCommSchemeEightPendingApplications />}
          />
          {/* schemenine */}
          <Route
            path="schemeninetotalapplications"
            element={<DeputyCommSchemeNineTotalApplications />}
          />
          <Route
            path="schemenineapprovedapplications"
            element={<DeputyCommSchemeNineApprovedApplications />}
          />
          <Route
            path="schemeninerejectedapplications"
            element={<DeputyCommSchemeNineRejectedApplications />}
          />
          <Route
            path="schemeninependingapplications"
            element={<DeputyCommSchemeNinePendingApplications />}
          />
          {/* Schemeten */}
          <Route
            path="schemetentotalapplications"
            element={<DeputyCommSchemeTenTotalApplications />}
          />
          <Route
            path="schemetenapprovedapplications"
            element={<DeputyCommSchemeTenApprovedApplications />}
          />
          <Route
            path="schemetenrejectedapplications"
            element={<DeputyCommSchemeTenRejectedApplications />}
          />
          <Route
            path="schemetenpendingapplications"
            element={<DeputyCommSchemeTenPendingApplications />}
          />
        </Route>

        {/* chanani samiti */}
        <Route path="/chanani-samiti" element={<ChananiSamitiLogin />} />
        <Route path="/chanani-samiti" element={<ChananiSamitiContainer />}>
          <Route path="dashboard" element={<ChananiSamitiDashboard />} />
          {/* schemeone */}
          <Route
            path="schemeonetotalapplications"
            element={<ChananiOneTotalApplications />}
          />
          <Route
            path="schemeoneapprovedapplications"
            element={<ChananiOneApprovedApplications />}
          />

          <Route
            path="schemeonerejectedapplications"
            element={<ChananiOneRejectedApplications />}
          />
          <Route
            path="schemeonependingapplications"
            element={<ChananiOnePendingApplications />}
          />

          {/* schemetwo */}
          <Route
            path="schemetwototalapplications"
            element={<ChananiTwoTotalApplications />}
          />
          <Route
            path="schemetwoapprovedapplications"
            element={<ChananiTwoApprovedApplications />}
          />
          <Route
            path="schemetworejectedapplications"
            element={<ChananiTwoRejectedApplications />}
          />
          <Route
            path="schemetwopendingapplications"
            element={<ChananiTwoPendingApplications />}
          />

          {/* schemethree */}
          <Route
            path="schemethreetotalapplications"
            element={<ChananiThreeTotalApplications />}
          />
          <Route
            path="schemethreeapprovedapplications"
            element={<ChananiThreeApprovedApplications />}
          />

          <Route
            path="schemethreerejectedapplications"
            d
            element={<ChananiThreeRejectedApplications />}
          />
          <Route
            path="schemethreependingapplications"
            element={<ChananiThreePendingApplications />}
          />

          {/* schemefour */}
          <Route
            path="schemefourtotalapplications"
            element={<ChananiFourTotalApplications />}
          />

          <Route
            path="schemefourrejectedapplications"
            element={<ChananiFourRejectedApplications />}
          />

          <Route
            path="schemeFourapprovedapplications"
            element={<ChananiFourApprovedApplications />}
          />
          <Route
            path="schemefourpendingapplications"
            element={<ChananiFourPendingApplications />}
          />

          {/* schemefive */}
          <Route
            path="schemefivetotalapplications"
            element={<ChananiFiveTotalApplications />}
          />

          <Route
            path="schemefiveapprovedapplications"
            element={<ChananiFiveApprovedApplications />}
          />
          <Route
            path="schemefiverejectedapplications"
            element={<ChananiFiveRejectedApplications />}
          />
          <Route
            path="schemefivependingapplications"
            element={<ChananiFivePendingApplications />}
          />

          {/* schemesix */}
          <Route
            path="schemesixtotalapplications"
            element={<ChananiSixTotalApplications />}
          />

          <Route
            path="schemesixapprovedapplications"
            element={<ChananiSixApprovedApplications />}
          />

          <Route
            path="schemesixrejectedapplications"
            element={<ChananiSixRejectedApplications />}
          />
          <Route
            path="schemesixpendingapplications"
            element={<ChananiSixPendingApplications />}
          />

          {/* schemeseven */}
          <Route
            path="schemeseventotalapplications"
            element={<ChananiSevenTotalApplications />}
          />
          <Route
            path="schemesevenapprovedapplications"
            element={<ChananiSevenApprovedApplications />}
          />
          <Route
            path="schemesevenrejectedapplications"
            element={<ChananiSevenRejectedApplications />}
          />
          <Route
            path="schemesevenpendingapplications"
            element={<ChananiSevenPendingApplications />}
          />

          {/* schemeeight */}
          <Route
            path="schemeeighttotalapplications"
            element={<ChananiEightTotalApplications />}
          />

          <Route
            path="schemeeightapprovedapplications"
            element={<ChananiEightApprovedApplications />}
          />
          <Route
            path="schemeeightrejectedapplications"
            element={<ChananiEightRejectedApplications />}
          />
          <Route
            path="schemeeightpendingapplications"
            element={<ChananiEightPendingApplications />}
          />

          {/* schemenine */}
          <Route
            path="schemeninetotalapplications"
            element={<ChananiNineTotalApplications />}
          />
          <Route
            path="schemenineapprovedapplications"
            element={<ChananiNineApprovedApplications />}
          />
          <Route
            path="schemeninerejectedapplications"
            element={<ChananiNineRejectedApplications />}
          />
          <Route
            path="schemeninependingapplications"
            element={<ChananiNinePendingApplications />}
          />

          {/* Schemeten */}

          <Route
            path="schemetentotalapplications"
            element={<ChananiTenTotalApplications />}
          />
          <Route
            path="schemetenapprovedapplications"
            element={<ChananiTenApprovedApplications />}
          />
          <Route
            path="schemetenrejectedapplications"
            element={<ChananiTenRejectedApplications />}
          />
          <Route
            path="schemetenpendingapplications"
            element={<ChananiTenPendingApplications />}
          />
        </Route>

        {/* chanani samiti1 */}
        <Route path="/chanani-samiti1" element={<ChananiSamiti1Login />} />
        <Route path="/chanani-samiti1" element={<ChananiSamiti1Container />}>
          <Route path="dashboard" element={<ChananiSamiti1Dashboard />} />

          {/* schemeone */}
          <Route
            path="schemeonetotalapplications"
            element={<Chanani1SchemeOneTotalApplications />}
          />
          <Route
            path="schemeoneapprovedapplications"
            element={<Chanani1SchemeOneApprovedApplications />}
          />

          <Route
            path="schemeonerejectedapplications"
            element={<Chanani1SchemeOneRejectedApplications />}
          />
          <Route
            path="schemeonependingapplications"
            element={<Chanani1SchemeOnePendingApplications />}
          />

          {/* schemetwo */}
          <Route
            path="schemetwototalapplications"
            element={<Chanani1SchemeTwoTotalApplications />}
          />
          <Route
            path="schemetwoapprovedapplications"
            element={<Chanani1SchemeTwoApprovedApplications />}
          />
          <Route
            path="schemetworejectedapplications"
            element={<Chanani1SchemeTwoRejectedApplications />}
          />
          <Route
            path="schemetwopendingapplications"
            element={<Chanani1SchemeTwoPendingApplications />}
          />

          {/* schemethree */}
          <Route
            path="schemethreetotalapplications"
            element={<Chanani1SchemeThreeTotalApplications />}
          />
          <Route
            path="schemethreeapprovedapplications"
            element={<Chanani1SchemeThreeApprovedApplications />}
          />

          <Route
            path="schemethreerejectedapplications"
            d
            element={<Chanani1SchemeThreeRejectedApplications />}
          />
          <Route
            path="schemethreependingapplications"
            element={<Chanani1SchemeThreePendingApplications />}
          />

          {/* schemefour */}
          <Route
            path="schemefourtotalapplications"
            element={<Chanani1SchemeFourTotalApplications />}
          />

          <Route
            path="schemefourrejectedapplications"
            element={<Chanani1SchemeFourRejectedApplications />}
          />

          <Route
            path="schemeFourapprovedapplications"
            element={<Chanani1SchemeFourApprovedApplications />}
          />
          <Route
            path="schemefourpendingapplications"
            element={<Chanani1SchemeFourPendingApplications />}
          />

          {/* schemefive */}
          <Route
            path="schemefivetotalapplications"
            element={<Chanani1SchemeFiveTotalApplications />}
          />

          <Route
            path="schemefiveapprovedapplications"
            element={<Chanani1SchemeFiveApprovedApplications />}
          />
          <Route
            path="schemefiverejectedapplications"
            element={<Chanani1SchemeFiveRejectedApplications />}
          />
          <Route
            path="schemefivependingapplications"
            element={<Chanani1SchemeFivePendingApplications />}
          />

          {/* schemesix */}
          <Route
            path="schemesixtotalapplications"
            element={<Chanani1SchemeSixTotalApplications />}
          />

          <Route
            path="schemesixapprovedapplications"
            element={<Chanani1SchemeSixApprovedApplications />}
          />

          <Route
            path="schemesixrejectedapplications"
            element={<Chanani1SchemeSixRejectedApplications />}
          />
          <Route
            path="schemesixpendingapplications"
            element={<Chanani1SchemeSixPendingApplications />}
          />

          {/* schemeseven */}
          <Route
            path="schemeseventotalapplications"
            element={<Chanani1SchemeSevenTotalApplications />}
          />
          <Route
            path="schemesevenapprovedapplications"
            element={<Chanani1SchemeSevenApprovedApplications />}
          />
          <Route
            path="schemesevenrejectedapplications"
            element={<Chanani1SchemeSevenRejectedApplications />}
          />
          <Route
            path="schemesevenpendingapplications"
            element={<Chanani1SchemeSevenPendingApplications />}
          />

          {/* schemeeight */}
          <Route
            path="schemeeighttotalapplications"
            element={<Chanani1SchemeEightTotalApplications />}
          />

          <Route
            path="schemeeightapprovedapplications"
            element={<Chanani1SchemeEightApprovedApplications />}
          />
          <Route
            path="schemeeightrejectedapplications"
            element={<Chanani1SchemeEightRejectedApplications />}
          />
          <Route
            path="schemeeightpendingapplications"
            element={<Chanani1SchemeEightPendingApplications />}
          />

          {/* schemenine */}
          <Route
            path="schemeninetotalapplications"
            element={<Chanani1SchemeNineTotalApplications />}
          />
          <Route
            path="schemenineapprovedapplications"
            element={<Chanani1SchemeNineApprovedApplications />}
          />
          <Route
            path="schemeninerejectedapplications"
            element={<Chanani1SchemeNineRejectedApplications />}
          />
          <Route
            path="schemeninependingapplications"
            element={<Chanani1SchemeNinePendingApplications />}
          />

          {/* Schemeten */}

          <Route
            path="schemetentotalapplications"
            element={<Chanani1SchemeTenTotalApplications />}
          />
          <Route
            path="schemetenapprovedapplications"
            element={<Chanani1SchemeTenApprovedApplications />}
          />
          <Route
            path="schemetenrejectedapplications"
            element={<Chanani1SchemeTenRejectedApplications />}
          />
          <Route
            path="schemetenpendingapplications"
            element={<Chanani1SchemeTenPendingApplications />}
          />
        </Route>

        {/* chanani samiti2 */}
        <Route path="/chanani-samiti2" element={<ChananiSamiti2Login />} />
        <Route path="/chanani-samiti2" element={<ChananiSamiti2Container />}>
          <Route path="dashboard" element={<ChananiSamiti2Dashboard />} />

          {/* schemeone */}
          <Route
            path="schemeonetotalapplications"
            element={<Chanani2SchemeOneTotalApplications />}
          />
          <Route
            path="schemeoneapprovedapplications"
            element={<Chanani2SchemeOneApprovedApplications />}
          />

          <Route
            path="schemeonerejectedapplications"
            element={<Chanani2SchemeOneRejectedApplications />}
          />
          <Route
            path="schemeonependingapplications"
            element={<Chanani2SchemeOnePendingApplications />}
          />

          {/* schemetwo */}
          <Route
            path="schemetwototalapplications"
            element={<Chanani2SchemeTwoTotalApplications />}
          />
          <Route
            path="schemetwoapprovedapplications"
            element={<Chanani2SchemeTwoApprovedApplications />}
          />
          <Route
            path="schemetworejectedapplications"
            element={<Chanani2SchemeTwoRejectedApplications />}
          />
          <Route
            path="schemetwopendingapplications"
            element={<Chanani2SchemeTwoPendingApplications />}
          />

          {/* schemethree */}
          <Route
            path="schemethreetotalapplications"
            element={<Chanani2SchemeThreeTotalApplications />}
          />
          <Route
            path="schemethreeapprovedapplications"
            element={<Chanani2SchemeThreeApprovedApplications />}
          />

          <Route
            path="schemethreerejectedapplications"
            d
            element={<Chanani2SchemeThreeRejectedApplications />}
          />
          <Route
            path="schemethreependingapplications"
            element={<Chanani2SchemeThreePendingApplications />}
          />

          {/* schemefour */}
          <Route
            path="schemefourtotalapplications"
            element={<Chanani2SchemeFourTotalApplications />}
          />

          <Route
            path="schemefourrejectedapplications"
            element={<Chanani2SchemeFourRejectedApplications />}
          />

          <Route
            path="schemeFourapprovedapplications"
            element={<Chanani2SchemeFourApprovedApplications />}
          />
          <Route
            path="schemefourpendingapplications"
            element={<Chanani2SchemeFourPendingApplications />}
          />

          {/* schemefive */}
          <Route
            path="schemefivetotalapplications"
            element={<Chanani2SchemeFiveTotalApplications />}
          />

          <Route
            path="schemefiveapprovedapplications"
            element={<Chanani2SchemeFiveApprovedApplications />}
          />
          <Route
            path="schemefiverejectedapplications"
            element={<Chanani2SchemeFiveRejectedApplications />}
          />
          <Route
            path="schemefivependingapplications"
            element={<Chanani2SchemeFivePendingApplications />}
          />

          {/* schemesix */}
          <Route
            path="schemesixtotalapplications"
            element={<Chanani2SchemeSixTotalApplications />}
          />

          <Route
            path="schemesixapprovedapplications"
            element={<Chanani2SchemeSixApprovedApplications />}
          />

          <Route
            path="schemesixrejectedapplications"
            element={<Chanani2SchemeSixRejectedApplications />}
          />
          <Route
            path="schemesixpendingapplications"
            element={<Chanani2SchemeSixPendingApplications />}
          />

          {/* schemeseven */}
          <Route
            path="schemeseventotalapplications"
            element={<Chanani2SchemeSevenTotalApplications />}
          />
          <Route
            path="schemesevenapprovedapplications"
            element={<Chanani2SchemeSevenApprovedApplications />}
          />
          <Route
            path="schemesevenrejectedapplications"
            element={<Chanani2SchemeSevenRejectedApplications />}
          />
          <Route
            path="schemesevenpendingapplications"
            element={<Chanani2SchemeSevenPendingApplications />}
          />

          {/* schemeeight */}
          <Route
            path="schemeeighttotalapplications"
            element={<Chanani2SchemeEightTotalApplications />}
          />

          <Route
            path="schemeeightapprovedapplications"
            element={<Chanani2SchemeEightApprovedApplications />}
          />
          <Route
            path="schemeeightrejectedapplications"
            element={<Chanani2SchemeEightRejectedApplications />}
          />
          <Route
            path="schemeeightpendingapplications"
            element={<Chanani2SchemeEightPendingApplications />}
          />

          {/* schemenine */}
          <Route
            path="schemeninetotalapplications"
            element={<Chanani2SchemeNineTotalApplications />}
          />
          <Route
            path="schemenineapprovedapplications"
            element={<Chanani2SchemeNineApprovedApplications />}
          />
          <Route
            path="schemeninerejectedapplications"
            element={<Chanani2SchemeNineRejectedApplications />}
          />
          <Route
            path="schemeninependingapplications"
            element={<Chanani2SchemeNinePendingApplications />}
          />

          {/* Schemeten */}

          <Route
            path="schemetentotalapplications"
            element={<Chanani2SchemeTenTotalApplications />}
          />
          <Route
            path="schemetenapprovedapplications"
            element={<Chanani2SchemeTenApprovedApplications />}
          />
          <Route
            path="schemetenrejectedapplications"
            element={<Chanani2SchemeTenRejectedApplications />}
          />
          <Route
            path="schemetenpendingapplications"
            element={<Chanani2SchemeTenPendingApplications />}
          />
        </Route>

        {/* chanani samiti3 */}
        <Route path="/chanani-samiti3" element={<ChananiSamiti3Login />} />
        <Route path="/chanani-samiti3" element={<ChananiSamiti3Container />}>
          <Route path="dashboard" element={<ChananiSamiti3Dashboard />} />

          {/* schemeone */}
          <Route
            path="schemeonetotalapplications"
            element={<Chanani3SchemeOneTotalApplications />}
          />
          <Route
            path="schemeoneapprovedapplications"
            element={<Chanani3SchemeOneApprovedApplications />}
          />

          <Route
            path="schemeonerejectedapplications"
            element={<Chanani3SchemeOneRejectedApplications />}
          />
          <Route
            path="schemeonependingapplications"
            element={<Chanani3SchemeOnePendingApplications />}
          />

          {/* schemetwo */}
          <Route
            path="schemetwototalapplications"
            element={<Chanani3SchemeTwoTotalApplications />}
          />
          <Route
            path="schemetwoapprovedapplications"
            element={<Chanani3SchemeTwoApprovedApplications />}
          />
          <Route
            path="schemetworejectedapplications"
            element={<Chanani3SchemeTwoRejectedApplications />}
          />
          <Route
            path="schemetwopendingapplications"
            element={<Chanani3SchemeTwoPendingApplications />}
          />

          {/* schemethree */}
          <Route
            path="schemethreetotalapplications"
            element={<Chanani3SchemeThreeTotalApplications />}
          />
          <Route
            path="schemethreeapprovedapplications"
            element={<Chanani3SchemeThreeApprovedApplications />}
          />

          <Route
            path="schemethreerejectedapplications"
            d
            element={<Chanani3SchemeThreeRejectedApplications />}
          />
          <Route
            path="schemethreependingapplications"
            element={<Chanani3SchemeThreePendingApplications />}
          />

          {/* schemefour */}
          <Route
            path="schemefourtotalapplications"
            element={<Chanani3SchemeFourTotalApplications />}
          />

          <Route
            path="schemefourrejectedapplications"
            element={<Chanani3SchemeFourRejectedApplications />}
          />

          <Route
            path="schemeFourapprovedapplications"
            element={<Chanani3SchemeFourApprovedApplications />}
          />
          <Route
            path="schemefourpendingapplications"
            element={<Chanani3SchemeFourPendingApplications />}
          />

          {/* schemefive */}
          <Route
            path="schemefivetotalapplications"
            element={<Chanani3SchemeFiveTotalApplications />}
          />

          <Route
            path="schemefiveapprovedapplications"
            element={<Chanani3SchemeFiveApprovedApplications />}
          />
          <Route
            path="schemefiverejectedapplications"
            element={<Chanani3SchemeFiveRejectedApplications />}
          />
          <Route
            path="schemefivependingapplications"
            element={<Chanani3SchemeFivePendingApplications />}
          />

          {/* schemesix */}
          <Route
            path="schemesixtotalapplications"
            element={<Chanani3SchemeSixTotalApplications />}
          />

          <Route
            path="schemesixapprovedapplications"
            element={<Chanani3SchemeSixApprovedApplications />}
          />

          <Route
            path="schemesixrejectedapplications"
            element={<Chanani3SchemeSixRejectedApplications />}
          />
          <Route
            path="schemesixpendingapplications"
            element={<Chanani3SchemeSixPendingApplications />}
          />

          {/* schemeseven */}
          <Route
            path="schemeseventotalapplications"
            element={<Chanani3SchemeSevenTotalApplications />}
          />
          <Route
            path="schemesevenapprovedapplications"
            element={<Chanani3SchemeSevenApprovedApplications />}
          />
          <Route
            path="schemesevenrejectedapplications"
            element={<Chanani3SchemeSevenRejectedApplications />}
          />
          <Route
            path="schemesevenpendingapplications"
            element={<Chanani3SchemeSevenPendingApplications />}
          />

          {/* schemeeight */}
          <Route
            path="schemeeighttotalapplications"
            element={<Chanani3SchemeEightTotalApplications />}
          />

          <Route
            path="schemeeightapprovedapplications"
            element={<Chanani3SchemeEightApprovedApplications />}
          />
          <Route
            path="schemeeightrejectedapplications"
            element={<Chanani3SchemeEightRejectedApplications />}
          />
          <Route
            path="schemeeightpendingapplications"
            element={<Chanani3SchemeEightPendingApplications />}
          />

          {/* schemenine */}
          <Route
            path="schemeninetotalapplications"
            element={<Chanani3SchemeNineTotalApplications />}
          />
          <Route
            path="schemenineapprovedapplications"
            element={<Chanani3SchemeNineApprovedApplications />}
          />
          <Route
            path="schemeninerejectedapplications"
            element={<Chanani3SchemeNineRejectedApplications />}
          />
          <Route
            path="schemeninependingapplications"
            element={<Chanani3SchemeNinePendingApplications />}
          />

          {/* Schemeten */}

          <Route
            path="schemetentotalapplications"
            element={<Chanani3SchemeTenTotalApplications />}
          />
          <Route
            path="schemetenapprovedapplications"
            element={<Chanani3SchemeTenApprovedApplications />}
          />
          <Route
            path="schemetenrejectedapplications"
            element={<Chanani3SchemeTenRejectedApplications />}
          />
          <Route
            path="schemetenpendingapplications"
            element={<Chanani3SchemeTenPendingApplications />}
          />
        </Route>

        {/* chanani samiti4 */}
        <Route path="/chanani-samiti4" element={<ChananiSamiti4Login />} />
        <Route path="/chanani-samiti4" element={<ChananiSamiti4Container />}>
          <Route path="dashboard" element={<ChananiSamiti4Dashboard />} />

          {/* schemeone */}
          <Route
            path="schemeonetotalapplications"
            element={<Chanani4SchemeOneTotalApplications />}
          />
          <Route
            path="schemeoneapprovedapplications"
            element={<Chanani4SchemeOneApprovedApplications />}
          />

          <Route
            path="schemeonerejectedapplications"
            element={<Chanani4SchemeOneRejectedApplications />}
          />
          <Route
            path="schemeonependingapplications"
            element={<Chanani4SchemeOnePendingApplications />}
          />

          {/* schemetwo */}
          <Route
            path="schemetwototalapplications"
            element={<Chanani4SchemeTwoTotalApplications />}
          />
          <Route
            path="schemetwoapprovedapplications"
            element={<Chanani4SchemeTwoApprovedApplications />}
          />
          <Route
            path="schemetworejectedapplications"
            element={<Chanani4SchemeTwoRejectedApplications />}
          />
          <Route
            path="schemetwopendingapplications"
            element={<Chanani4SchemeTwoPendingApplications />}
          />

          {/* schemethree */}
          <Route
            path="schemethreetotalapplications"
            element={<Chanani4SchemeThreeTotalApplications />}
          />
          <Route
            path="schemethreeapprovedapplications"
            element={<Chanani4SchemeThreeApprovedApplications />}
          />

          <Route
            path="schemethreerejectedapplications"
            d
            element={<Chanani4SchemeThreeRejectedApplications />}
          />
          <Route
            path="schemethreependingapplications"
            element={<Chanani4SchemeThreePendingApplications />}
          />

          {/* schemefour */}
          <Route
            path="schemefourtotalapplications"
            element={<Chanani4SchemeFourTotalApplications />}
          />

          <Route
            path="schemefourrejectedapplications"
            element={<Chanani4SchemeFourRejectedApplications />}
          />

          <Route
            path="schemeFourapprovedapplications"
            element={<Chanani4SchemeFourApprovedApplications />}
          />
          <Route
            path="schemefourpendingapplications"
            element={<Chanani4SchemeFourPendingApplications />}
          />

          {/* schemefive */}
          <Route
            path="schemefivetotalapplications"
            element={<Chanani4SchemeFiveTotalApplications />}
          />

          <Route
            path="schemefiveapprovedapplications"
            element={<Chanani4SchemeFiveApprovedApplications />}
          />
          <Route
            path="schemefiverejectedapplications"
            element={<Chanani4SchemeFiveRejectedApplications />}
          />
          <Route
            path="schemefivependingapplications"
            element={<Chanani4SchemeFivePendingApplications />}
          />

          {/* schemesix */}
          <Route
            path="schemesixtotalapplications"
            element={<Chanani4SchemeSixTotalApplications />}
          />

          <Route
            path="schemesixapprovedapplications"
            element={<Chanani4SchemeSixApprovedApplications />}
          />

          <Route
            path="schemesixrejectedapplications"
            element={<Chanani4SchemeSixRejectedApplications />}
          />
          <Route
            path="schemesixpendingapplications"
            element={<Chanani4SchemeSixPendingApplications />}
          />

          {/* schemeseven */}
          <Route
            path="schemeseventotalapplications"
            element={<Chanani4SchemeSevenTotalApplications />}
          />
          <Route
            path="schemesevenapprovedapplications"
            element={<Chanani4SchemeSevenApprovedApplications />}
          />
          <Route
            path="schemesevenrejectedapplications"
            element={<Chanani4SchemeSevenRejectedApplications />}
          />
          <Route
            path="schemesevenpendingapplications"
            element={<Chanani4SchemeSevenPendingApplications />}
          />

          {/* schemeeight */}
          <Route
            path="schemeeighttotalapplications"
            element={<Chanani4SchemeEightTotalApplications />}
          />

          <Route
            path="schemeeightapprovedapplications"
            element={<Chanani4SchemeEightApprovedApplications />}
          />
          <Route
            path="schemeeightrejectedapplications"
            element={<Chanani4SchemeEightRejectedApplications />}
          />
          <Route
            path="schemeeightpendingapplications"
            element={<Chanani4SchemeEightPendingApplications />}
          />

          {/* schemenine */}
          <Route
            path="schemeninetotalapplications"
            element={<Chanani4SchemeNineTotalApplications />}
          />
          <Route
            path="schemenineapprovedapplications"
            element={<Chanani4SchemeNineApprovedApplications />}
          />
          <Route
            path="schemeninerejectedapplications"
            element={<Chanani4SchemeNineRejectedApplications />}
          />
          <Route
            path="schemeninependingapplications"
            element={<Chanani4SchemeNinePendingApplications />}
          />

          {/* Schemeten */}

          <Route
            path="schemetentotalapplications"
            element={<Chanani4SchemeTenTotalApplications />}
          />
          <Route
            path="schemetenapprovedapplications"
            element={<Chanani4SchemeTenApprovedApplications />}
          />
          <Route
            path="schemetenrejectedapplications"
            element={<Chanani4SchemeTenRejectedApplications />}
          />
          <Route
            path="schemetenpendingapplications"
            element={<Chanani4SchemeTenPendingApplications />}
          />
        </Route>

        {/* shaskiy samiti */}
        <Route path="/shaskiy-samiti" element={<ShaskiySamitiLogin />} />
        <Route path="/shaskiy-samiti" element={<ShaskiySamitiContainer />}>
          <Route path="dashboard" element={<ShaskiySamitiDashboard />} />
          {/* schemeone */}
          <Route
            path="schemeonetotalapplications"
            element={<ShaskiySamitiSchemeOneTotalApplications />}
          />
          <Route
            path="schemeoneapprovedapplications"
            element={<ShaskiySamitiSchemeOneApprovedApplications />}
          />

          <Route
            path="schemeonerejectedapplications"
            element={<ShaskiySamitiSchemeOneRejectedApplications />}
          />
          <Route
            path="schemeonependingapplications"
            element={<ShaskiySamitiSchemeOnePendingApplications />}
          />

          {/* schemetwo */}
          <Route
            path="schemetwototalapplications"
            element={<ShaskiySamitiSchemeTwoTotalApplications />}
          />
          <Route
            path="schemetwoapprovedapplications"
            element={<ShaskiySamitiSchemeTwoApprovedApplications />}
          />
          <Route
            path="schemetworejectedapplications"
            element={<ShaskiySamitiSchemeTwoRejectedApplications />}
          />
          <Route
            path="schemetwopendingapplications"
            element={<ShaskiySamitiSchemeTwoPendingApplications />}
          />

          {/* schemethree */}
          <Route
            path="schemethreetotalapplications"
            element={<ShaskiySamitiSchemeThreeTotalApplications />}
          />
          <Route
            path="schemethreeapprovedapplications"
            element={<ShaskiySamitiSchemeThreeApprovedApplications />}
          />

          <Route
            path="schemethreerejectedapplications"
            d
            element={<ShaskiySamitiSchemeThreeRejectedApplications />}
          />
          <Route
            path="schemethreependingapplications"
            element={<ShaskiySamitiSchemeThreePendingApplications />}
          />

          {/* schemefour */}
          <Route
            path="schemefourtotalapplications"
            element={<ShaskiySamitiSchemeFourTotalApplications />}
          />

          <Route
            path="schemefourrejectedapplications"
            element={<ShaskiySamitiSchemeFourRejectedApplications />}
          />

          <Route
            path="schemeFourapprovedapplications"
            element={<ShaskiySamitiSchemeFourApprovedApplications />}
          />
          <Route
            path="schemefourpendingapplications"
            element={<ShaskiySamitiSchemeFourPendingApplications />}
          />

          {/* schemefive */}
          <Route
            path="schemefivetotalapplications"
            element={<ShaskiySamitiSchemeFiveTotalApplications />}
          />

          <Route
            path="schemefiveapprovedapplications"
            element={<ShaskiySamitiSchemeFiveApprovedApplications />}
          />
          <Route
            path="schemefiverejectedapplications"
            element={<ShaskiySamitiSchemeFiveRejectedApplications />}
          />
          <Route
            path="schemefivependingapplications"
            element={<ShaskiySamitiSchemeFivePendingApplications />}
          />

          {/* schemesix */}
          <Route
            path="schemesixtotalapplications"
            element={<ShaskiySamitiSchemeSixTotalApplications />}
          />

          <Route
            path="schemesixapprovedapplications"
            element={<ShaskiySamitiSchemeSixApprovedApplications />}
          />

          <Route
            path="schemesixrejectedapplications"
            element={<ShaskiySamitiSchemeSixRejectedApplications />}
          />
          <Route
            path="schemesixpendingapplications"
            element={<ShaskiySamitiSchemeSixPendingApplications />}
          />

          {/* schemeseven */}
          <Route
            path="schemeseventotalapplications"
            element={<ShaskiySamitiSchemeSevenTotalApplications />}
          />
          <Route
            path="schemesevenapprovedapplications"
            element={<ShaskiySamitiSchemeSevenApprovedApplications />}
          />
          <Route
            path="schemesevenrejectedapplications"
            element={<ShaskiySamitiSchemeSevenRejectedApplications />}
          />
          <Route
            path="schemesevenpendingapplications"
            element={<ShaskiySamitiSchemeSevenPendingApplications />}
          />

          {/* schemeeight */}
          <Route
            path="schemeeighttotalapplications"
            element={<ShaskiySamitiSchemeEightTotalApplications />}
          />

          <Route
            path="schemeeightapprovedapplications"
            element={<ShaskiySamitiSchemeEightApprovedApplications />}
          />
          <Route
            path="schemeeightrejectedapplications"
            element={<ShaskiySamitiSchemeEightRejectedApplications />}
          />
          <Route
            path="schemeeightpendingapplications"
            element={<ShaskiySamitiSchemeEightPendingApplications />}
          />

          {/* schemenine */}
          <Route
            path="schemeninetotalapplications"
            element={<ShaskiySamitiSchemeNineTotalApplications />}
          />
          <Route
            path="schemenineapprovedapplications"
            element={<ShaskiySamitiSchemeNineApprovedApplications />}
          />
          <Route
            path="schemeninerejectedapplications"
            element={<ShaskiySamitiSchemeNineRejectedApplications />}
          />
          <Route
            path="schemeninependingapplications"
            element={<ShaskiySamitiSchemeNinePendingApplications />}
          />

          {/* Schemeten */}

          <Route
            path="schemetentotalapplications"
            element={<ShaskiySamitiSchemeTenTotalApplications />}
          />
          <Route
            path="schemetenapprovedapplications"
            element={<ShaskiySamitiSchemeTenApprovedApplications />}
          />
          <Route
            path="schemetenrejectedapplications"
            element={<ShaskiySamitiSchemeTenRejectedApplications />}
          />
          <Route
            path="schemetenpendingapplications"
            element={<ShaskiySamitiSchemeTenPendingApplications />}
          />
        </Route>

        {/* shaskiy samiti1 */}
        <Route path="/shaskiy-samiti1" element={<ShaskiySamiti1Login />} />
        <Route path="/shaskiy-samiti1" element={<ShaskiySamiti1Container />}>
          <Route path="dashboard" element={<ShaskiySamiti1Dashboard />} />
          {/* schemeone */}
          <Route
            path="schemeonetotalapplications"
            element={<ShaskiySamiti1SchemeOneTotalApplications />}
          />
          <Route
            path="schemeoneapprovedapplications"
            element={<ShaskiySamiti1SchemeOneApprovedApplications />}
          />

          <Route
            path="schemeonerejectedapplications"
            element={<ShaskiySamiti1SchemeOneRejectedApplications />}
          />
          <Route
            path="schemeonependingapplications"
            element={<ShaskiySamiti1SchemeOnePendingApplications />}
          />

          {/* schemetwo */}
          <Route
            path="schemetwototalapplications"
            element={<ShaskiySamiti1SchemeTwoTotalApplications />}
          />
          <Route
            path="schemetwoapprovedapplications"
            element={<ShaskiySamiti1SchemeTwoApprovedApplications />}
          />
          <Route
            path="schemetworejectedapplications"
            element={<ShaskiySamiti1SchemeTwoRejectedApplications />}
          />
          <Route
            path="schemetwopendingapplications"
            element={<ShaskiySamiti1SchemeTwoPendingApplications />}
          />

          {/* schemethree */}
          <Route
            path="schemethreetotalapplications"
            element={<ShaskiySamiti1SchemeThreeTotalApplications />}
          />
          <Route
            path="schemethreeapprovedapplications"
            element={<ShaskiySamiti1SchemeThreeApprovedApplications />}
          />

          <Route
            path="schemethreerejectedapplications"
            d
            element={<ShaskiySamiti1SchemeThreeRejectedApplications />}
          />
          <Route
            path="schemethreependingapplications"
            element={<ShaskiySamiti1SchemeThreePendingApplications />}
          />

          {/* schemefour */}
          <Route
            path="schemefourtotalapplications"
            element={<ShaskiySamiti1SchemeFourTotalApplications />}
          />

          <Route
            path="schemefourrejectedapplications"
            element={<ShaskiySamiti1SchemeFourRejectedApplications />}
          />

          <Route
            path="schemeFourapprovedapplications"
            element={<ShaskiySamiti1SchemeFourApprovedApplications />}
          />
          <Route
            path="schemefourpendingapplications"
            element={<ShaskiySamiti1SchemeFourPendingApplications />}
          />

          {/* schemefive */}
          <Route
            path="schemefivetotalapplications"
            element={<ShaskiySamiti1SchemeFiveTotalApplications />}
          />

          <Route
            path="schemefiveapprovedapplications"
            element={<ShaskiySamiti1SchemeFiveApprovedApplications />}
          />
          <Route
            path="schemefiverejectedapplications"
            element={<ShaskiySamiti1SchemeFiveRejectedApplications />}
          />
          <Route
            path="schemefivependingapplications"
            element={<ShaskiySamiti1SchemeFivePendingApplications />}
          />

          {/* schemesix */}
          <Route
            path="schemesixtotalapplications"
            element={<ShaskiySamiti1SchemeSixTotalApplications />}
          />

          <Route
            path="schemesixapprovedapplications"
            element={<ShaskiySamiti1SchemeSixApprovedApplications />}
          />

          <Route
            path="schemesixrejectedapplications"
            element={<ShaskiySamiti1SchemeSixRejectedApplications />}
          />
          <Route
            path="schemesixpendingapplications"
            element={<ShaskiySamiti1SchemeSixPendingApplications />}
          />

          {/* schemeseven */}
          <Route
            path="schemeseventotalapplications"
            element={<ShaskiySamiti1SchemeSevenTotalApplications />}
          />
          <Route
            path="schemesevenapprovedapplications"
            element={<ShaskiySamiti1SchemeSevenApprovedApplications />}
          />
          <Route
            path="schemesevenrejectedapplications"
            element={<ShaskiySamiti1SchemeSevenRejectedApplications />}
          />
          <Route
            path="schemesevenpendingapplications"
            element={<ShaskiySamiti1SchemeSevenPendingApplications />}
          />

          {/* schemeeight */}
          <Route
            path="schemeeighttotalapplications"
            element={<ShaskiySamiti1SchemeEightTotalApplications />}
          />

          <Route
            path="schemeeightapprovedapplications"
            element={<ShaskiySamiti1SchemeEightApprovedApplications />}
          />
          <Route
            path="schemeeightrejectedapplications"
            element={<ShaskiySamiti1SchemeEightRejectedApplications />}
          />
          <Route
            path="schemeeightpendingapplications"
            element={<ShaskiySamiti1SchemeEightPendingApplications />}
          />

          {/* schemenine */}
          <Route
            path="schemeninetotalapplications"
            element={<ShaskiySamiti1SchemeNineTotalApplications />}
          />
          <Route
            path="schemenineapprovedapplications"
            element={<ShaskiySamiti1SchemeNineApprovedApplications />}
          />
          <Route
            path="schemeninerejectedapplications"
            element={<ShaskiySamiti1SchemeNineRejectedApplications />}
          />
          <Route
            path="schemeninependingapplications"
            element={<ShaskiySamiti1SchemeNinePendingApplications />}
          />

          {/* Schemeten */}

          <Route
            path="schemetentotalapplications"
            element={<ShaskiySamiti1SchemeTenTotalApplications />}
          />
          <Route
            path="schemetenapprovedapplications"
            element={<ShaskiySamiti1SchemeTenApprovedApplications />}
          />
          <Route
            path="schemetenrejectedapplications"
            element={<ShaskiySamiti1SchemeTenRejectedApplications />}
          />
          <Route
            path="schemetenpendingapplications"
            element={<ShaskiySamiti1SchemeTenPendingApplications />}
          />
        </Route>

        {/* shaskiy samiti2 */}
        <Route path="/shaskiy-samiti2" element={<ShaskiySamiti2Login />} />
        <Route path="/shaskiy-samiti2" element={<ShaskiySamiti2Container />}>
          <Route path="dashboard" element={<ShaskiySamiti2Dashboard />} />
          {/* schemeone */}
          <Route
            path="schemeonetotalapplications"
            element={<ShaskiySamiti2SchemeOneTotalApplications />}
          />
          <Route
            path="schemeoneapprovedapplications"
            element={<ShaskiySamiti2SchemeOneApprovedApplications />}
          />

          <Route
            path="schemeonerejectedapplications"
            element={<ShaskiySamiti2SchemeOneRejectedApplications />}
          />
          <Route
            path="schemeonependingapplications"
            element={<ShaskiySamiti2SchemeOnePendingApplications />}
          />

          {/* schemetwo */}
          <Route
            path="schemetwototalapplications"
            element={<ShaskiySamiti2SchemeTwoTotalApplications />}
          />
          <Route
            path="schemetwoapprovedapplications"
            element={<ShaskiySamiti2SchemeTwoApprovedApplications />}
          />
          <Route
            path="schemetworejectedapplications"
            element={<ShaskiySamiti2SchemeTwoRejectedApplications />}
          />
          <Route
            path="schemetwopendingapplications"
            element={<ShaskiySamiti2SchemeTwoPendingApplications />}
          />

          {/* schemethree */}
          <Route
            path="schemethreetotalapplications"
            element={<ShaskiySamiti2SchemeThreeTotalApplications />}
          />
          <Route
            path="schemethreeapprovedapplications"
            element={<ShaskiySamiti2SchemeThreeApprovedApplications />}
          />

          <Route
            path="schemethreerejectedapplications"
            d
            element={<ShaskiySamiti2SchemeThreeRejectedApplications />}
          />
          <Route
            path="schemethreependingapplications"
            element={<ShaskiySamiti2SchemeThreePendingApplications />}
          />

          {/* schemefour */}
          <Route
            path="schemefourtotalapplications"
            element={<ShaskiySamiti2SchemeFourTotalApplications />}
          />

          <Route
            path="schemefourrejectedapplications"
            element={<ShaskiySamiti2SchemeFourRejectedApplications />}
          />

          <Route
            path="schemeFourapprovedapplications"
            element={<ShaskiySamiti2SchemeFourApprovedApplications />}
          />
          <Route
            path="schemefourpendingapplications"
            element={<ShaskiySamiti2SchemeFourPendingApplications />}
          />

          {/* schemefive */}
          <Route
            path="schemefivetotalapplications"
            element={<ShaskiySamiti2SchemeFiveTotalApplications />}
          />

          <Route
            path="schemefiveapprovedapplications"
            element={<ShaskiySamiti2SchemeFiveApprovedApplications />}
          />
          <Route
            path="schemefiverejectedapplications"
            element={<ShaskiySamiti2SchemeFiveRejectedApplications />}
          />
          <Route
            path="schemefivependingapplications"
            element={<ShaskiySamiti2SchemeFivePendingApplications />}
          />

          {/* schemesix */}
          <Route
            path="schemesixtotalapplications"
            element={<ShaskiySamiti2SchemeSixTotalApplications />}
          />

          <Route
            path="schemesixapprovedapplications"
            element={<ShaskiySamiti2SchemeSixApprovedApplications />}
          />

          <Route
            path="schemesixrejectedapplications"
            element={<ShaskiySamiti2SchemeSixRejectedApplications />}
          />
          <Route
            path="schemesixpendingapplications"
            element={<ShaskiySamiti2SchemeSixPendingApplications />}
          />

          {/* schemeseven */}
          <Route
            path="schemeseventotalapplications"
            element={<ShaskiySamiti2SchemeSevenTotalApplications />}
          />
          <Route
            path="schemesevenapprovedapplications"
            element={<ShaskiySamiti2SchemeSevenApprovedApplications />}
          />
          <Route
            path="schemesevenrejectedapplications"
            element={<ShaskiySamiti2SchemeSevenRejectedApplications />}
          />
          <Route
            path="schemesevenpendingapplications"
            element={<ShaskiySamiti2SchemeSevenPendingApplications />}
          />

          {/* schemeeight */}
          <Route
            path="schemeeighttotalapplications"
            element={<ShaskiySamiti2SchemeEightTotalApplications />}
          />

          <Route
            path="schemeeightapprovedapplications"
            element={<ShaskiySamiti2SchemeEightApprovedApplications />}
          />
          <Route
            path="schemeeightrejectedapplications"
            element={<ShaskiySamiti2SchemeEightRejectedApplications />}
          />
          <Route
            path="schemeeightpendingapplications"
            element={<ShaskiySamiti2SchemeEightPendingApplications />}
          />

          {/* schemenine */}
          <Route
            path="schemeninetotalapplications"
            element={<ShaskiySamiti2SchemeNineTotalApplications />}
          />
          <Route
            path="schemenineapprovedapplications"
            element={<ShaskiySamiti2SchemeNineApprovedApplications />}
          />
          <Route
            path="schemeninerejectedapplications"
            element={<ShaskiySamiti2SchemeNineRejectedApplications />}
          />
          <Route
            path="schemeninependingapplications"
            element={<ShaskiySamiti2SchemeNinePendingApplications />}
          />

          {/* Schemeten */}

          <Route
            path="schemetentotalapplications"
            element={<ShaskiySamiti2SchemeTenTotalApplications />}
          />
          <Route
            path="schemetenapprovedapplications"
            element={<ShaskiySamiti2SchemeTenApprovedApplications />}
          />
          <Route
            path="schemetenrejectedapplications"
            element={<ShaskiySamiti2SchemeTenRejectedApplications />}
          />
          <Route
            path="schemetenpendingapplications"
            element={<ShaskiySamiti2SchemeTenPendingApplications />}
          />
        </Route>

        {/* shaskiy samiti3 */}
        <Route path="/shaskiy-samiti3" element={<ShaskiySamiti3Login />} />
        <Route path="/shaskiy-samiti3" element={<ShaskiySamiti3Container />}>
          <Route path="dashboard" element={<ShaskiySamiti3Dashboard />} />
          {/* schemeone */}
          <Route
            path="schemeonetotalapplications"
            element={<ShaskiySamiti3SchemeOneTotalApplications />}
          />
          <Route
            path="schemeoneapprovedapplications"
            element={<ShaskiySamiti3SchemeOneApprovedApplications />}
          />

          <Route
            path="schemeonerejectedapplications"
            element={<ShaskiySamiti3SchemeOneRejectedApplications />}
          />
          <Route
            path="schemeonependingapplications"
            element={<ShaskiySamiti3SchemeOnePendingApplications />}
          />

          {/* schemetwo */}
          <Route
            path="schemetwototalapplications"
            element={<ShaskiySamiti3SchemeTwoTotalApplications />}
          />
          <Route
            path="schemetwoapprovedapplications"
            element={<ShaskiySamiti3SchemeTwoApprovedApplications />}
          />
          <Route
            path="schemetworejectedapplications"
            element={<ShaskiySamiti3SchemeTwoRejectedApplications />}
          />
          <Route
            path="schemetwopendingapplications"
            element={<ShaskiySamiti3SchemeTwoPendingApplications />}
          />

          {/* schemethree */}
          <Route
            path="schemethreetotalapplications"
            element={<ShaskiySamiti3SchemeThreeTotalApplications />}
          />
          <Route
            path="schemethreeapprovedapplications"
            element={<ShaskiySamiti3SchemeThreeApprovedApplications />}
          />

          <Route
            path="schemethreerejectedapplications"
            d
            element={<ShaskiySamiti3SchemeThreeRejectedApplications />}
          />
          <Route
            path="schemethreependingapplications"
            element={<ShaskiySamiti3SchemeThreePendingApplications />}
          />

          {/* schemefour */}
          <Route
            path="schemefourtotalapplications"
            element={<ShaskiySamiti3SchemeFourTotalApplications />}
          />

          <Route
            path="schemefourrejectedapplications"
            element={<ShaskiySamiti3SchemeFourRejectedApplications />}
          />

          <Route
            path="schemeFourapprovedapplications"
            element={<ShaskiySamiti3SchemeFourApprovedApplications />}
          />
          <Route
            path="schemefourpendingapplications"
            element={<ShaskiySamiti3SchemeFourPendingApplications />}
          />

          {/* schemefive */}
          <Route
            path="schemefivetotalapplications"
            element={<ShaskiySamiti3SchemeFiveTotalApplications />}
          />

          <Route
            path="schemefiveapprovedapplications"
            element={<ShaskiySamiti3SchemeFiveApprovedApplications />}
          />
          <Route
            path="schemefiverejectedapplications"
            element={<ShaskiySamiti3SchemeFiveRejectedApplications />}
          />
          <Route
            path="schemefivependingapplications"
            element={<ShaskiySamiti3SchemeFivePendingApplications />}
          />

          {/* schemesix */}
          <Route
            path="schemesixtotalapplications"
            element={<ShaskiySamiti3SchemeSixTotalApplications />}
          />

          <Route
            path="schemesixapprovedapplications"
            element={<ShaskiySamiti3SchemeSixApprovedApplications />}
          />

          <Route
            path="schemesixrejectedapplications"
            element={<ShaskiySamiti3SchemeSixRejectedApplications />}
          />
          <Route
            path="schemesixpendingapplications"
            element={<ShaskiySamiti3SchemeSixPendingApplications />}
          />

          {/* schemeseven */}
          <Route
            path="schemeseventotalapplications"
            element={<ShaskiySamiti3SchemeSevenTotalApplications />}
          />
          <Route
            path="schemesevenapprovedapplications"
            element={<ShaskiySamiti3SchemeSevenApprovedApplications />}
          />
          <Route
            path="schemesevenrejectedapplications"
            element={<ShaskiySamiti3SchemeSevenRejectedApplications />}
          />
          <Route
            path="schemesevenpendingapplications"
            element={<ShaskiySamiti3SchemeSevenPendingApplications />}
          />

          {/* schemeeight */}
          <Route
            path="schemeeighttotalapplications"
            element={<ShaskiySamiti3SchemeEightTotalApplications />}
          />

          <Route
            path="schemeeightapprovedapplications"
            element={<ShaskiySamiti3SchemeEightApprovedApplications />}
          />
          <Route
            path="schemeeightrejectedapplications"
            element={<ShaskiySamiti3SchemeEightRejectedApplications />}
          />
          <Route
            path="schemeeightpendingapplications"
            element={<ShaskiySamiti3SchemeEightPendingApplications />}
          />

          {/* schemenine */}
          <Route
            path="schemeninetotalapplications"
            element={<ShaskiySamiti3SchemeNineTotalApplications />}
          />
          <Route
            path="schemenineapprovedapplications"
            element={<ShaskiySamiti3SchemeNineApprovedApplications />}
          />
          <Route
            path="schemeninerejectedapplications"
            element={<ShaskiySamiti3SchemeNineRejectedApplications />}
          />
          <Route
            path="schemeninependingapplications"
            element={<ShaskiySamiti3SchemeNinePendingApplications />}
          />

          {/* Schemeten */}

          <Route
            path="schemetentotalapplications"
            element={<ShaskiySamiti3SchemeTenTotalApplications />}
          />
          <Route
            path="schemetenapprovedapplications"
            element={<ShaskiySamiti3SchemeTenApprovedApplications />}
          />
          <Route
            path="schemetenrejectedapplications"
            element={<ShaskiySamiti3SchemeTenRejectedApplications />}
          />
          <Route
            path="schemetenpendingapplications"
            element={<ShaskiySamiti3SchemeTenPendingApplications />}
          />
        </Route>

        {/* shaskiy samiti4 */}
        <Route path="/shaskiy-samiti4" element={<ShaskiySamiti4Login />} />
        <Route path="/shaskiy-samiti4" element={<ShaskiySamiti4Container />}>
          <Route path="dashboard" element={<ShaskiySamiti4Dashboard />} />
          {/* schemeone */}
          <Route
            path="schemeonetotalapplications"
            element={<ShaskiySamiti4SchemeOneTotalApplications />}
          />
          <Route
            path="schemeoneapprovedapplications"
            element={<ShaskiySamiti4SchemeOneApprovedApplications />}
          />

          <Route
            path="schemeonerejectedapplications"
            element={<ShaskiySamiti4SchemeOneRejectedApplications />}
          />
          <Route
            path="schemeonependingapplications"
            element={<ShaskiySamiti4SchemeOnePendingApplications />}
          />

          {/* schemetwo */}
          <Route
            path="schemetwototalapplications"
            element={<ShaskiySamiti4SchemeTwoTotalApplications />}
          />
          <Route
            path="schemetwoapprovedapplications"
            element={<ShaskiySamiti4SchemeTwoApprovedApplications />}
          />
          <Route
            path="schemetworejectedapplications"
            element={<ShaskiySamiti4SchemeTwoRejectedApplications />}
          />
          <Route
            path="schemetwopendingapplications"
            element={<ShaskiySamiti4SchemeTwoPendingApplications />}
          />

          {/* schemethree */}
          <Route
            path="schemethreetotalapplications"
            element={<ShaskiySamiti4SchemeThreeTotalApplications />}
          />
          <Route
            path="schemethreeapprovedapplications"
            element={<ShaskiySamiti4SchemeThreeApprovedApplications />}
          />

          <Route
            path="schemethreerejectedapplications"
            d
            element={<ShaskiySamiti4SchemeThreeRejectedApplications />}
          />
          <Route
            path="schemethreependingapplications"
            element={<ShaskiySamiti4SchemeThreePendingApplications />}
          />

          {/* schemefour */}
          <Route
            path="schemefourtotalapplications"
            element={<ShaskiySamiti4SchemeFourTotalApplications />}
          />

          <Route
            path="schemefourrejectedapplications"
            element={<ShaskiySamiti4SchemeFourRejectedApplications />}
          />

          <Route
            path="schemeFourapprovedapplications"
            element={<ShaskiySamiti4SchemeFourApprovedApplications />}
          />
          <Route
            path="schemefourpendingapplications"
            element={<ShaskiySamiti4SchemeFourPendingApplications />}
          />

          {/* schemefive */}
          <Route
            path="schemefivetotalapplications"
            element={<ShaskiySamiti4SchemeFiveTotalApplications />}
          />

          <Route
            path="schemefiveapprovedapplications"
            element={<ShaskiySamiti4SchemeFiveApprovedApplications />}
          />
          <Route
            path="schemefiverejectedapplications"
            element={<ShaskiySamiti4SchemeFiveRejectedApplications />}
          />
          <Route
            path="schemefivependingapplications"
            element={<ShaskiySamiti4SchemeFivePendingApplications />}
          />

          {/* schemesix */}
          <Route
            path="schemesixtotalapplications"
            element={<ShaskiySamiti4SchemeSixTotalApplications />}
          />

          <Route
            path="schemesixapprovedapplications"
            element={<ShaskiySamiti4SchemeSixApprovedApplications />}
          />

          <Route
            path="schemesixrejectedapplications"
            element={<ShaskiySamiti4SchemeSixRejectedApplications />}
          />
          <Route
            path="schemesixpendingapplications"
            element={<ShaskiySamiti4SchemeSixPendingApplications />}
          />

          {/* schemeseven */}
          <Route
            path="schemeseventotalapplications"
            element={<ShaskiySamiti4SchemeSevenTotalApplications />}
          />
          <Route
            path="schemesevenapprovedapplications"
            element={<ShaskiySamiti4SchemeSevenApprovedApplications />}
          />
          <Route
            path="schemesevenrejectedapplications"
            element={<ShaskiySamiti4SchemeSevenRejectedApplications />}
          />
          <Route
            path="schemesevenpendingapplications"
            element={<ShaskiySamiti4SchemeSevenPendingApplications />}
          />

          {/* schemeeight */}
          <Route
            path="schemeeighttotalapplications"
            element={<ShaskiySamiti4SchemeEightTotalApplications />}
          />

          <Route
            path="schemeeightapprovedapplications"
            element={<ShaskiySamiti4SchemeEightApprovedApplications />}
          />
          <Route
            path="schemeeightrejectedapplications"
            element={<ShaskiySamiti4SchemeEightRejectedApplications />}
          />
          <Route
            path="schemeeightpendingapplications"
            element={<ShaskiySamiti4SchemeEightPendingApplications />}
          />

          {/* schemenine */}
          <Route
            path="schemeninetotalapplications"
            element={<ShaskiySamiti4SchemeNineTotalApplications />}
          />
          <Route
            path="schemenineapprovedapplications"
            element={<ShaskiySamiti4SchemeNineApprovedApplications />}
          />
          <Route
            path="schemeninerejectedapplications"
            element={<ShaskiySamiti4SchemeNineRejectedApplications />}
          />
          <Route
            path="schemeninependingapplications"
            element={<ShaskiySamiti4SchemeNinePendingApplications />}
          />

          {/* Schemeten */}

          <Route
            path="schemetentotalapplications"
            element={<ShaskiySamiti4SchemeTenTotalApplications />}
          />
          <Route
            path="schemetenapprovedapplications"
            element={<ShaskiySamiti4SchemeTenApprovedApplications />}
          />
          <Route
            path="schemetenrejectedapplications"
            element={<ShaskiySamiti4SchemeTenRejectedApplications />}
          />
          <Route
            path="schemetenpendingapplications"
            element={<ShaskiySamiti4SchemeTenPendingApplications />}
          />
        </Route>

        {/* shaskiy samiti5 */}
        <Route path="/shaskiy-samiti5" element={<ShaskiySamiti5Login />} />
        <Route path="/shaskiy-samiti5" element={<ShaskiySamiti5Container />}>
          <Route path="dashboard" element={<ShaskiySamiti5Dashboard />} />
          {/* schemeone */}
          <Route
            path="schemeonetotalapplications"
            element={<ShaskiySamiti5SchemeOneTotalApplications />}
          />
          <Route
            path="schemeoneapprovedapplications"
            element={<ShaskiySamiti5SchemeOneApprovedApplications />}
          />

          <Route
            path="schemeonerejectedapplications"
            element={<ShaskiySamiti5SchemeOneRejectedApplications />}
          />
          <Route
            path="schemeonependingapplications"
            element={<ShaskiySamiti5SchemeOnePendingApplications />}
          />

          {/* schemetwo */}
          <Route
            path="schemetwototalapplications"
            element={<ShaskiySamiti5SchemeTwoTotalApplications />}
          />
          <Route
            path="schemetwoapprovedapplications"
            element={<ShaskiySamiti5SchemeTwoApprovedApplications />}
          />
          <Route
            path="schemetworejectedapplications"
            element={<ShaskiySamiti5SchemeTwoRejectedApplications />}
          />
          <Route
            path="schemetwopendingapplications"
            element={<ShaskiySamiti5SchemeTwoPendingApplications />}
          />

          {/* schemethree */}
          <Route
            path="schemethreetotalapplications"
            element={<ShaskiySamiti5SchemeThreeTotalApplications />}
          />
          <Route
            path="schemethreeapprovedapplications"
            element={<ShaskiySamiti5SchemeThreeApprovedApplications />}
          />

          <Route
            path="schemethreerejectedapplications"
            d
            element={<ShaskiySamiti5SchemeThreeRejectedApplications />}
          />
          <Route
            path="schemethreependingapplications"
            element={<ShaskiySamiti5SchemeThreePendingApplications />}
          />

          {/* schemefour */}
          <Route
            path="schemefourtotalapplications"
            element={<ShaskiySamiti5SchemeFourTotalApplications />}
          />

          <Route
            path="schemefourrejectedapplications"
            element={<ShaskiySamiti5SchemeFourRejectedApplications />}
          />

          <Route
            path="schemeFourapprovedapplications"
            element={<ShaskiySamiti5SchemeFourApprovedApplications />}
          />
          <Route
            path="schemefourpendingapplications"
            element={<ShaskiySamiti5SchemeFourPendingApplications />}
          />

          {/* schemefive */}
          <Route
            path="schemefivetotalapplications"
            element={<ShaskiySamiti5SchemeFiveTotalApplications />}
          />

          <Route
            path="schemefiveapprovedapplications"
            element={<ShaskiySamiti5SchemeFiveApprovedApplications />}
          />
          <Route
            path="schemefiverejectedapplications"
            element={<ShaskiySamiti5SchemeFiveRejectedApplications />}
          />
          <Route
            path="schemefivependingapplications"
            element={<ShaskiySamiti5SchemeFivePendingApplications />}
          />

          {/* schemesix */}
          <Route
            path="schemesixtotalapplications"
            element={<ShaskiySamiti5SchemeSixTotalApplications />}
          />

          <Route
            path="schemesixapprovedapplications"
            element={<ShaskiySamiti5SchemeSixApprovedApplications />}
          />

          <Route
            path="schemesixrejectedapplications"
            element={<ShaskiySamiti5SchemeSixRejectedApplications />}
          />
          <Route
            path="schemesixpendingapplications"
            element={<ShaskiySamiti5SchemeSixPendingApplications />}
          />

          {/* schemeseven */}
          <Route
            path="schemeseventotalapplications"
            element={<ShaskiySamiti5SchemeSevenTotalApplications />}
          />
          <Route
            path="schemesevenapprovedapplications"
            element={<ShaskiySamiti5SchemeSevenApprovedApplications />}
          />
          <Route
            path="schemesevenrejectedapplications"
            element={<ShaskiySamiti5SchemeSevenRejectedApplications />}
          />
          <Route
            path="schemesevenpendingapplications"
            element={<ShaskiySamiti5SchemeSevenPendingApplications />}
          />

          {/* schemeeight */}
          <Route
            path="schemeeighttotalapplications"
            element={<ShaskiySamiti5SchemeEightTotalApplications />}
          />

          <Route
            path="schemeeightapprovedapplications"
            element={<ShaskiySamiti5SchemeEightApprovedApplications />}
          />
          <Route
            path="schemeeightrejectedapplications"
            element={<ShaskiySamiti5SchemeEightRejectedApplications />}
          />
          <Route
            path="schemeeightpendingapplications"
            element={<ShaskiySamiti5SchemeEightPendingApplications />}
          />

          {/* schemenine */}
          <Route
            path="schemeninetotalapplications"
            element={<ShaskiySamiti5SchemeNineTotalApplications />}
          />
          <Route
            path="schemenineapprovedapplications"
            element={<ShaskiySamiti5SchemeNineApprovedApplications />}
          />
          <Route
            path="schemeninerejectedapplications"
            element={<ShaskiySamiti5SchemeNineRejectedApplications />}
          />
          <Route
            path="schemeninependingapplications"
            element={<ShaskiySamiti5SchemeNinePendingApplications />}
          />

          {/* Schemeten */}

          <Route
            path="schemetentotalapplications"
            element={<ShaskiySamiti5SchemeTenTotalApplications />}
          />
          <Route
            path="schemetenapprovedapplications"
            element={<ShaskiySamiti5SchemeTenApprovedApplications />}
          />
          <Route
            path="schemetenrejectedapplications"
            element={<ShaskiySamiti5SchemeTenRejectedApplications />}
          />
          <Route
            path="schemetenpendingapplications"
            element={<ShaskiySamiti5SchemeTenPendingApplications />}
          />
        </Route>

        {/* clerk */}
        <Route path="/clerk" element={<ClerkLogin />} />
        <Route path="/clerk" element={<Clerk1Container />}>
          <Route path="dashboard" element={<ClerkDashboard />} />
          <Route path="addslider" element={<ClerkAddSlider />} />
          <Route path="contactentries" element={<ClerkContactEnquiries />} />
          <Route path="manageuser" element={<ClerkManageUser />} />

          {/* scheme one */}

          <Route
            path="schemeonetotalapplications"
            element={<ClerkSchemeOneTotalApplications />}
          />
          <Route
            path="schemeoneapprovedapplications"
            element={<ClerkSchemeOneApprovedApplications />}
          />

          <Route
            path="schemeonerejectedapplications"
            element={<ClerkSchemeOneRejectedApplications />}
          />
          <Route
            path="schemeonependingapplications"
            element={<ClerkSchemeOnePendingApplications />}
          />

          {/* schemetwo */}
          <Route
            path="schemetwototalapplications"
            element={<ClerkSchemeTwoTotalApplications />}
          />
          <Route
            path="schemetwoapprovedapplications"
            element={<ClerkSchemeTwoApprovedApplications />}
          />
          <Route
            path="schemetworejectedapplications"
            element={<ClerkSchemeTwoRejectedApplications />}
          />
          <Route
            path="schemetwopendingapplications"
            element={<ClerkSchemeTwoPendingApplications />}
          />

          {/* schemethree */}
          <Route
            path="schemethreetotalapplications"
            element={<ClerkSchemeThreeTotalApplications />}
          />
          <Route
            path="schemethreeapprovedapplications"
            element={<ClerkSchemeThreeApprovedApplications />}
          />

          <Route
            path="schemethreerejectedapplications"
            d
            element={<ClerkSchemeThreeRejectedApplications />}
          />
          <Route
            path="schemethreependingapplications"
            element={<ClerkSchemeThreePendingApplications />}
          />

          {/* schemefour */}
          <Route
            path="schemefourtotalapplications"
            element={<ClerkSchemeFourTotalApplications />}
          />

          <Route
            path="schemefourrejectedapplications"
            element={<ClerkSchemeFourRejectedApplications />}
          />

          <Route
            path="schemeFourapprovedapplications"
            element={<ClerkSchemeFourApprovedApplications />}
          />
          <Route
            path="schemefourpendingapplications"
            element={<ClerkSchemeFourPendingApplications />}
          />

          {/* schemefive */}
          <Route
            path="schemefivetotalapplications"
            element={<ClerkSchemeFiveTotalApplications />}
          />

          <Route
            path="schemefiveapprovedapplications"
            element={<ClerkSchemeFiveApprovedApplications />}
          />
          <Route
            path="schemefiverejectedapplications"
            element={<ClerkSchemeFiveRejectedApplications />}
          />
          <Route
            path="schemefivependingapplications"
            element={<ClerkSchemeFivePendingApplications />}
          />

          {/* schemesix */}
          <Route
            path="schemesixtotalapplications"
            element={<ClerkSchemeSixTotalApplications />}
          />

          <Route
            path="schemesixapprovedapplications"
            element={<ClerkSchemeSixApprovedApplications />}
          />

          <Route
            path="schemesixrejectedapplications"
            element={<ClerkSchemeSixRejectedApplications />}
          />
          <Route
            path="schemesixpendingapplications"
            element={<ClerkSchemeSixPendingApplications />}
          />

          {/* schemeseven */}
          <Route
            path="schemeseventotalapplications"
            element={<ClerkSchemeSevenTotalApplications />}
          />
          <Route
            path="schemesevenapprovedapplications"
            element={<ClerkSchemeSevenApprovedApplications />}
          />
          <Route
            path="schemesevenrejectedapplications"
            element={<ClerkSchemeSevenRejectedApplications />}
          />
          <Route
            path="schemesevenpendingapplications"
            element={<ClerkSchemeSevenPendingApplications />}
          />

          {/* schemeeight */}
          <Route
            path="schemeeighttotalapplications"
            element={<ClerkSchemeEightTotalApplications />}
          />

          <Route
            path="schemeeightapprovedapplications"
            element={<ClerkSchemeEightApprovedApplications />}
          />
          <Route
            path="schemeeightrejectedapplications"
            element={<ClerkSchemeEightRejectedApplications />}
          />
          <Route
            path="schemeeightpendingapplications"
            element={<ClerkSchemeEightPendingApplications />}
          />

          {/* schemenine */}
          <Route
            path="schemeninetotalapplications"
            element={<ClerkSchemeNineTotalApplications />}
          />
          <Route
            path="schemenineapprovedapplications"
            element={<ClerkSchemeNineApprovedApplications />}
          />
          <Route
            path="schemeninerejectedapplications"
            element={<ClerkSchemeNineRejectedApplications />}
          />
          <Route
            path="schemeninependingapplications"
            element={<ClerkSchemeNinePendingApplications />}
          />

          {/* Schemeten */}

          <Route
            path="schemetentotalapplications"
            element={<ClerkSchemeTenTotalApplications />}
          />
          <Route
            path="schemetenapprovedapplications"
            element={<ClerkSchemeTenApprovedApplications />}
          />
          <Route
            path="schemetenrejectedapplications"
            element={<ClerkSchemeTenRejectedApplications />}
          />
          <Route
            path="schemetenpendingapplications"
            element={<ClerkSchemeTenPendingApplications />}
          />
        </Route>

        <Route path="/clerk2" element={<Clerk2Login />} />

        <Route path="/clerk2" element={<Clerk2Container />}>
          <Route path="dashboard" element={<Clerk2Dashboard />} />
          {/* scheme one */}

          <Route
            path="schemeonetotalapplications"
            element={<Clerk2SchemeOneTotalApplications />}
          />
          <Route
            path="schemeoneapprovedapplications"
            element={<Clerk2SchemeOneApprovedApplications />}
          />

          <Route
            path="schemeonerejectedapplications"
            element={<Clerk2SchemeOneRejectedApplications />}
          />
          <Route
            path="schemeonependingapplications"
            element={<Clerk2SchemeOnePendingApplications />}
          />

          {/* schemetwo */}
          <Route
            path="schemetwototalapplications"
            element={<Clerk2SchemeTwoTotalApplications />}
          />
          <Route
            path="schemetwoapprovedapplications"
            element={<Clerk2SchemeTwoApprovedApplications />}
          />
          <Route
            path="schemetworejectedapplications"
            element={<Clerk2SchemeTwoRejectedApplications />}
          />
          <Route
            path="schemetwopendingapplications"
            element={<Clerk2SchemeTwoPendingApplications />}
          />

          {/* schemethree */}
          <Route
            path="schemethreetotalapplications"
            element={<Clerk2SchemeThreeTotalApplications />}
          />
          <Route
            path="schemethreeapprovedapplications"
            element={<Clerk2SchemeThreeApprovedApplications />}
          />

          <Route
            path="schemethreerejectedapplications"
            d
            element={<Clerk2SchemeThreeRejectedApplications />}
          />
          <Route
            path="schemethreependingapplications"
            element={<Clerk2SchemeThreePendingApplications />}
          />

          {/* schemefour */}
          <Route
            path="schemefourtotalapplications"
            element={<Clerk2SchemeFourTotalApplications />}
          />

          <Route
            path="schemefourrejectedapplications"
            element={<Clerk2SchemeFourRejectedApplications />}
          />

          <Route
            path="schemeFourapprovedapplications"
            element={<Clerk2SchemeFourApprovedApplications />}
          />
          <Route
            path="schemefourpendingapplications"
            element={<Clerk2SchemeFourPendingApplications />}
          />

          {/* schemefive */}
          <Route
            path="schemefivetotalapplications"
            element={<Clerk2SchemeFiveTotalApplications />}
          />

          <Route
            path="schemefiveapprovedapplications"
            element={<Clerk2SchemeFiveApprovedApplications />}
          />
          <Route
            path="schemefiverejectedapplications"
            element={<Clerk2SchemeFiveRejectedApplications />}
          />
          <Route
            path="schemefivependingapplications"
            element={<Clerk2SchemeFivePendingApplications />}
          />

          {/* schemesix */}
          <Route
            path="schemesixtotalapplications"
            element={<Clerk2SchemeSixTotalApplications />}
          />

          <Route
            path="schemesixapprovedapplications"
            element={<Clerk2SchemeSixApprovedApplications />}
          />

          <Route
            path="schemesixrejectedapplications"
            element={<Clerk2SchemeSixRejectedApplications />}
          />
          <Route
            path="schemesixpendingapplications"
            element={<Clerk2SchemeSixPendingApplications />}
          />

          {/* schemeseven */}
          <Route
            path="schemeseventotalapplications"
            element={<Clerk2SchemeSevenTotalApplications />}
          />
          <Route
            path="schemesevenapprovedapplications"
            element={<Clerk2SchemeSevenApprovedApplications />}
          />
          <Route
            path="schemesevenrejectedapplications"
            element={<Clerk2SchemeSevenRejectedApplications />}
          />
          <Route
            path="schemesevenpendingapplications"
            element={<Clerk2SchemeSevenPendingApplications />}
          />

          {/* schemeeight */}
          <Route
            path="schemeeighttotalapplications"
            element={<Clerk2SchemeEightTotalApplications />}
          />

          <Route
            path="schemeeightapprovedapplications"
            element={<Clerk2SchemeEightApprovedApplications />}
          />
          <Route
            path="schemeeightrejectedapplications"
            element={<Clerk2SchemeEightRejectedApplications />}
          />
          <Route
            path="schemeeightpendingapplications"
            element={<Clerk2SchemeEightPendingApplications />}
          />

          {/* schemenine */}
          <Route
            path="schemeninetotalapplications"
            element={<Clerk2SchemeNineTotalApplications />}
          />
          <Route
            path="schemenineapprovedapplications"
            element={<Clerk2SchemeNineApprovedApplications />}
          />
          <Route
            path="schemeninerejectedapplications"
            element={<Clerk2SchemeNineRejectedApplications />}
          />
          <Route
            path="schemeninependingapplications"
            element={<Clerk2SchemeNinePendingApplications />}
          />

          {/* Schemeten */}

          <Route
            path="schemetentotalapplications"
            element={<Clerk2SchemeTenTotalApplications />}
          />
          <Route
            path="schemetenapprovedapplications"
            element={<Clerk2SchemeTenApprovedApplications />}
          />
          <Route
            path="schemetenrejectedapplications"
            element={<Clerk2SchemeTenRejectedApplications />}
          />
          <Route
            path="schemetenpendingapplications"
            element={<Clerk2SchemeTenPendingApplications />}
          />
        </Route>

        <Route path="/clerk3" element={<Clerk3Login />} />

        <Route path="/clerk3" element={<Clerk3Container />}>
          <Route path="dashboard" element={<Clerk3Dashboard />} />
          {/* scheme one */}

          <Route
            path="schemeonetotalapplications"
            element={<Clerk3SchemeOneTotalApplications />}
          />
          <Route
            path="schemeoneapprovedapplications"
            element={<Clerk3SchemeOneApprovedApplications />}
          />

          <Route
            path="schemeonerejectedapplications"
            element={<Clerk3SchemeOneRejectedApplications />}
          />
          <Route
            path="schemeonependingapplications"
            element={<Clerk3SchemeOnePendingApplications />}
          />

          {/* schemetwo */}
          <Route
            path="schemetwototalapplications"
            element={<Clerk3SchemeTwoTotalApplications />}
          />
          <Route
            path="schemetwoapprovedapplications"
            element={<Clerk3SchemeTwoApprovedApplications />}
          />
          <Route
            path="schemetworejectedapplications"
            element={<Clerk3SchemeTwoRejectedApplications />}
          />
          <Route
            path="schemetwopendingapplications"
            element={<Clerk3SchemeTwoPendingApplications />}
          />

          {/* schemethree */}
          <Route
            path="schemethreetotalapplications"
            element={<Clerk3SchemeThreeTotalApplications />}
          />
          <Route
            path="schemethreeapprovedapplications"
            element={<Clerk3SchemeThreeApprovedApplications />}
          />

          <Route
            path="schemethreerejectedapplications"
            d
            element={<Clerk3SchemeThreeRejectedApplications />}
          />
          <Route
            path="schemethreependingapplications"
            element={<Clerk3SchemeThreePendingApplications />}
          />

          {/* schemefour */}
          <Route
            path="schemefourtotalapplications"
            element={<Clerk3SchemeFourTotalApplications />}
          />

          <Route
            path="schemefourrejectedapplications"
            element={<Clerk3SchemeFourRejectedApplications />}
          />

          <Route
            path="schemeFourapprovedapplications"
            element={<Clerk3SchemeFourApprovedApplications />}
          />
          <Route
            path="schemefourpendingapplications"
            element={<Clerk3SchemeFourPendingApplications />}
          />

          {/* schemefive */}
          <Route
            path="schemefivetotalapplications"
            element={<Clerk3SchemeFiveTotalApplications />}
          />

          <Route
            path="schemefiveapprovedapplications"
            element={<Clerk3SchemeFiveApprovedApplications />}
          />
          <Route
            path="schemefiverejectedapplications"
            element={<Clerk3SchemeFiveRejectedApplications />}
          />
          <Route
            path="schemefivependingapplications"
            element={<Clerk3SchemeFivePendingApplications />}
          />

          {/* schemesix */}
          <Route
            path="schemesixtotalapplications"
            element={<Clerk3SchemeSixTotalApplications />}
          />

          <Route
            path="schemesixapprovedapplications"
            element={<Clerk3SchemeSixApprovedApplications />}
          />

          <Route
            path="schemesixrejectedapplications"
            element={<Clerk3SchemeSixRejectedApplications />}
          />
          <Route
            path="schemesixpendingapplications"
            element={<Clerk3SchemeSixPendingApplications />}
          />

          {/* schemeseven */}
          <Route
            path="schemeseventotalapplications"
            element={<Clerk3SchemeSevenTotalApplications />}
          />
          <Route
            path="schemesevenapprovedapplications"
            element={<Clerk3SchemeSevenApprovedApplications />}
          />
          <Route
            path="schemesevenrejectedapplications"
            element={<Clerk3SchemeSevenRejectedApplications />}
          />
          <Route
            path="schemesevenpendingapplications"
            element={<Clerk3SchemeSevenPendingApplications />}
          />

          {/* schemeeight */}
          <Route
            path="schemeeighttotalapplications"
            element={<Clerk3SchemeEightTotalApplications />}
          />

          <Route
            path="schemeeightapprovedapplications"
            element={<Clerk3SchemeEightApprovedApplications />}
          />
          <Route
            path="schemeeightrejectedapplications"
            element={<Clerk3SchemeEightRejectedApplications />}
          />
          <Route
            path="schemeeightpendingapplications"
            element={<Clerk3SchemeEightPendingApplications />}
          />

          {/* schemenine */}
          <Route
            path="schemeninetotalapplications"
            element={<Clerk3SchemeNineTotalApplications />}
          />
          <Route
            path="schemenineapprovedapplications"
            element={<Clerk3SchemeNineApprovedApplications />}
          />
          <Route
            path="schemeninerejectedapplications"
            element={<Clerk3SchemeNineRejectedApplications />}
          />
          <Route
            path="schemeninependingapplications"
            element={<Clerk3SchemeNinePendingApplications />}
          />

          {/* Schemeten */}

          <Route
            path="schemetentotalapplications"
            element={<Clerk3SchemeTenTotalApplications />}
          />
          <Route
            path="schemetenapprovedapplications"
            element={<Clerk3SchemeTenApprovedApplications />}
          />
          <Route
            path="schemetenrejectedapplications"
            element={<Clerk3SchemeTenRejectedApplications />}
          />
          <Route
            path="schemetenpendingapplications"
            element={<Clerk3SchemeTenPendingApplications />}
          />
        </Route>
      </Routes>
    </>
  );
};

export default App;
