import React from "react";
import SchemeTenapprovedapplications from "../../Administration/SchemeTen/SchemeTenapprovedapplications";

const ShaskiySamitiSchemeTenApprovedApplications = () => {
    return (
        <div>
            <SchemeTenapprovedapplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeTenApprovedApplications;
