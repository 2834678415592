import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { Button } from "@mui/material";
import SuperAdminSidebarTwo from "../superadmin/SuperAdminSidebarTwo";

const SuperAdminContainer = () => {
  document.title = "Commissioner ";
  const superadmin = useSelector((store) => store.superadmin);

  return (
    <>
      <div className="row ">
        <SuperAdminSidebarTwo role="PWD Department" />
        <div className="container">
          {superadmin ? <Outlet /> : <Navigate to="/super-admin" />}
        </div>
      </div>
    </>
  );
};

export default SuperAdminContainer;
