import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { useSelectAccess } from "../store/stateFunctions";
import ShaskiySamiti5Sidebar from "../shaskiysamiti5/ShaskiySamiti5Sidebar";
// import { Outlet } from "react-router-dom";

const ShaskiySamiti5Container = () => {
  document.title = "Shaskiy Samiti";
  const shaskiysamiti5 = useSelectAccess("Shaskiy Samiti5");

  return (
    <>
      <div className="row ">
        <ShaskiySamiti5Sidebar role="Shaskiy Samiti5" />
        <div className="container">
          {shaskiysamiti5 ? <Outlet /> : <Navigate to="/shaskiy-samiti5" />}
          {/* <Outlet /> */}
        </div>
      </div>
    </>
  );
};

export default ShaskiySamiti5Container;
