import React, { useState } from "react";

import { Table, FormControl, InputGroup } from "react-bootstrap";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelectAccess } from "../../store/stateFunctions";
import { toast } from "react-toastify";
// import SendIcon from '@mui/icons-material/Send';
// import VisibilityIcon from '@mui/icons-material/Visibility';

function Womenwelfare() {
  const [searchTerm, setSearchTerm] = useState("");
    const { t } = useTranslation();
  const user = useSelectAccess("User");
  const isLoggedIn = user && user;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleApplyNowClick = (e) => {
    if (!isLoggedIn) {
      e.preventDefault(); // Prevent navigation
      toast.info("Please log in to apply for the Scheme");
      navigate("/login");
    } else {
      setShow(true);
    }
  };


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchClick = () => {
    console.log("Search term:", searchTerm);
  };

  return (
    <div className="container mt-4">
    <h2 className="text-center" style={{ fontWeight: "700" }}>
    महिला व बालकल्याण विभाग  
    </h2>
  
    <InputGroup className="my-3">
      <FormControl
        type="text"
        placeholder="Search schemes..."
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <Button variant="contained" onClick={handleSearchClick}>
        Search
      </Button>
    </InputGroup>
  
    <Table striped bordered hover responsive="md" className="mt-4">
      <thead>
        <tr>
          <th style={{ width: "50px", textAlign: "center" }}>अनु क्र.</th>
          <th>योजना</th>
          <th style={{ textAlign: "center", verticalAlign: "middle" }}>
            Apply Now
          </th>
          <th style={{ textAlign: "center", verticalAlign: "middle" }}>
            View Details
          </th>
        </tr>
      </thead>
      <tbody>
        {/* Scheme 1 */}
        <tr>
          <td style={{ width: "50px", textAlign: "center" }}>1</td>
          <td> निराधार/विधवा/परित्यकता/घटस्फोटित महिलांच्या मुलींचे विवाह करिता  अर्धसहाय्य  देणे </td>
          <td style={{ textAlign: "center", verticalAlign: "middle" }}>
           <NavLink to="/schemeoneform" onClick={handleApplyNowClick}>
                <Button variant="contained" className="bluebutton">
                  {t("allschemestable.applynowbtn")}
                </Button>
              </NavLink>
          </td>
          <td style={{ textAlign: "center", verticalAlign: "middle" }}>
            <NavLink to="/welfareschemeonedetails">
              <Button variant="contained" className="redbutton">
                View Details
              </Button>
            </NavLink>
          </td>
        </tr>
  
        {/* Scheme 2 */}
        <tr>
          <td style={{ width: "50px", textAlign: "center" }}>2</td>
          <td> विधवा /घटस्फोटित महिलांच्या मुलांना तसेच निराधार मुलांना शिष्यवृत्ती  देणे </td>
          <td style={{ textAlign: "center", verticalAlign: "middle" }}>
            <NavLink to="/schemetwoform" onClick={handleApplyNowClick}>
                <Button variant="contained" className="bluebutton">
                  {t("allschemestable.applynowbtn")}
                </Button>
              </NavLink>
          </td>
          <td style={{ textAlign: "center", verticalAlign: "middle" }}>
            <NavLink to="/welfareschemetwodetails">
              <Button variant="contained" className="redbutton">
                View Details
              </Button>
            </NavLink>
          </td>
        </tr>
  
        {/* Scheme 3 */}
        <tr>
          <td style={{ width: "50px", textAlign: "center" }}>3</td>
          <td> अनाथ/ निराश्रित मुलांकरिता वार्षिक शैक्षणिक शुल्कासाठी (फी) अर्थसहाय्य मिळणे बाबत </td>
          <td style={{ textAlign: "center", verticalAlign: "middle" }}>
             <NavLink to="/schemethreeform" onClick={handleApplyNowClick}>
                <Button variant="contained" className="bluebutton">
                  {t("allschemestable.applynowbtn")}
                </Button>
              </NavLink>
          </td>
          <td style={{ textAlign: "center", verticalAlign: "middle" }}>
            <NavLink to="/welfareschemethreedetails">
              <Button variant="contained" className="redbutton">
                View Details
              </Button>
            </NavLink>
          </td>
        </tr>
         {/* Scheme 4  */}
         <tr>
          <td style={{ width: "50px", textAlign: "center" }}>4</td>
          <td> अनाथ/ निराश्रित मुलांना दत्तक घेतलेल्या पालकांना प्रोत्साहनात्मक  अनुदान मिळणे बाबत  </td>
          <td style={{ textAlign: "center", verticalAlign: "middle" }}>
            <NavLink to="/schemefourform" onClick={handleApplyNowClick}>
                <Button variant="contained" className="bluebutton">
                  {t("allschemestable.applynowbtn")}
                </Button>
              </NavLink>
          </td>
          <td style={{ textAlign: "center", verticalAlign: "middle" }}>
            <NavLink to="/welfareschemefourdetails">
              <Button variant="contained" className="redbutton">
                View Details
              </Button>
            </NavLink>
          </td>
        </tr>
      </tbody>
    </Table>
  </div>
  );
}

export default Womenwelfare ;
