import React from "react";
import SchemeTenPendingApplications from "../../Administration/SchemeTen/SchemeTenPendingApplications";

const ChananiTenPendingApplications = () => {
  return (
    <div>
      <SchemeTenPendingApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiTenPendingApplications;
