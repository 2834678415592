import React from "react";
import SchemeSixPendingApplications from "../../Administration/SchemeSix/SchemeSixPendingApplications";

const ShaskiySamitiSchemeSixPendingApplications = () => {
    return (
        <div>
            <SchemeSixPendingApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeSixPendingApplications;
