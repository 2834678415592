import React from "react";
import Dashboard from "../Administration/dashboard/TableDashboard/Dashboard";

const ShaskiySamiti1Dashboard = () => {
  return (
    <>
      <Dashboard />
    </>
  );
};

export default ShaskiySamiti1Dashboard;
