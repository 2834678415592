import React from "react";
import SchemeEightPendingApplications from "../../Administration/SchemeEight/SchemeEightPendingApplications";

const ChananiEightPendingApplications = () => {
  return (
    <div>
      <SchemeEightPendingApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiEightPendingApplications;
