import React from "react";
import SchemeOneApprovedApplications from "../../Administration/SchemeOne/SchemeOneApprovedApplications";

const ChananiOneApprovedApplications = () => {
  return (
    <div>
      <SchemeOneApprovedApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiOneApprovedApplications;
