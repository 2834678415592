import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { useSelectAccess } from "../store/stateFunctions";
import ChananiSamiti1Sidebar from "../chananisamiti1/ChananiSamiti1Sidebar";

const ChananiSamiti1Container = () => {
  document.title = "Chanani Samiti";
  const chananisamiti1 = useSelectAccess("Chanani Samiti1");

  return (
    <>
      <div className="row ">
        <ChananiSamiti1Sidebar role="Chanani Samiti1" />
        <div className="container">
          {chananisamiti1 ? <Outlet /> : <Navigate to="/chanani-samiti1" />}
          {/* <Outlet /> */}
        </div>
      </div>
    </>
  );
};

export default ChananiSamiti1Container;
