export const loginShaskiySamiti3 = (session) => {
  return {
    type: "LOGIN_SHASKIY_SAMITI3",
    payload: session,
  };
};

export const logoutShaskiySamiti3 = () => {
  return {
    type: "LOGOUT_SHASKIY_SAMITI3",
  };
};
