import React from "react";
import SchemeFivePendingApplications from "../../Administration/SchemeFive/SchemeFivePendingApplications";

const ChananiFivePendingApplications = () => {
  return (
    <div>
      <SchemeFivePendingApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiFivePendingApplications;
