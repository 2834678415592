import React from "react";
import SchemeSevenTotalApplications from "../../Administration/SchemeSeven/SchemeSevenTotalApplications";

const ShaskiySamitiSchemeSevenTotalApplications = () => {
    return (
        <div>
            <SchemeSevenTotalApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeSevenTotalApplications;
