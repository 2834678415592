import React from "react";
import SchemeTwoTotalApplications from "../../Administration/SchemeTwo/SchemeTwoTotalApplications";

const ShaskiySamitiSchemeTwoTotalApplications = () => {
    return (
        <div>
            <SchemeTwoTotalApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeTwoTotalApplications;
