import React from "react";
import SchemeTwoApprovedApplications from "../../Administration/SchemeTwo/SchemeTwoApprovedApplications";

const ChananiTwoApprovedApplications = () => {
  return (
    <div>
      <SchemeTwoApprovedApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiTwoApprovedApplications;
