import React, { useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

const FeaturingSchemes = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const { t } = useTranslation();

  const schemes = [
    {
      title: "featuringschemes.scheme1.title",
      description: "featuringschemes.scheme1.description",
      funding: "featuringschemes.scheme1.funding",
      link: "/SchemeOne",
    },
    {
      title: "featuringschemes.scheme2.title",
      description: "featuringschemes.scheme2.description",
      funding: "featuringschemes.scheme2.funding",
      link: "/SchemeTwo",
    },
    {
      title: "featuringschemes.scheme3.title",
      description: "featuringschemes.scheme3.description",
      funding: "featuringschemes.scheme3.funding",
      link: "/SchemeThree",
    },
    {
      title: "featuringschemes.scheme4.title",
      description: "featuringschemes.scheme4.description",
      funding: "featuringschemes.scheme4.funding",
      link: "/SchemeFour",
    },
    {
      title: "featuringschemes.scheme5.title",
      description: "featuringschemes.scheme5.description",
      funding: "featuringschemes.scheme5.funding",
      link: "/SchemeFive",
    },
    {
      title: "featuringschemes.scheme6.title",
      description: "featuringschemes.scheme6.description",
      funding: "featuringschemes.scheme6.funding",
      link: "/SchemeSix",
    },
  ];

  return (
    <Box sx={{ p: 4 }}>
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Typography
          className="header-text"
          variant="h3"
          component="h1"
          gutterBottom
        >
          {t("featuringschemes.title")}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {t("featuringschemes.description")}
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {schemes.map((scheme, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} data-aos="fade-up">
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                background: "linear-gradient(135deg, #ffffff, #e0f7fa)",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                "&:hover": {
                  transform: "scale(1.05)",
                  transition: "transform 0.3s ease-in-out",
                },
              }}
            >
              <CardContent>
                <Typography
                  className="fw-700"
                  variant="h6"
                  component="h2"
                  gutterBottom
                >
                  {t(scheme.title)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {t(scheme.description)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {t(scheme.funding)}
                </Typography>
              </CardContent>
              <Box sx={{ p: 2, textAlign: "center" }}>
                <Button
                  component={NavLink}
                  to={scheme.link}
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: "#3d5a80",
                    "&:hover": {
                      backgroundColor: "#f39c12",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                >
                  {t("featuringschemes.button")}
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FeaturingSchemes;
// import React from 'react';
// import { Card, CardContent, Typography, Box, IconButton } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// import { DocumentScanner, Gavel, People, Work } from '@mui/icons-material';

// const useStyles = makeStyles((theme) => ({
//   card: {
//     width: 250,
//     margin: '10px',
//     cursor: 'pointer',
//     transition: 'transform 0.3s',
//     '&:hover': {
//       transform: 'scale(1.05)',
//       boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
//     },
//   },
//   icon: {
//     color: '#D32F2F',
//     fontSize: '40px',
//   },
//   title: {
//     fontWeight: 'bold',
//     marginBottom: '10px',
//   },
//   link: {
//     color: '#333',
//     textDecoration: 'none',
//     '&:hover': {
//       textDecoration: 'underline',
//     },
//   },
// }));

// const data = [
//   {
//     title: 'Benefits',
//     icon: <DocumentScanner />,
//     links: ['U.S. Social Security Administration Disability Benefits Information', 'Benefits.gov Disability Assistance'],
//   },
//   {
//     title: 'Civil Rights',
//     icon: <Gavel />,
//     links: ['U.S. Department of Justice Disability Rights Section', 'File a Disability Complaint', 'U.S. Department of Education Office for Civil Rights'],
//   },
//   {
//     title: 'Community Life',
//     icon: <People />,
//     links: ['Administration for Community Living — Programs', 'Medicaid Home and Community-Based Services'],
//   },
//   {
//     title: 'Employment',
//     icon: <Work />,
//     links: ['U.S. Department of Labor Office of Disability Employment Policy', 'Social Security Administration Ticket to Work'],
//   },
// ];

// function InfoCards() {
//   const classes = useStyles();

//   return (
//     <Box display="flex" justifyContent="center" flexWrap="wrap">
//       {data.map((item, index) => (
//         <Card key={index} className={classes.card}>
//           <CardContent>
//             <IconButton className={classes.icon}>{item.icon}</IconButton>
//             <Typography variant="h6" className={classes.title}>
//               {item.title}
//             </Typography>
//             {item.links.map((link, i) => (
//               <Typography key={i} variant="body2" className={classes.link}>
//                 {link}
//               </Typography>
//             ))}
//           </CardContent>
//         </Card>
//       ))}
//     </Box>
//   );
// }

// export default InfoCards;
