import React from "react";
import SchemeSevenRejectedApplications from "../../Administration/SchemeSeven/SchemeSevenRejectedApplications";

const ShaskiySamitiSchemeSevenRejectedApplications = () => {
    return (
        <div>
            <SchemeSevenRejectedApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeSevenRejectedApplications;
