import React from "react";
import SchemeNinePendingApplications from "../../Administration/schemeNine/SchemeNinePendingApplications";

const ShaskiySamitiSchemeNinePendingApplications = () => {
    return (
        <div>
            <SchemeNinePendingApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeNinePendingApplications;
