import React from "react";
import Dashboard from "../Administration/dashboard/TableDashboard/Dashboard";

const ChananiSamitiDashboard = () => {
  return (
    <>
      <h1>
        <Dashboard role="Chanani Samiti" />
      </h1>
    </>
  );
};

export default ChananiSamitiDashboard;
