import React from 'react';
import { useTranslation } from 'react-i18next';


const News = () => {
  const {t}=useTranslation()
  return (
    <div style={styles.newsContainer}>
      <h2 style={styles.heading}>{t("Latestnews.latestNews")}</h2>
      <div style={styles.cardsContainer}>
        <div style={styles.card}>
          <img
            src="https://via.placeholder.com/250x180?text=Disability+Scheme"
            alt="NMC कडून नवीन अपंग योजना सुरू"
            style={styles.cardImage}
          />
          <div style={styles.cardContent}>
            <h3 style={styles.newsTitle}>{t("Latestnews.newsItems.title")}</h3>
            <p style={styles.newsDescription}>
            {t("Latestnews.newsItems.description")}
            </p>
          </div>
        </div>

        <div style={styles.card}>
          <img
            src="https://via.placeholder.com/250x180?text=Sports+Scheme"
            alt="अपंगांसाठी क्रीडा योजना"
            style={styles.cardImage}
          />
          <div style={styles.cardContent}>
            <h3 style={styles.newsTitle}>अपंगांसाठी क्रीडा योजना</h3>
            <p style={styles.newsDescription}>
              NMC ने अपंग व्यक्तींना मनोरंजक आणि स्पर्धात्मक क्रीडा मध्ये सहभागी होण्यासाठी नवीन क्रीडा योजना सुरू केली आहेत, ज्यामुळे त्यांचे शारीरिक आणि मानसिक आरोग्य सुधारेल.
            </p>
          </div>
        </div>

        <div style={styles.card}>
          <img
            src="https://via.placeholder.com/250x180?text=Women+and+Child+Welfare"
            alt="महिला आणि बाल कल्याण उपक्रम"
            style={styles.cardImage}
          />
          <div style={styles.cardContent}>
            <h3 style={styles.newsTitle}>महिला आणि बाल कल्याण उपक्रम</h3>
            <p style={styles.newsDescription}>
              नाशिक महापालिकेच्या महिला आणि बाल कल्याण विभागाने महिलांच्या सक्षमीकरण आणि मुलांच्या संरक्षणावर लक्ष केंद्रित करणारी नवीन कार्यक्रम सुरू केली आहेत.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  newsContainer: {
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
  heading: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    marginBottom: '15px',
    textAlign: 'center',
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    margin: '15px',
    width: '400px',
    overflow: 'hidden',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  },
  cardImage: {
    width: '100%',
    height: '180px',
    objectFit: 'cover',
    transition: 'transform 0.3s ease-in-out',
  },
  cardContent: {
    padding: '15px',
  },
  newsTitle: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  newsDescription: {
    fontSize: '1em',
    color: '#555',
    lineHeight: '1.4',
  },
};

export default News;
