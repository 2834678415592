import React from "react";
import SchemeNinePendingApplications from "../../Administration/schemeNine/SchemeNinePendingApplications";

const ChananiNinePendingApplications = () => {
  return (
    <div>
      <SchemeNinePendingApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiNinePendingApplications;
