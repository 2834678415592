import React from "react";
import SchemeSevenTotalApplications from "../../Administration/SchemeSeven/SchemeSevenTotalApplications";

const ChananiSevenTotalApplications = () => {
  return (
    <div>
      <SchemeSevenTotalApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiSevenTotalApplications;
