import React from "react";
import SchemeTenRejectedApplications from "../../Administration/SchemeTen/SchemeTenRejectedApplications";

const ChananiTenRejectedApplications = () => {
  return (
    <div>
      <SchemeTenRejectedApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiTenRejectedApplications;
