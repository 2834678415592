import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Table, FormControl, InputGroup } from "react-bootstrap";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelectAccess } from "../../store/stateFunctions";
import { toast } from "react-toastify";

function SportsFormsTable() {
  const { t } = useTranslation();
  const user = useSelectAccess("User");
  const isLoggedIn = user && user;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState(""); // Add search state

  const schemes = [
    { id: 1, name: "आंतरराष्ट्रीयस्तरावरील क्रीडा शिष्यवृत्ती अर्थसहाय्य", formLink: "/schemeform", detailsLink: "/sportschemeonedetails" },
    { id: 2, name: "राष्ट्रीयस्तरावरील क्रीडा शिष्यवृत्ती अर्थसहाय्य", formLink: "/sportschemetwoform", detailsLink: "/sportschemetwodetails" },
    { id: 3, name: "राज्यस्तरावरील क्रीडा शिष्यवृत्ती अर्थसहाय्य", formLink: "/sportschemethreeform", detailsLink: "/sportschemethreedetails" }
  ];

  const handleApplyNowClick = (e) => {
    if (!isLoggedIn) {
      e.preventDefault();
      toast.info("Please log in to apply for the Scheme");
      navigate("/login");
    } else {
      setShow(true);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredSchemes = schemes.filter(scheme =>
    scheme.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container mt-4">
      <h2 className="text-center" style={{ fontWeight: "700" }}>क्रीडा विभाग</h2>

      <InputGroup className="my-3">
        <FormControl
          type="text"
          placeholder="Search schemes..."
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <Button variant="contained">Search</Button>
      </InputGroup>

      <Table striped bordered hover responsive="md" className="mt-4">
        <thead>
          <tr>
            <th style={{ width: "50px", textAlign: "center" }}>अनु क्र.</th>
            <th>योजना</th>
            <th style={{ textAlign: "center", verticalAlign: "middle" }}>Apply Now</th>
            <th style={{ textAlign: "center", verticalAlign: "middle" }}>View Details</th>
          </tr>
        </thead>
        <tbody>
          {filteredSchemes.map((scheme, index) => (
            <tr key={scheme.id}>
              <td style={{ width: "50px", textAlign: "center" }}>{index + 1}</td>
              <td>{scheme.name}</td>
              <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                <NavLink to={scheme.formLink} onClick={handleApplyNowClick}>
                  <Button variant="contained" className="bluebutton">Apply Now</Button>
                </NavLink>
              </td>
              <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                <NavLink to={scheme.detailsLink}>
                  <Button variant="contained" className="redbutton">View Details</Button>
                </NavLink>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default SportsFormsTable;
