import React, { useState } from "react";
import { Card, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
// import brochure from "../../assets/documents/Funding for Assistive Devices and Technological Know-How for the Disabled.pdf";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelectAccess } from "../../store/stateFunctions";

const SchemeSixDetails = () => {
  const { t } = useTranslation();
  const user = useSelectAccess("User");
  const isLoggedIn = user && user;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleApplyNowClick = (e) => {
    if (!isLoggedIn) {
      e.preventDefault(); // Prevent navigation
      toast.info("Please log in to apply for the Scheme");
      navigate("/login");
    } else {
      setShow(true);
    }
  };

  return (
    <div
      className="container-fluid mt-4"
      style={{ backgroundColor: "#eaf6ff" }}
    >
      <div className="row">
        <div className="col-12">
          <div
            style={{
              backgroundColor: "#ffffff",
              padding: "30px",
              border: "1px solid #e0e0e0",
            }}
          >
            <h4 style={{ fontWeight: "bold", marginBottom: "20px" }}>
              {t("schemesixdetails.title")}
            </h4>

            <div>
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                <li>{t("schemesixdetails.description.0")}</li>
                <li>{t("schemesixdetails.description.1")}</li>
                <li>{t("schemesixdetails.description.2")}</li>
                <li>{t("schemesixdetails.description.3")}</li>
                <li>{t("schemesixdetails.description.4")}</li>
              </ul>
              <h5 style={{ marginBottom: "10px" }}>
                {t("schemesixdetails.inclusionTitle")}
              </h5>
            </div>

            {/* Card Sections */}
            {[
              {
                title: t("schemesixdetails.categories.blindLabel"),
                items: [
                  t("schemesixdetails.categories.blindItems.0"),
                  t("schemesixdetails.categories.blindItems.1"),
                  t("schemesixdetails.categories.blindItems.2"),
                  t("schemesixdetails.categories.blindItems.3"),
                  t("schemesixdetails.categories.blindItems.4"),
                  t("schemesixdetails.categories.blindItems.5"),
                  t("schemesixdetails.categories.blindItems.6"),
                  t("schemesixdetails.categories.blindItems.7"),
                  t("schemesixdetails.categories.blindItems.8"),
                  t("schemesixdetails.categories.blindItems.9"),
                  t("schemesixdetails.categories.blindItems.10"),
                ],
              },
              {
                title: t("schemesixdetails.categories.deafLabel"),
                items: [
                  t("schemesixdetails.categories.deafItems.0"),
                  t("schemesixdetails.categories.deafItems.1"),
                  t("schemesixdetails.categories.deafItems.2"),
                  t("schemesixdetails.categories.deafItems.3"),
                ],
              },
              {
                title: t(
                  "schemesixdetails.categories.orthopedicallyDisabledLabel"
                ),
                items: [
                  t("schemesixdetails.categories.physicalItems.0"),
                  t("schemesixdetails.categories.physicalItems.1"),
                  t("schemesixdetails.categories.physicalItems.2"),
                  t("schemesixdetails.categories.physicalItems.3"),
                  t("schemesixdetails.categories.physicalItems.4"),
                  t("schemesixdetails.categories.physicalItems.5"),
                  t("schemesixdetails.categories.physicalItems.6"),
                  t("schemesixdetails.categories.physicalItems.7"),
                ],
              },
              {
                title: t("schemesixdetails.categories.mentallyChallengedLabel"),
                items: [
                  t("schemesixdetails.categories.mentallyChallenged.0"),
                  t("schemesixdetails.categories.mentallyChallenged.1"),
                  t("schemesixdetails.categories.mentallyChallenged.2"),
                  t("schemesixdetails.categories.mentallyChallenged.3"),
                ],
              },
              {
                title: t("schemesixdetails.categories.multipleDisabledLabel"),
                items: [
                  t("schemesixdetails.categories.multiDisabilityItems.0"),
                  t("schemesixdetails.categories.multiDisabilityItems.1"),
                  t("schemesixdetails.categories.multiDisabilityItems.2"),
                  t("schemesixdetails.categories.multiDisabilityItems.3"),
                ],
              },
              {
                title: t("schemesixdetails.categories.leprosyCuredLabel"),
                items: [
                  t("schemesixdetails.categories.leprosyCuredItems.0"),
                  t("schemesixdetails.categories.leprosyCuredItems.1"),
                  t("schemesixdetails.categories.leprosyCuredItems.2"),
                  t("schemesixdetails.categories.leprosyCuredItems.3"),
                ],
              },
            ].map((section, index) => (
              <Card
                key={index}
                style={{ padding: "15px", marginBottom: "20px" }}
              >
                <h6>{section.title}</h6>
                <ul>
                  {section.items.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
              </Card>
            ))}

            <h5 style={{ marginBottom: "10px" }}>
              {t("schemesixdetails.financialAssistanceTitle")}
            </h5>
            <p>{t("schemesixdetails.financialAssistanceDescription")}</p>

            <div>
              {/* <Button
                variant="contained"
                className="bluebutton"
                href={brochure}
                download
                style={{ marginRight: "10px" }}
              >
                {t("viewdetails.scheme1.downloadbrochure")}
              </Button> */}
              <NavLink to="/schemesix" onClick={handleApplyNowClick}>
                <Button variant="contained" color="secondary">
                  {t("schemesixdetails.applyNow")}
                </Button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchemeSixDetails;
