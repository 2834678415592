import React from "react";
import SchemeNineapprovedapplications from "../../Administration/schemeNine/SchemeNineapprovedapplications";

const ChananiNineApprovedApplications = () => {
  return (
    <div>
      <SchemeNineapprovedapplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiNineApprovedApplications;
