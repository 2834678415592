import React from "react";
import SchemeEightapprovedapplications from "../../Administration/SchemeEight/SchemeEightapprovedapplications";

const ChananiEightApprovedApplications = () => {
  return (
    <div>
      <SchemeEightapprovedapplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiEightApprovedApplications;
