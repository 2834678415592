import React from "react";
import SchemeTenPendingApplications from "../../Administration/SchemeTen/SchemeTenPendingApplications";

const ShaskiySamitiSchemeTenPendingApplications = () => {
    return (
        <div>
            <SchemeTenPendingApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeTenPendingApplications;
