import React from "react";
import Schemethreeapprovedapplications from "../../Administration/SchemeThree/Schemethreeapprovedapplications";

const ChananiThreeApprovedApplications = () => {
  return (
    <div>
      <Schemethreeapprovedapplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiThreeApprovedApplications;
