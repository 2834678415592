import { removeShaskiySamiti5Token } from "../../common";
import { shaskiysamityTokenName5 } from "../../config";

const getshaskiysamitiFromLocalStorage = () => {
  try {
    const shaskiysamiti5 = JSON.parse(
      sessionStorage.getItem(shaskiysamityTokenName5)
    );
    if (
      shaskiysamiti5 &&
      shaskiysamiti5.authToken &&
      shaskiysamiti5.loggedInTime
    ) {
      console.log("Returning Internal User:", shaskiysamiti5);
      return shaskiysamiti5;
    } else {
      removeShaskiySamiti5Token();
      return null;
    }
  } catch (error) {
    removeShaskiySamiti5Token();
    return null;
  }
};

const getInitial = () => {
  return getshaskiysamitiFromLocalStorage();
};

const shaskiySamity5Reducer = (state = getInitial(), action) => {
  switch (action.type) {
    case "LOGIN_SHASKIY_SAMITI5":
      state = {};
      const time = action.payload.loggedInTime;
      if (time) {
        state.loggedInTime = time;
      } else {
        state.loggedInTime = Date.now();
      }
      state.authToken = action.payload.authToken;
      sessionStorage.setItem(shaskiysamityTokenName5, JSON.stringify(state));
      return state;
    case "LOGOUT_SHASKIY_SAMITI5":
      removeShaskiySamiti5Token();
      return null;
    default:
      return state;
  }
};

export default shaskiySamity5Reducer;
