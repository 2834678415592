import React from "react";
import SchemeNineRejectedApplications from "../../Administration/schemeNine/SchemeNineRejectedApplications";

const ChananiNineRejectedApplications = () => {
  return (
    <div>
      <SchemeNineRejectedApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiNineRejectedApplications;
