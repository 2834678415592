import React from "react";
import SchemeFiveRejectedApplications from "../../Administration/SchemeFive/SchemeFiveRejectedApplications";

const ShaskiySamitiSchemeFiveRejectedApplications = () => {
    return (
        <div>
            <SchemeFiveRejectedApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeFiveRejectedApplications;
