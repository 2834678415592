import React, { useState } from "react";
import { TextField, Button, Alert, Typography } from "@mui/material";
import UserProfileSidebar from "./UserProfileSidebar";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AxiosError } from "axios";
import { server } from "../../common";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const initialValues = {
  name: "",
  email: "",
  message: "",
};

const ContactAndSupport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { values, handleBlur, handleSubmit, handleChange, resetForm, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: Yup.object({
        name: Yup.string()
          .min(2, t("contactandsupport.validations.name_required"))
          .required(t("contactandsupport.validations.name_min")),
        email: Yup.string()
          .email(t("contactandsupport.validations.email_required"))
          .required(t("contactandsupport.validations.email_valid")),
        message: Yup.string()
          .min(2, t("contactandsupport.validations.message_required"))
          .required(t("contactandsupport.validations.message_min")),
      }),
      onSubmit: (values, action) => {
        console.log(values);
        server
          .post("/contact/addcontact", values, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(function (response) {
            console.log("api response", response.data);
            if (response.status === 200 || response.status === 201) {
              if (response.data) {
                toast.success("Message successfully");
                navigate("/");
                resetForm();
              }
            }
          })
          .catch(function (error) {
            if (error instanceof AxiosError && error.response?.data?.message)
              toast.error(error.response.data.message);
            else if (error.response?.data?.error) {
              toast.error(error.response.data.error);
            } else {
              toast.error("Failed to connect to server");
              console.log(error.message);
            }
          });
      },
    });
  return (
    <div className="container mt-4 " >
      <div className="row">
        <div>
          <Typography variant="h4" gutterBottom>
            {t("contactandsupport.contactsupport")}
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              name="name"
              label={t("contactandsupport.name")}
              variant="outlined"
              margin="normal"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
            {errors.name ? <p className="text-danger">{errors.name}</p> : null}
            <TextField
              fullWidth
              name="email"
              label={t("contactandsupport.email")}
              variant="outlined"
              margin="normal"
              type="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
            {errors.email ? (
              <p className="text-danger">{errors.email}</p>
            ) : null}
            <TextField
              fullWidth
              label={t("contactandsupport.message")}
              name="message"
              variant="outlined"
              margin="normal"
              multiline
              rows={4}
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />{" "}
            {errors.message ? (
              <p className="text-danger">{errors.message}</p>
            ) : null}
            <Button
              type="submit"
              variant="contained"
              // color="primary"
              style={{ marginRight: "10px" }}
              className="bluebutton mb-3"
            >
              {t("contactandsupport.sendmessege")}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactAndSupport;
