import React from "react";
import SchemeOnePendingApplications from "../../Administration/SchemeOne/SchemeOnePendingApplications";

const ChananiOnePendingApplications = () => {
  return (
    <div>
      <SchemeOnePendingApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiOnePendingApplications;
