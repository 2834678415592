import React from "react";
import SchemeNineTotalApplications from "../../Administration/schemeNine/SchemeNineTotalApplications";

const ChananiNineTotalApplications = () => {
  return (
    <div>
      <SchemeNineTotalApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiNineTotalApplications;
