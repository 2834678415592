import React from "react";
import SchemeSixTotalApplications from "../../Administration/SchemeSix/SchemeSixTotalApplications";

const ChananiSixTotalApplications = () => {
  return (
    <div>
      <SchemeSixTotalApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiSixTotalApplications;
