import React from "react";
import SchemeTwoRejectedApplications from "../../Administration/SchemeTwo/SchemeTwoRejectedApplications";

const ShaskiySamitiSchemeTwoRejectedApplications = () => {
    return (
        <div>
            <SchemeTwoRejectedApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeTwoRejectedApplications;
