import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Container,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
  Typography,
  Alert,
} from "@mui/material";
import doc1 from "../../assets/doc/declaration1.pdf"
import doc2 from "../../assets/doc/selfdeclaration1.pdf"
import { Card, Col, Row } from "react-bootstrap";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import Grid from "@mui/material/Grid2";

import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { server } from "../../common";
import Loader from "../../Loader";
import { useSelectAccess } from "../../store/stateFunctions";
import { useNavigate } from "react-router-dom";
const swalalert = withReactContent(Swal);

const initialValues = {
  fullname: "",
  mobile: "",
  adhar_number: "",
  dob: "",
  address: "",
  division: "",
  ward_no: "",
  resident_duration_years: "",
  disability_type: "",
  pancard: "",
  family_annualincome: "",
  family_member_no: "",
  applicant_schoolname: "",
  school_mobileno: "",
  child_study_classroom: "",
  bankname: "",
  branch: "",
  ac_holder_name: "",
  account_no: "",
  ifsc_code: "",
  micr_code: "",

  passport_photo: null,
  upload_adhar: null,
  upload_pancard: null,
  upload_bankpassbook: null,
  fee_reciept: null,
  certificate_of_disability: null,
  birth_or_age_proof: null,
  proof1: null,
  proof2: null,

  self_declaration: null,
  self_attestation_declaration: null,
  recommendation_letter_principal: null,
  expert_recommendation_ot_pt: null,
};

const SchemeFive = () => {
  const [step, setStep] = useState(0);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const user = useSelectAccess("User");
  const navigate = useNavigate();

  const stepLabels = [
    t("schemeForms.stepLabels.personalInfo"),
    t("schemeForms.stepLabels.residentialInfo"),
    t("schemeForms.stepLabels.schoolInfo"),
    t("schemeForms.stepLabels.bankInfo"),
    t("schemeForms.stepLabels.uploadDocuments"),
  ];

  const steps = 4;

  const handleNext = () => {
    if (step < steps) setStep(step + 1);
  };
  const handleBack = () => {
    if (step > 0) setStep(step - 1);
  };

  const formik = useFormik({
    initialValues: initialValues, // Ensure this is defined
    validationSchema: Yup.object({
      fullname: Yup.string().required(t("schemeForms.name_required")),
      mobile: Yup.string()
        .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits") // Assuming mobile number is 10 digits
        .required("Mobile number is required"),
      adhar_number: Yup.string()
        .matches(/^\d{12}$/, t("schemeForms.adhar_required"))
        .required(t("schemeForms.adhar_invalid")),

      dob: Yup.date()
        .required(t("schemeForms.bod_required"))
        .max(new Date(), t("schemeForms.futuredob")),

      address: Yup.string().required(t("schemeForms.address_required")),

      division: Yup.string().required(t("schemeForms.division_required")),

      ward_no: Yup.number()
        .max(300, t("schemeForms.division_validation"))
        .required(t("schemeForms.division_required")),
      resident_duration_years: Yup.number()
        .typeError("Resident duration must be a number")
        .max(99, "Resident duration cannot exceed 99 years") // Sets the maximum to 2-digit input (up to 99 years)
        .required(t("schemeForms.resident_duration_required")),
      school_mobileno: Yup.string()
        .matches(/^[6-9]\d{9}$/, t("schemeForms.mobile_invalid"))
        .required(t("schemeForms.schoolnumber_required")),

      child_study_classroom: Yup.string().required(
        t("schemeForms.school_address_required")
      ),

      disability_type: Yup.string().required(
        t("schemeForms.disability_required")
      ),

      pancard: Yup.string()
        .required(t("schemeForms.pan_required"))
        .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, t("schemeForms.pancardnumber")),

      applicant_schoolname: Yup.string().required(
        t("schemeForms.family_job_info_required")
      ),

      family_annualincome: Yup.number()
        .required(t("schemeForms.family_annual_income_required"))
        .min(1000, t("schemeForms.maximum_income")),

      family_member_no: Yup.number()
        .required(t("schemeForms.family_members_validation"))
        .min(1, t("schemeForms.family_members_number_validation")),

      bankname: Yup.string().required(t("schemeForms.bankname_required")),

      branch: Yup.string().required(t("schemeForms.branch_required")),

      ac_holder_name: Yup.string().required(
        t("schemeForms.ac_holder_name_required")
      ),

      ifsc_code: Yup.string().required(t("schemeForms.ifsc_required")),

      micr_code: Yup.string().required(t("schemeForms.micr_required")),

      account_no: Yup.string()
        .matches(/^\d{9,18}$/, t("schemeForms.account_number_validation"))
        .required(t("schemeForms.account_no_required")),

      passport_photo: Yup.mixed()
        .required(t("schemeForms.passport_photo_required"))
        .test(
          "fileSize",
          t("schemeForms.file_size_"),
          (value) => value && value.size <= 5000000
        ),

      upload_adhar: Yup.mixed()
        .required(t("schemeForms.upload_adhar_required"))
        .test(
          "fileSize",
          t("schemeForms.file_size_"),
          (value) => value && value.size <= 5000000
        ),

      upload_pancard: Yup.mixed()
        .required(t("schemeForms.upload_pancard_required"))
        .test(
          "fileSize",
          t("schemeForms.file_size_"),
          (value) => value && value.size <= 5000000
        ),

      upload_bankpassbook: Yup.mixed()
        .required(t("schemeForms.upload_bankpassbook_required"))
        .test(
          "fileSize",
          t("schemeForms.file_size_"),
          (value) => value && value.size <= 5000000
        ),

      fee_reciept: Yup.mixed()
        .required(t("schemeForms.charge_receipt_required"))
        .test(
          "fileSize",
          t("schemeForms.file_size_"),
          (value) => value && value.size <= 5000000
        ),

      certificate_of_disability: Yup.mixed()
        .required(t("schemeForms.certificate_of_disability_required"))
        .test(
          "fileSize",
          t("schemeForms.file_size_"),
          (value) => value && value.size <= 5000000
        ),

      birth_or_age_proof: Yup.mixed()
        .required(t("schemeForms.birth_or_age_proof_required"))
        .test(
          "fileSize",
          t("schemeForms.file_size_"),
          (value) => value && value.size <= 5000000
        ),

      proof1: Yup.mixed()
        .required(t("schemeForms.proof1_required"))
        .test(
          "fileSize",
          t("schemeForms.file_size_"),
          (value) => value && value.size <= 5000000
        ),

      proof2: Yup.mixed().test(
        "fileSize",
        t("schemeForms.file_size_"),
        (value) => value && value.size <= 5000000
      ),

      self_declaration: Yup.mixed()
        .required(t("schemeForms.self_declaration_required"))
        .test(
          "fileSize",
          t("schemeForms.file_size_"),
          (value) => value && value.size <= 5000000
        ),

      self_attestation_declaration: Yup.mixed()
        .required(t("schemeForms.self_attestation_declaration_required"))
        .test(
          "fileSize",
          t("schemeForms.file_size_"),
          (value) => value && value.size <= 5000000
        ),

      recommendation_letter_principal: Yup.mixed()
        .required(
          t("schemeForms.Recommendation_of_the_School_Principal_required")
        )
        .test(
          "fileSize",
          t("schemeForms.file_size_"),
          (value) => value && value.size <= 5000000
        ),

      expert_recommendation_ot_pt: Yup.mixed()
        .required(t("schemeForms.expert_recommendation"))
        .test(
          "fileSize",
          t("schemeForms.file_size_"),
          (value) => value && value.size <= 5000000
        ),
    }),

    onSubmit: (values, action) => {
      // Validate images manually before submission
      if (!values.passport_photo) {
        toast.error("Please select an passport_photo image");
        return;
      }
      if (!values.upload_adhar) {
        toast.error("Please select an Aadhar Card image");
        return;
      }
      if (!values.upload_pancard) {
        toast.error("Please select a upload_pancard image");
        return;
      }
      if (!values.upload_bankpassbook) {
        toast.error("Please select a upload_bankpassbook image");
        return;
      }

      if (!values.certificate_of_disability) {
        toast.error("Please select a certificate_of_disability image");
        return;
      }

      if (!values.birth_or_age_proof) {
        toast.error("Please select a birth_or_age_proof image");
        return;
      }

      if (!values.proof1) {
        toast.error("Please select a proof1 image");
        return;
      }

      // Create FormData and append values
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      swalalert
        .fire({
          title: "Are You Sure To Submit the Application?",
          text: `Confirm Application Submission?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          buttonsStyling: true,
        })
        .then((swalObject) => {
          setLoading(true);

          if (swalObject.isConfirmed) {
            server
              .post(`/fiveappl/addfiveappl`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: user.authToken,
                },
              })
              .then(function (response) {
                if (response.status === 200 || response.status === 201) {
                  swalalert.fire({
                    title: "Success!",
                    text: `Application Submitted Successfully. Application Id is ${response.data.id}`,
                    icon: "success",
                  });
                  formik.resetForm();
                  navigate("/");
                }
              })
              .catch((error) => {
                toast.error(
                  error.response?.data?.message ||
                    error.response?.data?.error ||
                    "Failed to connect to server"
                );
              })
              .finally(() => {
                setLoading(false); // Hide loader after API call
              });
          }
        });
    },
  });

  return (
    <div className="container">
      {console.log(formik.values)}
      <Alert severity="info" className="mt-2">
        {t("schemeForms.note")}
      </Alert>
      {/* Progress Bar Component */}
      <ProgressBar activeStep={step} stepLabels={stepLabels} />
      <Card className="mt-2">
        <Card.Header>
          <Typography variant="h5" gutterBottom style={{ marginTop: "10px" }}>
            {t("schemeForms.scheme_number_5")}
          </Typography>
        </Card.Header>

        <form style={{ padding: "20px" }}>
          {step === 0 && (
            <>
              <Typography variant="h6" gutterBottom>
                {t("schemeForms.personal_info")}
              </Typography>

              <Row container spacing={2}>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.full_name")}
                    required
                    variant="outlined"
                    name="fullname"
                    onChange={formik.handleChange}
                    value={formik.values.fullname}
                  />
                  {formik.errors.fullname ? (
                    <p className="text-danger">{formik.errors.fullname}</p>
                  ) : null}
                </Col>

                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.dob")}
                    required
                    variant="outlined"
                    type="date"
                    name="dob"
                    value={formik.values.dob}
                    onChange={formik.handleChange}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      max: new Date().toISOString().substring(0, 10), // Restricts to today or earlier
                    }}
                  />
                  {formik.errors.dob ? (
                    <p className="text-danger">{formik.errors.dob}</p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.type_of_disabilty")}
                    required
                    variant="outlined"
                    name="disability_type"
                    onChange={formik.handleChange}
                    value={formik.values.disability_type}
                  />
                  {formik.errors.disability_type ? (
                    <p className="text-danger">
                      {formik.errors.disability_type}
                    </p>
                  ) : null}
                </Col>

                <Col md={4} className="mt-4">
                  <TextField
                    label="Mobile No."
                    variant="outlined"
                    fullWidth
                    name="mobile"
                    value={formik.values.mobile}
                    onChange={(e) => {
                      // Allow only digits and limit to 10 characters
                      const mobile = e.target.value
                        .replace(/\D/g, "")
                        .slice(0, 10);
                      formik.setFieldValue("mobile", mobile);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+91</InputAdornment>
                      ),
                    }}
                    required
                  />
                  {formik.errors.mobile ? (
                    <p className="text-danger">{formik.errors.mobile}</p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.aadhaar_no")}
                    required
                    variant="outlined"
                    name="adhar_number"
                    onChange={(e) => {
                      // Allow only digits and limit to 12 characters
                      const aadhaar = e.target.value
                        .replace(/\D/g, "")
                        .slice(0, 12);
                      formik.setFieldValue("adhar_number", aadhaar);
                    }}
                    value={formik.values.adhar_number}
                  />
                  {formik.errors.adhar_number ? (
                    <p className="text-danger">{formik.errors.adhar_number}</p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.Pan_card_number")}
                    required
                    variant="outlined"
                    name="pancard"
                    onChange={(e) => {
                      // Allow only alphanumeric characters and limit to 10 characters
                      const pan = e.target.value
                        .replace(/[^a-zA-Z0-9]/g, "")
                        .slice(0, 10);
                      formik.setFieldValue("pancard", pan);
                    }}
                    value={formik.values.pancard}
                  />
                  {formik.errors.pancard ? (
                    <p className="text-danger">{formik.errors.pancard}</p>
                  ) : null}
                </Col>

                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.family_income")}
                    required
                    variant="outlined"
                    name="family_annualincome"
                    onChange={formik.handleChange}
                    value={formik.values.family_annualincome}
                  />
                  {formik.errors.family_annualincome ? (
                    <p className="text-danger">
                      {formik.errors.family_annualincome}
                    </p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.famiy_members")}
                    required
                    variant="outlined"
                    name="family_member_no"
                    value={formik.values.family_member_no}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.family_member_no ? (
                    <p className="text-danger">
                      {formik.errors.family_member_no}
                    </p>
                  ) : null}
                </Col>
              </Row>
            </>
          )}
          {step === 1 && (
            <>
              <Typography
                variant="h6"
                gutterBottom
                style={{ marginTop: "20px" }}
              >
                {t("schemeForms.resident_info")}
              </Typography>
              <Row container spacing={2}>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.address")}
                    required
                    variant="outlined"
                    name="address"
                    onChange={formik.handleChange}
                    value={formik.values.address}
                  />
                  {formik.errors.address ? (
                    <p className="text-danger">{formik.errors.address}</p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      {t("schemeForms.division")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Division"
                      variant="outlined"
                      name="division"
                      value={formik.values.division}
                      onChange={formik.handleChange}
                      required
                    >
                      <MenuItem value="">
                        <em> {t("schemeForms.selectDivision")}</em>
                      </MenuItem>
                      <MenuItem value="satpur">
                        <em>{t("schemeForms.divisions.satpur")}</em>
                      </MenuItem>
                      <MenuItem value="cidco">
                        <em>{t("schemeForms.divisions.cidco")}</em>
                      </MenuItem>
                      <MenuItem value="nashikroad">
                        <em>{t("schemeForms.divisions.nashikroad")} </em>
                      </MenuItem>
                      <MenuItem value="nashikeast">
                        <em> {t("schemeForms.divisions.nashikeast")} </em>
                      </MenuItem>
                      <MenuItem value="nashikwest">
                        <em> {t("schemeForms.divisions.nashikwest")} </em>
                      </MenuItem>
                      <MenuItem value="panchavati">
                        <em>t{"schemeForms.divisions.panchavati"}</em>
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {formik.errors.division ? (
                    <p className="text-danger">{formik.errors.division}</p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.ward")}
                    required
                    variant="outlined"
                    name="ward_no"
                    onChange={formik.handleChange}
                    value={formik.values.ward_no}
                  />
                  {formik.errors.ward_no ? (
                    <p className="text-danger">{formik.errors.ward_no}</p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.years_resident")}
                    required
                    variant="outlined"
                    name="resident_duration_years"
                    onChange={formik.handleChange}
                    value={formik.values.resident_duration_years}
                  />
                  {formik.errors.resident_duration_years ? (
                    <p className="text-danger">
                      {formik.errors.resident_duration_years}
                    </p>
                  ) : null}
                </Col>
              </Row>
            </>
          )}
          {/* Step 3: School Information */}
          {step === 2 && (
            <>
              <Typography
                variant="h6"
                gutterBottom
                style={{ marginTop: "20px" }}
              >
                {t("schemeForms.school_info")}
              </Typography>
              <Row container spacing={2}>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.university_name")}
                    required
                    variant="outlined"
                    name="applicant_schoolname"
                    onChange={formik.handleChange}
                    value={formik.values.applicant_schoolname}
                  />
                  {formik.errors.applicant_schoolname ? (
                    <p className="text-danger">
                      {formik.errors.applicant_schoolname}
                    </p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.school_mobileno")}
                    required
                    variant="outlined"
                    name="school_mobileno"
                    onChange={formik.handleChange}
                    value={formik.values.school_mobileno}
                  />
                  {formik.errors.school_mobileno ? (
                    <p className="text-danger">
                      {formik.errors.school_mobileno}
                    </p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.child_study_classroom")}
                    required
                    variant="outlined"
                    name="child_study_classroom"
                    onChange={formik.handleChange}
                    value={formik.values.child_study_classroom}
                  />
                  {formik.errors.child_study_classroom ? (
                    <p className="text-danger">
                      {formik.errors.child_study_classroom}
                    </p>
                  ) : null}
                </Col>
              </Row>
            </>
          )}

          {/* Step 5: Bank Information */}
          {step === 3 && (
            <>
              <Typography
                variant="h6"
                gutterBottom
                style={{ marginTop: "20px" }}
              >
                {t("schemeForms.bank_info")}
              </Typography>
              <hr />
              <Row container spacing={2}>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.bank_name")}
                    required
                    variant="outlined"
                    name="bankname"
                    onChange={formik.handleChange}
                    value={formik.values.bankname}
                  />
                  {formik.errors.bankname ? (
                    <p className="text-danger">{formik.errors.bankname}</p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.bank_branch")}
                    required
                    variant="outlined"
                    name="branch"
                    onChange={formik.handleChange}
                    value={formik.values.branch}
                  />
                  {formik.errors.branch ? (
                    <p className="text-danger">{formik.errors.branch}</p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.account_holder_name")}
                    required
                    variant="outlined"
                    name="ac_holder_name"
                    onChange={formik.handleChange}
                    value={formik.values.ac_holder_name}
                  />
                  {formik.errors.ac_holder_name ? (
                    <p className="text-danger">
                      {formik.errors.ac_holder_name}
                    </p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.account_number")}
                    required
                    variant="outlined"
                    name="account_no"
                    onChange={formik.handleChange}
                    value={formik.values.account_no}
                  />
                  {formik.errors.account_no ? (
                    <p className="text-danger">{formik.errors.account_no}</p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.ifsc_code")}
                    required
                    variant="outlined"
                    name="ifsc_code"
                    onChange={formik.handleChange}
                    value={formik.values.ifsc_code}
                  />
                  {formik.errors.ifsc_code ? (
                    <p className="text-danger">{formik.errors.ifsc_code}</p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.micr_code")}
                    required
                    variant="outlined"
                    name="micr_code"
                    onChange={formik.handleChange}
                    value={formik.values.micr_code}
                  />
                  {formik.errors.micr_code ? (
                    <p className="text-danger">{formik.errors.micr_code}</p>
                  ) : null}
                </Col>
              </Row>
            </>
          )}
          {/* Step 6: Document Upload */}
          {step === 4 && (
            <>
              <Typography
                variant="h6"
                gutterBottom
                style={{ marginTop: "20px" }}
              >
                {t("schemeForms.upload_documents")}
              </Typography>

              <hr />
              <Row container spacing={2}>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.PassportPhoto")}
                    required
                    variant="outlined"
                    name="passport_photo"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "passport_photo",
                        event.currentTarget.files[0]
                      );
                    }}
                    type="file"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      accept: ".pdf, .png, .jpg, .jpeg",
                    }}
                  />
                  {formik.errors.passport_photo ? (
                    <p className="text-danger">
                      {formik.errors.passport_photo}
                    </p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.AadharCard")}
                    required
                    variant="outlined"
                    name="upload_adhar"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "upload_adhar",
                        event.currentTarget.files[0]
                      );
                    }}
                    type="file"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      accept: ".pdf, .png, .jpg, .jpeg",
                    }}
                  />
                  {formik.errors.upload_adhar ? (
                    <p className="text-danger">{formik.errors.upload_adhar}</p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.PanCard")}
                    required
                    variant="outlined"
                    name="upload_pancard"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "upload_pancard",
                        event.currentTarget.files[0]
                      );
                    }}
                    type="file"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      accept: ".pdf, .png, .jpg, .jpeg",
                    }}
                  />
                  {formik.errors.upload_pancard ? (
                    <p className="text-danger">
                      {formik.errors.upload_pancard}
                    </p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.BankPassbook")}
                    required
                    variant="outlined"
                    name="upload_bankpassbook"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "upload_bankpassbook",
                        event.currentTarget.files[0]
                      );
                    }}
                    type="file"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      accept: ".pdf, .png, .jpg, .jpeg",
                    }}
                  />
                  {formik.errors.upload_bankpassbook ? (
                    <p className="text-danger">
                      {formik.errors.upload_bankpassbook}
                    </p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.disability_certificate")}
                    required
                    variant="outlined"
                    name="certificate_of_disability"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "certificate_of_disability",
                        event.currentTarget.files[0]
                      );
                    }}
                    type="file"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      accept: ".pdf, .png, .jpg, .jpeg",
                    }}
                  />
                  {formik.errors.certificate_of_disability ? (
                    <p className="text-danger">
                      {formik.errors.certificate_of_disability}
                    </p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.BirthProof")}
                    required
                    variant="outlined"
                    name="birth_or_age_proof"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "birth_or_age_proof",
                        event.currentTarget.files[0]
                      );
                    }}
                    type="file"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      accept: ".pdf, .png, .jpg, .jpeg",
                    }}
                  />
                  {formik.errors.birth_or_age_proof ? (
                    <p className="text-danger">
                      {formik.errors.birth_or_age_proof}
                    </p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.fee_receipt")}
                    required
                    variant="outlined"
                    name="fee_reciept"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "fee_reciept",
                        event.currentTarget.files[0]
                      );
                    }}
                    type="file"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      accept: ".pdf, .png, .jpg, .jpeg",
                    }}
                  />
                  {formik.errors.fee_reciept ? (
                    <p className="text-danger">{formik.errors.fee_reciept}</p>
                  ) : null}
                </Col>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: "20px" }}
                >
                  {t("schemeForms.Proof1")}
                </Typography>
                <hr />
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    // label={t("schemeForms.Proof1")}
                    required
                    variant="outlined"
                    name="proof1"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "proof1",
                        event.currentTarget.files[0]
                      );
                    }}
                    type="file"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      accept: ".pdf, .png, .jpg, .jpeg",
                    }}
                  />
                  {formik.errors.proof1 ? (
                    <p className="text-danger">{formik.errors.proof1}</p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="proof2"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "proof2",
                        event.currentTarget.files[0]
                      );
                    }}
                    type="file"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      accept: ".pdf, .png, .jpg, .jpeg",
                    }}
                  />
                  {formik.errors.proof2 ? (
                    <p className="text-danger">{formik.errors.proof2}</p>
                  ) : null}
                </Col>
              </Row>
              <div className="card mt-3">
                  <div className="card-header">Download Required Documents</div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered">
                        <thead className="thead-dark">
                        
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-left">Self-Declaration Form</td>
                            <td className="text-left">
                              <Button variant="contained" color="primary">
                                <a
                                  href={doc1} 
                                  className="text-decoration-none text-white"
                                  download
                                >
                                  Download
                                </a>
                              </Button>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left">
                              Self-Attested Declaration Form
                            </td>
                            <td className="text-left">
                              <Button variant="contained" color="primary">
                                <a
                                  href={doc2} 
                                  className="text-decoration-none text-white"
                                  download
                                >
                                  Download
                                </a>
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              <Typography
                variant="h6"
                gutterBottom
                style={{ marginTop: "20px" }}
              >
                {t("schemeForms.DeclarationForm")}
              </Typography>
              <hr />
              <Row container spacing={2}>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.DeclarationForm")}
                    required
                    variant="outlined"
                    name="self_declaration"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "self_declaration",
                        event.currentTarget.files[0]
                      );
                    }}
                    type="file"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      accept: ".pdf, .png, .jpg, .jpeg",
                    }}
                  />
                  {formik.errors.self_declaration ? (
                    <p className="text-danger">
                      {formik.errors.self_declaration}
                    </p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.SelfAttestationDeclaration")}
                    required
                    variant="outlined"
                    name="self_attestation_declaration"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "self_attestation_declaration",
                        event.currentTarget.files[0]
                      );
                    }}
                    type="file"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      accept: ".pdf, .png, .jpg, .jpeg",
                    }}
                  />
                  {formik.errors.self_attestation_declaration ? (
                    <p className="text-danger">
                      {formik.errors.self_attestation_declaration}
                    </p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t(
                      "schemeForms.Recommendation_of_the_School_Principal"
                    )}
                    required
                    variant="outlined"
                    name="recommendation_letter_principal"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "recommendation_letter_principal",
                        event.currentTarget.files[0]
                      );
                    }}
                    type="file"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      accept: ".pdf, .png, .jpg, .jpeg",
                    }}
                  />
                  {formik.errors.recommendation_letter_principal ? (
                    <p className="text-danger">
                      {formik.errors.recommendation_letter_principal}
                    </p>
                  ) : null}
                </Col>
                <Col md={4} className="mt-4">
                  <TextField
                    fullWidth
                    label={t("schemeForms.recommendation")}
                    required
                    variant="outlined"
                    name="expert_recommendation_ot_pt"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "expert_recommendation_ot_pt",
                        event.currentTarget.files[0]
                      );
                    }}
                    type="file"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      accept: ".pdf, .png, .jpg, .jpeg",
                    }}
                  />
                  {formik.errors.expert_recommendation_ot_pt ? (
                    <p className="text-danger">
                      {formik.errors.expert_recommendation_ot_pt}
                    </p>
                  ) : null}
                </Col>
              </Row>
            </>
          )}

          {/* Navigation Buttons */}
          <Grid
            container
            justifyContent="space-between"
            style={{ marginTop: "20px" }}
          >
            <Button
              variant="contained"
              color="secondary"
              disabled={step === 0}
              onClick={handleBack}
            >
              {t("schemeForms.Back")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              // onClick={handleNext}
              // disabled={step === steps}
              onClick={step === steps ? formik.handleSubmit : handleNext}
            >
              {step === steps ? t("schemeForms.Submit") : t("schemeForms.Next")}
            </Button>
          </Grid>
        </form>
      </Card>
    </div>
  );
};

export default SchemeFive;
