export const loginChananisamiti1 = (session) => {
  return {
    type: "LOGIN_CHANANI_SAMITY1",
    payload: session,
  };
};

export const logoutChananisamiti1 = () => {
  return {
    type: "LOGOUT_CHANANI_SAMITY1",
  };
};
