export const loginAdd_Comm = (session) => {
  return {
    type: "LOGIN_ADD_COMM",
    payload: session,
  };
};
export const logoutAdd_Comm = () => {
  return {
    type: "LOGOUT_ADD_COMM",
  };
};
