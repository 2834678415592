import React from "react";
import SchemeOneTotalApplications from "../../Administration/SchemeOne/SchemeOneTotalApplications";

const ShaskiySamitiSchemeOneTotalApplications = () => {
    return (
        <div>
            <SchemeOneTotalApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeOneTotalApplications;
