export const loginDepuComm = (session) => {
  return {
    type: "LOGIN_DEPU_COMM",
    payload: session,
  };
};
export const logoutDepuComm = () => {
  return {
    type: "LOGOUT_DEPU_COMM",
  };
};
