import React, { useState } from "react";
import { Container, Grid, Button, Typography, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
// import brochure from "../../assets/documents/Support for Organizations Working with Disabled Persons in Nashik Municipal Area.pdf"
import { NavLink, useNavigate } from "react-router-dom";
import { useSelectAccess } from "../../store/stateFunctions";
import { toast } from "react-toastify";

const SchemeEightDetails = () => {
  const { t } = useTranslation();
  const user = useSelectAccess("User");
  const isLoggedIn = user && user;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleApplyNowClick = (e) => {
    if (!isLoggedIn) {
      e.preventDefault(); // Prevent navigation
      toast.info("Please log in to apply for the Scheme");
      navigate("/login");
    } else {
      setShow(true);
    }
  };

  return (
    <div className="container-fluid">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper
            style={{
              backgroundColor: "#ffffff",
              padding: "30px",
              border: "1px solid #e0e0e0",
            }}
          >
            <Typography
              variant="h4"
              component="div"
              style={{ fontWeight: "bold", marginBottom: "20px" }}
              className="text-dark"
            >
              {t("schemeEightDetails.title")}
            </Typography>

            <Typography
              className="text-dark"
              variant="body1"
              component="div"
              style={{ marginBottom: "20px" }}
            >
              {t("schemeEightDetails.guidelinesTitle")}
              <br />
              {t("schemeEightDetails.guidelinesText")}
            </Typography>

            <Typography
              className="text-dark"
              variant="h6"
              component="div"
              style={{ fontWeight: "bold", marginBottom: "10px" }}
            >
              {t("schemeEightDetails.committeeTitle")}
            </Typography>
            <ol style={{ paddingLeft: "20px", marginBottom: "20px" }}>
              <li>{t("schemeEightDetails.committeeItems.0")}</li>
              <li>{t("schemeEightDetails.committeeItems.1")}</li>
              <li>{t("schemeEightDetails.committeeItems.2")}</li>
              <li>{t("schemeEightDetails.committeeItems.3")}</li>
              <li>{t("schemeEightDetails.committeeItems.4")}</li>
              <li>{t("schemeEightDetails.committeeItems.5")}</li>
            </ol>

            <Typography
              variant="h6"
              component="div"
              style={{ fontWeight: "bold", marginBottom: "10px" }}
            >
              {t("schemeEightDetails.grantProposalTitle")}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              style={{ marginBottom: "20px" }}
            >
              {t("schemeEightDetails.grantProposalText")}
            </Typography>

            <div>
              {/* <Button
                variant="contained"
                className="bluebutton"
                // href={brochure}
                download
                style={{ marginRight: "10px" }}
              >
                {t("viewdetails.scheme1.downloadbrochure")}
              </Button> */}
              <NavLink to="/schemeeight" onClick={handleApplyNowClick}>
                {" "}
                <Button
                  variant="contained"
                  className="redbutton"
                  color="secondary"
                >
                  {t("schemeEightDetails.applyNow")}
                </Button>
              </NavLink>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default SchemeEightDetails;
