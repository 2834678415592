import React from "react";
import SchemeFiveRejectedApplications from "../../Administration/SchemeFive/SchemeFiveRejectedApplications";

const ChananiFiveRejectedApplications = () => {
  return (
    <div>
      <SchemeFiveRejectedApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiFiveRejectedApplications;
