import React from "react";
import SchemeFourapprovedapplications from "../../Administration/SchemeFour/SchemeFourapprovedapplications";

const ChananiFourApprovedApplications = () => {
  return (
    <div>
      <SchemeFourapprovedapplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiFourApprovedApplications;
