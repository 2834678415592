import React, { useEffect, useState } from "react";
import { TextField, Button } from "@mui/material";
import * as XLSX from "xlsx";
import { server, statusMessage } from "../../common";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { Card, Modal } from "react-bootstrap";
import SchemeOneUserModal from "./SchemeOneUserModal";
import { useSelectAccess } from "../../store/stateFunctions";

const SchemeOneTotalApplications = ({ role }) => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const user = useSelectAccess(role);

  const handleShow = (id) => {
    setSelectedId(id);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);
  useEffect(() => {
    getData();
  }, []);

  // Get
  const getData = () => {
    server
      .get("/firstappl/getallfirstappl", {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200 || response.status === 201) {
          setData(response.data);
        }
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else {
          toast.error(error.response?.data?.error || "An error occurred");
        }
      });
  };

  // search
  const SearchData = (searchValue) => {
    server
      .get(`/firstappl/searchfirstapplbyid?search=${searchValue}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200 || response.status === 201) {
          setData(response.data);
        }
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Total Applications");
    XLSX.writeFile(workbook, "Total_Applications.xlsx");
  };

  return (
    <>
      <div className="container-fluid mt-4">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h3>
            योजना क्र.१ : कर्णबधिरांना Cochler Implant शस्त्रक्रियेकरिता
            अर्थसहाय्य योजना.
          </h3>
          <h4>Total Applications</h4>
        </div>
        <div className="card mb-3">
          <div className="card-header">Search Application</div>
          <div className="card-body">
            <div className="row">
              <div className="form-group col-md-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Application Id / Name"
                  onChange={(e) => SearchData(e.target.value)}
                />
              </div>

              <div className="form-group col-md-4">
                <select id="divisionFilter" className="form-control">
                  <option value="">All Divisions</option>
                  <option value="North">Division 1</option>
                  <option value="South">Division 2</option>
                  <option value="North">Division 3</option>
                  <option value="North">Division 4</option>
                  <option value="North">Division 5</option>
                  <option value="North">Division 6</option>
                  <option value="North">Division 7</option>
                </select>
              </div>
              <div className="col-md-2"></div>
              <Button
                className="btn col-md-2 btn-sm"
                onClick={exportToExcel}
                variant="contained"
              >
                Export
              </Button>
            </div>
          </div>
        </div>
        <div className="card mb-3">
          <div className="card-header">Total Application</div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th>Sr.No.</th>
                    <th>Application No.</th>
                    <th>Application Date/Time</th>
                    <th>Applicant Name</th>
                    <th>Adhar Number</th>
                    <th>Contact No.</th>
                    <th>Ward No</th>
                    <th>Division</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((app, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{app.id}</td>
                      <td>{app.created_at}</td>
                      <td>{app.fullname}</td>
                      <td>{app.adhar_number}</td>
                      <td>{app.mobile}</td>
                      <td>{app.ward_no}</td>
                      <td>{app.division}</td>
                      <td>{statusMessage(app, role)}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => handleShow(app.id)}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {data.length === 0 && (
              <p>No applications found for the selected division.</p>
            )}
          </div>
        </div>
        <SchemeOneUserModal
          show={showModal}
          handleClose={handleClose}
          id={selectedId}
          role={role}
        />
      </div>
    </>
  );
};

export default SchemeOneTotalApplications;
