import React from "react";
import { Button } from "@mui/material";


const ContactEntries = () => {
  const contactEntries = [
    {
      id: 1,
      name: "John Doe",
      email: "john.doe@example.com",
      contact: "+1234567890",
      message: "Hello, I would like to inquire about your services.",
    },
  ];

  
  return (
    <div>
      <div className="container-fluid mt-4">
        <div className="card mb-3">
          <div className="card-header">Contact Entries</div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th>Sr.No.</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Contact</th>
                    <th>Message</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {contactEntries.map((entry) => (
                    <tr key={entry.id}>
                      <td>{entry.id}</td>
                      <td>{entry.name}</td>
                      <td>{entry.email}</td>
                      <td>{entry.contact}</td>
                      <td>{entry.message}</td>
                      <td>
                       
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactEntries;
