import { removeAddCommiUserToken } from "../../common";
import { AddCommiTokenName } from "../../config";

const getAdditionalCommissionerFromLocalStorage = () => {
  try {
    const addcomm = JSON.parse(sessionStorage.getItem(AddCommiTokenName));
    if (addcomm && addcomm.authToken && addcomm.loggedInTime) {
      return addcomm;
    } else {
      removeAddCommiUserToken();
      return null;
    }
  } catch (error) {
    removeAddCommiUserToken();
    return null;
  }
};

const getInitial = () => {
  return getAdditionalCommissionerFromLocalStorage();
};

const additionalCommissionerReducer = (state = getInitial(), action) => {
  switch (action.type) {
    case "LOGIN_ADD_COMM":
      const { loggedInTime, authToken } = action.payload;
      const newState = {
        loggedInTime: loggedInTime || Date.now(),
        authToken: authToken,
      };
      sessionStorage.setItem(AddCommiTokenName, JSON.stringify(newState));
      return newState;
    case "LOGOUT_ADD_COMM":
      removeAddCommiUserToken();
      return null;
    default:
      return state;
  }
};

export default additionalCommissionerReducer;
