import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import MyNavbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";

export default function HomepageContainer() {
  document.title = "Nashik Municipal Corporation, Nashik";
  return (
    <>
      <MyNavbar />
      <div >
        <Outlet />
      </div>
      <Footer />
    </>
  );
}
