import React from "react";
import SchemeTenapprovedapplications from "../../Administration/SchemeTen/SchemeTenapprovedapplications";

const ChananiTenApprovedApplications = () => {
  return (
    <div>
      <SchemeTenapprovedapplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiTenApprovedApplications;
