export const loginShaskiySamiti2 = (session) => {
  return {
    type: "LOGIN_SHASKIY_SAMITI2",
    payload: session,
  };
};

export const logoutShaskiySamiti2 = () => {
  return {
    type: "LOGOUT_SHASKIY_SAMITI2",
  };
};
