import React from "react";
import Schemethreeapprovedapplications from "../../Administration/SchemeThree/Schemethreeapprovedapplications";

const ShaskiySamitiSchemeThreeApprovedApplications = () => {
    return (
        <div>
            <Schemethreeapprovedapplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeThreeApprovedApplications;
