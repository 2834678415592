import React from "react";
import SchemeThreeTotalApplications from "../../Administration/SchemeThree/SchemeThreeTotalApplications";

const ChananiThreeTotalApplications = () => {
  return (
    <div>
      <SchemeThreeTotalApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiThreeTotalApplications;
