import React from "react";
import SchemeSevenRejectedApplications from "../../Administration/SchemeSeven/SchemeSevenRejectedApplications";

const ChananiSevenRejectedApplications = () => {
  return (
    <div>
      <SchemeSevenRejectedApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiSevenRejectedApplications;
