import React from "react";
import SchemeOneTotalApplications from "../../Administration/SchemeOne/SchemeOneTotalApplications";

const ChananiOneTotalApplications = () => {
  return (
    <div>
      <SchemeOneTotalApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiOneTotalApplications;
