import React from "react";
import SchemeFiveTotalApplications from "../../Administration/SchemeFive/SchemeFiveTotalApplications";

const ChananiFiveTotalApplications = () => {
  return (
    <div>
      <SchemeFiveTotalApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiFiveTotalApplications;
