import React from "react";
import SchemeTenRejectedApplications from "../../Administration/SchemeTen/SchemeTenRejectedApplications";

const ShaskiySamitiSchemeTenRejectedApplications = () => {
    return (
        <div>
            <SchemeTenRejectedApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeTenRejectedApplications;
