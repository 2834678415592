export const loginChananisamiti = (session) => {
    return {
        type: "LOGIN_CHANANI_SAMITY",
        payload: session,
    };
};

export const logoutChananisamiti = () => {
    return {
        type: "LOGOUT_CHANANI_SAMITY",
    };
};
