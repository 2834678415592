import React from "react";
import SchemeThreeRejectedApplications from "../../Administration/SchemeThree/SchemeThreeRejectedApplications";

const ShaskiySamitiSchemeThreeRejectedApplications = () => {
    return (
        <div>
            <SchemeThreeRejectedApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeThreeRejectedApplications;
