import React from "react";
import SchemeTwoApprovedApplications from "../../Administration/SchemeTwo/SchemeTwoApprovedApplications";

const ShaskiySamitiSchemeTwoApprovedApplications = () => {
    return (
        <div>
            <SchemeTwoApprovedApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeTwoApprovedApplications;
