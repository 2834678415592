import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { useSelectAccess } from "../store/stateFunctions";
import ShaskiySamiti3Sidebar from "../shaskiysamiti3/ShaskiySamiti3Sidebar";
// import { Outlet } from "react-router-dom";

const ShaskiySamiti3Container = () => {
  document.title = "Shaskiy Samiti";
  const shaskiysamiti3 = useSelectAccess("Shaskiy Samiti3");

  return (
    <>
      <div className="row ">
        <ShaskiySamiti3Sidebar role="Shaskiy Samiti3" />
        <div className="container">
          {shaskiysamiti3 ? <Outlet /> : <Navigate to="/shaskiy-samiti3" />}
          {/* <Outlet /> */}
        </div>
      </div>
    </>
  );
};

export default ShaskiySamiti3Container;
