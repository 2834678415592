import React from "react";
import SchemeSevenapprovedapplications from "../../Administration/SchemeSeven/SchemeSevenapprovedapplications";

const ChananiSevenApprovedApplications = () => {
  return (
    <div>
      <SchemeSevenapprovedapplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiSevenApprovedApplications;
