import React from "react";
import SchemeTenTotalApplications from "../../Administration/SchemeTen/SchemeTenTotalApplications";

const ShaskiySamitiSchemeTenTotalApplications = () => {
    return (
        <div>
            <SchemeTenTotalApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeTenTotalApplications;
