import { removeClerk2UserToken } from "../../common";
import { clerkUserTokenName2 } from "../../config";

const getTrafficFromLocalStorage = () => {
  try {
    const clerk = JSON.parse(sessionStorage.getItem(clerkUserTokenName2));
    if (clerk && clerk.authToken && clerk.loggedInTime) {
      return clerk;
    } else {
      removeClerk2UserToken();
      return null;
    }
  } catch (error) {
    removeClerk2UserToken();
    return null;
  }
};

const getInitial = () => {
  return getTrafficFromLocalStorage();
};

const clerk2UserReducers = (state = getInitial(), action) => {
  switch (action.type) {
    case "LOGIN_CLERK2":
      const { loggedInTime, authToken } = action.payload;
      const newState = {
        loggedInTime: loggedInTime || Date.now(),
        authToken: authToken,
      };
      sessionStorage.setItem(clerkUserTokenName2, JSON.stringify(newState));
      return newState;
    case "LOGOUT_CLERK2":
      removeClerk2UserToken();
      return null;
    default:
      return state;
  }
};

export default clerk2UserReducers;
