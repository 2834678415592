import React from "react";
import SchemeNineTotalApplications from "../../Administration/schemeNine/SchemeNineTotalApplications";

const ShaskiySamitiSchemeNineTotalApplications = () => {
    return (
        <div>
            <SchemeNineTotalApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeNineTotalApplications;
