import React from "react";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import logo from "../../assets/images/newlogo.png";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer style={styles.footer}>
      <div style={styles.container}>
        <div style={styles.section}>
          <h3 style={styles.heading}>{t("footer.find_us")}</h3>
          <p>{t("footer.address")}</p>
        </div>
        <div style={styles.section}>
          <h3 style={styles.heading}>{t("footer.call_us")}</h3>
          <p>{t("footer.phone")}</p>
        </div>
        <div style={styles.section}>
          <h3 style={styles.heading}>{t("footer.mail_us")}</h3>
          <p>{t("footer.email")}</p>
        </div>
      </div>
      <hr style={styles.divider} />
      <div className="container">
        <div className="row" style={styles.bottomSection}>
          <div className="col-md-4" style={styles.column}>
            <div style={styles.logoSection}>
              <img src={logo} alt="NMC Logo" style={styles.logo} />
              <p style={styles.description}>{t("footer.description")}</p>
            </div>
          </div>
          <div className="col-md-4 mt-5" style={styles.column}>
            <div style={styles.linksSection}>
              <h3 style={styles.heading}>{t("footer.useful_links")}</h3>
              <ul style={styles.linksList}>
                <a href="#/aboutus" className="text-decoration-none text-white">
                  <li>{t("footer.about_us")}</li>
                </a>
                <a href="#/login" className="text-decoration-none text-white">
                  <li>{t("footer.login")}</li>
                </a>
                <a
                  href="#/registration"
                  className="text-decoration-none text-white"
                >
                  <li>{t("footer.register")}</li>
                </a>
                <a href="#/news" className="text-decoration-none text-white">
                  <li>{t("footer.news")}</li>
                </a>
              </ul>
            </div>
          </div>
          <div className="col-md-4" style={styles.column}>
            <div style={styles.mapSection}>
              <iframe
                title="Nashik Municipal Corporation"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7498.389377027401!2d73.775504!3d20.000342!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddeba099a18541%3A0x2502d3b07814ecdc!2sRajiv%20Gandhi%20Bhavan%20(NMC)!5e0!3m2!1sen!2sin!4v1731148541454!5m2!1sen!2sin"
                width="300"
                height="200"
                style={styles.map}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
        <div style={styles.socialSection}>
          <h3 style={styles.heading}>{t("footer.follow_us")}</h3>
          <div style={styles.socialIcons}>
            <a
              href="https://www.facebook.com/mynashikmc/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook style={styles.icon} />
            </a>
            <a
              href="https://x.com/my_nmc?lang=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter style={styles.icon} />
            </a>
            <a
              href="https://www.instagram.com/my_nmc/?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram style={styles.icon} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: "#2080b0",
    color: "#ffffff",
    padding: "40px 20px",
    fontFamily: "Arial, sans-serif",
    marginTop: "30px",
  },
  container: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    textAlign: "center",
  },
  section: {
    flex: "1",
    minWidth: "200px",
    marginBottom: "20px",
  },
  heading: {
    fontSize: "1.2em",
    fontWeight: "bold",
  },
  divider: {
    border: "0.5px solid #ffffff",
  },
  bottomSection: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    textAlign: "left",
  },
  logoSection: {
    flex: "1",
    minWidth: "200px",
    textAlign: "center",
    marginBottom: "20px",
  },
  logo: {
    width: "100px",
    borderRadius: "50%",
  },
  description: {
    padding: "10px",
    fontSize: "0.9em",
    color: "#ffffff",
    maxWidth: "250px",
    margin: "0 auto",
    textAlign: "justify",
  },
  linksSection: {
    flex: "1",
    minWidth: "200px",
    marginLeft: "40px",
  },
  linksList: {
    listStyleType: "none",
    padding: 0,
    lineHeight: "1.6",
  },
  mapSection: {
    flex: "1",
    minWidth: "200px",
  },
  map: {
    height: "400px",
    width: "300px",
    border: "none",
  },
  socialSection: {
    textAlign: "center",
    marginTop: "20px",
  },
  socialIcons: {
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    fontSize: "1.5em",
  },
  icon: {
    color: "#ffffff",
    cursor: "pointer",
  },
};

export default Footer;
