import React from "react";
import SchemeOneApprovedApplications from "../../Administration/SchemeOne/SchemeOneApprovedApplications";

const ShaskiySamitiSchemeOneApprovedApplications = () => {
    return (
        <div>
            <SchemeOneApprovedApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeOneApprovedApplications;
