import React from "react";
import SchemeEightPendingApplications from "../../Administration/SchemeEight/SchemeEightPendingApplications";

const ShaskiySamitiSchemeEightPendingApplications = () => {
    return (
        <div>
            <SchemeEightPendingApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeEightPendingApplications;
