export const loginShaskiySamiti4 = (session) => {
  return {
    type: "LOGIN_SHASKIY_SAMITI4",
    payload: session,
  };
};

export const logoutShaskiySamiti4 = () => {
  return {
    type: "LOGOUT_SHASKIY_SAMITI4",
  };
};
