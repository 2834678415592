import React from "react";
import SchemeTwoPendingApplications from "../../Administration/SchemeTwo/SchemeTwoPendingApplications";

const ShaskiySamitiSchemeTwoPendingApplications = () => {
    return (
        <div>
            <SchemeTwoPendingApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeTwoPendingApplications;
