import React from "react";
import SchemeFivePendingApplications from "../../Administration/SchemeFive/SchemeFivePendingApplications";

const ShaskiySamitiSchemeFivePendingApplications = () => {
    return (
        <div>
            <SchemeFivePendingApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeFivePendingApplications;
