import React from "react";
import SchemeEightTotalApplications from "../../Administration/SchemeEight/SchemeEightTotalApplications";

const ShaskiySamitiSchemeEightTotalApplications = () => {
    return (
        <div>
            <SchemeEightTotalApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeEightTotalApplications;
