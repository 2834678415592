import store from ".";
import {
  loginAdd_Comm,
  loginChananisamiti3,
  logoutAdd_Comm,
  logoutChananisamiti,
  logoutChananisamiti1,
  logoutChananisamiti2,
  logoutChananisamiti3,
  logoutChananisamiti4,
  logoutclerk,
  logoutclerk1,
  logoutclerk2,
  logoutclerk3,
  logoutDepuComm,
  logoutShaskiySamiti1,
  logoutShaskiySamiti2,
  logoutShaskiySamiti3,
  logoutShaskiySamity,
  logoutSuperAdmin,
  logoutUser,
} from "./actions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { logoutShaskiySamiti4 } from "./actions/shaskiySamiti4Actions";
import { logoutShaskiySamiti5 } from "./actions/shaskiySamiti5Actions";

export function updateDashboardState(role, data) {
  switch (role) {
    // case "Student":
    //     store.dispatch(setStudentDashboard(data));
    //     break;
    default:
      break;
  }
}

export function useSelectAccess(role) {
  return useSelector((store) => {
    switch (role) {
      case "Super Admin":
        return store.superadmin;
      case "User":
        return store.user;
      case "Additional Commissioner":
        return store.addcomm;
      case "Deputy Commissioner":
        return store.deputycomm;
      case "Chanani Samiti":
        return store.chananisamiti;
      case "Chanani Samiti1":
        return store.chananisamiti1;
      case "Chanani Samiti2":
        return store.chananisamiti2;
      case "Chanani Samiti3":
        return store.chananisamiti3;
      case "Chanani Samiti4":
        return store.chananisamiti4;
      case "Shaskiy Samiti":
        return store.shaskiysamiti;
      case "Shaskiy Samiti1":
        return store.shaskiysamiti1;
      case "Shaskiy Samiti2":
        return store.shaskiysamiti2;
      case "Shaskiy Samiti3":
        return store.shaskiysamiti3;
      case "Shaskiy Samiti4":
        return store.shaskiysamiti4;
      case "Shaskiy Samiti5":
        return store.shaskiysamiti5;
      case "Clerk1":
        return store.clerk1;
      case "Clerk2":
        return store.clerk2;
      case "Clerk3":
        return store.clerk3;
      default:
        return null;
    }
  });
}

export function logoutAccess(role) {
  switch (role) {
    case "Super Admin":
      store.dispatch(logoutSuperAdmin());
      break;
    case "User":
      store.dispatch(logoutUser());
      break;
    case "Additional Commissioner":
      store.dispatch(logoutAdd_Comm());
      break;
    case "Deputy Commissioner":
      store.dispatch(logoutDepuComm());
      break;
    case "Chanani Samiti":
      store.dispatch(logoutChananisamiti());
      break;
    case "Chanani Samiti1":
      store.dispatch(logoutChananisamiti1());
      break;
    case "Chanani Samiti2":
      store.dispatch(logoutChananisamiti2());
      break;
    case "Chanani Samiti3":
      store.dispatch(logoutChananisamiti3());
      break;
    case "Chanani Samiti4":
      store.dispatch(logoutChananisamiti4());
      break;
    case "Shaskiy Samiti":
      store.dispatch(logoutShaskiySamity());
      break;
    case "Shaskiy Samiti1":
      store.dispatch(logoutShaskiySamiti1());
      break;
    case "Shaskiy Samiti2":
      store.dispatch(logoutShaskiySamiti2());
      break;
    case "Shaskiy Samiti3":
      store.dispatch(logoutShaskiySamiti3());
      break;
    case "Shaskiy Samiti4":
      store.dispatch(logoutShaskiySamiti4());
      break;
    case "Shaskiy Samiti5":
      store.dispatch(logoutShaskiySamiti5());
      break;
    case "Clerk1":
      store.dispatch(logoutclerk1());
      break;
    case "Clerk2":
      store.dispatch(logoutclerk2());
      break;
    case "Clerk3":
      store.dispatch(logoutclerk3());
      break;
    default:
      return;
  }
  toast.info("Logout successful", { toastId: "LogoutReplacable" });
}
