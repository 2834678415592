import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Line, Bar, Doughnut, Pie } from "react-chartjs-2";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Legend,
  Title,
} from "chart.js";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Legend,
  Title
);

const Dashboard = () => {
  const [selectedMonth, setSelectedMonth] = useState("All");
  const [selectedScheme, setSelectedScheme] = useState("All");

  const totalFunding = 80000;
  const totalApplications = 7230;
  const approvedApplications = 3800;
  const rejectedApplications = 9500;

  const schemes = [
    "Scheme 1",
    "Scheme 2",
    "Scheme 3",
    "Scheme 4",
    "Scheme 5",
    "Scheme 6",
    "Scheme 7",
    "Scheme 8",
    "Scheme 9",
    "Scheme 10",
  ];

  const filteredData = {
    barChartData: {
      labels: schemes,
      datasets: [
        {
          label: "Total Applications",
          data: [100, 200, 150, 80, 220, 140, 180, 200, 90, 120],
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(75,192,192,0.6)",
        },
      ],
    },
    lineChartData: {
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
      datasets: [
        {
          label: "Funding Over Time",
          data: [12000, 15000, 13000, 17000, 16000, 19000],
          borderColor: "#742774",
          backgroundColor: "rgba(116, 39, 116, 0.5)",
          fill: true,
          tension: 0.4,
        },
      ],
    },
    doughnutChartData: {
      labels: ["Approved", "Rejected"],
      datasets: [
        {
          data: [approvedApplications, rejectedApplications],
          backgroundColor: ["#4CAF50", "#FF5252"],
          hoverBackgroundColor: ["#66BB6A", "#FF7043"],
        },
      ],
    },
    pieChartData: {
      labels: schemes.slice(0, 5),
      datasets: [
        {
          data: [100, 200, 150, 80, 220],
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#9966FF",
          ],
          hoverBackgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#9966FF",
          ],
        },
      ],
    },
    demographicData: {
      labels: ["Under 18", "18-35", "36-50", "51-65", "65+"],
      datasets: [
        {
          label: "Applicant Age Distribution",
          data: [2000, 2500, 1500, 1000, 730],
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#9966FF",
          ],
        },
      ],
    },
    disabilityTypeData: {
      labels: [
        "Visual Impairment",
        "Hearing Impairment",
        "Mobility Issues",
        "Cognitive Impairment",
        "Other",
      ],
      datasets: [
        {
          label: "Disability Types",
          data: [3000, 1500, 2000, 1000, 700],
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#9966FF",
          ],
        },
      ],
    },
  };

  const handleFilterChange = (e) => {
    if (e.target.name === "month") {
      setSelectedMonth(e.target.value);
    } else if (e.target.name === "scheme") {
      setSelectedScheme(e.target.value);
    }
  };

  const applyFilters = () => {
    console.log("Filters applied:", selectedMonth, selectedScheme);
  };

  return (
    <Container>
      <Grid container spacing={2} className="mb-4">
        <Grid item xs={12}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <FormControl
              variant="outlined"
              className="mr-2"
              style={{ marginRight: "10px", width: "200px" }}
            >
              <InputLabel>Month</InputLabel>
              <Select
                name="month"
                value={selectedMonth}
                onChange={handleFilterChange}
                label="Month"
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="January">January</MenuItem>
                <MenuItem value="February">February</MenuItem>
                <MenuItem value="March">March</MenuItem>
                <MenuItem value="April">April</MenuItem>
                <MenuItem value="May">May</MenuItem>
                <MenuItem value="June">June</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              variant="outlined"
              className="mr-2"
              style={{ marginRight: "10px", width: "200px" }}
            >
              <InputLabel>Scheme</InputLabel>
              <Select
                name="scheme"
                value={selectedScheme}
                onChange={handleFilterChange}
                label="Scheme"
              >
                <MenuItem value="All">All</MenuItem>
                {schemes.map((scheme) => (
                  <MenuItem key={scheme} value={scheme}>
                    {scheme}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button variant="contained" color="primary" onClick={applyFilters}>
              Apply Filters
            </Button>
          </div>
        </Grid>
      </Grid>

      <Row className="mb-4">
        <Col md={6}>
          <Card>
            <Card.Header>Applications by Scheme</Card.Header>
            <Card.Body>
              <Bar
                data={filteredData.barChartData}
                options={{
                  maintainAspectRatio: false,
                  animation: {
                    duration: 2000,
                    easing: "easeOutBounce",
                  },
                }}
                height={250}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Header>Funding Over Time</Card.Header>
            <Card.Body>
              <Line
                data={filteredData.lineChartData}
                options={{
                  maintainAspectRatio: false,
                  animation: {
                    duration: 1500,
                    easing: "easeInOutQuad",
                  },
                }}
                height={250}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={6}>
          <Card>
            <Card.Header>Approval vs Rejection (Doughnut Chart)</Card.Header>
            <Card.Body>
              <Doughnut
                data={filteredData.doughnutChartData}
                options={{
                  maintainAspectRatio: false,
                  animation: {
                    animateScale: true,
                    animateRotate: true,
                  },
                }}
                height={250}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Header>Applications Distribution (Pie Chart)</Card.Header>
            <Card.Body>
              <Pie
                data={filteredData.pieChartData}
                options={{
                  maintainAspectRatio: false,
                  animation: {
                    animateScale: true,
                    animateRotate: true,
                  },
                }}
                height={250}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={6}>
          <Card>
            <Card.Header>Applicant Age Distribution</Card.Header>
            <Card.Body>
              <Bar
                data={filteredData.demographicData}
                options={{
                  maintainAspectRatio: false,
                  animation: {
                    duration: 2000,
                    easing: "easeOutBounce",
                  },
                }}
                height={250}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Header>Disability Types Distribution</Card.Header>
            <Card.Body>
              <Pie
                data={filteredData.disabilityTypeData}
                options={{
                  maintainAspectRatio: false,
                  animation: {
                    animateScale: true,
                    animateRotate: true,
                  },
                }}
                height={250}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
