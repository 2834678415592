import React from "react";
import SchemeFourTotalApplications from "../../Administration/SchemeFour/SchemeFourTotalApplications";

const ChananiFourTotalApplications = () => {
  return (
    <div>
      <SchemeFourTotalApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiFourTotalApplications;
