import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { useSelectAccess } from "../store/stateFunctions";
import ChananiSamiti3Sidebar from "../chananisamiti3/ChananiSamiti3Sidebar";

const ChananiSamiti3Container = () => {
  document.title = "Chanani Samiti";
  const chananisamiti3 = useSelectAccess("Chanani Samiti3");

  return (
    <>
      <div className="row ">
        <ChananiSamiti3Sidebar role="Chanani Samiti3" />
        <div className="container">
          {chananisamiti3 ? <Outlet /> : <Navigate to="/chanani-samiti3" />}
          {/* <Outlet /> */}
        </div>
      </div>
    </>
  );
};

export default ChananiSamiti3Container;
