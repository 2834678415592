import React from "react";
import SchemeNineRejectedApplications from "../../Administration/schemeNine/SchemeNineRejectedApplications";

const ShaskiySamitiSchemeNineRejectedApplications = () => {
    return (
        <div>
            <SchemeNineRejectedApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeNineRejectedApplications;
