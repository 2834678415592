import React from "react";
import SchemeEightRejectedApplications from "../../Administration/SchemeEight/SchemeEightRejectedApplications";

const ChananiEightRejectedApplications = () => {
  return (
    <div>
      <SchemeEightRejectedApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiEightRejectedApplications;
