import React from "react";
import SchemeTenTotalApplications from "../../Administration/SchemeTen/SchemeTenTotalApplications";

const ChananiTenTotalApplications = () => {
  return (
    <div>
      <SchemeTenTotalApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiTenTotalApplications;
