import React from "react";
import SchemeNineapprovedapplications from "../../Administration/schemeNine/SchemeNineapprovedapplications";

const ShaskiySamitiSchemeNineApprovedApplications = () => {
    return (
        <div>
            <SchemeNineapprovedapplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeNineApprovedApplications;
