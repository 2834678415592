import React from "react";
import Dashboard from "../Administration/dashboard/GraphDashboard/Graphdashboard";

const ShaskiySamiti4Dashboard = () => {
  return (
    <>
      <Dashboard />
    </>
  );
};

export default ShaskiySamiti4Dashboard;
