export const loginShaskiySamiti1 = (session) => {
  return {
    type: "LOGIN_SHASKIY_SAMITI1",
    payload: session,
  };
};

export const logoutShaskiySamiti1 = () => {
  return {
    type: "LOGOUT_SHASKIY_SAMITI1",
  };
};
