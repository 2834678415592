import React from "react";
import SchemeFourPendingApplications from "../../Administration/SchemeFour/SchemeFourPendingApplications";

const ShaskiySamitiSchemeFourPendingApplications = () => {
    return (
        <div>
            <SchemeFourPendingApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeFourPendingApplications;
