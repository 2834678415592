import { removeChananisamiti2UserToken } from "../../common";
import { shaskiysamityTokenName2 } from "../../config";

const getshaskiysamitiFromLocalStorage = () => {
  try {
    const shaskiysamiti2 = JSON.parse(
      sessionStorage.getItem(shaskiysamityTokenName2)
    );
    if (
      shaskiysamiti2 &&
      shaskiysamiti2.authToken &&
      shaskiysamiti2.loggedInTime
    ) {
      console.log("Returning Internal User:", shaskiysamiti2);
      return shaskiysamiti2;
    } else {
      removeChananisamiti2UserToken();
      return null;
    }
  } catch (error) {
    removeChananisamiti2UserToken();
    return null;
  }
};

const getInitial = () => {
  return getshaskiysamitiFromLocalStorage();
};

const shaskiySamity2Reducer = (state = getInitial(), action) => {
  switch (action.type) {
    case "LOGIN_SHASKIY_SAMITI2":
      state = {};
      const time = action.payload.loggedInTime;
      if (time) {
        state.loggedInTime = time;
      } else {
        state.loggedInTime = Date.now();
      }
      state.authToken = action.payload.authToken;
      sessionStorage.setItem(shaskiysamityTokenName2, JSON.stringify(state));
      return state;
    case "LOGOUT_SHASKIY_SAMITI2":
      removeChananisamiti2UserToken();
      return null;
    default:
      return state;
  }
};

export default shaskiySamity2Reducer;
