export const loginclerk3 = (session) => {
  return {
    type: "LOGIN_CLERK3",
    payload: session,
  };
};
export const logoutclerk3 = () => {
  return {
    type: "LOGOUT_CLERK3",
  };
};
