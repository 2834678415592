import React, { useContext, useEffect, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
  Typography,
  Alert,
} from "@mui/material";
import { Card, Col, Row } from "react-bootstrap";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import Grid from "@mui/material/Grid2";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios, { AxiosError } from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { server } from "../../common";
import Loader from "../../Loader";
import { useSelectAccess } from "../../store/stateFunctions";
import { useNavigate, useParams } from "react-router-dom";
const swalalert = withReactContent(Swal);

const initialValues = {
  fullname: "",
  mobile: "",
  adhar_number: "",
  bod: "",
  address: "",
  division: "",
  ward_no: "",
  resident_duration_years: "",
  school_name: "",
  school_address: "",
  audiogram: "",
  ci_surgery_report: "",
  ci_hearing_cost: "",
  ci_hospital_pan_no: "",
  bankname: "",
  branch: "",
  ac_holder_name: "",
  account_no: "",
  ifsc_code: "",
  micr_code: "",

  passport_photo: null,
  upload_adhar: null,
  upload_pancard: null,
  upload_bankpassbook: null,
  certificate_of_disability: null,
  birth_or_age_proof: null,
  medical_officer_letter: null,
  proof1: null,
  proof2: null,

  self_declaration: null,
  self_attestation_declaration: null,
};

const SchemeOneEdit = () => {
  const [loading, setLoading] = useState(false);
  const user = useSelectAccess("User");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      fullname: Yup.string().required(t("schemeForms.name_required")),
      mobile: Yup.string()
        .matches(/^[6-9]\d{9}$/, t("schemeForms.mobile_required"))
        .required(t("schemeForms.mobile_invalid")),
      adhar_number: Yup.string()
        .matches(/^\d{12}$/, t("schemeForms.adhar_invalid"))
        .required(t("schemeForms.adhar_required")),
      bod: Yup.string().required(t("schemeForms.bod_required")),
      address: Yup.string().required(t("schemeForms.address_required")),
      division: Yup.string().required(t("schemeForms.division_required")),
      ward_no: Yup.number()
        .max(300, t("schemeForms.division_validation"))
        .required(t("schemeForms.division_required")),
      resident_duration_years: Yup.string().required(
        t("schemeForms.resident_duration_required")
      ),
      school_name: Yup.string().required(t("schemeForms.school_name_required")),
      school_address: Yup.string().required(
        t("schemeForms.school_address_required")
      ),
      audiogram: Yup.string().required(t("schemeForms.audiogram_required")),
      ci_surgery_report: Yup.string().required(
        t("schemeForms.ci_report_required")
      ),
      ci_hearing_cost: Yup.string().required(
        t("schemeForms.ci_hearing_cost_required")
      ),
      ci_hospital_pan_no: Yup.string().required(
        t("schemeForms.ci_hospital_pan_required")
      ),
      bankname: Yup.string().required(t("schemeForms.bankname_required")),
      branch: Yup.string().required(t("schemeForms.branch_required")),
      ac_holder_name: Yup.string().required(
        t("schemeForms.ac_holder_name_required")
      ),
      ifsc_code: Yup.string().required(t("schemeForms.ifsc_required")),
      micr_code: Yup.string().required(t("schemeForms.micr_required")),
      account_no: Yup.string().required(t("schemeForms.account_no_required")),

      // Removed required and file size test validations for documents
      passport_photo: Yup.mixed(),
      upload_adhar: Yup.mixed(),
      upload_pancard: Yup.mixed(),
      upload_bankpassbook: Yup.mixed(),
      certificate_of_disability: Yup.mixed(),
      birth_or_age_proof: Yup.mixed(),
      medical_officer_letter: Yup.mixed(),
      proof1: Yup.mixed(),
      proof2: Yup.mixed(),
      self_declaration: Yup.mixed(),
      self_attestation_declaration: Yup.mixed(),
    }),

    onSubmit: (values, action) => {
      setLoading(true);

      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (values[key]) {
          // Append only if a value exists
          formData.append(key, values[key]);
        }
      });

      server
        .put(`/firstappl/updatefirstappl/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: user.authToken,
          },
        })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            toast.success(
              `Application Submitted Successfully. Application Id is ${response.data.id}`
            );
            formik.resetForm();
            navigate("/profile/myappliedschemes");
          }
        })
        .catch((error) => {
          toast.error(
            error.response?.data?.message ||
              error.response?.data?.error ||
              "Failed to connect to server"
          );
        })
        .finally(() => {
          setLoading(false); // Hide loader after API call
        });
    },
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    server
      .get(`/firstappl/firstapplication/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          formik.setValues(response.data);
        }
      })
      .catch((error) => {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else {
          toast.error(error.response?.data?.error || "An error occurred");
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {loading && <Loader loading={loading} />}

      <div className="container">
        {console.log(formik.values)}
        <Alert severity="info" className="mt-2">
          {t("schemeForms.note")}
        </Alert>

        <Card className="mt-2">
          <Card.Header>
            <Typography variant="h5" gutterBottom style={{ marginTop: "10px" }}>
              {t("schemeForms.scheme_number_1")}
            </Typography>
          </Card.Header>

          <form
            onSubmit={formik.handleSubmit}
            noValidate
            style={{ padding: "20px" }}
          >
            <Typography variant="h6" gutterBottom>
              {t("schemeForms.personal_info")}
            </Typography>
            <hr />
            <Row>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.full_name")}
                  required
                  variant="outlined"
                  name="fullname"
                  value={formik.values.fullname}
                  onChange={formik.handleChange}
                />
                {formik.errors.fullname ? (
                  <p className="text-danger">{formik.errors.fullname}</p>
                ) : null}
              </Col>

              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.dob")}
                  required
                  variant="outlined"
                  type="date"
                  name="bod"
                  value={formik.values.bod}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: new Date().toISOString().substring(0, 10), // Restricts to today or earlier
                  }}
                />

                {formik.errors.bod ? (
                  <p className="text-danger">{formik.errors.bod}</p>
                ) : null}
              </Col>

              {/* <Col md={4}  className="mt-4">
                  <TextField
                    fullWidth
                    label="अपंगत्वाचा प्रकार "
                    required
                    variant="outlined"
                    name="dob"
                    value={formik.values.dob}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.dob ? (
                    <p className="text-danger">{formik.errors.dob}</p>
                  ) : null}
                </Col> */}

              <Col md={4} className="mt-2">
                <TextField
                  label="Mobile No."
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="mobile"
                  value={formik.values.mobile}
                  onChange={(e) => {
                    // Allow only digits and limit to 10 characters
                    const mobile = e.target.value
                      .replace(/\D/g, "")
                      .slice(0, 10);
                    formik.setFieldValue("mobile", mobile);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    ),
                  }}
                  required
                />
                {formik.errors.mobile ? (
                  <p className="text-danger">{formik.errors.mobile}</p>
                ) : null}
              </Col>

              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.aadhaar_no")}
                  required
                  variant="outlined"
                  name="adhar_number"
                  value={formik.values.adhar_number}
                  onChange={(e) => {
                    // Allow only digits and limit to 12 characters
                    const aadhaar = e.target.value
                      .replace(/\D/g, "")
                      .slice(0, 12);
                    formik.setFieldValue("adhar_number", aadhaar);
                  }}
                />
                {formik.errors.adhar_number ? (
                  <p className="text-danger">{formik.errors.adhar_number}</p>
                ) : null}
              </Col>
            </Row>
            <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
              {t("schemeForms.resident_info")}
            </Typography>
            <hr />
            <Row>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.address")}
                  required
                  variant="outlined"
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                />
                {formik.errors.address ? (
                  <p className="text-danger">{formik.errors.address}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-2">
                <FormControl variant="outlined" fullWidth margin="normal">
                  <InputLabel id="demo-simple-select-label">
                    {t("schemeForms.division")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Division"
                    variant="outlined"
                    name="division"
                    value={formik.values.division}
                    onChange={formik.handleChange}
                    required
                  >
                    <MenuItem value="">
                      <em> {t("schemeForms.selectDivision")}</em>
                    </MenuItem>
                    <MenuItem value="satpur">
                      <em>{t("schemeForms.divisions.satpur")}</em>
                    </MenuItem>
                    <MenuItem value="cidco">
                      <em>{t("schemeForms.divisions.cidco")}</em>
                    </MenuItem>
                    <MenuItem value="nashikroad">
                      <em>{t("schemeForms.divisions.nashikroad")} </em>
                    </MenuItem>
                    <MenuItem value="nashikeast">
                      <em> {t("schemeForms.divisions.newnashik")} </em>
                    </MenuItem>
                    <MenuItem value="nashikwest">
                      <em> {t("schemeForms.divisions.nashikwest")} </em>
                    </MenuItem>
                    <MenuItem value="panchavati">
                      <em>t{"schemeForms.divisions.panchavati"}</em>
                    </MenuItem>
                  </Select>
                </FormControl>
                {formik.errors.division ? (
                  <p className="text-danger">{formik.errors.division}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.ward")}
                  required
                  variant="outlined"
                  name="ward_no"
                  value={formik.values.ward_no}
                  onChange={formik.handleChange}
                />
                {formik.errors.ward_no ? (
                  <p className="text-danger">{formik.errors.ward_no}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.years_resident")}
                  required
                  variant="outlined"
                  name="resident_duration_years"
                  value={formik.values.resident_duration_years}
                  onChange={formik.handleChange}
                />
                {formik.errors.resident_duration_years ? (
                  <p className="text-danger">
                    {formik.errors.resident_duration_years}
                  </p>
                ) : null}
              </Col>
            </Row>
            {/* Step 3: School Information */}
            <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
              {t("schemeForms.school_info")}
            </Typography>
            <hr />
            <Row>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.school_name")}
                  required
                  variant="outlined"
                  name="school_name"
                  value={formik.values.school_name}
                  onChange={formik.handleChange}
                />
                {formik.errors.school_name ? (
                  <p className="text-danger">{formik.errors.school_name}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.school_address")}
                  required
                  variant="outlined"
                  name="school_address"
                  value={formik.values.school_address}
                  onChange={formik.handleChange}
                />
                {formik.errors.school_address ? (
                  <p className="text-danger">{formik.errors.school_address}</p>
                ) : null}
              </Col>
            </Row>

            {/* hospital Information */}

            <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
              {t("schemeForms.hospital_info")}
            </Typography>
            <hr />
            <Row>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.audiogram")}
                  required
                  variant="outlined"
                  name="audiogram"
                  value={formik.values.audiogram}
                  onChange={formik.handleChange}
                />
                {formik.errors.audiogram ? (
                  <p className="text-danger">{formik.errors.audiogram}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.ci_pre_test")}
                  required
                  variant="outlined"
                  name="ci_surgery_report"
                  value={formik.values.ci_surgery_report}
                  onChange={formik.handleChange}
                />
                {formik.errors.ci_surgery_report ? (
                  <p className="text-danger">
                    {formik.errors.ci_surgery_report}
                  </p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.hearing_costs_details")}
                  required
                  variant="outlined"
                  name="ci_hearing_cost"
                  value={formik.values.ci_hearing_cost}
                  onChange={formik.handleChange}
                />
                {formik.errors.ci_hearing_cost ? (
                  <p className="text-danger">{formik.errors.ci_hearing_cost}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.ci_hospital_pan")}
                  required
                  variant="outlined"
                  name="ci_hospital_pan_no"
                  value={formik.values.ci_hospital_pan_no}
                  onChange={formik.handleChange}
                />
                {formik.errors.ci_hospital_pan_no ? (
                  <p className="text-danger">
                    {formik.errors.ci_hospital_pan_no}
                  </p>
                ) : null}
              </Col>
            </Row>

            {/* Step 5: Bank Information */}
            <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
              {t("schemeForms.bank_info")}
            </Typography>
            <hr />
            <Row>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.bank_name")}
                  required
                  variant="outlined"
                  name="bankname"
                  value={formik.values.bankname}
                  onChange={formik.handleChange}
                />
                {formik.errors.bankname ? (
                  <p className="text-danger">{formik.errors.bankname}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.bank_branch")}
                  required
                  variant="outlined"
                  name="branch"
                  value={formik.values.branch}
                  onChange={formik.handleChange}
                />
                {formik.errors.branch ? (
                  <p className="text-danger">{formik.errors.branch}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.account_holder_name")}
                  required
                  variant="outlined"
                  name="ac_holder_name"
                  value={formik.values.ac_holder_name}
                  onChange={formik.handleChange}
                />
                {formik.errors.ac_holder_name ? (
                  <p className="text-danger">{formik.errors.ac_holder_name}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.account_number")}
                  required
                  variant="outlined"
                  name="account_no"
                  value={formik.values.account_no}
                  onChange={formik.handleChange}
                />
                {formik.errors.account_no ? (
                  <p className="text-danger">{formik.errors.account_no}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.ifsc_code")}
                  required
                  variant="outlined"
                  name="ifsc_code"
                  value={formik.values.ifsc_code}
                  onChange={formik.handleChange}
                />
                {formik.errors.ifsc_code ? (
                  <p className="text-danger">{formik.errors.ifsc_code}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.micr_code")}
                  required
                  variant="outlined"
                  name="micr_code"
                  value={formik.values.micr_code}
                  onChange={formik.handleChange}
                />
                {formik.errors.micr_code ? (
                  <p className="text-danger">{formik.errors.micr_code}</p>
                ) : null}
              </Col>
            </Row>

            {/* Step 6: Document Upload */}
            <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
              {t("schemeForms.upload_documents")}
            </Typography>
            <hr />

            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField
                        fullWidth
                        label={t("schemeForms.PassportPhoto")}
                        required
                        sx={{ width: "400px" }}
                        variant="outlined"
                        type="file"
                        name="passport_photo"
                        onChange={(event) =>
                          formik.setFieldValue(
                            "passport_photo",
                            event.currentTarget.files[0]
                          )
                        }
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ accept: ".pdf, .png, .jpg, .jpeg" }}
                      />
                      {/* {formik.errors.passport_photo && (
                        <p className="text-danger">
                          {formik.errors.passport_photo}
                        </p>
                      )} */}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained">
                        <a
                          href={formik.values.passport_photo}
                          className="text-decoration-none text-white"
                          target="_blank"
                        >
                          View
                        </a>
                      </Button>
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        required
                        label={t("schemeForms.AadharCard")}
                        variant="outlined"
                        type="file"
                        name="upload_adhar"
                        onChange={(event) =>
                          formik.setFieldValue(
                            "upload_adhar",
                            event.currentTarget.files[0]
                          )
                        }
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ accept: ".pdf, .png, .jpg, .jpeg" }}
                      />
                      {/* {formik.errors.upload_adhar && (
                        <p className="text-danger">
                          {formik.errors.upload_adhar}
                        </p>
                      )} */}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained">
                        <a
                          href={formik.values.upload_adhar}
                          className="text-decoration-none text-white"
                          target="_blank"
                        >
                          View
                        </a>
                      </Button>{" "}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField
                        fullWidth
                        label={t("schemeForms.PanCard")}
                        required
                        variant="outlined"
                        type="file"
                        name="upload_pancard"
                        onChange={(event) =>
                          formik.setFieldValue(
                            "upload_pancard",
                            event.currentTarget.files[0]
                          )
                        }
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ accept: ".pdf, .png, .jpg, .jpeg" }}
                      />
                      {/* {formik.errors.upload_pancard && (
                        <p className="text-danger">
                          {formik.errors.upload_pancard}
                        </p>
                      )} */}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained">
                        <a
                          href={formik.values.upload_pancard}
                          className="text-decoration-none text-white"
                          target="_blank"
                        >
                          View
                        </a>
                      </Button>{" "}
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        label={t("schemeForms.BankPassbook")}
                        required
                        variant="outlined"
                        type="file"
                        name="upload_bankpassbook"
                        onChange={(event) =>
                          formik.setFieldValue(
                            "upload_bankpassbook",
                            event.currentTarget.files[0]
                          )
                        }
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ accept: ".pdf, .png, .jpg, .jpeg" }}
                      />
                      {/* {formik.errors.upload_bankpassbook && (
                        <p className="text-danger">
                          {formik.errors.upload_bankpassbook}
                        </p>
                      )} */}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained">
                        <a
                          href={formik.values.upload_bankpassbook}
                          className="text-decoration-none text-white"
                          target="_blank"
                        >
                          View
                        </a>
                      </Button>{" "}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField
                        fullWidth
                        label={t("schemeForms.disability_certificate")}
                        required
                        variant="outlined"
                        type="file"
                        name="certificate_of_disability"
                        onChange={(event) =>
                          formik.setFieldValue(
                            "certificate_of_disability",
                            event.currentTarget.files[0]
                          )
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                      {/* {formik.errors.certificate_of_disability && (
                        <p className="text-danger">
                          {formik.errors.certificate_of_disability}
                        </p>
                      )} */}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained">
                        <a
                          href={formik.values.certificate_of_disability}
                          className="text-decoration-none text-white"
                          target="_blank"
                        >
                          View
                        </a>
                      </Button>{" "}
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        required
                        label={t("schemeForms.BirthProof")}
                        variant="outlined"
                        type="file"
                        name="birth_or_age_proof"
                        onChange={(event) =>
                          formik.setFieldValue(
                            "birth_or_age_proof",
                            event.currentTarget.files[0]
                          )
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                      {/* {formik.errors.birth_or_age_proof && (
                        <p className="text-danger">
                          {formik.errors.birth_or_age_proof}
                        </p>
                      )} */}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained">
                        <a
                          href={formik.values.birth_or_age_proof}
                          className="text-decoration-none text-white"
                          target="_blank"
                        >
                          View
                        </a>
                      </Button>{" "}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField
                        fullWidth
                        label={t("schemeForms.MedicalOfficerLetter")}
                        required
                        variant="outlined"
                        type="file"
                        name="medical_officer_letter"
                        onChange={(event) =>
                          formik.setFieldValue(
                            "medical_officer_letter",
                            event.currentTarget.files[0]
                          )
                        }
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ accept: ".pdf, .png, .jpg, .jpeg" }}
                      />
                      {/* {formik.errors.medical_officer_letter && (
                        <p className="text-danger">
                          {formik.errors.medical_officer_letter}
                        </p>
                      )} */}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained">
                        <a
                          href={formik.values.medical_officer_letter}
                          className="text-decoration-none text-white"
                          target="_blank"
                        >
                          View
                        </a>
                      </Button>{" "}
                    </TableCell>

                    <TableCell>
                      <TextField
                        fullWidth
                        label={t("schemeForms.SelfDeclaration")}
                        required
                        variant="outlined"
                        type="file"
                        name="self_declaration"
                        onChange={(event) =>
                          formik.setFieldValue(
                            "self_declaration",
                            event.currentTarget.files[0]
                          )
                        }
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ accept: ".pdf, .png, .jpg, .jpeg" }}
                      />
                      {/* {formik.errors.self_declaration && (
                        <p className="text-danger">
                          {formik.errors.self_declaration}
                        </p>
                      )} */}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained">
                        <a
                          href={formik.values.self_declaration}
                          className="text-decoration-none text-white"
                          target="_blank"
                        >
                          View
                        </a>
                      </Button>{" "}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField
                        fullWidth
                        label={t("schemeForms.SelfAttestationDeclaration")}
                        required
                        variant="outlined"
                        type="file"
                        name="self_attestation_declaration"
                        onChange={(event) =>
                          formik.setFieldValue(
                            "self_attestation_declaration",
                            event.currentTarget.files[0]
                          )
                        }
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ accept: ".pdf, .png, .jpg, .jpeg" }}
                      />
                      {/* {formik.errors.self_attestation_declaration && (
                        <p className="text-danger">
                          {formik.errors.self_attestation_declaration}
                        </p>
                      )} */}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained">
                        <a
                          href={formik.values.self_attestation_declaration}
                          className="text-decoration-none text-white"
                          target="_blank"
                        >
                          View
                        </a>
                      </Button>{" "}
                    </TableCell>
                    {/* <TableCell>erfefs</TableCell>
                    <TableCell>erfefs</TableCell> */}
                    <TableCell>
                      <TextField
                        fullWidth
                        required
                        label={t("schemeForms.Proof1")}
                        variant="outlined"
                        type="file"
                        name="proof1"
                        onChange={(event) =>
                          formik.setFieldValue(
                            "proof1",
                            event.currentTarget.files[0]
                          )
                        }
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ accept: ".pdf, .png, .jpg, .jpeg" }}
                      />
                      {/* {formik.errors.proof1 && (
                        <p className="text-danger">{formik.errors.proof1}</p>
                      )} */}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained">
                        <a
                          href={formik.values.proof1}
                          className="text-decoration-none text-white"
                          target="_blank"
                        >
                          View
                        </a>
                      </Button>{" "}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField
                        fullWidth
                        required
                        variant="outlined"
                        type="file"
                        name="proof2"
                        onChange={(event) =>
                          formik.setFieldValue(
                            "proof2",
                            event.currentTarget.files[0]
                          )
                        }
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ accept: ".pdf, .png, .jpg, .jpeg" }}
                      />
                      {/* {formik.errors.proof2 && (
                        <p className="text-danger">{formik.errors.proof2}</p>
                      )} */}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained">
                        <a
                          href={formik.values.proof2}
                          className="text-decoration-none text-white"
                          target="_blank"
                        >
                          View
                        </a>
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Button
              variant="contained"
              type="submit"
              color="primary"
              className="mt-2"
              // disabled={step === steps}
            >
              Update
            </Button>
          </form>
        </Card>
      </div>
    </>
  );
};

export default SchemeOneEdit;
