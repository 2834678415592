import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import { server } from "../../common";
import { toast } from "react-toastify";
import { useSelectAccess } from "../../store/stateFunctions";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import UserApprovalTable from "../../Administration/UserApprovalTable";

const initialValues = {
  rejection_reason: "",
};

function SchemeOneUserModal({ show, handleClose, id, role }) {
  const [data, setData] = useState(null);
  const user = useSelectAccess("Super Admin");
  const [isChecked, setIsChecked] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  useEffect(() => {
    if (show && id) {
      getData(id);
    }
  }, [show, id]);

  // Status message role wise
  const statusMessage = (data, role) => {
    switch (role) {
      case "Clerk1":
        return data?.statusbyclerk === "Approve" ? "Approved" : "Approve";
      case "Clerk2":
        return data?.statusbyclerk === "Approve" ? "Approved" : "Approve";
      case "Clerk3":
        return data?.statusbyclerk === "Approve" ? "Approved" : "Approve";
      case "Deputy Commissioner":
        return data?.statusbydeputy === "Approve" ? "Approved" : "Approve";
      case "Chanani Samiti1":
        return data?.statusbychanani1 === "Approve" ? "Approved" : "Approve";
      case "Chanani Samiti2":
        return data?.statusbychanani2 === "Approve" ? "Approved" : "Approve";
      case "Chanani Samiti3":
        return data?.statusbychanani3 === "Approve" ? "Approved" : "Approve";
      case "Chanani Samiti4":
        return data?.statusbychanani4 === "Approve" ? "Approved" : "Approve";
      case "Additional Commissioner":
        return data?.statusbyadditional === "Approve" ? "Approved" : "Approve";
      case "Shaskiy Samiti1":
        return data?.statusbyshaskiya1 === "Approve" ? "Approved" : "Approve";
      case "Shaskiy Samiti2":
        return data?.statusbyshaskiya2 === "Approve" ? "Approved" : "Approve";
      case "Shaskiy Samiti3":
        return data?.statusbyshaskiya3 === "Approve" ? "Approved" : "Approve";
      case "Shaskiy Samiti4":
        return data?.statusbyshaskiya4 === "Approve" ? "Approved" : "Approve";
      case "Shaskiy Samiti5":
        return data?.statusbyshaskiya5 === "Approve" ? "Approved" : "Approve";
      case "Super Admin":
        return data?.permission_status === "Approve" ? "Approved" : "Approve";
      default:
        return "No Status Available";
    }
  };

  const isDisabled = () => {
    if (!isChecked) return true; // Disable if not checked

    switch (role) {
      case "Clerk1":
        return (
          data?.statusbyclerk === "Approve" ||
          data?.statusbyclerk === "Rejected"
        );
      case "Clerk2":
        return (
          data?.statusbyclerk === "Approve" ||
          data?.statusbyclerk === "Rejected"
        );
      case "Clerk3":
        return (
          data?.statusbyclerk === "Approve" ||
          data?.statusbyclerk === "Rejected"
        );
      case "Deputy Commissioner":
        return (
          data?.statusbydeputy === "Approve" ||
          data?.statusbydeputy === "Rejected"
        );
      case "Chanani Samiti1":
        return (
          data?.statusbychanani1 === "Approve" ||
          data?.statusbychanani1 === "Rejected"
        );
      case "Chanani Samiti2":
        return (
          data?.statusbychanani2 === "Approve" ||
          data?.statusbychanani2 === "Rejected"
        );
      case "Chanani Samiti3":
        return (
          data?.statusbychanani3 === "Approve" ||
          data?.statusbychanani3 === "Rejected"
        );
      case "Chanani Samiti4":
        return (
          data?.statusbychanani4 === "Approve" ||
          data?.statusbychanani4 === "Rejected"
        );
      case "Additional Commissioner":
        return (
          data?.statusbyadditional === "Approve" ||
          data?.statusbyadditional === "Rejected"
        );
      case "Shaskiy Samiti1":
        return (
          data?.statusbyshaskiya1 === "Approve" ||
          data?.statusbyshaskiya1 === "Rejected"
        );
      case "Shaskiy Samiti2":
        return (
          data?.statusbyshaskiya2 === "Approve" ||
          data?.statusbyshaskiya2 === "Rejected"
        );
      case "Shaskiy Samiti3":
        return (
          data?.statusbyshaskiya3 === "Approve" ||
          data?.statusbyshaskiya3 === "Rejected"
        );
      case "Shaskiy Samiti4":
        return (
          data?.statusbyshaskiya4 === "Approve" ||
          data?.statusbyshaskiya4 === "Rejected"
        );
      case "Shaskiy Samiti5":
        return (
          data?.statusbyshaskiya5 === "Approve" ||
          data?.statusbyshaskiya5 === "Rejected"
        );
      case "Super Admin":
        return (
          data?.permission_status === "Approve" ||
          data?.permission_status === "Rejected"
        );
      default:
        return false;
    }
  };

  const getData = (applicationId) => {
    server
      .get(`/firstappl/firstapplication/${applicationId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then((response) => {
        console.log("API response", response.data);
        if (response.status === 200 || response.status === 201) {
          setData(response.data);
        }
      })
      .catch((error) => {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else {
          toast.error(error.response?.data?.error || "An error occurred");
        }
      });
  };

  // Update Status function
  const updateStatus = async (status) => {
    try {
      await server.put(
        `/firstappl/firstfinalperm/${id}`,
        { permission_status: status },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: user.authToken,
          },
        }
      );
      toast.success(`Permission Status ${status} successfully`);
      getData(id); // Reload data after status update
    } catch (error) {
      if (error instanceof AxiosError && error.response?.data?.message)
        toast.error(error.response.data.message);
      else if (error.response?.data?.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Failed to connect to the server");
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      rejection_reason: Yup.string()
        .max(250)
        .required("Please select an image"),
    }),
    onSubmit: (values, action) => {
      server
        .post(`/firstappl/rejectionreason/${id}`, values, {
          headers: {
            "Content-Type": "application/json",
            Authorization: user.authToken,
          },
        })
        .then(function (response) {
          if (response.status === 200 || response.status === 201) {
            toast.success(response.data.message);
            formik.resetForm();
            handleCloseModal();
            updateStatus("Rejected");
          }
        })
        .catch(function (error) {
          if (error.response?.data?.message)
            toast.error(error.response.data.message);
          else if (error.response?.data?.error) {
            toast.error(error.response.data.error);
          } else toast.error("Failed to connect to server");
        });
    },
  });

  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            {data
              ? `Application Details for Cochler Implant शस्त्रक्रियेसाठी अर्थसहाय्य योजना. (Application No. ${data.id})`
              : "Application Details"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data ? (
            <>
              <div style={{ overflowX: "scroll" }}>
                <Table bordered striped>
                  <thead>
                    <tr>
                      <th colSpan="4">Application Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="fw-bold">Applicant Id</td>
                      <td>{data.id}</td>
                      <td className="fw-bold">Contact</td>
                      <td>{data.mobile}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold">अर्जदाराचे संपूर्ण नाव</td>
                      <td>{data.fullname}</td>
                      <td className="fw-bold">अर्जदार/ पाल्याची जन्मतारीख</td>
                      <td>{data.bod}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold">मोबाईल क्रमांक </td>
                      <td>{data.mobile}</td>
                      <td className="fw-bold">आधार क्रमांक </td>
                      <td>{data.adhar_number}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold">संपूर्ण पत्ता </td>
                      <td>{data.address}</td>
                      <td className="fw-bold">प्रभाग </td>
                      <td>{data.division}</td>
                    </tr>

                    <tr>
                      <td className="fw-bold">वार्ड/विभाग क्रमांक</td>
                      <td>{data.ward_no}</td>
                      <td className="fw-bold">किती वर्षापासून रहिवासी आहात</td>
                      <td>{data.resident_duration_years}</td>
                    </tr>

                    <tr>
                      <td className="fw-bold">शाळेचे नाव</td>
                      <td>{data.school_name}</td>
                      <td className="fw-bold">शाळेचा संपूर्ण पत्ता </td>
                      <td>{data.school_address}</td>
                    </tr>

                    <tr>
                      <td className="fw-bold">ओडिओग्राम</td>
                      <td>{data.audiogram}</td>
                      <td className="fw-bold">
                        C.I शस्र्क्रियेचा पूर्व चाचणी अहवाल
                      </td>
                      <td>{data.ci_surgery_report}</td>
                    </tr>

                    <tr>
                      <td className="fw-bold">C.I करिता ऐकून खर्चाचा तपशील</td>
                      <td>{data.ci_hearing_cost}</td>
                      <td className="fw-bold">
                        C.I शस्र्यक्रिया करणार्‍या रुग्णालयाचा पॅन क्र्माक
                      </td>
                      <td>{data.ci_hospital_pan_no}</td>
                    </tr>

                    <tr>
                      <td className="fw-bold">बँकेचे नावं</td>
                      <td>{data.bankname}</td>
                      <td className="fw-bold">बँक शाखा</td>
                      <td>{data.branch}</td>
                    </tr>

                    <tr>
                      <td className="fw-bold">खातेदाराचे नाव</td>
                      <td>{data.ac_holder_name}</td>
                      <td className="fw-bold">खाते क्रमांक</td>
                      <td>{data.account_no}</td>
                    </tr>

                    <tr>
                      <td className="fw-bold">IFSC Code</td>
                      <td>{data.ifsc_code}</td>
                      <td className="fw-bold">MICR Code</td>
                      <td>{data.micr_code}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <div>
                <Table bordered striped>
                  <thead>
                    <tr>
                      <th colSpan="5" className="text-dark">
                        Document Details
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>शारिरीक अपंगत्व दर्शविणारा स्वसाक्षांकीत फोटो</td>
                      <td>
                        <a
                          href={data.passport_photo}
                          target="_blank"
                          className="btn btn-success btn-sm"
                        >
                          View
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <td>आधार कार्ड / पावती</td>
                      <td>
                        <a
                          href={data.upload_adhar}
                          target="_blank"
                          className="btn btn-success btn-sm"
                        >
                          View
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <td>पॅन कार्ड</td>
                      <td>
                        <a
                          href={data.upload_pancard}
                          target="_blank"
                          className="btn btn-success btn-sm"
                        >
                          View
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <td>बँक पासबुक</td>
                      <td>
                        <a
                          href={data.upload_bankpassbook}
                          target="_blank"
                          className="btn btn-success btn-sm"
                        >
                          View
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <td>अपांगत्वाचे प्रमाणपत्र</td>
                      <td>
                        <a
                          href={data.certificate_of_disability}
                          target="_blank"
                          className="btn btn-success btn-sm"
                        >
                          View
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <td>जन्माचा दाखला अथवा वयाचा पुरावा</td>
                      <td>
                        <a
                          href={data.birth_or_age_proof}
                          target="_blank"
                          className="btn btn-success btn-sm"
                        >
                          View
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>हॉस्पीटलच्या वैद्यकीय अधिका-याचे पत्र</td>
                      <td>
                        <a
                          href={data.medical_officer_letter}
                          target="_blank"
                          className="btn btn-success btn-sm"
                        >
                          View
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>स्वयंघोषणापत्र</td>
                      <td>
                        <a
                          href={data.self_declaration}
                          target="_blank"
                          className="btn btn-success btn-sm"
                        >
                          View
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>स्वयं साक्षांकनासाठी स्वयं घोषणापत्र</td>
                      <td>
                        <a
                          href={data.self_attestation_declaration}
                          target="_blank"
                          className="btn btn-success btn-sm"
                        >
                          View
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        यापैकी कोणतेही दोन पुरावे (पाणी बिल / वीजबिल / मालमत्ता
                        कर पावती / वास्तव्याचा करारनामा / रेशनकार्ड)
                      </td>
                      <td>
                        <a
                          href={data.proof1}
                          target="_blank"
                          className="btn btn-success btn-sm"
                        >
                          View
                        </a>
                        <br />
                        <a
                          href={data.proof2}
                          target="_blank"
                          className="btn btn-success btn-sm"
                        >
                          View
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <UserApprovalTable data={data} />

              <div className="form-check my-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="termsCheck"
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="termsCheck">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ea,
                  sint iste? Rerum adipisci debitis dolore, nostrum excepturi
                  laboriosam neque autem?
                </label>
              </div>

              <button
                className="btn btn-info"
                onClick={() => updateStatus("Approve")}
                disabled={
                  data.permission_status == "Approve" ||
                  data.permission_status == "Rejected"
                }
              >
                {data.permission_status === "Raised" ? "Approve" : "Approved"}
              </button>
              <button
                className="btn btn-danger ms-2"
                onClick={handleShowModal}
                disabled={
                  data.permission_status == "Approve" ||
                  data.permission_status == "Rejected"
                }
              >
                Reject
              </button>
            </>
          ) : (
            "Loading..."
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for file upload */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Please Give Reject Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <input
              type="text"
              name="rejection_reason"
              className="form-control"
              onChange={formik.handleChange}
              value={formik.values.rejection_reason}
            />
            {formik.errors.rejection_reason ? (
              <p className="text-danger">{formik.errors.rejection_reason}</p>
            ) : null}
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                // onClick={() => updateStatus("REJECTED")}
              >
                Submit
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SchemeOneUserModal;
