import { removeUserToken } from "../../common";
import { userTokenName } from "../../config";

const getUserFromLocalStorage = () => {
    try {
        const user = JSON.parse(localStorage.getItem(userTokenName)); // Changed to localStorage
        if (user && user.authToken && user.loggedInTime) {
            console.log("Returning User:", user);
            return user;
        } else {
            removeUserToken();
            return null;
        }
    } catch (error) {
        removeUserToken();
        return null;
    }
};

const getInitial = () => {
    return getUserFromLocalStorage();
};

const userReducer = (state = getInitial(), action) => {
    switch (action.type) {
        case "LOGIN_USER":
            state = {};
            const time = action.payload.loggedInTime;
            if (time) {
                state.loggedInTime = time;
            } else {
                state.loggedInTime = Date.now();
            }
            state.authToken = "Bearer" + " " + action.payload.authToken;
            localStorage.setItem(userTokenName, JSON.stringify(state)); // Changed to localStorage
            return state;
        case "LOGOUT_USER":
            removeUserToken();
            return null;
        default:
            return state;
    }
};

export default userReducer;
