import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { useSelectAccess } from "../store/stateFunctions";
import ChananiSamiti2Sidebar from "../chananisamiti2/ChananiSamiti2Sidebar";

const ChananiSamiti2Container = () => {
  document.title = "Chanani Samiti";
  const chananisamiti2 = useSelectAccess("Chanani Samiti2");

  return (
    <>
      <div className="row ">
        <ChananiSamiti2Sidebar role="Chanani Samiti2" />
        <div className="container">
          {chananisamiti2 ? <Outlet /> : <Navigate to="/chanani-samiti2" />}
          {/* <Outlet /> */}
        </div>
      </div>
    </>
  );
};

export default ChananiSamiti2Container;
