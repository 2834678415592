import React, { useState } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
} from "@mui/material";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import doc1 from "../../assets/doc/declaration1.pdf"
const DeclarationForm = () => {
  const [selectedScheme, setSelectedScheme] = useState("");

  // Updated array with 10 schemes
  const schemes = [
    {
      name: "Scheme A",
      documents: [
        {
          name: "Self Declaration Form",
          url: {doc1},
        },
        { name: "Self-Attested Declaration Form", url: "/docs/schemeA/application.pdf" },
      ],
    },
    {
      name: "Scheme B",
      documents: [
        {
          name: "Self Declaration Form",
          url: "/docs/schemeB/self-declaration.pdf",
        },
        {
          name: "Income Certificate Form",
          url: "/docs/schemeB/income-certificate.pdf",
        },
      ],
    },
    {
      name: "Scheme C",
      documents: [
        {
          name: "Self Declaration Form",
          url: "/docs/schemeC/self-declaration.pdf",
        },
        {
          name: "Medical Certificate Form",
          url: "/docs/schemeC/medical-certificate.pdf",
        },
      ],
    },
    {
      name: "Scheme D",
      documents: [
        {
          name: "Self Declaration Form",
          url: "/docs/schemeD/self-declaration.pdf",
        },
        {
          name: "Income Certificate Form",
          url: "/docs/schemeD/income-certificate.pdf",
        },
      ],
    },
    {
      name: "Scheme E",
      documents: [
        {
          name: "Self Declaration Form",
          url: "/docs/schemeE/self-declaration.pdf",
        },
        {
          name: "Tax Returns Form",
          url: "/docs/schemeE/tax-returns.pdf",
        },
      ],
    },
    {
      name: "Scheme F",
      documents: [
        {
          name: "Self Declaration Form",
          url: "/docs/schemeF/self-declaration.pdf",
        },
        {
          name: "Application Form",
          url: "/docs/schemeF/application.pdf",
        },
      ],
    },
    {
      name: "Scheme G",
      documents: [
        {
          name: "Self Declaration Form",
          url: "/docs/schemeG/self-declaration.pdf",
        },
        {
          name: "Proof of Residency Form",
          url: "/docs/schemeG/proof-residency.pdf",
        },
      ],
    },
    {
      name: "Scheme H",
      documents: [
        {
          name: "Self Declaration Form",
          url: "/docs/schemeH/self-declaration.pdf",
        },
        {
          name: "Financial Statement Form",
          url: "/docs/schemeH/financial-statement.pdf",
        },
      ],
    },
    {
      name: "Scheme I",
      documents: [
        {
          name: "Self Declaration Form",
          url: "/docs/schemeI/self-declaration.pdf",
        },
        {
          name: "Income Statement Form",
          url: "/docs/schemeI/income-statement.pdf",
        },
      ],
    },
    {
      name: "Scheme J",
      documents: [
        {
          name: "Self Declaration Form",
          url: "/docs/schemeJ/self-declaration.pdf",
        },
        {
          name: "Medical Report Form",
          url: "/docs/schemeJ/medical-report.pdf",
        },
      ],
    },
  ];

  const selectedSchemeDocuments = schemes.find(
    (scheme) => scheme.name === selectedScheme
  );

  return (
    <div className="container-fluid mt-4">
      <div className="row">
        <div>
          <div className="row">
            <div className="col-md-6">
              <Typography variant="h4" gutterBottom>
                Download Required Documents
              </Typography>
            </div>

            <div className="col-md-6">
              <FormControl fullWidth>
                <InputLabel id="scheme-select-label">Select a scheme</InputLabel>
                <Select
                  labelId="scheme-select-label"
                  value={selectedScheme}
                  onChange={(e) => setSelectedScheme(e.target.value)}
                  label="Select a scheme"
                >
                  <MenuItem value="">
                    <em>Select a scheme</em>
                  </MenuItem>
                  {schemes.map((scheme) => (
                    <MenuItem key={scheme.name} value={scheme.name}>
                      {scheme.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Show the documents for the selected scheme */}
          {selectedScheme && selectedSchemeDocuments && (
            <div className="mt-4">
              <Typography variant="h6" gutterBottom>
                Available Documents for {selectedScheme}
              </Typography><hr/>
              <TableContainer component={Paper}>
                <Table className="table table-bordered table-striped">
                  <TableHead>
                    <TableRow>
                      <TableCell>Document Name</TableCell>
                      <TableCell>Download Link</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedSchemeDocuments.documents.map((doc, index) => (
                      <TableRow key={index}>
                        <TableCell>{doc.name}</TableCell>
                        <TableCell>
                          <Button variant="contained" color="primary">
                            <a
                              href={doc.url}
                              className="text-decoration-none text-white"
                              download // This attribute forces the file to be downloaded
                            >
                              Download
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeclarationForm;
