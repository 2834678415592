import axios from "axios";
import {
  BACKEND_SERVER_URL,
  userTokenName,
  superAdminTokenName,
  deputyCommTokenName,
  AddCommiTokenName,
  shaskiysamityTokenName,
  chananisamitiTokenName,
  chananisamitiTokenName1,
  chananisamitiTokenName2,
  chananisamitiTokenName3,
  chananisamitiTokenName4,
  shaskiysamityTokenName1,
  shaskiysamityTokenName2,
  shaskiysamityTokenName3,
  shaskiysamityTokenName4,
  shaskiysamityTokenName5,
  clerkUserTokenName1,
  clerkUserTokenName2,
  clerkUserTokenName3,
} from "./config";

export const server = axios.create({
  baseURL: BACKEND_SERVER_URL,
  proxy: BACKEND_SERVER_URL,
});

export const removeUserToken = () => {
  return localStorage.removeItem(userTokenName);
};

export const removeSuperAdminToken = () => {
  return sessionStorage.removeItem(superAdminTokenName);
};

export const removeAddCommiUserToken = () => {
  return sessionStorage.removeItem(AddCommiTokenName);
};

export const removeDeputyCommToken = () => {
  return sessionStorage.removeItem(deputyCommTokenName);
};

export const removeChananisamitiUserToken = () => {
  return sessionStorage.removeItem(chananisamitiTokenName);
};

export const removeChananisamiti1UserToken = () => {
  return sessionStorage.removeItem(chananisamitiTokenName1);
};

export const removeChananisamiti2UserToken = () => {
  return sessionStorage.removeItem(chananisamitiTokenName2);
};
export const removeChananisamiti3UserToken = () => {
  return sessionStorage.removeItem(chananisamitiTokenName3);
};
export const removeChananisamiti4UserToken = () => {
  return sessionStorage.removeItem(chananisamitiTokenName4);
};

export const removeShaskiySamitiToken = () => {
  return sessionStorage.removeItem(shaskiysamityTokenName);
};

export const removeShaskiySamiti1Token = () => {
  return sessionStorage.removeItem(shaskiysamityTokenName1);
};

export const removeShaskiySamiti2Token = () => {
  return sessionStorage.removeItem(shaskiysamityTokenName2);
};

export const removeShaskiySamiti3Token = () => {
  return sessionStorage.removeItem(shaskiysamityTokenName3);
};

export const removeShaskiySamiti4Token = () => {
  return sessionStorage.removeItem(shaskiysamityTokenName4);
};

export const removeShaskiySamiti5Token = () => {
  return sessionStorage.removeItem(shaskiysamityTokenName5);
};

export const removeClerk1UserToken = () => {
  return sessionStorage.removeItem(clerkUserTokenName1);
};

export const removeClerk2UserToken = () => {
  return sessionStorage.removeItem(clerkUserTokenName2);
};

export const removeClerk3UserToken = () => {
  return sessionStorage.removeItem(clerkUserTokenName3);
};

// export const getIndustryUserToken = () => {
//   return sessionStorage.setItem(industryUserTokenName);
// };

export const blobToFile = (data) => {
  return data ? window.URL.createObjectURL(data) : null;
};

export const truncateText = (text, limit) => {
  const words = text.split(" ");
  if (words.length > limit) {
    return words.slice(0, limit).join(" ") + " ...";
  }
  return text;
};

export const statusMessage = (app, role) => {
  switch (role) {
    case "Clerk1":
      return app?.statusbyclerk;
    case "Clerk2":
      return app?.statusbyclerk;
    case "Clerk3":
      return app?.statusbyclerk;
    case "Deputy Commissioner":
      return app?.statusbydeputy;
    case "Chanani Samiti1":
      return app?.statusbychanani1;
    case "Chanani Samiti2":
      return app?.statusbychanani2;
    case "Chanani Samiti3":
      return app?.statusbychanani3;
    case "Chanani Samiti4":
      return app?.statusbychanani4;
    case "Additional Commissioner":
      return app?.statusbyadditional;
    case "Shaskiy Samiti1":
      return app?.statusbyshaskiya1;
    case "Shaskiy Samiti2":
      return app?.statusbyshaskiya2;
    case "Shaskiy Samiti3":
      return app?.statusbyshaskiya3;
    case "Shaskiy Samiti4":
      return app?.statusbyshaskiya4;
    case "Shaskiy Samiti5":
      return app?.statusbyshaskiya5;
    case "Super Admin":
      return app?.permission_status;
    default:
      return "No Status Available";
  }
};

export const isDisabled = (data, role) => {
  // if (!isChecked) return true; // Disable if not checked

  switch (role) {
    case "Clerk1":
      return (
        data?.statusbyclerk === "Approve" || data?.statusbyclerk === "Rejected"
      );
    case "Clerk2":
      return (
        data?.statusbyclerk === "Approve" || data?.statusbyclerk === "Rejected"
      );
    case "Clerk3":
      return (
        data?.statusbyclerk === "Approve" || data?.statusbyclerk === "Rejected"
      );
    case "Deputy Commissioner":
      return (
        data?.statusbydeputy === "Approve" ||
        data?.statusbydeputy === "Rejected"
      );
    case "Chanani Samiti1":
      return (
        data?.statusbychanani1 === "Approve" ||
        data?.statusbychanani1 === "Rejected"
      );
    case "Chanani Samiti2":
      return (
        data?.statusbychanani2 === "Approve" ||
        data?.statusbychanani2 === "Rejected"
      );
    case "Chanani Samiti3":
      return (
        data?.statusbychanani3 === "Approve" ||
        data?.statusbychanani3 === "Rejected"
      );
    case "Chanani Samiti4":
      return (
        data?.statusbychanani4 === "Approve" ||
        data?.statusbychanani4 === "Rejected"
      );
    case "Additional Commissioner":
      return (
        data?.statusbyadditional === "Approve" ||
        data?.statusbyadditional === "Rejected"
      );
    case "Shaskiy Samiti1":
      return (
        data?.statusbyshaskiya1 === "Approve" ||
        data?.statusbyshaskiya1 === "Rejected"
      );
    case "Shaskiy Samiti2":
      return (
        data?.statusbyshaskiya2 === "Approve" ||
        data?.statusbyshaskiya2 === "Rejected"
      );
    case "Shaskiy Samiti3":
      return (
        data?.statusbyshaskiya3 === "Approve" ||
        data?.statusbyshaskiya3 === "Rejected"
      );
    case "Shaskiy Samiti4":
      return (
        data?.statusbyshaskiya4 === "Approve" ||
        data?.statusbyshaskiya4 === "Rejected"
      );
    case "Shaskiy Samiti5":
      return (
        data?.statusbyshaskiya5 === "Approve" ||
        data?.statusbyshaskiya5 === "Rejected"
      );
    case "Super Admin":
      return (
        data?.permission_status === "Approve" ||
        data?.permission_status === "Rejected"
      );
    default:
      return false;
  }
};
