import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { useSelectAccess } from "../store/stateFunctions";
import ShaskiySamiti2Sidebar from "../shaskiysamiti2/ShaskiySamiti2Sidebar";
// import { Outlet } from "react-router-dom";

const ShaskiySamiti2Container = () => {
  document.title = "Shaskiy Samiti";
  const shaskiysamiti2 = useSelectAccess("Shaskiy Samiti2");

  return (
    <>
      <div className="row ">
        <ShaskiySamiti2Sidebar role="Shaskiy Samiti2" />
        <div className="container">
          {shaskiysamiti2 ? <Outlet /> : <Navigate to="/shaskiy-samiti2" />}
          {/* <Outlet /> */}
        </div>
      </div>
    </>
  );
};

export default ShaskiySamiti2Container;
