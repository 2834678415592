import React from "react";
import SchemeFourTotalApplications from "../../Administration/SchemeFour/SchemeFourTotalApplications";

const ShaskiySamitiSchemeFourTotalApplications = () => {
    return (
        <div>
            <SchemeFourTotalApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeFourTotalApplications;
