import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { useSelectAccess } from "../store/stateFunctions";
import ChananiSamiti4Sidebar from "../chananisamiti4/ChananiSamiti4Sidebar";

const ChananiSamiti4Container = () => {
  document.title = "Chanani Samiti";
  const chananisamiti4 = useSelectAccess("Chanani Samiti4");

  return (
    <>
      <div className="row ">
        <ChananiSamiti4Sidebar role="Chanani Samiti4" />
        <div className="container">
          {chananisamiti4 ? <Outlet /> : <Navigate to="/chanani-samiti4" />}
          {/* <Outlet /> */}
        </div>
      </div>
    </>
  );
};

export default ChananiSamiti4Container;
