import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../Navbar/Navbar.css";
import logo from "../../assets/images/newlogo.png";
import logo2 from "../../assets/images/second-logo.png";
import { Button } from "@mui/material";
import { logoutAccess, useSelectAccess } from "../../store/stateFunctions";
import { useTranslation } from "react-i18next";

function MyNavbar() {
  const user = useSelectAccess("User");
  const { t, i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <>
      <div className="header-section">
        <div className="logo ">
          <img src={logo} alt="Logo" className="logo-img1" />
        </div>
        <div className="header-text">
          <h2>{t("header.title")}</h2>
          <h3>{t("header.department")}</h3>
        </div>

        <div className="second-logo">
          <img src={logo2} alt="Logo" className="logo-img2" />
        </div>
      </div>
      <div className="navbar-container">
        <Navbar expand="lg" className="sticky-top smooth-navbar">
          <Container fluid>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="navbaritems mx-auto">
                <Nav.Link
                  className="text-white text-decoration-none"
                  as={Link}
                  to="/"
                >
                  {t("navbar.home")}
                </Nav.Link>
                <Nav.Link
                  className="text-white text-decoration-none"
                  as={Link}
                  to="/allschemespage"
                >
                  {t("navbar.schemes")}
                </Nav.Link>
                <Nav.Link
                  className="text-white text-decoration-none"
                  as={Link}
                  to="/aboutus"
                >
                  {t("navbar.aboutus")}
                </Nav.Link>
                <Nav.Link
                  className="text-white text-decoration-none"
                  as={Link}
                  to="/news"
                >
                  {t("navbar.news")}
                </Nav.Link>
                {/* <Nav.Link
                  className="text-white text-decoration-none"
                  as={Link}
                  to="/profile/trackapplication"
                >
                  {t("navbar.applicationStatus")}
                </Nav.Link> */}
                {user && (
                  <Nav.Link
                    className="text-white text-decoration-none"
                    as={Link}
                    to="/profile/profilepage"
                  >
                    {t("navbar.profile")}
                  </Nav.Link>
                )}
              </Nav>

              {/* {user && (
                <Button
                  onClick={() => logoutAccess("User")}
                  variant="contained"
                  color="error"
                  className="me-2"
                >
                  {t("navbar.logout")}
                </Button>
              )} */}
              <div className="auth-buttons">
                {!user && (
                  <Link to="/login" className="text-decoration-none me-3 ">
                    <Button
                      variant="contained"
                      color="primary"
                      className="whitebutton"
                    >
                      Login
                    </Button>
                  </Link>
                )}
                {!user && (
                  <Link to="/registration">
                    <Button variant="contained" className="redbutton">
                      Register
                    </Button>
                  </Link>
                )}

                <select
                  name="language"
                  id="language-select"
                  onChange={(event) => changeLanguage(event.target.value)}
                  className="ms-2"
                >
                  <option value="en"> Language</option>
                  <option value="en">English</option>
                  <option value="mr">Marathi</option>
                </select>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default MyNavbar;
