import superAdminReducer from "./superAdminReducers.js";
import deputyCommReducers from "./deputyCommReducers.js";
import additionalCommissionerReducer from "./additionalCommissionerReducer.js";
import chananiSamitiReducer from "./chananiSamitiReducer.js";
import shaskiySamityReducer from "./shaskiySamityReducer.js";
import userReducer from "./userReducer.js";
import chananiSamiti1Reducer from "./chananiSamiti1Reducer.js";
import chananiSamiti2Reducer from "./chananiSamiti2Reducer.js";
import chananiSamiti3Reducer from "./chananiSamiti3Reducer.js";
import chananiSamiti4Reducer from "./chananiSamiti4Reducer.js";
import shaskiySamity1Reducer from "./shaskiySamity1Reducer.js";
import shaskiySamity2Reducer from "./shaskiySamity2Reducer.js";
import shaskiySamity3Reducer from "./shaskiySamity3Reducer.js";
import shaskiySamity4Reducer from "./shaskiySamity4Reducer.js";
import shaskiySamity5Reducer from "./shaskiySamity5Reducer.js";
import clerk1UserReducers from "./clerk1UserReducers.js";
import clerk2UserReducers from "./clerk2UserReducers.js";
import clerk3UserReducers from "./clerk3UserReducers.js";

const storeReducer = {
  deputycomm: deputyCommReducers,
  superadmin: superAdminReducer,
  user: userReducer,
  addcomm: additionalCommissionerReducer,

  chananisamiti: chananiSamitiReducer,
  chananisamiti1: chananiSamiti1Reducer,
  chananisamiti2: chananiSamiti2Reducer,
  chananisamiti3: chananiSamiti3Reducer,
  chananisamiti4: chananiSamiti4Reducer,

  shaskiysamiti: shaskiySamityReducer,
  shaskiysamiti1: shaskiySamity1Reducer,
  shaskiysamiti2: shaskiySamity2Reducer,
  shaskiysamiti3: shaskiySamity3Reducer,
  shaskiysamiti4: shaskiySamity4Reducer,
  shaskiysamiti5: shaskiySamity5Reducer,

  clerk1: clerk1UserReducers,
  clerk2: clerk2UserReducers,
  clerk3: clerk3UserReducers,
};

export default storeReducer;
