import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { useSelectAccess } from "../store/stateFunctions";
import ChananiSamitiSidebar from "../chananisamiti/ChananiSamitiSidebar";
// import { Outlet } from "react-router-dom";

const ChananiSamitiContainer = () => {
  document.title = "Chanani Samiti";
  const chananisamiti = useSelectAccess("Chanani Samiti");

  return (
    <>
      <div className="row ">
        <ChananiSamitiSidebar role="Chanani Samiti" />
        <div className="container">
          {chananisamiti ? <Outlet /> : <Navigate to="/chanani-samiti" />}
          {/* <Outlet /> */}
        </div>
      </div>
    </>
  );
};

export default ChananiSamitiContainer;
