import React from "react";
import SchemeFourapprovedapplications from "../../Administration/SchemeFour/SchemeFourapprovedapplications";

const ShaskiySamitiSchemeFourApprovedApplications = () => {
    return (
        <div>
            <SchemeFourapprovedapplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeFourApprovedApplications;
