import React from "react";
import Dashboard from "../Administration/dashboard/TableDashboard/Dashboard";

const Clerk2Dashboard = () => {
  return (
    <>
      <h1>
        <Dashboard />
      </h1>
    </>
  );
};

export default Clerk2Dashboard;
