export const loginChananisamiti4 = (session) => {
  return {
    type: "LOGIN_CHANANI_SAMITY4",
    payload: session,
  };
};

export const logoutChananisamiti4 = () => {
  return {
    type: "LOGOUT_CHANANI_SAMITY4",
  };
};
