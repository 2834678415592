import React from "react";

const ShaskiySamitiDashboard = () => {
  return (
    <>
      <h1>Hello</h1>
    </>
  );
};

export default ShaskiySamitiDashboard;
