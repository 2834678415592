import React from "react";
import Dashboard from "../Administration/dashboard/TableDashboard/Dashboard";

const ChananiSamiti2Dashboard = () => {
  return (
    <>
      <h1>
        <Dashboard />
      </h1>
    </>
  );
};

export default ChananiSamiti2Dashboard;
