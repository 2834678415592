export const loginclerk2 = (session) => {
  return {
    type: "LOGIN_CLERK2",
    payload: session,
  };
};
export const logoutclerk2 = () => {
  return {
    type: "LOGOUT_CLERK2",
  };
};
