import React from "react";
import SchemeThreeTotalApplications from "../../Administration/SchemeThree/SchemeThreeTotalApplications";

const ShaskiySamitiSchemeThreeTotalApplications = () => {
    return (
        <div>
            <SchemeThreeTotalApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeThreeTotalApplications;
