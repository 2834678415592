export const loginclerk1 = (session) => {
  return {
    type: "LOGIN_CLERK1",
    payload: session,
  };
};
export const logoutclerk1 = () => {
  return {
    type: "LOGOUT_CLERK1",
  };
};
