import React from "react";
import SchemeFiveApprovedApplications from "../../Administration/SchemeFive/SchemeFiveApprovedApplications";

const ShaskiySamitiSchemeFiveApprovedApplications = () => {
    return (
        <div>
            <SchemeFiveApprovedApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeFiveApprovedApplications;
