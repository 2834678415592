import React, { useState } from "react";
import { Container, Grid, Button, Typography, Paper } from "@mui/material";
import { useTransition } from "react";
import { useTranslation } from "react-i18next";
// import brochure from "../../assets/documents/Financial Aid for Severely Mentally Retarded and Multi-Handicapped Individuals (70-100% Disability).pdf";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelectAccess } from "../../store/stateFunctions";
import { toast } from "react-toastify";

const SchemeNineDetails = () => {
  const { t } = useTranslation();
  const user = useSelectAccess("User");
  const isLoggedIn = user && user;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleApplyNowClick = (e) => {
    if (!isLoggedIn) {
      e.preventDefault(); // Prevent navigation
      toast.info("Please log in to apply for the Scheme");
      navigate("/login");
    } else {
      setShow(true);
    }
  };

  return (
    <div className="container-fluid">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper
            style={{
              backgroundColor: "#ffffff",
              padding: "30px",
              border: "1px solid #e0e0e0",
            }}
          >
            <Typography
              variant="h4"
              component="div"
              style={{ fontWeight: "bold", marginBottom: "20px" }}
              className="text-dark"
            >
              {t("schemeNineDetails.title")}
            </Typography>
            <Typography className="text-dark" variant="body1" component="div">
              <ul
                style={{
                  listStyleType: "disc",
                  paddingLeft: "20px",
                  marginBottom: "20px",
                }}
              >
                <li>{t("schemeNineDetails.point1")}</li>
                <li>{t("schemeNineDetails.point2")}</li>
                <li>{t("schemeNineDetails.point3")}</li>
                <li>{t("schemeNineDetails.point4")}</li>
                <li>{t("schemeNineDetails.point5")}</li>
                <li>{t("schemeNineDetails.point6")}</li>
              </ul>
            </Typography>
            <div>
              {/* <Button
                variant="contained"
                className="bluebutton"
                href={brochure}
                download
                style={{ marginRight: "10px" }}
              >
                {t("viewdetails.scheme1.downloadbrochure")}
              </Button> */}
              <NavLink to="/schemeeight" onClick={handleApplyNowClick}>
                {" "}
                <Button
                  variant="contained"
                  className="redbutton"
                  color="secondary"
                >
                  {t("schemeEightDetails.applyNow")}
                </Button>
              </NavLink>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default SchemeNineDetails;
