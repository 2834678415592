import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { useSelectAccess } from "../store/stateFunctions";
import ShaskiySamiti1Sidebar from "../shaskiysamiti1/ShaskiySamiti1Sidebar";
// import { Outlet } from "react-router-dom";

const ShaskiySamiti1Container = () => {
  document.title = "Shaskiy Samiti";
  const shaskiysamiti1 = useSelectAccess("Shaskiy Samiti1");

  return (
    <>
      <div className="row ">
        <ShaskiySamiti1Sidebar role="Shaskiy Samiti1" />
        <div className="container">
          {shaskiysamiti1 ? <Outlet /> : <Navigate to="/shaskiy-samiti1" />}
          {/* <Outlet /> */}
        </div>
      </div>
    </>
  );
};

export default ShaskiySamiti1Container;
