import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { dark } from "@mui/material/styles/createPalette";
import { useParams } from "react-router-dom";
import { server } from "../../../common";
import { useSelectAccess } from "../../../store/stateFunctions";

const FinalPermission = () => {
  const params = useParams();
  const [permission, setPermission] = useState(null);
  const user = useSelectAccess("User");

  const { id } = params;

  useEffect(() => {
    getdata();
  }, [params]);

  const getdata = () => {
    server
      .get(`/dashboard/getsingleappl/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200 || response.status === 201) {
          setPermission(response.data);
          console.log(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div
      className="container my-5 "
      style={{ border: "2px solid black", padding: "25px" }}
    >
      <div className="d-flex flex-column align-items-center">
        <div>
          <h2>नाशिक महानगर पालिका, नाशिक </h2>
        </div>
        <div>
          <p className="text-dark">
            राज्यस्तरीय स्पर्धेसाठी प्राविण्य मिळवलेले अशा मनपा कार्यक्षेत्रातील
            खेळाडूंना शिष्यवृत्ती अर्थसहाय्य योजना
          </p>
        </div>
      </div>
      <hr />
      <div className="row align-items-center">
        <div className="col-md-9">
          <div
            style={{
              display: "flex",

              flexDirection: "column",
              margin: "0 auto",
              paddingRight: "20px",
            }}
          >
            <label htmlFor="name">अर्जदाराचे नांव :</label>
            <p>{permission?.fullname}</p>

            <div
              style={{
                display: "flex",

                justifyContent: "space-between",
                marginTop: "16px",
              }}
            >
              <div style={{ display: "flex" }}>
                <label htmlFor="phone">मोबाईल नंबर :</label>
                <p style={{ marginLeft: "10px" }}>{permission?.mobile}</p>
              </div>

              <div style={{ display: "flex" }}>
                <label htmlFor="address">राहणार :</label>
                <p style={{ marginLeft: "10px" }}>{permission?.address}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3 text-center">
          <img
            src="https://bootdey.com/img/Content/avatar/avatar7.png"
            alt="Admin"
            className="square"
            width="160"
            style={{
              height: "180px",
              border: "3px solid black",
              borderRadius: "4px",
              margin: "0 auto",
            }}
          />
        </div>
      </div>
      <hr />
      <h5>• Documents Upload </h5>
      <table className="table table-striped table-bordered mt-4 ">
        <thead className="thead-light" style={{ textAlign: "left" }}>
          <tr>
            <th style={{ textAlign: "left" }}>अ.क्र</th>
            <th style={{ textAlign: "left" }}>कागदपत्राचे तपशील</th>
            <th style={{ textAlign: "left" }}>सादर केलेले कागदपत्रे</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td style={{ textAlign: "left" }}>अपंगत्वाचे प्रमाणपत्र</td>
            <td></td>
          </tr>
          <tr>
            <td>2</td>
            <td style={{ textAlign: "left" }}>
              हॉस्पीटलच्या वैद्यकीय अधिका-याचे पत्र
            </td>
            <td></td>
          </tr>

          <tr>
            <td>3</td>
            <td style={{ textAlign: "left" }}>
              स्वयं साक्षांकनासाठी स्वयं घोषणापत्र
            </td>
            <td></td>
          </tr>
          <tr>
            <td>4</td>
            <td style={{ textAlign: "left" }}>स्वयंघोषणापत्र </td>
            <td></td>
          </tr>
          <tr>
            <td>5</td>
            <td style={{ textAlign: "left" }}>आधार कार्ड तथा पावती</td>
            <td></td>
          </tr>
          <tr>
            <td>6</td>
            <td style={{ textAlign: "left" }}>बँक पासबूक</td>
            <td></td>
          </tr>
          <tr>
            <td>7</td>
            <td style={{ textAlign: "left" }}>पॅन कार्ड</td>
            <td></td>
          </tr>
          <tr>
            <td>8</td>
            <td style={{ textAlign: "left" }}>
              {" "}
              कोणतेही दोन पुरावे (पाणी बिल / वीजबिल / मालमत्ता कर पावती /
              वास्तव्याचा करारनामा / रेशनकार्ड)
            </td>
            <td></td>
          </tr>
          <tr>
            <td>9</td>
            <td style={{ textAlign: "left" }}>proof2</td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <hr />
      <h5> • Permissions </h5>
      <table className="table table-striped table-bordered mt-4 ">
        <thead className="thead-light" style={{ textAlign: "left" }}>
          <tr>
            <th style={{ textAlign: "left" }}>अ.क्र</th>
            <th style={{ textAlign: "left" }}>Users</th>
            <th style={{ textAlign: "left" }}>Approved</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td style={{ textAlign: "left" }}>Clerk</td>
            <td>
              {permission?.statusbyclerk === "Approve" ? (
                <span className="text-success">&#10004;</span>
              ) : (
                <span className="text-danger">&#10060;</span>
              )}{" "}
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td style={{ textAlign: "left" }}>Deputy Commissioner</td>
            <td>
              {permission?.statusbydeputy === "Approve" ? (
                <span className="text-success">&#10004;</span>
              ) : (
                <span className="text-danger">&#10060;</span>
              )}
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td style={{ textAlign: "left" }}>Chanani 1</td>
            <td>
              {permission?.statusbychanani1 === "Approve" ? (
                <span className="text-success">&#10004;</span>
              ) : (
                <span className="text-danger">&#10060;</span>
              )}
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td style={{ textAlign: "left" }}>Chanani 2 </td>
            <td>
              {permission?.statusbychanani2 === "Approve" ? (
                <span className="text-success">&#10004;</span>
              ) : (
                <span className="text-danger">&#10060;</span>
              )}
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td style={{ textAlign: "left" }}>Chanani 3 </td>
            <td>
              {permission?.statusbychanani3 === "Approve" ? (
                <span className="text-success">&#10004;</span>
              ) : (
                <span className="text-danger">&#10060;</span>
              )}
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td style={{ textAlign: "left" }}>Chanani 4 </td>
            <td>
              {permission?.statusbychanani4 === "Approve" ? (
                <span className="text-success">&#10004;</span>
              ) : (
                <span className="text-danger">&#10060;</span>
              )}
            </td>
          </tr>

          <tr>
            <td>8</td>
            <td style={{ textAlign: "left" }}> Additional Commissioner</td>
            <td>
              {permission?.statusbyadditional === "Approve" ? (
                <span className="text-success">&#10004;</span>
              ) : (
                <span className="text-danger">&#10060;</span>
              )}
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td style={{ textAlign: "left" }}>Shaskiy Samiti 1</td>
            <td>
              {permission?.statusbyshaskiya1 === "Approve" ? (
                <span className="text-success">&#10004;</span>
              ) : (
                <span className="text-danger">&#10060;</span>
              )}
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td style={{ textAlign: "left" }}>Shaskiy Samiti 2</td>
            <td>
              {permission?.statusbyshaskiya2 === "Approve" ? (
                <span className="text-success">&#10004;</span>
              ) : (
                <span className="text-danger">&#10060;</span>
              )}
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td style={{ textAlign: "left" }}>Shaskiy Samiti 3</td>
            <td>
              {permission?.statusbyshaskiya3 === "Approve" ? (
                <span className="text-success">&#10004;</span>
              ) : (
                <span className="text-danger">&#10060;</span>
              )}
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td style={{ textAlign: "left" }}>Shaskiy Samiti 4</td>
            <td>
              {permission?.statusbyshaskiya4 === "Approve" ? (
                <span className="text-success">&#10004;</span>
              ) : (
                <span className="text-danger">&#10060;</span>
              )}
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td style={{ textAlign: "left" }}>Shaskiy Samiti 5</td>
            <td>
              {permission?.statusbyshaskiya5 === "Approve" ? (
                <span className="text-success">&#10004;</span>
              ) : (
                <span className="text-danger">&#10060;</span>
              )}
            </td>
          </tr>
        </tbody>
      </table>

      <div>
        {/* <div className="row mt-4">
          <div className="col-md-4 d-flex flex-column justify-content-center align-items-center">
            <p className="text-dark">१.कागदपत्रांची पूर्तता केली आहे.</p>
            <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
              <p className="text-dark">क्रीडा अधिकारी ( सदस्य ) </p>
              <p className="text-dark"> नाशिक महानगर पालिका , नाशिक </p>
            </div>
          </div>

          <div className="col-md-4 d-flex flex-column justify-content-center align-items-center">
            <p className="text-dark">२.अपूर्ण कागदपत्रांची पूर्तता करून घेणे</p>
            <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
              <p className="text-dark">उपलेखा अधिकारी(सदस्य ) </p>
              <p className="text-dark"> नाशिक महानगर पालिका , नाशिक </p>
            </div>
          </div>

          <div className="col-md-4 d-flex flex-column justify-content-center align-items-center">
            <p className="text-dark">३.अर्ज निकाली काढण्यात आला आहे .</p>
            <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
              <p className="text-dark">उपलेखा परीक्षक (सदस्य ) </p>
              <p className="text-dark"> नाशिक महानगर पालिका , नाशिक</p>
            </div>
          </div>
        </div> */}

        <div className="row mt-4 d-flex align-items-center">
          <div className="col-md-6 mt-5 d-flex flex-column justify-content-center align-items-center">
            <p className="text-dark">जिल्हा क्रीडा अधिकारी (विशेष सदस्य ) </p>
            <p className="text-dark">अध्यक्ष महाराष्ट्र्र शासन </p>
          </div>
          <div className="col-md-6 mt-5 d-flex flex-column justify-content-center align-items-center">
            <p className="text-dark">उपआयुक्त (समाजकल्याण ) </p>
            <p className="text-dark">अध्यक्ष नाशिक महानगर पालिका , नाशिक</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalPermission;
