import { Table } from "react-bootstrap";

const UserApprovalTable = ({ data }) => {
  return (
    <>
      <div style={{ overflowX: "scroll" }}>
        <Table bordered striped>
          <thead>
            <tr>
              <th colSpan="6" className="text-dark">
                Application Status
              </th>
            </tr>
            <tr>
              <th>Department</th>
              <th>Is Approved?</th>
              <th>Approved By</th>
              <th>Approved Date/Time</th>
              <th>Status</th>
              <th>Reject Reason</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Clerk</td>
              <td className="text-center">
                {data.statusbyclerk === "Approve" ? (
                  <span className="text-success">&#10004;</span>
                ) : (
                  <span className="text-danger">&#10060;</span>
                )}
                {/* add */}
              </td>
              <td>{data.uploadedbyclerk}</td>
              <td>
                {data.uploaddateclerk
                  ? new Date(data.uploaddateclerk).toLocaleDateString()
                  : "-"}
              </td>
              <td>{data.statusbyclerk}</td>

              <td>{data.reasonbyclerk ? data.reasonbyclerk : "-"}</td>
            </tr>
            <tr>
              <td>Deputy Commissioner</td>
              <td className="text-center">
                {data.statusbydeputy === "Approve" ? (
                  <span className="text-success">&#10004;</span>
                ) : (
                  <span className="text-danger">&#10060;</span>
                )}
              </td>
              <td>{data.uploadedbydeputy}</td>
              <td>
                {" "}
                {data.uploaddatedeputy
                  ? new Date(data.uploaddatedeputy).toLocaleDateString()
                  : "-"}
              </td>
              <td>{data.statusbydeputy}</td>

              <td>{data.reasonbydeputy ? data.reasonbydeputy : "-"}</td>
            </tr>
            <tr>
              <td>Chanani Samiti 1</td>
              <td className="text-center">
                {data.statusbychanani1 === "Approve" ? (
                  <span className="text-success">&#10004;</span>
                ) : (
                  <span className="text-danger">&#10060;</span>
                )}
              </td>
              <td>{data.uploadedbychanani1}</td>
              <td>
                {data.uploaddatechanani1
                  ? new Date(data.uploaddatechanani1).toLocaleDateString()
                  : "-"}
              </td>
              <td>{data.statusbychanani1}</td>

              <td>{data.reasonbychanani1 ? data.reasonbychanani1 : "-"}</td>
            </tr>
            <tr>
              <td>Chanani Samiti 2</td>
              <td className="text-center">
                {data.statusbychanani2 === "Approve" ? (
                  <span className="text-success">&#10004;</span>
                ) : (
                  <span className="text-danger">&#10060;</span>
                )}
              </td>
              <td>{data.uploadedbychanani2}</td>
              <td>
                {data.uploaddatechanani2
                  ? new Date(data.uploaddatechanani2).toLocaleDateString()
                  : "-"}
              </td>
              <td>{data.statusbychanani2}</td>

              <td>{data?.reasonbychanani2 ? data.reasonbychanani2 : "-"}</td>
            </tr>
            <tr>
              <td>Chanani Samiti 3</td>
              <td className="text-center">
                {data.statusbychanani3 === "Approve" ? (
                  <span className="text-success">&#10004;</span>
                ) : (
                  <span className="text-danger">&#10060;</span>
                )}
              </td>
              <td>{data.uploadedbychanani3}</td>
              <td>
                {data.uploaddatechanani3
                  ? new Date(data.uploaddatechanani3).toLocaleDateString()
                  : "-"}
              </td>
              <td>{data.statusbychanani3}</td>

              <td>{data?.reasonbychanani3 ? data.reasonbychanani3 : "-"}</td>
            </tr>

            <tr>
              <td>Chanani Samiti 4</td>
              <td className="text-center">
                {data.statusbychanani4 === "Approve" ? (
                  <span className="text-success">&#10004;</span>
                ) : (
                  <span className="text-danger">&#10060;</span>
                )}
              </td>
              <td>{data.uploadedbychanani4}</td>
              <td>
                {data.uploaddatechanani4
                  ? new Date(data.uploaddatechanani4).toLocaleDateString()
                  : "-"}
              </td>
              <td>{data.statusbychanani4}</td>

              <td>{data.reasonbychanani4 ? data.reasonbychanani4 : "-"}</td>
            </tr>

            <tr>
              <td>Additional Commissioner</td>
              <td className="text-center">
                {data.statusbyadditional === "Approve" ? (
                  <span className="text-success">&#10004;</span>
                ) : (
                  <span className="text-danger">&#10060;</span>
                )}
              </td>
              <td>{data.uploadedbyadditional}</td>
              <td>
                {data.uploaddateadditional
                  ? new Date(data.uploaddateadditional).toLocaleDateString()
                  : "-"}
              </td>
              <td>{data.statusbyadditional}</td>

              <td>{data.reasonbyadditional ? data.reasonbyadditional : "-"}</td>
            </tr>
            <tr>
              <td>Shaskiy Samiti 1</td>
              <td className="text-center">
                {data.statusbyshaskiya1 === "Approve" ? (
                  <span className="text-success">&#10004;</span>
                ) : (
                  <span className="text-danger">&#10060;</span>
                )}
              </td>
              <td>{data.uploadedbyshaskiya1}</td>
              <td>
                {data.uploaddateshaskiya1
                  ? new Date(data.uploaddateshaskiya1).toLocaleDateString()
                  : "-"}
              </td>
              <td>{data.statusbyshaskiya1}</td>

              <td>{data.reasonbyshaskiya1 ? data.reasonbyshaskiya1 : "-"} </td>
            </tr>
            <tr>
              <td>Shaskiy Samiti 2</td>
              <td className="text-center">
                {data.statusbyshaskiya2 === "Approve" ? (
                  <span className="text-success">&#10004;</span>
                ) : (
                  <span className="text-danger">&#10060;</span>
                )}
              </td>
              <td>{data.uploadedbyshaskiya2}</td>
              <td>
                {data.uploaddateshaskiya2
                  ? new Date(data.uploaddateshaskiya2).toLocaleDateString()
                  : "-"}
              </td>
              <td>{data.statusbyshaskiya2}</td>

              <td>{data.reasonbyshaskiya2 ? data.reasonbyshaskiya2 : "-"}</td>
            </tr>
            <tr>
              <td>Shaskiy Samiti 3</td>
              <td className="text-center">
                {data.statusbyshaskiya3 === "Approve" ? (
                  <span className="text-success">&#10004;</span>
                ) : (
                  <span className="text-danger">&#10060;</span>
                )}
              </td>
              <td>{data.uploadedbyshaskiya3}</td>
              <td>
                {data.uploaddateshaskiya3
                  ? new Date(data.uploaddateshaskiya3).toLocaleDateString()
                  : "-"}
              </td>
              <td>{data.statusbyshaskiya3}</td>

              <td>{data.reasonbyshaskiya3 ? data.reasonbyshaskiya3 : "-"}</td>
            </tr>
            <tr>
              <td>Shaskiy Samiti 4</td>
              <td className="text-center">
                {data.statusbyshaskiya4 === "Approve" ? (
                  <span className="text-success">&#10004;</span>
                ) : (
                  <span className="text-danger">&#10060;</span>
                )}
              </td>
              <td>{data.uploadedbyshaskiya4}</td>
              <td>
                {data.uploaddateshaskiya4
                  ? new Date(data.uploaddateshaskiya4).toLocaleDateString()
                  : "-"}
              </td>
              <td>{data.statusbyshaskiya4}</td>

              <td>{data.reasonbyshaskiya4 ? data.reasonbyshaskiya4 : "-"}</td>
            </tr>
            <tr>
              <td>Shaskiy Samiti 5</td>
              <td className="text-center">
                {data.statusbyshaskiya5 === "Approve" ? (
                  <span className="text-success">&#10004;</span>
                ) : (
                  <span className="text-danger">&#10060;</span>
                )}
              </td>
              <td>{data.uploadedbyshaskiya5}</td>
              <td>
                {data.uploaddateshaskiya5
                  ? new Date(data.uploaddateshaskiya5).toLocaleDateString()
                  : "-"}
              </td>
              <td>{data.statusbyshaskiya5}</td>
              <td>{data.reasonbyshaskiya5 ? data.reasonbyshaskiya5 : "-"}</td>
            </tr>
            <tr>
              <td>Commissioner</td>
              <td className="text-center">
                {data.permission_status === "Approve" ? (
                  <span className="text-success">&#10004;</span>
                ) : (
                  <span className="text-danger">&#10060;</span>
                )}
              </td>
              <td>{data.finalpermissionuploadby}</td>
              <td>
                {data.uploaddatefinalpermission
                  ? new Date(
                      data.uploaddatefinalpermission
                    ).toLocaleDateString()
                  : "-"}
              </td>
              <td>{data.permission_status}</td>
              <td>{data.rejection_reason ? data.rejection_reason : "-"}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default UserApprovalTable;
