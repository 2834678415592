import React from "react";
import "../Herosection/Herosection.css";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
const HeroSection = () => {
  const {t}=useTranslation()
  return (
    <header className="hero">
      <div className="hero-content">
        <h1>{t("slider.empowering_lives")}</h1>
        <p>
        {t("slider.explore_schemes")}
        </p>
       
       <a href="#/allschemespage" className="bluebutton text-decoration-none ">{t("slider.explore_schemes_button")} </a>
       
      </div>
    </header>
  );
};

export default HeroSection;
