import {
  AiOutlineDashboard,
  AiOutlineUser,
  AiOutlinePaperClip,
  AiFillHdd,
  AiFillPhone,
} from "react-icons/ai";
import { FaBusinessTime, FaBlogger, FaPhotoVideo } from "react-icons/fa";
import { BiMenu, BiPodcast, BiSolidBusiness } from "react-icons/bi";
// import { Outlet } from "react-router-dom";
import { IoIosContact } from "react-icons/io";
import { FaBoltLightning } from "react-icons/fa6";
import { Layout, Menu } from "antd";
import { logoutAccess } from "../store/stateFunctions";
import { Outlet, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { GrUserPolice } from "react-icons/gr";
import { FaTrafficLight } from "react-icons/fa";
import { FaUserShield } from "react-icons/fa";
import { CiUser } from "react-icons/ci";
const { Sider, Content } = Layout;

const ShaskiySamiti4Sidebar = ({ role }) => {
  // const {
  //   token: { colorBgContainer },
  // } = theme.useToken();
  const navigate = useNavigate();

  return (
    <div>
      <div className="row background p-3">
        <div className="col-md-2">
          <a
            className="bg-white p-2 rounded-3 border border-bottom border-2 border-dark shadow"
            data-bs-toggle="offcanvas"
            href="#offcanvasExample"
            role="button"
            aria-controls="offcanvasExample"
            id="side"
          >
            <BiMenu style={{ color: "black", fontSize: 25 }} />
          </a>
        </div>
        <div className="col-md-8 text-center text-white">
          <h4>Welcome To Nashik Municipal Corporation, Nashik</h4>
        </div>
        <div className="col-md-2 text-end">
          <Button
            variant="contained"
            color="error"
            className=" fw-bold"
            onClick={() => logoutAccess("Shaskiy Samiti4")}
          >
            Logout
          </Button>
        </div>
      </div>
      <ul className="nav">
        <li className="nav-item" id="sidebarm">
          <div
            className="offcanvas offcanvas-start"
            style={{ width: "22rem" }}
            tabIndex={-1}
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel"
          >
            <div className="offcanvas-header background text-white text-center ">
              <p id="size" className="fs-4">
                Super Admin
              </p>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
            <div className="offcanvas-body ">
              <Sider width={300} style={{ height: "100vh" }}>
                <div style={{ height: "100%" }}>
                  <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={["dashboard"]}
                    onClick={({ key }) => {
                      if (key === "signout") {
                      } else {
                        navigate(key);
                      }
                    }}
                    items={[
                      // {
                      //   key: "manageuser",
                      //   icon: <CiUser className="fs-4" />,
                      //   label: (
                      //     <span style={{ fontSize: "18px" }}>Manage Users</span>
                      //   ),
                      // },
                      // {
                      //   key: "dashboard",
                      //   icon: <AiOutlineDashboard className="fs-4" />,
                      //   label: (
                      //     <span style={{ fontSize: "18px" }}>Dashboard</span>
                      //   ),
                      //   children: [
                      //     {
                      //       key: "dashboard",
                      //       icon: <AiFillHdd className="fs-4" />,
                      //       label: (
                      //         <span style={{ fontSize: "18px" }}>
                      //           Table Dashboard
                      //         </span>
                      //       ),
                      //     },
                      //     {
                      //       key: "graphdashboard",
                      //       icon: <AiFillHdd className="fs-4" />,
                      //       label: (
                      //         <span style={{ fontSize: "18px" }}>
                      //           Graph Dashboard
                      //         </span>
                      //       ),
                      //     },
                      //   ],
                      // },
                      // {
                      //   key: "addslider",
                      //   icon: <AiOutlineDashboard className="fs-4" />,
                      //   label: (
                      //     <span style={{ fontSize: "18px" }}>Add Slider</span>
                      //   ),
                      // },
                      {
                        key: "Scheme One",
                        icon: <FaBusinessTime className="fs-4" />,
                        label: (
                          <span style={{ fontSize: "18px" }}>Scheme one</span>
                        ),
                        children: [
                          {
                            key: "schemeonetotalapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Total Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemeoneapprovedapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Approved Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemeonerejectedapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Rejected Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemeonependingapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Pending Applications
                              </span>
                            ),
                          },
                        ],
                      },
                      {
                        key: "Scheme Two",
                        icon: <FaBusinessTime className="fs-4" />,
                        label: (
                          <span style={{ fontSize: "18px" }}>Scheme Two</span>
                        ),
                        children: [
                          {
                            key: "schemetwototalapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Total Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemetwoapprovedapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Approved Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemetworejectedapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Rejected Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemetwopendingapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Pending Applications
                              </span>
                            ),
                          },
                        ],
                      },
                      {
                        key: "Scheme Three",
                        icon: <FaBusinessTime className="fs-4" />,
                        label: (
                          <span style={{ fontSize: "18px" }}>Scheme Three</span>
                        ),
                        children: [
                          {
                            key: "schemethreetotalapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Total Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemethreeapprovedapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Approved Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemethreerejectedapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Rejected Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemethreependingapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Pending Applications
                              </span>
                            ),
                          },
                        ],
                      },
                      {
                        key: "Scheme Four",
                        icon: <FaBusinessTime className="fs-4" />,
                        label: (
                          <span style={{ fontSize: "18px" }}>Scheme Four</span>
                        ),
                        children: [
                          {
                            key: "schemefourtotalapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Total Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemeFourapprovedapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Approved Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemefourrejectedapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Rejected Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemefourpendingapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Pending Applications
                              </span>
                            ),
                          },
                        ],
                      },
                      {
                        key: "Scheme Five",
                        icon: <FaBusinessTime className="fs-4" />,
                        label: (
                          <span style={{ fontSize: "18px" }}>Scheme Five</span>
                        ),
                        children: [
                          {
                            key: "schemefivetotalapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Total Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemefiveapprovedapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Approved Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemefiverejectedapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Rejected Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemefivependingapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Pending Applications
                              </span>
                            ),
                          },
                        ],
                      },
                      {
                        key: "Scheme Six",
                        icon: <FaBusinessTime className="fs-4" />,
                        label: (
                          <span style={{ fontSize: "18px" }}>Scheme Six</span>
                        ),
                        children: [
                          {
                            key: "schemesixtotalapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Total Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemesixapprovedapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Approved Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemesixrejectedapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Rejected Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemesixpendingapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Pending Applications
                              </span>
                            ),
                          },
                        ],
                      },
                      {
                        key: "Scheme Seven",
                        icon: <FaBusinessTime className="fs-4" />,
                        label: (
                          <span style={{ fontSize: "18px" }}>Scheme Seven</span>
                        ),
                        children: [
                          {
                            key: "schemeseventotalapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Total Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemesevenapprovedapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Approved Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemesevenrejectedapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Rejected Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemesevenpendingapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Pending Applications
                              </span>
                            ),
                          },
                        ],
                      },
                      {
                        key: "Scheme Eight",
                        icon: <FaBusinessTime className="fs-4" />,
                        label: (
                          <span style={{ fontSize: "18px" }}>Scheme Eight</span>
                        ),
                        children: [
                          {
                            key: "schemeeighttotalapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Total Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemeeightapprovedapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Approved Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemeeightrejectedapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Rejected Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemeeightpendingapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Pending Applications
                              </span>
                            ),
                          },
                        ],
                      },
                      {
                        key: "Scheme Nine",
                        icon: <FaBusinessTime className="fs-4" />,
                        label: (
                          <span style={{ fontSize: "18px" }}>Scheme Nine</span>
                        ),
                        children: [
                          {
                            key: "schemeninetotalapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Total Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemenineapprovedapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Approved Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemeninerejectedapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Rejected Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemeninependingapplicationS",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Pending Applications
                              </span>
                            ),
                          },
                        ],
                      },
                      {
                        key: "Scheme Ten",
                        icon: <FaBusinessTime className="fs-4" />,
                        label: (
                          <span style={{ fontSize: "18px" }}>Scheme Ten</span>
                        ),
                        children: [
                          {
                            key: "schemetentotalapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Total Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemetenapprovedapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Approved Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemetenrejectedapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Rejected Applications
                              </span>
                            ),
                          },
                          {
                            key: "schemetenpendingapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: (
                              <span style={{ fontSize: "18px" }}>
                                Pending Applications
                              </span>
                            ),
                          },
                        ],
                      },
                      {
                        key: "contactentries",
                        icon: <AiFillPhone className="fs-4" />,
                        label: (
                          <span style={{ fontSize: "18px" }}>
                            Contact Enquiries
                          </span>
                        ),
                      },
                    ]}
                  />
                </div>
              </Sider>
            </div>
          </div>
        </li>
      </ul>

      <Content
        style={{
          // margin: "24px 16px",
          padding: 22,
          // minHeight: 280,
        }}
      >
        {/* <Outlet /> */}
      </Content>
    </div>
  );
};

export default ShaskiySamiti4Sidebar;
