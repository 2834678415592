import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { useSelectAccess } from "../store/stateFunctions";
import ShaskiySamitiSidebar from "../shaskiysamiti/ShaskiySamitiSidebar";
// import { Outlet } from "react-router-dom";

const ShaskiySamitiContainer = () => {
  document.title = "Shaskiy Samiti";
  const shaskiysamiti = useSelectAccess("Shaskiy Samiti");

  return (
    <>
      <div className="row ">
        <ShaskiySamitiSidebar role="Shaskiy Samiti" />
        <div className="container">
          {shaskiysamiti ? <Outlet /> : <Navigate to="/shaskiy-samiti" />}
          {/* <Outlet /> */}
        </div>
      </div>
    </>
  );
};

export default ShaskiySamitiContainer;
