import React from "react";
import SchemeEightTotalApplications from "../../Administration/SchemeEight/SchemeEightTotalApplications";

const ChananiEightTotalApplications = () => {
  return (
    <div>
      <SchemeEightTotalApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiEightTotalApplications;
