import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import AddissionalCommissionerSidebar from "../AdditionalCommissioner/AddissionalCommissionerSidebar";
import { useSelectAccess } from "../store/stateFunctions";
// import { Outlet } from "react-router-dom";

const AddissionalCommissionerContainer = () => {
  document.title = "Additional Commissioner";
  const addcom = useSelectAccess("Additional Commissioner");

  return (
    <>
      <div className="row ">
        <AddissionalCommissionerSidebar role="Additional Commissioner" />
        <div className="container">
          {addcom ? <Outlet /> : <Navigate to="/additional-commissioner" />}
        </div>
      </div>
    </>
  );
};

export default AddissionalCommissionerContainer;
