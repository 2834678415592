import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { useSelectAccess } from "../store/stateFunctions";
import Clerk2Sidebar from "../clerk2/Clerk2Sidebar";
// import { Outlet } from "react-router-dom";

const Clerk2Container = () => {
  document.title = "Clerk";
  const clerk2 = useSelectAccess("Clerk2");

  return (
    <>
      <div className="row ">
        <Clerk2Sidebar role="Clerk" />
        <div className="container">
          {clerk2 ? <Outlet /> : <Navigate to="/clerk" />}
          {/* <Outlet /> */}
        </div>
      </div>
    </>
  );
};

export default Clerk2Container;
