import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelectAccess } from "../store/stateFunctions";
import DeputyCommissionerSidebar from "../deputycommissioner/DeputyCommissionerSidebar";
// import { Outlet } from "react-router-dom";

const DeputyCommissionerContainer = () => {
  document.title = "Deputy Commissioner";
  const deputycomm = useSelectAccess("Deputy Commissioner");

  return (
    <>
      <div className="row ">
        <DeputyCommissionerSidebar role="Deputy Commissioner" />
        <div className="container">
          {deputycomm ? <Outlet /> : <Navigate to="/deputy-commissioner" />}
          {/* <Outlet /> */}
        </div>
      </div>
    </>
  );
};

export default DeputyCommissionerContainer;
