import React from "react";
import Dashboard from "../Administration/dashboard/TableDashboard/Dashboard";

const SuperAdminDashboard = () => {
  return (
    <>
        <Dashboard/>
        </>
  );
};

export default SuperAdminDashboard;
