import React, { useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Divider,
  Button,
  Container,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useTranslation } from "react-i18next";
// import brochure from "../../assets/documents/Scholarships and Vocational Training Assistance for Disabled Individuals.pdf";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelectAccess } from "../../store/stateFunctions";

const SchemeFourDetails = () => {
  const { t } = useTranslation();
  const user = useSelectAccess("User");
  const isLoggedIn = user && user;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleApplyNowClick = (e) => {
    if (!isLoggedIn) {
      e.preventDefault(); // Prevent navigation
      toast.info("Please log in to apply for the Scheme");
      navigate("/login");
    } else {
      setShow(true);
    }
  };
  return (
    <div
      className="container-fluid"
      maxWidth="lg"
      style={{ padding: "20px", backgroundColor: "#eaf6ff" }}
    >
      <Card style={{ marginBottom: "20px" }}>
        <CardContent>
          <Typography
            className="text-dark"
            variant="h5"
            style={{ fontWeight: "bold" }}
          >
            {t("schemeFiveDetails.title")}
          </Typography>
          <Divider style={{ margin: "10px 0" }} />
          <ul>
            <li>{t("schemeFiveDetails.point1")}</li>
            <li>{t("schemeFiveDetails.point2")}</li>
            <li> {t("schemeFiveDetails.point3")}</li>
            <li> {t("schemeFiveDetails.point4")}</li>
          </ul>
        </CardContent>
      </Card>
      <div>
        {/* <Button
                variant="contained"
                className="bluebutton"
                href={brochure} 
                download 
                style={{ marginRight: "10px" }}
              >
                {t("viewdetails.scheme1.downloadbrochure")}
              </Button> */}
        <NavLink to="/schemefive" onClick={handleApplyNowClick}>
          {" "}
          <Button variant="contained" className="redbutton" color="secondary">
            {t("schemeEightDetails.applyNow")}
          </Button>
        </NavLink>
      </div>
    </div>
  );
};

export default SchemeFourDetails;
