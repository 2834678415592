export const loginChananisamiti2 = (session) => {
  return {
    type: "LOGIN_CHANANI_SAMITY2",
    payload: session,
  };
};

export const logoutChananisamiti2 = () => {
  return {
    type: "LOGOUT_CHANANI_SAMITY2",
  };
};
