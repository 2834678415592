import React from "react";
import SchemeFiveApprovedApplications from "../../Administration/SchemeFive/SchemeFiveApprovedApplications";

const ChananiFiveApprovedApplications = () => {
  return (
    <div>
      <SchemeFiveApprovedApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiFiveApprovedApplications;
