import React from "react";
import SchemeFourRejectedApplications from "../../Administration/SchemeFour/SchemeFourRejectedApplications";

const ShaskiySamitiSchemeFourRejectedApplications = () => {
    return (
        <div>
            <SchemeFourRejectedApplications role="Shaskiy Samiti" />
        </div>
    );
};

export default ShaskiySamitiSchemeFourRejectedApplications;
