import React from "react";
import Dashboard from "../Administration/dashboard/TableDashboard/Dashboard";

const ClerkDashboard = () => {
  return (
    <>
      <h1><Dashboard/></h1>
    </>
  );
};

export default ClerkDashboard;
