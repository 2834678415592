import React from "react";
import ManageUser from "../Administration/ManageUser";

const SuperAdminManageUser = () => {
  return (
    <div>
      <ManageUser />
    </div>
  );
};

export default SuperAdminManageUser;
