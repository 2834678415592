import React from "react";
import SchemeFourRejectedApplications from "../../Administration/SchemeFour/SchemeFourRejectedApplications";

const ChananiFourRejectedApplications = () => {
  return (
    <div>
      <SchemeFourRejectedApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiFourRejectedApplications;
