import React from "react";
import SchemeSixapprovedapplications from "../../Administration/SchemeSix/SchemeSixapprovedapplications";

const ChananiSixApprovedApplications = () => {
  return (
    <div>
      <SchemeSixapprovedapplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiSixApprovedApplications;
