export const loginShaskiySamiti5 = (session) => {
  return {
    type: "LOGIN_SHASKIY_SAMITI5",
    payload: session,
  };
};

export const logoutShaskiySamiti5 = () => {
  return {
    type: "LOGOUT_SHASKIY_SAMITI5",
  };
};
