import React, { useContext, useState } from "react";
import { TextField } from "@mui/material";
import { AxiosError } from "axios";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { server } from "../../common";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import configContext from "../../configContext/configContext";
// import { useSelectAccess } from "../../../store/stateFunctions";

const initialValues = {
  email: "",
  newPassword: "",
};

const NewPassword = () => {
  // const user = useSelectAccess("Student");
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const { setProgress } = useContext(configContext);

  const { values, handleChange, handleSubmit, errors } = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Enter a valid email")
        .required("Please Enter Your Email"),
      newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
        )
        .required("Please Enter Your password"),
    }),
    onSubmit: (values, action) => {
      setProcessing(true);
      setProgress(10);
      console.log(values);
      server
        .post(`/user/resetnewpassword`, values, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          console.log("api response", response.data);
          if (response.status === 200 || response.status === 201) {
            if (response.data) {
              //   dispatch(loginStudent({ authToken: response.data.token }));
              toast.success("Password Reset Successfully");
              navigate("/loginstudent");
              setProgress(100);
            }
            setProcessing(false);
          }
        })
        .catch(function (error) {
          if (error instanceof AxiosError && error.response?.data?.message)
            toast.error(error.response.data.message);
          else if (error.response?.data?.error) {
            toast.error(error.response.data.error);
          } else toast.error("Failed to connect to server");
          setProcessing(false);
          setProgress(100);
        });
    },
  });

  return (
    <>
      <div>
        <section className="vh-100">
          <div className="container py-3 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                <div className="card shadow-2-strong">
                  <div className="card-body p-4 text-center">
                    {/* <h3>Yuvasarthi</h3> */}
                    <h3 className="mb-2">Enter New Password</h3>
                    <form onSubmit={handleSubmit}>
                      <div className="form-outline mb-2">
                        <TextField
                          name="email"
                          margin="dense"
                          value={values.email}
                          onChange={handleChange}
                          type="email"
                          placeholder="Enter You Email Id"
                          variant="outlined"
                          label=" Email Id"
                          fullWidth
                          required
                        ></TextField>
                      </div>
                      <div className="form-outline mb-2">
                        <TextField
                          name="newPassword"
                          margin="dense"
                          value={values.newPassword}
                          onChange={handleChange}
                          type="password"
                          placeholder="Enter New Password"
                          variant="outlined"
                          label="New Password"
                          fullWidth
                          required
                        ></TextField>
                        {errors.newPassword ? (
                          <p className="text-danger">{errors.newPassword}</p>
                        ) : null}
                      </div>
                      <button
                        type="submit"
                        className="btn bluebutton text-white w-100 mb-2"
                      >
                        Save
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default NewPassword;
