export const loginShaskiySamity = (session) => {
    return {
        type: "LOGIN_SHASKIY_SAMITI",
        payload: session,
    };
};

export const logoutShaskiySamity = () => {
    return {
        type: "LOGOUT_SHASKIY_SAMITI",
    };
};
