import React from "react";
import SchemeThreeRejectedApplications from "../../Administration/SchemeThree/SchemeThreeRejectedApplications";

const ChananiThreeRejectedApplications = () => {
  return (
    <div>
      <SchemeThreeRejectedApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiThreeRejectedApplications;
