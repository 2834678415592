import React, { useState } from "react";
import { Container, Row, Col, Table, Card, Form, Pagination } from "react-bootstrap";
import "./dashboard.css";

const Dashboard = () => {
  const schemes = [
    { name: "Scheme 1", division: "Division A", total: 100, approved: 80, rejected: 10, pending: 10 },
    { name: "Scheme 2", division: "Division B", total: 200, approved: 150, rejected: 30, pending: 20 },
    { name: "Scheme 3", division: "Division A", total: 300, approved: 200, rejected: 50, pending: 50 },
    { name: "Scheme 4", division: "Division C", total: 120, approved: 100, rejected: 10, pending: 10 },
    { name: "Scheme 5", division: "Division B", total: 180, approved: 160, rejected: 10, pending: 10 },
    { name: "Scheme 6", division: "Division A", total: 250, approved: 220, rejected: 10, pending: 20 },
    { name: "Scheme 7", division: "Division C", total: 400, approved: 350, rejected: 20, pending: 30 },
    { name: "Scheme 8", division: "Division B", total: 130, approved: 110, rejected: 10, pending: 10 },
    { name: "Scheme 9", division: "Division A", total: 90, approved: 70, rejected: 10, pending: 10 },
    { name: "Scheme 10", division: "Division C", total: 220, approved: 190, rejected: 20, pending: 10 },
    { name: "Scheme 11", division: "Division A", total: 100, approved: 80, rejected: 10, pending: 10 },
    { name: "Scheme 12", division: "Division B", total: 200, approved: 150, rejected: 30, pending: 20 },
    // Add more schemes as needed...
  ];

  const [selectedDivision, setSelectedDivision] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Filter schemes based on selected division
  const filteredSchemes =
    selectedDivision === "All"
      ? schemes
      : schemes.filter((scheme) => scheme.division === selectedDivision);

  // Calculate total summary stats based on filtered schemes
  const totalApplications = filteredSchemes.reduce((acc, scheme) => acc + scheme.total, 0);
  const totalApproved = filteredSchemes.reduce((acc, scheme) => acc + scheme.approved, 0);
  const totalRejected = filteredSchemes.reduce((acc, scheme) => acc + scheme.rejected, 0);
  const totalPending = filteredSchemes.reduce((acc, scheme) => acc + scheme.pending, 0);

  // Pagination logic
  const totalPages = Math.ceil(filteredSchemes.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedSchemes = filteredSchemes.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Container>
      <Row className="mt-4 mb-3">
        <Col md={3}>
          <Form.Select
            aria-label="Division filter"
            value={selectedDivision}
            onChange={(e) => {
              setSelectedDivision(e.target.value);
              setCurrentPage(1); // Reset to first page on division change
            }}
          >
            <option value="All">All Divisions</option>
            <option value="Division A">Division A</option>
            <option value="Division B">Division B</option>
            <option value="Division C">Division C</option>
          </Form.Select>
        </Col>
      </Row>

      {/* Summary Cards */}
      <Row className="mb-4">
        <Col md={3}>
          <Card className="summary-card">
            <Card.Body>
              <Card.Title>Total Applications</Card.Title>
              <Card.Text>{totalApplications}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="summary-card">
            <Card.Body>
              <Card.Title>Approved</Card.Title>
              <Card.Text className="text-success">{totalApproved}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="summary-card">
            <Card.Body>
              <Card.Title>Rejected</Card.Title>
              <Card.Text className="text-danger">{totalRejected}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="summary-card">
            <Card.Body>
              <Card.Title>Pending</Card.Title>
              <Card.Text className="text-warning">{totalPending}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Table */}
      <Table striped bordered hover responsive style={{ fontSize: "20px" }}>
        <thead>
          <tr>
            <th>Scheme Name</th>
            <th>Division</th>
            <th>Total Applications</th>
            <th>Approved</th>
            <th>Rejected</th>
            <th>Pending</th>
          </tr>
        </thead>
        <tbody>
          {paginatedSchemes.map((scheme, index) => (
            <tr key={index}>
              <td>{scheme.name}</td>
              <td>{scheme.division}</td>
              <td>{scheme.total}</td>
              <td className="text-success">{scheme.approved}</td>
              <td className="text-danger">{scheme.rejected}</td>
              <td className="text-warning">{scheme.pending}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Pagination */}
      <Pagination className="justify-content-center">
        {Array.from({ length: totalPages }, (_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </Container>
  );
};

export default Dashboard;
