import { removeChananisamiti3UserToken } from "../../common";
import { chananisamitiTokenName3 } from "../../config";

// const getStudentFromLocalStorage = () => {
//   try {
//     const studentJSON = getStudentTokenFromLocal();
//     if (studentJSON) {
//       const student = JSON.parse(studentJSON);
//       if (student.authToken && student.loggedInTime) {
//         return student;
//       } else {
//         removeStudentTokenFromLocal();
//         return null;
//       }
//     }
//   } catch (e) {
//     removeStudentTokenFromLocal();
//     return null;
//   }
// };

const getChananisamiti1FromLocalStorage = () => {
  try {
    const chananisamiti2 = JSON.parse(
      sessionStorage.getItem(chananisamitiTokenName3)
    );
    if (
      chananisamiti2 &&
      chananisamiti2.authToken &&
      chananisamiti2.loggedInTime
    ) {
      return chananisamiti2;
    } else {
      removeChananisamiti3UserToken();
      return null;
    }
  } catch (error) {
    removeChananisamiti3UserToken();
    return null;
  }
};

const getInitial = () => {
  return getChananisamiti1FromLocalStorage();
};

const chananiSamiti3Reducer = (state = getInitial(), action) => {
  switch (action.type) {
    case "LOGIN_CHANANI_SAMITY3":
      const { loggedInTime, authToken } = action.payload;
      const newState = {
        loggedInTime: loggedInTime || Date.now(),
        authToken: authToken,
      };
      sessionStorage.setItem(chananisamitiTokenName3, JSON.stringify(newState));
      return newState;
    case "LOGOUT_CHANANI_SAMITY3":
      removeChananisamiti3UserToken();
      return null;
    default:
      return state;
  }
};

export default chananiSamiti3Reducer;
