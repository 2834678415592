import React from "react";
import Dashboard from "../Administration/dashboard/TableDashboard/Dashboard";

const ChananiSamiti4Dashboard = () => {
  return (
    <>
      <h1><Dashboard/></h1>
    </>
  );
};

export default ChananiSamiti4Dashboard;
