import React from "react";
import Dashboard from "../Administration/dashboard/TableDashboard/Dashboard";

const ShaskiySamiti5Dashboard = () => {
  return (
    <>
      <h1><Dashboard/></h1>
    </>
  );
};

export default ShaskiySamiti5Dashboard;
