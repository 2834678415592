export const loginChananisamiti3 = (session) => {
  return {
    type: "LOGIN_CHANANI_SAMITY3",
    payload: session,
  };
};

export const logoutChananisamiti3 = () => {
  return {
    type: "LOGOUT_CHANANI_SAMITY3",
  };
};
