import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { useSelectAccess } from "../store/stateFunctions";
import ShaskiySamiti4Sidebar from "../shaskiysamiti4/ShaskiySamiti4Sidebar";
// import { Outlet } from "react-router-dom";

const ShaskiySamiti4Container = () => {
  document.title = "Shaskiy Samiti";
  const shaskiysamiti4 = useSelectAccess("Shaskiy Samiti4");

  return (
    <>
      <div className="row ">
        <ShaskiySamiti4Sidebar role="Shaskiy Samiti4" />
        <div className="container">
          {shaskiysamiti4 ? <Outlet /> : <Navigate to="/shaskiy-samiti4" />}
          {/* <Outlet /> */}
        </div>
      </div>
    </>
  );
};

export default ShaskiySamiti4Container;
