import { removeClerk1UserToken } from "../../common";
import { clerkUserTokenName1 } from "../../config";

const getTrafficFromLocalStorage = () => {
  try {
    const clerk = JSON.parse(sessionStorage.getItem(clerkUserTokenName1));
    if (clerk && clerk.authToken && clerk.loggedInTime) {
      return clerk;
    } else {
      removeClerk1UserToken();
      return null;
    }
  } catch (error) {
    removeClerk1UserToken();
    return null;
  }
};

const getInitial = () => {
  return getTrafficFromLocalStorage();
};

const clerk1UserReducers = (state = getInitial(), action) => {
  switch (action.type) {
    case "LOGIN_CLERK1":
      const { loggedInTime, authToken } = action.payload;
      const newState = {
        loggedInTime: loggedInTime || Date.now(),
        authToken: authToken,
      };
      sessionStorage.setItem(clerkUserTokenName1, JSON.stringify(newState));
      return newState;
    case "LOGOUT_CLERK1":
      removeClerk1UserToken();
      return null;
    default:
      return state;
  }
};

export default clerk1UserReducers;
