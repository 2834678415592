import React from "react";
import SchemeTwoRejectedApplications from "../../Administration/SchemeTwo/SchemeTwoRejectedApplications";

const ChananiTwoRejectedApplications = () => {
  return (
    <div>
      <SchemeTwoRejectedApplications role="Chanani Samiti" />
    </div>
  );
};

export default ChananiTwoRejectedApplications;
